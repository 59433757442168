import {
  AspectRatio,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from '@chakra-ui/react';
import ArrowLeftIcon from '@shared/assets/icons/round-arrow-left.svg';
import ArrowRightIcon from '@shared/assets/icons/round-arrow-right.svg';
import { useIsMobile, useIsTablet } from '@shared/utils/screen';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  images: Website.Gallery[];
  defaultImageId: string;
}

export const Carousel = ({
  isOpen,
  onClose,
  images = [],
  defaultImageId,
}: Props) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    if (defaultImageId) {
      setSelected(defaultImageId);
    }
  }, [defaultImageId]);

  const selectedImg = useMemo(() => {
    return images.find((img) => img.id === selected) ?? images[0];
  }, [images, selected]);

  const onSelectImage = useCallback(
    (id: string) => () => {
      setSelected(id);
    },
    [],
  );

  const onPrevClick = useCallback(() => {
    const selectedIndex = images.findIndex((img) => img.id === selected);
    switch (selectedIndex) {
      case 0:
        setSelected(images[images.length - 1].id);
        break;
      case -1:
        setSelected(images[0].id);
      default:
        setSelected(images[selectedIndex - 1].id);
        break;
    }
  }, [images, selected]);

  const onNextClick = useCallback(() => {
    const selectedIndex = images.findIndex((img) => img.id === selected);
    switch (selectedIndex) {
      case images.length - 1:
      case -1:
        setSelected(images[0].id);
        break;
      default:
        setSelected(images[selectedIndex + 1].id);
        break;
    }
  }, [images, selected]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" autoFocus={false}>
      <ModalContent bgColor="rgba(0,0,0,0.8)" h="100dvh" overflow="hidden">
        <ModalCloseButton color="white" size="lg" zIndex={1} />
        <ModalBody h="100%">
          <Flex
            position="absolute"
            w="calc(100% - 48px)"
            h={`calc(100% - 16px - ${isMobile ? '120px' : '0px'})`}
            align="center"
          >
            <IconButton
              aria-label="previous"
              variant="unstyled"
              icon={<Image src={ArrowLeftIcon} />}
              onClick={onPrevClick}
              position={isMobile ? 'absolute' : 'static'}
              bottom={0}
            />
            <AspectRatio
              ratio={16 / 9}
              flex={1}
              mx={isMobile ? 0 : isTablet ? '48px' : '96px'}
            >
              <Image src={selectedImg.image} alt="full-picture" />
            </AspectRatio>
            <IconButton
              aria-label="next"
              variant="unstyled"
              icon={<Image src={ArrowRightIcon} />}
              onClick={onNextClick}
              position={isMobile ? 'absolute' : 'static'}
              bottom={0}
              right={0}
            />
          </Flex>
          <Center position="absolute" w="calc(100% - 48px)" bottom={'8px'}>
            <HStack overflowX="auto" spacing={0}>
              {images.map((img, index) => (
                <Image
                  key={img.id}
                  src={img.image}
                  alt={`pic-${index}`}
                  objectFit="cover"
                  cursor="pointer"
                  w="120px"
                  h="64px"
                  minW="120px"
                  borderWidth="4px"
                  borderStyle="solid"
                  borderColor={
                    selected === img.id ? 'primary.400' : 'transparent'
                  }
                  borderRadius={selected === img.id ? '4px' : 0}
                  onClick={onSelectImage(img.id)}
                />
              ))}
            </HStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
