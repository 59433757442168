import { useToast } from '@chakra-ui/react';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import { VenuesApi } from 'api/VenuesApi';
import debounce from 'lodash.debounce';
import get from 'lodash/get';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  getBasketUpsells,
  selectBasketUpsells,
  setUpsellsModalOpen,
} from 'store/slices/basketUpsells';
import {
  clearDepartments,
  filterDepartments,
  getDepartments,
  selectDepartments,
} from 'store/slices/departments';
import {
  clearBasket,
  selectProductModal,
  setIds,
} from 'store/slices/productModal';
import { getAvailability } from 'store/slices/services';

const useProductMenu = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();

  const { error, isUpsellsModalOpen } = useAppSelector(selectBasketUpsells);

  const {
    isLoading,
    departments,
    positions,
    error: departmentsError,
  } = useAppSelector(selectDepartments);

  const { isOpen } = useAppSelector(selectProductModal);

  const { isLoading: userLoading, user } = useAppSelector(selectAuth);

  const serviceName = storageSession.get(StorageKeys.SERVICE_NAME);
  const currency = storage.get(StorageKeys.CURRENCY);

  const { venueId, serviceId } = useParams<{
    venueId: string;
    serviceId: string;
  }>();

  useLayoutEffect(() => {
    // prevent too double requests
    if (userLoading) return;
    let interval: NodeJS.Timer;

    const prevVenueId = storageSession.get(StorageKeys.VENUE_ID);
    const prevServiceId = storageSession.get(StorageKeys.SERVICE_ID);
    if (prevServiceId !== serviceId || prevVenueId !== venueId) {
      dispatch(clearBasket());
    }

    const getRequests = ({
      serviceId,
      venueId,
      serviceType,
    }: {
      serviceId: string;
      venueId: string;
      serviceType: string | number;
    }) => {
      dispatch(
        getDepartments({
          venueId,
          serviceId,
          serviceType,
        }),
      );
      dispatch(setIds({ venueId, serviceId }));

      dispatch(getBasketUpsells(serviceId));

      dispatch(getAvailability(serviceId));
      interval = setInterval(() => {
        dispatch(getAvailability(serviceId));
      }, 60 * 1000);
    };

    if (serviceId && venueId) {
      VenuesApi.getVenue(venueId, 'services,location')
        .then((res) => {
          const currentService = res.services.find(
            ({ id }) => id === serviceId,
          );
          const serviceType = get(currentService, 'service_type', '');
          storageSession.set(
            StorageKeys.SERVICE_NAME,
            serviceType === 1 ? 'Table' : get(currentService, 'name', 'Menu'),
          );
          storage.set(
            StorageKeys.CURRENCY,
            get(res, ['location', 'currency'], ''),
          );
          storageSession.set(StorageKeys.SERVICE_TYPE, String(serviceType));
          getRequests({ serviceId, venueId, serviceType });
        })
        .catch((error: Error) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    }

    return () => {
      clearInterval(interval);
      dispatch(clearDepartments());
    };
  }, [user?.id]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    } else if (departmentsError) {
      toast({
        description: departmentsError.message || String(departmentsError),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error, departmentsError]);

  const menuTabsItems = useMemo(() => {
    return (departments || []).map((d) => d.name);
  }, [departments]);

  const handleSearch = debounce(
    useCallback((value: string) => {
      dispatch(filterDepartments(value));
    }, []),
    500,
  );

  return {
    actions: { handleSearch, setUpsellsModalOpen },
    data: {
      isUpsellsModalOpen: isUpsellsModalOpen && !isOpen,
      isLoading,
      serviceName,
      menuTabsItems,
      currency,
      positions,
    },
  };
};

export default useProductMenu;
