export const CLUSTERER_COLORS = {
  order: '#1672EF',
  origin: '#D64320',
  carrier: '#008D26',
  destination: '#DF3245',
  event: '#EDAE09',
};

export const CLUSTERER_LABELS = {
  order: 'Orders',
  origin: 'Venues',
  carrier: 'Drivers',
  destination: 'Delivery Addresses',
  event: 'Events',
};
