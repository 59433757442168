import { BaseApiService } from './BaseApiService';

export class BasketApi {
  public static async getBasketUpsells(service: string) {
    try {
      const firstRes = await BaseApiService.get<{ menu_file: string }>({
        path: `basket/upsells/?service=${service}`,
        authRequired: false,
        version: 'v2',
      });
      return await BaseApiService.get<Product.RootObject[]>({
        path: '',
        customUrl: firstRes.menu_file,
        authRequired: false,
      });
    } catch (error) {
      throw error;
    }
  }
}
