import { createSlice } from '@reduxjs/toolkit';
import { WebsitesApi } from 'api/WebsitesApi';
import { AppThunk, RootState } from 'store';
import { getWebsiteId } from 'utils/getEnvData';

type WebsiteState = {
  isLoading: boolean;
  error: Error | null;
  websiteData: Website.RootObject | null;
};

const initialState: WebsiteState = {
  isLoading: true,
  error: null,
  websiteData: null,
};

export const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setWebsiteRequest: (state) => {
      state.isLoading = true;
      state.error = null;
      state.websiteData = null;
    },
    setWebsiteSuccess: (state, action: { payload: Website.RootObject }) => {
      state.websiteData = action.payload;
    },
  },
});

const { setLoading, setError, setWebsiteRequest, setWebsiteSuccess } =
  websiteSlice.actions;

export const getWebsiteData = (): AppThunk => async (dispatch) => {
  dispatch(setWebsiteRequest());
  try {
    const res = await WebsitesApi.getWebsite(getWebsiteId());
    dispatch(setWebsiteSuccess(res));
  } catch (error: unknown) {
    dispatch(setError(error as Error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const selectWebsite = (state: RootState) => state.website;

export default websiteSlice.reducer;
