import dialCodes from '../assets/DialCodes.json';

export const matchingDialCode = (phoneNumber: string): string =>
  dialCodes.reduce(
    (match, { dial_code }) => {
      const matches = phoneNumber.match(new RegExp(`\\${dial_code}`, 'g'));
      const occurrences = matches ? matches[0].length : 0;
      return occurrences > match.occurrences
        ? { dial_code, occurrences }
        : match;
    },
    { dial_code: '', occurrences: 0 },
  ).dial_code;

export const searchFromListOfTexts = (
  searchText: string,
  texts: string[],
): boolean => {
  const lowerCaseSearchText = searchText.toLowerCase();
  return texts.some((text) => text.toLowerCase().includes(lowerCaseSearchText));
};
