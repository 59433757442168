import { BaseApiService } from './BaseApiService';

export class DepartmentsApi {
  public static async getDepartmentsProducts({
    venueId,
    serviceId,
  }: Departments.DepartmentsProductsParams) {
    try {
      const firstRes = await BaseApiService.get<{ menu_file: string }>({
        path: `departments/products/?venue=${venueId}&service=${serviceId}`,
        authRequired: false,
        version: 'v2',
      });

      return await BaseApiService.get<Departments.Response>({
        path: '',
        authRequired: false,
        customUrl: firstRes.menu_file,
      });
    } catch (error) {
      throw error;
    }
  }
}
