import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import { useIsMobile, useIsTablet } from '@shared/utils/screen';
import UserButton from 'components/UserButton';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { AuthModal } from 'pages/AuthModal';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { Suspense } from 'react';
import { isLandingMode } from 'utils/document';

import CollectionSection from './CollectionSection';
import DeliveryDetailsSection from './DeliveryDetailsSection';
import DeliverySection from './DeliverySection';
import useWidget from './hooks';
import OtherServiceSection from './OtherServiceSection';
import ServiceSection from './ServiceSection';
import TableSection from './TableSection';

export const Widget = () => {
  const {
    actions: {
      setNewAddress,
      setSelectedServiceType,
      setShowServicesSection,
      setShowDeliveryDetailsSection,
      setFlatNo,
      setFlatNoError,
      setEircode,
      setEircodeError,
      handleSelectDeliveryVenue,
      handleSelectTableVenue,
      handleSelectCollectionVenue,
      handleSelectOtherServiceVenue,
      handleSetStreetNumber,
    },
    data: {
      logo,
      name,
      title,
      bgImage,
      availableServices,
      selectedServiceType,
      showServicesSection,
      showDeliveryDetailsSection,
      newAddress,
      flatNo,
      flatNoError,
      eircode,
      eircodeError,
      streetNumber,
    },
  } = useWidget();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const showDeliverySection1 =
    selectedServiceType === 0 && !newAddress && !showDeliveryDetailsSection;

  const showDeliverySection2 =
    selectedServiceType === 0 &&
    Boolean(newAddress) &&
    showDeliveryDetailsSection;

  const showTableSection = selectedServiceType === 1;

  const showCollectionSection = selectedServiceType === 2;

  const showOtherServiceSection = selectedServiceType === 4;

  return (
    <Suspense>
      <WidgetWrapper>
        <Flex
          minH={`calc(100dvh - ${MENU_HEIGHT}px)`}
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          bgColor="#fff"
        >
          <Box
            width={isMobile ? '100%' : isTablet ? '44%' : '56%'}
            bgImage={bgImage || ''}
            bgSize="cover"
            bgPosition="center"
            minHeight={isMobile ? 'auto' : '300px'}
            flexGrow="1"
            bgColor="gray.200"
            position="relative"
          >
            {!isLandingMode && logo && (
              <Image
                src={logo}
                position="absolute"
                top="40px"
                left="40px"
                maxWidth="80px"
                alt={name}
              />
            )}
          </Box>

          <Box
            width={isMobile ? '100%' : isTablet ? '56%' : '44%'}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding={isTablet ? '64px 16px 8px' : '64px'}
            position="relative"
            top={isMobile ? '-16px' : '0'}
            borderTopLeftRadius={isMobile ? '16px' : '0'}
            borderTopRightRadius={isMobile ? '16px' : '0'}
            bgColor="#fff"
          >
            <Box
              position="absolute"
              top={isMobile ? '16px' : '26px'}
              right={isTablet ? '16px' : '24px'}
            >
              <UserButton />
            </Box>

            <Heading
              as="h1"
              fontSize={isMobile ? '24px' : '28px'}
              lineHeight={isMobile ? '28px' : '32px'}
              mb={availableServices.length > 1 ? '40px' : '0'}
            >
              {title}
            </Heading>

            {showServicesSection && (
              <ServiceSection
                availableServices={availableServices}
                selectedServiceType={selectedServiceType}
                setSelectedServiceType={setSelectedServiceType}
              />
            )}

            {showDeliverySection1 && (
              <DeliverySection
                setNewAddress={setNewAddress}
                setShowServicesSection={setShowServicesSection}
                setShowDeliveryDetailsSection={setShowDeliveryDetailsSection}
                setEircode={setEircode}
                setEircodeError={setEircodeError}
                setFlatNo={setFlatNo}
                setFlatNoError={setFlatNoError}
                handleSetStreetNumber={handleSetStreetNumber}
              />
            )}

            {showDeliverySection2 && (
              <DeliveryDetailsSection
                newAddress={newAddress}
                setNewAddress={setNewAddress}
                flatNo={flatNo}
                eircode={eircode}
                setFlatNo={setFlatNo}
                setEircode={setEircode}
                flatNoError={flatNoError}
                setFlatNoError={setFlatNoError}
                eircodeError={eircodeError}
                setEircodeError={setEircodeError}
                handleSelectDeliveryVenue={handleSelectDeliveryVenue}
                setShowDeliveryDetailsSection={setShowDeliveryDetailsSection}
                setShowServicesSection={setShowServicesSection}
                handleSetStreetNumber={handleSetStreetNumber}
                streetNumber={streetNumber}
              />
            )}

            {showTableSection && (
              <Box paddingBottom={isMobile ? '42px' : '0'}>
                <TableSection handleSelectTableVenue={handleSelectTableVenue} />
              </Box>
            )}

            {showCollectionSection && (
              <Box paddingBottom={isMobile ? '42px' : '0'}>
                <CollectionSection
                  handleSelectCollectionVenue={handleSelectCollectionVenue}
                />
              </Box>
            )}

            {showOtherServiceSection && (
              <Box paddingBottom={isMobile ? '42px' : '0'}>
                <OtherServiceSection
                  handleSelectOtherServiceVenue={handleSelectOtherServiceVenue}
                />
              </Box>
            )}
          </Box>
        </Flex>

        <AuthModal />
      </WidgetWrapper>
    </Suspense>
  );
};
