import { Heading, Text } from '@chakra-ui/react';
import { Footer } from 'components/LandingSections/Footer';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

export const TermsConditions = () => {
  const { websiteData } = useAppSelector(selectWebsite);

  const name = websiteData?.name;
  const domain = websiteData?.domain;

  return (
    <>
      <WidgetWrapper withContainer containerWidth="xl">
        <Heading
          as="h1"
          fontSize="28px"
          lineHeight="36px"
          textAlign="center"
          mt="16px"
          mb="36px"
        >
          Terms and Conditions
        </Heading>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Website & applications terms and conditions
        </Heading>
        <Text mb="16px">
          Welcome to the {domain} website and mobile applications (together the
          “Websites and Apps”). This page tells you the terms and conditions on
          which {name} branches supply any of the products (the “Products”)
          listed on the Websites and Apps to you. Please read these terms and
          conditions carefully before ordering any Products from the Websites
          and Apps.
        </Text>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Acceptance of Terms
        </Heading>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          By using the online ordering system on our website, you agree to these
          terms and conditions. If you do not agree to these terms, you should
          not use the system.
        </Heading>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Placing an Order
        </Heading>

        <Text mb="16px">
          When you place an order through the {name} website or Apps, you are
          making an offer to purchase products or services. We reserve the right
          to accept or decline your offer for any reason, including but not
          limited to product availability, errors in pricing, or failure to meet
          credit card verification standards. The agreement will be between you
          and {name} Outlet you chose and will only be formed when you have been
          presented with the Confirmation Screen.
        </Text>

        <Text mb="16px">
          You should also receive a confirmation email. Please make sure the
          email address you provide is correct and your mailbox is in proper
          working order, as all correspondence regarding your order is sent to
          this address.
        </Text>

        <Text mb="16px">
          Incorrect personal details may lead to problems or delays in delivery,
          so please ensure that you have included your correct address
          (including postcode), email address and contact telephone number when
          ordering.
        </Text>

        <Text mb="16px">Minimum delivery spend may apply.</Text>

        <Text mb="16px">Payment</Text>

        <Text mb="16px">
          1. All prices on our website are in the currency specified on the
          website. We accept payment by credit card. Payment must be made in
          full at the time of the order.
        </Text>

        <Text mb="16px">Cancellation and Refunds</Text>

        <Text mb="16px">
          2. If you need to cancel or modify an order, please contact us as soon
          as possible. We may not be able to cancel or modify an order once it
          has been processed. If we are unable to fulfil your order, we will
          issue a refund.
        </Text>

        <Text mb="16px">Delivery</Text>

        <Text mb="16px">
          3. We will make every effort to deliver your order in a timely manner.
          However, we are not responsible for any delays that may occur. We will
          not be liable for any damages or losses resulting from a delay in
          delivery.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Service availability
        </Heading>

        <Text mb="16px">
          We do not accept orders from individuals who are outside of our
          delivery radius. Store opening hours will vary depending on local
          trading conditions and the availability of late openings. To find a
          store and view its opening hours, please click on the
          &#8216;Locations&#8217; button for a list of branches. We reserve the
          right to cease online ordering at any time
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Products
        </Heading>

        <Text mb="16px">
          All Products are subject to availability. In most cases, your local
          {name} Branch / Franchisee will offer an alternative for any
          out-of-stock item.
        </Text>

        <Text mb="16px">
          The Stores are busy working environments and there is a risk of
          cross-contamination between toppings. If you have an allergy we kindly
          ask that you do not order online. In this scenario, please telephone
          your chosen Store and inform your order-taker at the Store directly in
          full of your allergies.
        </Text>

        <Text mb="16px">
          Great care is taken in the preparation of all the Products, however,
          we cannot guarantee that all bones from meat products have been
          removed and some may remain.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Availability and delivery
        </Heading>

        <Text mb="16px">
          Our goal is to provide the best delivery service in the world which
          includes maintaining our excellent reputation for on-time delivery
          (i.e. delivering your food at the time we quote). Unfortunately things
          do not always go to plan and factors, such as weather and traffic
          conditions, may occasionally prevent us from achieving this.
        </Text>

        <Text mb="16px">
          We will do our best to fulfill your order in accordance with the
          delivery date and time set out in the confirmation email or, if
          delivery is specified as ASAP, within a reasonable time from the time
          the confirmation email was sent taking into account the volume of
          orders and circumstances facing the {name} branch / Franchisee at the
          time.
        </Text>

        <Text mb="16px">
          We will inform you if we become aware of an unexpected delay.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Cancellation
        </Heading>

        <Text mb="16px">
          You have the right to cancel an order by telephoning the relevant
          Store up until either:
        </Text>

        <Text mb="16px">
          a. in the case of any advance order (which is/are order(s) placed more
          than 1 day before a requested delivery date), up to 1 day before the
          order is due to be delivered; or
        </Text>

        <Text mb="16px">
          b. in the case of same day orders and deliveries, within a reasonable
          time (usually just a matter of minutes) and, in any event, before any
          food has been used to start preparing the order.
        </Text>

        <Text mb="16px">
          Where an order is cancelled after food has been used to start
          preparing the order, the {name} Branch / Franchisee may, at its sole
          discretion, classify this as a “Bad Order”. Bad Orders will be charged
          to the customer and no refund will be due to the customer.
        </Text>

        <Text mb="16px">
          An order may be subsequently cancelled by a {name} Branch /
          Franchisee, at its sole discretion, after you have been presented with
          the Confirmation Screen. {name} and the {name} Franchisee reserves the
          right to cancel any order, before or after acceptance, and will notify
          you immediately of any such cancellation.
        </Text>

        <Text mb="16px">
          You will not be charged for cancelled orders made in accordance with
          these cancellation provisions. Any payment made prior to an order
          being cancelled by {name} or a {name} Franchisee, in accordance with
          the above, will usually be reimbursed using the same method originally
          used by you to pay for your purchase.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Price and payment
        </Heading>

        <Text mb="16px">
          The price of any Products will be as quoted on our Websites and Apps
          and may vary from time to time, except in cases of obvious error.
          Prices include VAT. Prices will vary between Stores.
        </Text>

        <Text mb="16px">
          Prices are liable to change at any time, but changes will not affect
          orders placed where you have already checked-out and been presented
          with the Confirmation Screen (unless, due to a technical error, you
          have been overcharged for some reason). Despite our best efforts, some
          of the Products listed on our Websites and Apps may be incorrectly
          priced (either due to human or technical error). The Store will
          normally verify prices as part of the order process and the
          Store&#8217;s decision in relation to pricing is final and binding.
        </Text>

        <Text mb="16px">
          Payment for all Products can be by credit or debit card at the point
          of ordering or in cash at the point of collection. We accept payment
          via Maestro, Visa and MasterCard. If you are placing an order from the
          Republic of Ireland we also accept payment via Laser. We will charge
          your credit or debit card at the point of order.
        </Text>

        <Text mb="16px">
          Should you choose to pay by credit or debit card, once your order has
          been confirmed your credit or debit card will have been authorised and
          the amount marked for payment. Payment is made directly to {name} and
          is subsequently passed on by {name} to the appropriate {name}
          Franchisee.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Our liability
        </Heading>

        <Text mb="16px">
          If we and/or the {name} Franchisee fail to comply with these terms and
          conditions we and/or the {name} Franchisee are responsible for loss or
          damage you suffer that is a foreseeable result of our and/or the
          {name} Franchisee’s breach of these terms and conditions or our and/or
          the {name} Franchisee’s negligence, up to the amount of the purchase
          price paid by you for the Products you ordered. However, we and/or the
          {name} Franchisee are not responsible for any loss or damage that is
          not foreseeable. Loss or damage is foreseeable if it was an obvious
          consequence of our breach or if it was contemplated by you and us at
          the time you purchased from the Websites and Apps.
        </Text>

        <Text mb="16px">
          We and the {name} Franchisees only supply the Products for domestic,
          private and business use. You agree not to use any Product for any
          commercial or re-sale purposes and we have no liability to you for any
          loss of profit, loss of business, business interruption, or loss of
          business opportunity.
        </Text>

        <Text mb="16px">
          We, and/or the {name} Franchisees (as appropriate), do not in any way
          exclude or limit our liability for:
        </Text>

        <Text mb="16px">
          a. death or personal injury caused by our and/or the {name}
          Franchisee’s negligence;
        </Text>

        <Text mb="16px">b. fraud or fraudulent misrepresentation;</Text>

        <Text mb="16px">
          c. any breach of the terms implied by section 12 of the Sale of Goods
          Act 1979 (title and quiet possession);
        </Text>

        <Text mb="16px">
          d. any breach of the terms implied by section 13 to 15 of the Sale of
          Goods Act 1979 (description, satisfactory quality, fitness for purpose
          and samples); and
        </Text>

        <Text mb="16px">
          e. defective products under the Consumer Protection Act 1987.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Termination
        </Heading>

        <Text mb="16px">
          We reserve the right to decline a new registration, terminate your
          right to link to the Websites and Apps under these terms and
          conditions. If we consider there to be a risk of liability or loss to
          us or the {name} Franchisees we may take any action deemed necessary
          to prevent such a liability or loss from occurring.
        </Text>

        <Text mb="16px">
          If we terminate your right to link to the Websites and Apps you must
          cease linking to the Websites and Apps immediately. We reserve the
          right to assign or sub-contract any or all of our rights and
          obligations under these terms.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Written communication
        </Heading>

        <Text mb="16px">
          Applicable laws require that some of the information or communications
          we send to you should be in writing. When using the Websites and Apps,
          you accept that communication with us will be mainly electronic. We
          will contact you by e-mail or provide you with information by posting
          notices on the Websites and Apps. For contractual purposes, you agree
          to this electronic means of communication and you acknowledge that all
          contracts, notices, information and other communications that we
          provide to you electronically comply with any legal requirement that
          such communications be in writing. This condition does not affect your
          statutory rights.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Events outside our control
        </Heading>

        <Text mb="16px">
          We and the {name} Franchisees will not be liable or responsible for
          any failure to perform, or delay in performance of, any of our
          obligations under a contract that is caused by events outside our
          reasonable control (a “Force Majeure Event”).
        </Text>

        <Text mb="16px">
          A Force Majeure Event includes any act, event, non-happening, omission
          or accident beyond both our reasonable control and the reasonable
          control of the {name} Franchisees and includes in particular (without
          limitation) the following:
        </Text>

        <Text mb="16px">a. strikes, lock-outs or other industrial action;</Text>
        <Text mb="16px">
          b. civil commotion, riot, invasion, terrorist attack or threat of
          terrorist attack, war (whether declared or not) or threat or
          preparation for war;
        </Text>
        <Text mb="16px">
          c. fire, explosion, storm, flood, earthquake, subsidence, epidemic or
          other natural disaster;
        </Text>
        <Text mb="16px">
          d. impossibility of the use of railways, shipping, aircraft, motor
          transport or other means of public or private transport;
        </Text>
        <Text mb="16px">
          e. impossibility of the use of public or private telecommunications
          networks;
        </Text>
        <Text mb="16px">
          f. the acts, decrees, legislation, regulations or restrictions of any
          government; or
        </Text>
        <Text mb="16px">
          g. non-performance by suppliers or subcontractors.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Waiver
        </Heading>
        <Text mb="16px">
          If the {name} Family fails, at any time during the term of a contract,
          to insist upon strict performance of any of your obligations under any
          contract or any of these terms and conditions, or if the {name}
          Family fails to exercise any of the rights or remedies to which it is
          entitled under the contract, this shall not constitute a waiver of
          such rights or remedies and shall not relieve you from compliance with
          such obligations.
        </Text>
        <Text mb="16px">
          A waiver by the {name} Family of any default shall not constitute a
          waiver of any subsequent default.
        </Text>
        <Text mb="16px">
          No waiver by the {name} Family of any of these terms and conditions
          shall be effective unless it is expressly stated to be a waiver and is
          communicated to you in writing (email correspondence will be
          permitted).
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Severability
        </Heading>
        <Text mb="16px">
          If any of these terms and conditions or any provisions of a contract
          are determined by any competent authority to be invalid, unlawful or
          unenforceable to any extent, such term, condition or provision will to
          that extent be severed from the remaining terms, conditions and
          provisions which will continue to be valid to the fullest extent
          permitted by law.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Entire agreement
        </Heading>
        <Text mb="16px">
          These terms and conditions and any document expressly referred to in
          them represent the entire agreement in relation to the subject matter
          of any contract and supersede any prior agreement, understanding or
          arrangement between us, whether oral or in writing. We each
          acknowledge that, in entering into a contract, neither party has
          relied on any representation, undertaking or promise given by the
          other or be implied from anything said or written in negotiations
          between us prior to such contract except as expressly stated in these
          terms and conditions. Neither party shall have any remedy in respect
          of any untrue statement made by the other, whether orally or in
          writing, prior to the date of any contract (unless such untrue
          statement was made fraudulently) and the other party&#8217;s only
          remedy shall be for breach of contract as provided in these terms and
          conditions.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Our right to vary these terms and conditions
        </Heading>
        <Text mb="16px">
          {name} has the right to revise and amend these terms and conditions
          from time to time. Any changes we make to these terms and conditions
          will be posted on this page. You will be subject to the policies and
          terms and conditions in force at the time that you order Product(s)
          from a {name} Franchisee, unless any change to those policies or these
          terms and conditions is required to be made by law or governmental
          authority.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Third party rights
        </Heading>
        <Text mb="16px">
          No one other than a party to these terms and conditions shall have any
          right to enforce any of its terms and conditions.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Law and jurisdiction
        </Heading>
        <Text mb="16px">
          Contracts for the purchase of Products through our site and any
          dispute or claim arising out of or in connection with them or their
          subject matter or formation (including non-contractual disputes or
          claims) will be governed by Irish law. Any dispute or claim arising
          out of or in connection with such contracts or their formation
          (including non-contractual disputes or claims) shall be subject to the
          non-exclusive jurisdiction of the courts of Ireland.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Intellectual property
        </Heading>
        <Text mb="16px">
          {name} owns all intellectual property rights and copyright in the
          {name} Platform, website and Services (including with respect to
          functionality, content, text, graphics, logos and icons) and the
          software and other material underlying or forming part of the
          Platform, Services and/or website. You agree that you will not, in any
          manner or by any means, reverse engineer, resell, adapt, reproduce,
          copy, store, distribute, print, display, publish or create derivative
          works from any part of the Platform, website or Services, or attempt
          to commercialise, copy or sell any information or matters derived from
          the Platform, website or Services.
        </Text>
        <Text>
          {name} does not claim any intellectual property rights over the
          material you upload or otherwise provide when utilising the Services.
          All such material remains yours. By uploading images of and
          information describing your products you agree that other internet
          users can view the same and you agree that {name} may store and
          display them and may at any time review all content submitted by you
          utilising the Services.
        </Text>
      </WidgetWrapper>
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
      />
    </>
  );
};
