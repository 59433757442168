import { Box, Flex, Link, Text } from '@chakra-ui/react';

interface Iprops {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  isLink?: boolean;
}

export const OrderInfoItem = ({
  title,
  subtitle,
  children,
  isLink,
}: Iprops) => (
  <Flex alignItems="center" width="100%">
    {children}
    <Box paddingLeft="16px" flexGrow="1" flexShrink="1">
      <Text
        as="span"
        display="block"
        fontSize="12px"
        lineHeight="16px"
        marginBottom="4px"
        color="gray.400"
      >
        {title}
      </Text>
      {isLink ? (
        <Link
          href={`tel:${subtitle.replace(/\s/g, '')}`}
          display="block"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="600"
        >
          {subtitle}
        </Link>
      ) : (
        <Text
          display="block"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="600"
        >
          {subtitle}
        </Text>
      )}
    </Box>
  </Flex>
);
