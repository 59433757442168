import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  CircularProgress,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import { PlusIcon } from '@shared/assets/icons';
import VoucherPlaceholder from '@shared/assets/images/voucher-placeholder.svg';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { useIsMobile } from '@shared/utils/screen';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';

import useProfileVouchers from './hooks';
import VoucherList from './VoucherList';

export const ProfileVouchers = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const {
    data: { isLoading, activeVouchers, disabledVouchers, isShowVoucher },
  } = useProfileVouchers();
  return (
    <WidgetWrapper
      withContainer
      containerWidth="xl"
      containerProps={{ position: 'relative' }}
    >
      <BackButton onClick={() => navigate(-1)} position="absolute" />
      {isShowVoucher ? (
        isLoading ? (
          <Center>
            <CircularProgress isIndeterminate color="primary.400" />
          </Center>
        ) : (
          <>
            <Button
              variant="secondary"
              position="absolute"
              right={isMobile ? '16px' : '24px'}
              top={isMobile ? '16px' : '24px'}
              size="sm"
              onClick={() => navigate(Paths.ProfileVoucherRedeem)}
            >
              <PlusIcon w="24px" h="24px" mr="8px" />
              Redeem
            </Button>
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem border="0">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  paddingBottom="16px"
                  borderBottom="1px solid"
                  borderColor="gray.200"
                >
                  <AccordionButton _hover={{ background: '#fff' }} py="4px">
                    <Text
                      as="span"
                      fontSize="14px"
                      lineHeight="24px"
                      flexGrow="1"
                      fontWeight="600"
                    >
                      {`Voucher Wallet (${activeVouchers.length})`}
                    </Text>
                    <Box w="20px" />
                  </AccordionButton>
                </Flex>

                <AccordionPanel padding="0 0">
                  {Boolean(activeVouchers.length) ? (
                    <VoucherList vouchers={activeVouchers} />
                  ) : (
                    <Box padding="36px 0">
                      <Box
                        height="150px"
                        width="150px"
                        borderRadius="50%"
                        bgColor="gray.100"
                        display="flex"
                        alignItems="center"
                        margin="0 auto"
                      >
                        <Image
                          src={VoucherPlaceholder}
                          alt="no vouchers"
                          margin="0 auto"
                        />
                      </Box>
                    </Box>
                  )}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem border="0">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  paddingTop="4px"
                  paddingBottom="16px"
                >
                  <AccordionButton _hover={{ background: '#fff' }}>
                    <Text
                      as="span"
                      fontSize="14px"
                      lineHeight="24px"
                      flexGrow="1"
                      fontWeight="600"
                      color="gray.300"
                    >
                      {`Used Vouchers (${disabledVouchers.length})`}
                    </Text>
                    <AccordionIcon color="gray.300" />
                  </AccordionButton>
                </Flex>
                <AccordionPanel padding="0 0">
                  {Boolean(disabledVouchers.length) ? (
                    <VoucherList disabled vouchers={disabledVouchers} />
                  ) : (
                    <Box padding="36px 0">
                      <Box
                        height="150px"
                        width="150px"
                        borderRadius="50%"
                        bgColor="gray.100"
                        display="flex"
                        alignItems="center"
                        margin="0 auto"
                      >
                        <Image
                          src={VoucherPlaceholder}
                          alt="no vouchers"
                          margin="0 auto"
                        />
                      </Box>
                    </Box>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        )
      ) : (
        <Text as="h2" textAlign="center" fontWeight="bold">
          404 forbidden
        </Text>
      )}
    </WidgetWrapper>
  );
};
