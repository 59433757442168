import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

interface Props {
  isApplied: boolean;
  onApply: (coupon: string) => void;
  onClear: () => void;
  couponCode?: string;
  setCouponCode?: (code: string) => void;
}

export const CouponInput = ({
  isApplied,
  onApply,
  onClear,
  couponCode,
  setCouponCode,
}: Props) => {
  const [coupon, setCoupon] = useState<string>(couponCode || '');
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);

    if (setCouponCode) {
      setCouponCode(e.target.value);
    }
  }, []);

  useEffect(() => {
    if (couponCode) {
      setCoupon(couponCode);
    }
  }, [couponCode]);

  const handleSubmit = () => {
    if (isApplied) {
      setCoupon('');
      onClear();
    } else {
      onApply(coupon);
    }
  };

  return (
    <InputGroup>
      <Input
        pr="80px"
        placeholder="Coupon code"
        value={coupon}
        onChange={onChange}
        isDisabled={isApplied}
        maxLength={32}
        fontSize="inputBase"
      />
      <InputRightElement width="80px">
        <Button variant="link" onClick={handleSubmit} isDisabled={!coupon}>
          {isApplied ? 'Clear' : 'Apply'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
