import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import TableIcon from '@shared/assets/icons/table.svg';
import { ErrorText } from '@shared/components/Texts/ErrorText';
import { TablesModal } from 'components/TablesModal';
import { useCallback, useState } from 'react';

interface Props {
  table?: Venues.Table;
  setTable: (address: Venues.Table) => void;
  error: string;
  clearError: VoidFunction;
}

export const Tables = ({ table, setTable, error, clearError }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setOpen(true);
    clearError();
  }, [clearError]);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <Stack direction="row" align="center" spacing="16px" py="16px">
        <Box w="24px">
          <Image src={TableIcon} w="16px" />
        </Box>
        <Box flex={1}>
          <Text fontWeight={600} fontSize="sm">
            {table?.name ?? 'Table number*'}
          </Text>
          {error && <ErrorText error={error} />}
        </Box>
        <Button variant="link" onClick={openModal}>
          Choose
        </Button>
      </Stack>
      {isOpen && (
        <TablesModal
          isOpen={isOpen}
          onClose={closeModal}
          onSelect={setTable}
          selected={table?.id}
        />
      )}
    </>
  );
};
