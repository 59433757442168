import {
  Alert,
  AlertDescription,
  AlertTitle,
  CloseButton,
  ToastProps,
} from '@chakra-ui/react';

export const CustomToastAlert = ({
  status,
  title,
  description,
  onClose,
  isClosable,
}: ToastProps) => {
  return (
    <Alert
      status={status}
      variant="solid"
      borderRadius="md"
      bg={status === 'info' ? 'gray.900' : undefined}
    >
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription flex={1}>{description}</AlertDescription>
      {isClosable && <CloseButton onClick={onClose} ml="8px" />}
    </Alert>
  );
};
