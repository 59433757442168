import { List, ListItem, Text } from '@chakra-ui/react';

interface ExtraListProps {
  quantity: number;
  defaultQuantity: number;
  name: string;
  price?: string;
  currency: string;
  errorMessage?: string;
  thirdLevelProducts?: {
    name: string;
    price?: string;
    quantity: number;
    defaultQuantity: number;
    errorMessage?: string;
  }[];
}

export const ExtraListItem = ({
  quantity,
  defaultQuantity,
  name,
  price,
  currency,
  thirdLevelProducts = [],
  errorMessage,
}: ExtraListProps) => (
  <ListItem marginBottom="4px" _last={{ marginBottom: '0' }}>
    <Text
      fontSize="12px"
      lineHeight="16px"
      color={errorMessage ? 'red.400' : 'gray.400'}
      marginBottom="4px"
    >
      {defaultQuantity > 0 && quantity < defaultQuantity
        ? quantity > 0 && defaultQuantity - quantity > 0
          ? `Remove ${defaultQuantity - quantity} `
          : 'No '
        : quantity > 1
        ? `${quantity} `
        : ''}
      {name}
      {!!price &&
        Boolean(+price) &&
        ` (+ ${currency} ${Number(price).toFixed(2)})`}
    </Text>

    {thirdLevelProducts.length > 0 && (
      <List fontSize="14px" lineHeight="20px">
        {thirdLevelProducts.map((tProduct) => (
          <ListItem
            key={tProduct.name}
            marginBottom="4px"
            _last={{ marginBottom: '0' }}
          >
            <Text fontSize="12px" lineHeight="20px" color="gray.400">
              {`- ${
                tProduct.defaultQuantity > 0 &&
                tProduct.quantity < tProduct.defaultQuantity
                  ? tProduct.quantity > 0 &&
                    tProduct.defaultQuantity - tProduct.quantity > 0
                    ? `Remove ${tProduct.defaultQuantity - tProduct.quantity} `
                    : 'No '
                  : tProduct.quantity > 1
                  ? `${tProduct.quantity} `
                  : ''
              }${tProduct.name}`}
              {tProduct.errorMessage && (
                <Text fontSize="12px" lineHeight="20px" color="red.400">
                  {tProduct.errorMessage}
                </Text>
              )}
              {!!tProduct.quantity &&
                tProduct.quantity > 0 &&
                !!tProduct.price &&
                Boolean(+tProduct.price) &&
                ` (+ ${currency} ${Number(tProduct.price).toFixed(2)})`}
            </Text>
          </ListItem>
        ))}
      </List>
    )}
  </ListItem>
);
