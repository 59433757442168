import { getQueryString } from '@shared/utils/requests';

import { BaseApiService } from './BaseApiService';

export class VenuesApi {
  public static async getPaymentMethods({
    venueId,
    serviceType,
    websiteId,
  }: Payments.GetPaymentMethodsParams) {
    return await BaseApiService.get<Payments.PaymentMethod[]>({
      path: `venues/${venueId}/payment-methods/?is_active=true&service_type=${serviceType}&website=${websiteId}`,
    });
  }

  public static async getDeliverySurcharge({
    venueId,
    lat,
    lng,
  }: Venues.GetDeliverySurchargeParams) {
    return await BaseApiService.get<Venues.GetDeliverySurchargeResponse>({
      path: `venues/${venueId}/delivery-surcharge/?lat=${lat}&lng=${lng}`,
      authRequired: false,
    });
  }

  public static async getVenues(
    websiteId: string,
    params: Venues.VenuesParams,
  ) {
    let path = `websites/${websiteId}/venues/`;
    if (params) {
      path = path + '?' + getQueryString(params);
    }

    return await BaseApiService.get<Venues.VenuesResponse>({
      path,
      authRequired: false,
    });
  }

  public static async getVenue(venueId: string, expand?: string) {
    return await BaseApiService.get<Venues.Venue>({
      path: `venues/${venueId}/?expand=${expand}`,
      authRequired: false,
    });
  }

  public static async getTables(venueId: string) {
    return await BaseApiService.get<Venues.Table[]>({
      path: `venues/${venueId}/tables/`,
      authRequired: false,
    });
  }

  public static async getVenueTimeSlots(venueId: string, serviceId: string) {
    return await BaseApiService.get<Venues.VenueTimeSlots>({
      path: `venues/${venueId}/time-slots/?service=${serviceId}`,
      authRequired: false,
    });
  }

  public static async getOrderedProducts(
    venueId: string,
    serviceType: string | number,
  ) {
    return await BaseApiService.get<{ results: string[] }>({
      path: `venues/${venueId}/ordered-products/?service_type=${serviceType}`,
    });
  }
}
