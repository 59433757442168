import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  clearData,
  deleteAddress,
  getAddresses,
  selectProfileAddresses,
  stopAddressLoading,
  toggleConfirmModal,
} from 'store/slices/profileAddresses';

const useProfileAddresses = (noclear?: boolean) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { user } = useAppSelector(selectAuth);
  const { addresses, error, isLoading, isConfirmModalOpen } = useAppSelector(
    selectProfileAddresses,
  );
  const [addressId, setAddressId] = useState('');

  useEffect(() => {
    if (user) {
      dispatch(getAddresses({ no_page: true }));
    } else {
      dispatch(stopAddressLoading());
    }
    return () => {
      if (!noclear) {
        dispatch(clearData());
      }
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const handleOpenModal = useCallback((addressId: string) => {
    dispatch(toggleConfirmModal(true));
    setAddressId(addressId);
  }, []);

  const handleClose = useCallback(() => {
    dispatch(toggleConfirmModal(false));
    setAddressId('');
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(deleteAddress(addressId, { no_page: true }));
  }, [addressId]);

  return {
    actions: {
      handleOpenModal,
      handleDelete,
      handleClose,
    },
    data: {
      addresses,
      isOpen: isConfirmModalOpen,
      isLoading,
    },
  };
};

export default useProfileAddresses;
