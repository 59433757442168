import { NavigationMenu } from 'pages/NavigationMenu';
import { Outlet } from 'react-router';
import { isLandingMode } from 'utils/document';

export const Landing = () => {
  return (
    <>
      {isLandingMode ? <NavigationMenu /> : null}
      <Outlet />
    </>
  );
};
