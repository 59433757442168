import { createSlice } from '@reduxjs/toolkit';
import { parseStringToObject } from '@shared/utils/object';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import { OrdersApi } from 'api/OrdersApi';
import { AppThunk, RootState } from 'store';
import { prepareReorderProductsForCheckout } from 'utils/product';

type CustomiseExtraSections = {
  parentId: string;
  productId: string;
  data: Product.ExtraSection[];
};

type ProductModalState = {
  isOpen: boolean;
  product: Product.RootObject | null;
  customiseExtraSections: CustomiseExtraSections | null;
  selected: Product.SelectedProducts;
  totalAmount: number;
  basketData: Product.RootObject[];
  venueId: string;
  serviceId: string;
  isUpsellsProduct: boolean;
  scrollPosition: number | null;
  reorderResponse: true | ApiTypes.ResponseError | null;
  reorderData: Product.ReorderProduct[];
};

const initialState: ProductModalState = {
  isOpen: false,
  product: null,
  customiseExtraSections: null,
  selected: {},
  basketData: [],
  totalAmount: 1,
  venueId: '',
  serviceId: '',
  isUpsellsProduct: false,
  scrollPosition: null,
  reorderResponse: null,
  reorderData: [],
};

export const productModalSlice = createSlice({
  name: 'productModal',
  initialState,
  reducers: {
    toggleModal: (state, action: { payload: boolean }) => {
      state.isOpen = action.payload;
    },
    setProduct: (state, action: { payload: Product.RootObject }) => {
      state.product = action.payload;
    },
    setCustomiseExtraSections: (
      state,
      action: { payload: CustomiseExtraSections | null },
    ) => {
      state.customiseExtraSections = action.payload;
    },
    setSeleted: (state, action: { payload: Product.SelectedProducts }) => {
      state.selected = action.payload;
    },
    closeProductModal: (state) => {
      state.isOpen = false;
      state.product = null;
      state.customiseExtraSections = null;
      state.selected = {};
      state.totalAmount = 1;
    },
    setBasketData: (state, action: { payload: Product.RootObject[] }) => {
      state.basketData = action.payload;
    },
    setTotalAmount: (state, action: { payload: number }) => {
      state.totalAmount = action.payload;
    },
    setVenueId: (state, action: { payload: string }) => {
      state.venueId = action.payload;
    },
    setServiceId: (state, action: { payload: string }) => {
      state.serviceId = action.payload;
    },
    setIsUpsellsProduct: (state, action: { payload: boolean }) => {
      state.isUpsellsProduct = action.payload;
    },
    setScrollPosition: (
      state,
      action: {
        payload: number | null;
      },
    ) => {
      state.scrollPosition = action.payload;
    },
    setReorderResponse: (
      state,
      action: {
        payload: true | ApiTypes.ResponseError | null;
      },
    ) => {
      state.reorderResponse = action.payload;
    },
    setReorderData: (
      state,
      action: {
        payload: Product.ReorderProduct[];
      },
    ) => {
      state.reorderData = action.payload;
    },
  },
});

const {
  toggleModal,
  setProduct,
  setCustomiseExtraSections,
  setSeleted,
  setBasketData,
  setServiceId,
  setVenueId,
  setIsUpsellsProduct,
  setReorderData,
} = productModalSlice.actions;

export const {
  setReorderResponse,
  setTotalAmount,
  closeProductModal,
  setScrollPosition,
} = productModalSlice.actions;

export const openProductModal =
  (product: Product.RootObject, isUpsellsProduct?: boolean): AppThunk =>
  (dispatch) => {
    dispatch(toggleModal(true));
    dispatch(setProduct(product));
    dispatch(setIsUpsellsProduct(isUpsellsProduct || false));
  };

export const openCustomise =
  (
    parentId: string,
    productId: string,
    extraSections: Product.ExtraSection[],
  ): AppThunk =>
  (dispatch) => {
    dispatch(
      setCustomiseExtraSections({ parentId, productId, data: extraSections }),
    );
  };

export const closeCustomise = (): AppThunk => (dispatch) => {
  dispatch(setCustomiseExtraSections(null));
};

export const handleSelect =
  (
    data: Product.SelectedProducts,
    extraSectionId?: string,
    productId?: string,
    parentProductQuantity?: number,
  ): AppThunk =>
  (dispatch, getState) => {
    const { selected } = getState().productModal;
    let newData: Product.SelectedProducts;
    if (productId && extraSectionId) {
      newData = {
        ...selected,
        [extraSectionId]: {
          ...selected[extraSectionId],
          [productId]: {
            amount:
              selected[extraSectionId]?.[productId]?.amount ??
              parentProductQuantity,
            defaultQuantity:
              selected[extraSectionId]?.[productId].defaultQuantity ?? 0,
            price: selected[extraSectionId]?.[productId].price ?? 0,
            thirdLevel: {
              ...selected[extraSectionId]?.[productId].thirdLevel,
              ...data,
            },
          },
        },
      };
    } else {
      newData = {
        ...selected,
        ...data,
      };
    }
    dispatch(setSeleted(newData));
  };

export const addProductToBasket =
  (product: Product.RootObject): AppThunk =>
  (dispatch, getState) => {
    const { basketData } = getState().productModal;
    dispatch(setBasketData([...basketData, product]));
    dispatch(closeProductModal());
  };

export const deleteBasketProductByPositionIds =
  (ids: string[]): AppThunk =>
  (dispatch, getState) => {
    const newData = getState().productModal.basketData.filter(
      (data) => data.position_id && !ids.includes(data.position_id),
    );
    dispatch(setBasketData(newData));
  };

export const editBasketProductByPositionIds =
  (product: Product.RootObject): AppThunk =>
  (dispatch, getState) => {
    const newData = [...getState().productModal.basketData];
    const index = newData.findIndex(
      (p) => p.position_id === product.position_id,
    );
    if (index > -1) {
      newData[index] = product;
      dispatch(setBasketData(newData));
    }
  };

export const editBasketProductError =
  (errors: { positionId: string; errorMessage: string }[]): AppThunk =>
  (dispatch, getState) => {
    const newData = [...getState().productModal.basketData];

    const updateProductErrorMessage = (
      product: Product.RootObject,
      positionId: string,
      errorMessage: string,
    ): Product.RootObject => {
      // Check if the current product matches the positionId
      if (product.position_id === positionId) {
        return { ...product, errorMessage: errorMessage ?? '' };
      }

      // Check and update extra sections
      const updatedExtraSections = product.extra_sections.map((section) => ({
        ...section,
        products: section.products.map((subProduct) =>
          updateProductErrorMessage(subProduct, positionId, errorMessage),
        ),
      }));

      return {
        ...product,
        extra_sections: updatedExtraSections,
      };
    };

    const updateProductAndExtraSections = (
      product: Product.RootObject,
      errorList: { positionId: string; errorMessage: string }[],
    ): Product.RootObject => {
      let updatedProduct = { ...product };

      // Check if the current product needs an error update
      const errorInfo = errorList.find(
        (item) => item.positionId === product.position_id,
      );
      if (errorInfo) {
        updatedProduct = updateProductErrorMessage(
          updatedProduct,
          errorInfo.positionId,
          errorInfo.errorMessage,
        );
      }

      // Update extra sections
      if (updatedProduct.extra_sections) {
        updatedProduct.extra_sections = updatedProduct.extra_sections.map(
          (section) => ({
            ...section,
            products: section.products.map((subProduct) =>
              updateProductAndExtraSections(subProduct, errorList),
            ),
          }),
        );
      }

      return updatedProduct;
    };

    const updatedData = newData.map((product) =>
      updateProductAndExtraSections(product, errors),
    );

    dispatch(setBasketData(updatedData));
  };

export const removeBasketProductError =
  (): AppThunk => (dispatch, getState) => {
    const newData = [...getState().productModal.basketData];

    const clearProductErrorMessage = (
      product: Product.RootObject,
    ): Product.RootObject => {
      // Clear error message from the current product
      const updatedProduct = { ...product, errorMessage: undefined };

      // Clear error messages from extra sections
      if (updatedProduct.extra_sections) {
        updatedProduct.extra_sections = updatedProduct.extra_sections.map(
          (section) => ({
            ...section,
            products: section.products.map((subProduct) =>
              clearProductErrorMessage(subProduct),
            ),
          }),
        );
      }

      return updatedProduct;
    };

    const updatedData = newData.map((product) =>
      clearProductErrorMessage(product),
    );

    dispatch(setBasketData(updatedData));
  };

export const clearBasket = (): AppThunk => (dispatch) => {
  storage.remove(StorageKeys.BASKET_DATA);
  storageSession.remove(StorageKeys.VENUE_ID);
  storageSession.remove(StorageKeys.SERVICE_ID);
  dispatch(setBasketData([]));
  dispatch(setVenueId(''));
  dispatch(setServiceId(''));
  dispatch(setReorderData([]));
};

export const restoreBasketDataFromStorage = (): AppThunk => (dispatch) => {
  const data = storage.get(StorageKeys.BASKET_DATA);
  const venueId = storageSession.get(StorageKeys.VENUE_ID);
  const serviceId = storageSession.get(StorageKeys.SERVICE_ID);
  const reorderData = storage.get(StorageKeys.REORDER_DATA);

  dispatch(
    setBasketData(parseStringToObject(data || '[]') as Product.RootObject[]),
  );
  dispatch(
    setReorderData(
      parseStringToObject(reorderData || '[]') as Product.ReorderProduct[],
    ),
  );
  dispatch(setVenueId(venueId ?? ''));
  dispatch(setServiceId(serviceId ?? ''));
};

export const setIds =
  ({ venueId, serviceId }: { venueId: string; serviceId: string }): AppThunk =>
  (dispatch) => {
    storageSession.set(StorageKeys.SERVICE_ID, serviceId);
    storageSession.set(StorageKeys.VENUE_ID, venueId);
    dispatch(setVenueId(venueId));
    dispatch(setServiceId(serviceId));
  };

export const reorder =
  (orderId: string): AppThunk =>
  (dispatch) => {
    dispatch(setReorderResponse(null));
    dispatch(setReorderData([]));
    OrdersApi.reorder(orderId)
      .then((res) => {
        storage.set(
          StorageKeys.ADDRESS,
          res.delivery_address ? JSON.stringify(res.delivery_address) : '',
        );
        dispatch(setIds({ venueId: res.venue, serviceId: res.service }));
        dispatch(setReorderResponse(true));
        dispatch(
          setReorderData(prepareReorderProductsForCheckout(res.products)),
        );
      })
      .catch((e: ApiTypes.ResponseError) => {
        dispatch(setReorderResponse(e));
        dispatch(setReorderData([]));
      });
  };

export const deleteReorderProductByPositionId =
  (positionId: string): AppThunk =>
  (dispatch, getState) => {
    const newData = getState().productModal.reorderData.filter(
      (data) => data.position_id && data.position_id !== positionId,
    );
    dispatch(setReorderData(newData));
  };

export const editReorderProductByPositionId =
  (product: Product.ReorderProduct): AppThunk =>
  (dispatch, getState) => {
    const newData = [...getState().productModal.reorderData];
    const index = newData.findIndex(
      (p) => p.position_id === product.position_id,
    );
    newData[index] = product;
    dispatch(setReorderData(newData));
  };

export const editReorderProductError =
  (errors: { positionId: string; errorMessage: string }[]): AppThunk =>
  (dispatch, getState) => {
    const newData = [...getState().productModal.reorderData];

    const updateProductErrorMessage = (
      product: Product.ReorderProduct | Product.ReorderExtra,
      positionId: string,
      errorMessage: string,
    ): Product.ReorderProduct | Product.ReorderExtra => {
      // Check if the current product matches the positionId
      if (product.position_id === positionId) {
        return { ...product, errorMessage: errorMessage ?? '' };
      }

      // Check if the product is a ReorderProduct (has extras property)
      if ('extras' in product && Array.isArray(product.extras)) {
        return {
          ...product,
          extras: product.extras.map((section) => ({
            ...section,
            extras: section.extras.map(
              (subProduct) =>
                updateProductErrorMessage(
                  subProduct,
                  positionId,
                  errorMessage,
                ) as Product.ReorderExtra,
            ),
          })),
        } as Product.ReorderProduct;
      }

      return product;
    };

    const updateProductAndExtraSections = (
      product: Product.ReorderProduct | Product.ReorderExtra,
      errorList: { positionId: string; errorMessage: string }[],
    ): Product.ReorderProduct | Product.ReorderExtra => {
      let updatedProduct = { ...product };

      // Check if the current product needs an error update
      const errorInfo = errorList.find(
        (item) => item.positionId === product.position_id,
      );
      if (errorInfo) {
        updatedProduct = updateProductErrorMessage(
          updatedProduct,
          errorInfo.positionId,
          errorInfo.errorMessage,
        );
      }

      if ('extras' in updatedProduct && Array.isArray(updatedProduct.extras)) {
        return {
          ...updatedProduct,
          extras: updatedProduct.extras.map((extra) =>
            updateProductAndExtraSections(extra, errorList),
          ),
        } as Product.ReorderProduct;
      }

      return updatedProduct;
    };

    const updatedData = newData.map((product) =>
      updateProductAndExtraSections(product, errors),
    );

    dispatch(setReorderData(updatedData as Product.ReorderProduct[]));
  };

export const removeReorderProductError =
  (): AppThunk => (dispatch, getState) => {
    const newData = [...getState().productModal.reorderData];

    const clearProductErrorMessage = (
      product: Product.ReorderProduct | Product.ReorderExtra,
    ): Product.ReorderProduct | Product.ReorderExtra => {
      const updatedProduct = { ...product, errorMessage: undefined };

      // Check if the product is a ReorderProduct (has extras property)
      if ('extras' in updatedProduct && Array.isArray(updatedProduct.extras)) {
        return {
          ...updatedProduct,
          extras: updatedProduct.extras.map((section) => ({
            ...section,
            extras: section.extras.map(
              (subProduct) =>
                clearProductErrorMessage(subProduct) as Product.ReorderExtra,
            ),
          })),
        } as Product.ReorderProduct;
      }

      return updatedProduct;
    };

    const updatedData = newData.map((product) =>
      clearProductErrorMessage(product),
    );

    dispatch(setReorderData(updatedData as Product.ReorderProduct[]));
  };

export const selectProductModal = (state: RootState) => state.productModal;

export default productModalSlice.reducer;
