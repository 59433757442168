import { parseStringToObject } from './object';
import { storage, StorageKeys } from './storage';

type AuthTokens = {
  access: string | null;
  refresh: string | null;
};

export const getAuthTokens = (): AuthTokens => {
  return (
    parseStringToObject<AuthTokens>(
      storage.get(StorageKeys.AUTH_TOKENS) as string,
    ) ?? { access: null, refresh: null }
  );
};

export const setAuthTokens = (token: string, refreshToken: string): void => {
  storage.set(
    StorageKeys.AUTH_TOKENS,
    JSON.stringify({ access: token, refresh: refreshToken }),
  );
};

export const deleteAuthTokens = (): void => {
  storage.remove(StorageKeys.AUTH_TOKENS);
};
