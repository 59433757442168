import { Flex, Image, Text } from '@chakra-ui/react';
import VoucherIcon from '@shared/assets/icons/voucher.svg';
import VoucherDisabledIcon from '@shared/assets/icons/voucher-disabled.svg';
import { formatCurrency } from '@shared/utils/format';

interface Props {
  isUsed?: boolean;
  code: string;
  currency: Currency;
  balance: string;
}

export const VoucherWithBalance = ({
  code,
  currency,
  balance,
  isUsed,
}: Props) => (
  <Flex alignItems="center">
    <Image
      src={isUsed ? VoucherDisabledIcon : VoucherIcon}
      alt="voucher icon"
    />
    <Text
      fontSize="16px"
      lineHeight="24px"
      flexGrow="1"
      padding="0 24px"
      fontWeight="600"
      color={isUsed ? 'gray.300' : 'gray.900'}
    >
      {code}
    </Text>
    <Text
      fontSize="16px"
      lineHeight="24px"
      fontWeight="600"
      color={isUsed ? 'gray.300' : 'gray.900'}
    >
      {formatCurrency(currency)} {balance}
    </Text>
  </Flex>
);
