import { Box, Center, CircularProgress, List, Text } from '@chakra-ui/react';
import { CouponInput } from '@shared/components/Input/CouponInput';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';
import { storage, StorageKeys } from '@shared/utils/storage';
import { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectCheckoutCoupons,
  setIsCouponModalOpen,
  setSelectedCouponCode,
} from 'store/slices/checkoutCoupons';

import CouponListItem from './CouponListItem';

interface Iprops {
  isApplied: boolean;
  onCouponApply: (coupon: string) => void;
  onCouponClear: () => void;
}

const CouponModal = ({ isApplied, onCouponApply, onCouponClear }: Iprops) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const currency = storage.get(StorageKeys.CURRENCY) as Currency;

  const { isCouponModalOpen, coupons, selectedCouponCode, isLoading } =
    useAppSelector(selectCheckoutCoupons);

  const handleCloseModal = useCallback(() => {
    dispatch(setIsCouponModalOpen(false));
  }, []);

  const handleSetCouponCode = useCallback((code: string) => {
    dispatch(setSelectedCouponCode(code));
  }, []);

  return (
    <Modal
      isOpen={isCouponModalOpen}
      onClose={handleCloseModal}
      size={{ mobile: 'full', desktop: 'md' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
      title="Add Coupon Code"
    >
      <Box position="relative">
        <Box>
          <CouponInput
            isApplied={isApplied}
            onApply={onCouponApply}
            onClear={onCouponClear}
            couponCode={selectedCouponCode}
            setCouponCode={handleSetCouponCode}
          />

          {!!coupons.length && (
            <Text my="24px" fontWeight={600}>
              My Coupons
            </Text>
          )}

          <List>
            {coupons.map((coupon) => (
              <CouponListItem
                key={coupon.id}
                coupon={coupon}
                selectedCode={selectedCouponCode}
                handleSetCouponCode={handleSetCouponCode}
                currency={currency}
                onCouponApply={onCouponApply}
              />
            ))}
          </List>
        </Box>

        {isLoading && (
          <Center
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bgColor="rgba(255, 255, 255, 0.5)"
          >
            <CircularProgress isIndeterminate color="primary.400" />
          </Center>
        )}
      </Box>
    </Modal>
  );
};

export default memo(CouponModal);
