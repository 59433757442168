import { Box, Container, Divider } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';
import { Fragment } from 'react';

import { AboutCard } from './Card';

interface Props {
  aboutSections?: Website.AboutSection[];
}

export const About = ({ aboutSections = [] }: Props) => {
  const isMobile = useIsMobile();
  if (aboutSections.length < 1) return null;
  return (
    <Box bgColor="gray.100">
      <Container
        maxW="1280px"
        px={isMobile ? '16px' : '72px'}
        py={isMobile ? '80px' : '120px'}
      >
        {aboutSections.map((section, index) => (
          <Fragment key={section.id}>
            {index > 0 && <Divider my={isMobile ? '80px' : '120px'} />}
            <AboutCard
              reverse={index % 2 === 1}
              title={section.title}
              content={section.content}
              imgUrl={section.image}
            />
          </Fragment>
        ))}
      </Container>
    </Box>
  );
};
