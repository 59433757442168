import { useToast } from '@chakra-ui/react';
import { isEmpty } from '@shared/utils/string';
import { validateEircode, validateFlatNo } from '@shared/utils/validation';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearData,
  getAddress,
  selectProfileAddresses,
  updateAddress,
} from 'store/slices/profileAddresses';

type FormErrors = {
  address: string;
  flatNo?: string;
  code?: string;
};

const useAddressEdit = () => {
  const { isLoading, error, selectedAddress, message } = useAppSelector(
    selectProfileAddresses,
  );
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [address, setAddress] = useState<Partial<Addresses.Address> | null>(
    selectedAddress,
  );

  const [formErrors, setFormError] = useState<FormErrors>({
    address: '',
    flatNo: '',
    code: '',
  });

  const { addressId } = useParams<{ addressId: string }>();

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, []);

  useEffect(() => {
    if (addressId) {
      dispatch(getAddress(addressId));
    }
  }, [addressId]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    } else if (message) {
      toast({
        description: message,
        status: 'success',
        isClosable: true,
      });
    }
  }, [error, message]);

  useEffect(() => {
    setAddress(selectedAddress);
  }, [selectedAddress]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case 'flatNo':
        setAddress((prevState) => ({ ...prevState, flat_no: value }));
        break;
      case 'code':
        setAddress((prevState) => ({ ...prevState, eircode: value }));
        break;
    }
  }, []);

  const resetFieldValidation = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFormError((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    },
    [],
  );

  const validate = useCallback(() => {
    const validation = {
      address: isEmpty(address?.full_address) ? 'Field is required' : '',
      flatNo: validateFlatNo(address?.flat_no ?? ''),
      code: validateEircode(address?.eircode ?? ''),
    };

    setFormError(validation);

    return Object.values(validation).every((v) => !v);
  }, [address?.full_address, address?.flat_no, address?.eircode]);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate() && addressId && address) {
        dispatch(
          updateAddress(addressId, {
            full_address: address.full_address as string,
            flat_no: address.flat_no,
            eircode: address.eircode,
          }),
        );
      }
    },
    [address, addressId],
  );

  return {
    actions: {
      onChange,
      resetFieldValidation,
      onSubmit,
    },
    data: {
      isLoading,
      address,
      formErrors,
    },
  };
};

export default useAddressEdit;
