import { Box, Flex, useBoolean, useToast } from '@chakra-ui/react';
import {
  Content as BasketContent,
  EmptyBasket,
} from '@shared/components/Basket';
import { LabelWithPriceButton } from '@shared/components/Buttons/LabelWithPriceButton';
import { Modal } from '@shared/components/Modal';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import { formatCurrency } from '@shared/utils/format';
import { generateOrderItems } from '@shared/utils/product';
import { useIsMobile, useIsTablet } from '@shared/utils/screen';
import { OrdersApi } from 'api/OrdersApi';
import OrderDetailsList from 'components/OrderDetailsList';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { useEffect, useMemo } from 'react';
import {
  matchRoutes,
  unstable_useBlocker as useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  selectBasketUpsells,
  setUpsellsModalOpen,
} from 'store/slices/basketUpsells';
import { clearBasket, selectProductModal } from 'store/slices/productModal';
import { selectServices } from 'store/slices/services';
import { selectWebsite } from 'store/slices/website';
import { prepareOrderItems } from 'utils/product';

export const basketWidth = {
  desktop: '328px',
  tablet: '248px',
};

export const Basket = () => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const toast = useToast();

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const { products: upsellsProducts, isUpsellsModalWasOpen } =
    useAppSelector(selectBasketUpsells);
  const { isAvailable } = useAppSelector(selectServices);
  const { basketData, reorderData } = useAppSelector(selectProductModal);
  const { user } = useAppSelector(selectAuth);
  const { websiteData } = useAppSelector(selectWebsite);
  const dispatch = useAppDispatch();

  const { venueId, serviceId } = useParams<{
    venueId: string;
    serviceId: string;
  }>();

  const [open, toggleModal] = useBoolean(false);

  useBlocker(({ nextLocation }) => {
    const match = matchRoutes(
      [{ path: Paths.Menu }, { path: Paths.Checkout }, { path: Paths.Profile }],
      nextLocation,
    );
    if (!match) {
      dispatch(clearBasket());
    }
    return false;
  });

  const allProducts = [...reorderData, ...basketData];

  useEffect(() => {
    if (user && venueId && serviceId && websiteData) {
      void OrdersApi.syncCart({
        order_id: null,
        venue: venueId,
        venue_group_id: websiteData.venue_group.id,
        service: serviceId,
        website: websiteData.id,
        products: [
          ...reorderData,
          ...generateOrderItems(basketData, prepareOrderItems),
        ],
      });
    }
  }, [reorderData, basketData]);

  const isEmpty = allProducts.length === 0;

  const totalAmount = useMemo(
    () =>
      reorderData.reduce(
        (sum, product) => sum + Number(product.price) * product.quantity,
        0,
      ) +
      basketData.reduce(
        (sum, product) =>
          sum + (product.amount ?? 0) * (product.totalPrice ?? 0),
        0,
      ),
    [basketData, reorderData],
  );

  const handlePlaceOrder = () => {
    if (isAvailable) {
      toggleModal.off();
      if (upsellsProducts.length && !isUpsellsModalWasOpen) {
        dispatch(setUpsellsModalOpen(true));
      } else {
        if (user) {
          navigate(Paths.Checkout);
        } else {
          setSearchParams({ modal: AuthModalParams.SignIn, ref: 'Checkout' });
        }
      }
    } else {
      toast.closeAll();
      toast({
        description: 'Unfortunately this venue is currently closed',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const renderContent = useMemo(
    () => (
      <Flex
        direction="column"
        h="100%"
        align={isEmpty ? 'center' : 'flex-start'}
        justify={isEmpty ? 'center' : 'flex-start'}
      >
        {isEmpty ? (
          <EmptyBasket />
        ) : (
          <BasketContent
            totalAmount={totalAmount}
            onPlaceOrder={handlePlaceOrder}
          >
            <OrderDetailsList
              items={allProducts}
              formattedCurrency={formatCurrency()}
            />
          </BasketContent>
        )}
      </Flex>
    ),
    [isEmpty, allProducts],
  );

  if (isMobile)
    return (
      <>
        <Box position="sticky" bottom={0} px="16px" py="12px" bgColor="white">
          <LabelWithPriceButton
            label="Your order"
            price={totalAmount}
            onClick={toggleModal.on}
          />
        </Box>
        <Modal
          isOpen={open}
          onClose={toggleModal.off}
          variant="drawer"
          bodyProps={{ p: 0 }}
        >
          {renderContent}
        </Modal>
      </>
    );

  return (
    <Box
      position="sticky"
      top={`calc(${MENU_HEIGHT}px + ${TOOLBAR_HEIGHT}px)`}
      py="16px"
      h={`calc(100dvh - ${MENU_HEIGHT}px - ${TOOLBAR_HEIGHT}px)`}
      w={isTablet ? basketWidth.tablet : basketWidth.desktop}
    >
      <Box borderRadius="2xl" bgColor="white" h="100%">
        {renderContent}
      </Box>
    </Box>
  );
};
