import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { Rating } from 'components/Rating';
import { PointsText } from 'components/Texts/PointsText';

interface Props {
  review: Orders.Review;
}

interface FeedbackProps {
  label: string;
  text: string;
  points?: string;
}

const Feedback = ({ label, text, points }: FeedbackProps) => {
  return (
    <Box>
      <Text fontSize="xs" color="gray.400" mb="4px" lineHeight="16px">
        {label}
      </Text>
      <Text fontSize="sm" lineHeight="24px" whiteSpace="pre-line">
        {points && +points > 0 ? (
          <>
            {`${text}\nThe venue gave you `}
            <PointsText points={points} isDefault /> as compensation.
          </>
        ) : (
          text
        )}
      </Text>
    </Box>
  );
};

export const ReviewResponseBlock = ({ review }: Props) => {
  return (
    <VStack
      spacing="24px"
      mt="24px"
      p="24px"
      bgColor="gray.100"
      borderRadius="10px"
      align="stretch"
    >
      <Flex justify="space-between" align="center">
        <Text fontWeight={500}>Your Review</Text>
        <Rating spacing="12px" stars={review.stars} />
      </Flex>
      <Divider />
      {review.feedback && (
        <Feedback label="Your feedback" text={review.feedback} />
      )}
      {review.response && (
        <Feedback
          label="Response"
          text={review.response}
          points={review.points_rewarded}
        />
      )}
    </VStack>
  );
};
