import { createSlice } from '@reduxjs/toolkit';
import { CouponsApi } from 'api/CouponsApi';
import { AppThunk, RootState } from 'store';

type CheckoutCouponsState = {
  isLoading: boolean;
  error: Error | null;
  coupons: Coupons.Coupon[];
  selectedCouponCode: string;
  isCouponModalOpen: boolean;
};

const initialState: CheckoutCouponsState = {
  isLoading: false,
  error: null,
  coupons: [],
  selectedCouponCode: '',
  isCouponModalOpen: false,
};

export const checkoutCouponsSlice = createSlice({
  name: 'checkoutCoupons',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },

    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },

    setCoupons: (state, action: { payload: Coupons.Coupon[] }) => {
      state.coupons = action.payload;
    },

    setSelectedCouponCode: (state, action: { payload: string }) => {
      state.selectedCouponCode = action.payload;
    },

    setIsCouponModalOpen: (state, action: { payload: boolean }) => {
      state.isCouponModalOpen = action.payload;
    },

    clearCheckoutCouponsState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.coupons = [];
      state.selectedCouponCode = '';
      state.isCouponModalOpen = false;
    },
  },
});

export const {
  setLoading,
  setError,
  setCoupons,
  setSelectedCouponCode,
  setIsCouponModalOpen,
  clearCheckoutCouponsState,
} = checkoutCouponsSlice.actions;

export const getCheckoutCoupons = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setError(null));
  try {
    const res = await CouponsApi.getConsumerCoupons();

    dispatch(setCoupons(res));
  } catch (error: unknown) {
    dispatch(setError(error as Error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const selectCheckoutCoupons = (state: RootState) =>
  state.checkoutCoupons;

export default checkoutCouponsSlice.reducer;
