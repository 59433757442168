export const areObjectsEquals = <
  O extends Record<string | number, unknown> | ReadonlyArray<unknown>,
>(
  obj1: O,
  obj2: O,
): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  const isArray = Array.isArray(obj1) && Array.isArray(obj2);

  if (isArray) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      const areElemsEqual =
        typeof obj1[i] === 'object' &&
        obj1[i] !== null &&
        typeof obj2[i] === 'object' &&
        obj2[i] !== null
          ? areObjectsEquals(
              obj1[i] as Record<string, unknown>,
              obj2[i] as Record<string, unknown>,
            )
          : obj1[i] === obj2[i];

      if (!areElemsEqual) {
        return false;
      }
    }

    return true;
  }

  const keys1 = Object.keys(obj1) as (keyof O)[];
  const keys2 = Object.keys(obj2) as (keyof O)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areValsEqual =
      typeof val1 === 'object' &&
      val1 !== null &&
      typeof val2 === 'object' &&
      val2 !== null
        ? areObjectsEquals(
            val1 as Record<string, unknown>,
            val2 as Record<string, unknown>,
          )
        : val1 === val2;

    if (!areValsEqual) {
      return false;
    }
  }

  return true;
};

export const deepObjectCopy = <O>(obj: O): O => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepObjectCopy) as O;
  }

  // Recursively deep copy object properties
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, deepObjectCopy(value)]),
  ) as O;
};

export const parseStringToObject = <T>(objString: string | null): T | null => {
  try {
    if (objString && (objString ?? '').trim()) {
      return JSON.parse(objString) as T;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const isObjectEmpty = (value: unknown[] | Record<string, unknown>) => {
  if (typeof value === 'object') return JSON.stringify(value).length < 3;
  return false;
};
