import { matchingDialCode } from './filters';
import { storage, StorageKeys } from './storage';

export const formatCurrency = (currency?: Currency | string | null): string => {
  const curr = currency ?? (storage.get(StorageKeys.CURRENCY) as Currency);
  switch (curr) {
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'USD':
      return '$';
    default:
      return curr;
  }
};

export const formatNoSpaces = (value: string) =>
  String(value).replace(/\s+/g, '');

export const formatOnlyNumbers = (value: string) =>
  formatNoSpaces(value).replace(/[^0-9]/gi, '');

export const formatPhoneNumber = (value: string) =>
  value[0] === '+'
    ? value[0] + formatOnlyNumbers(value.slice(1))
    : formatOnlyNumbers(value);

export const separateDialCodeAndPhoneNumber = (
  phoneNumber: string,
): [string, string] => {
  const dialCode = matchingDialCode(phoneNumber);
  const prefixLength = dialCode.length;
  const prefixDigits = phoneNumber.slice(0, prefixLength);
  const remainingDigits = phoneNumber.slice(prefixLength);
  return [prefixDigits, remainingDigits];
};
