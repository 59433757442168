import { IconButton } from '@chakra-ui/react';
import { BackIcon } from '@shared/assets/icons';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch } from 'store/hooks';
import {
  setNewAddress,
  setShowDeliveryDetailsSection,
  setShowServicesSection,
} from 'store/slices/uiServices';
import { isLandingMode } from 'utils/document';

export const BackToOrderOnline = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onBack = () => {
    // clear selected service and related data, same for Order online button
    dispatch(setShowDeliveryDetailsSection(false));
    dispatch(setNewAddress(null));
    dispatch(setShowServicesSection(true));

    navigate(isLandingMode ? Paths.Order : Paths.Home);
  };

  return (
    <IconButton
      aria-label="back"
      icon={<BackIcon w="24px" h="24px" />}
      isRound
      variant="gray"
      size="sm"
      onClick={onBack}
    />
  );
};
