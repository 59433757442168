import { StyleConfig, StyleFunctionProps } from '@chakra-ui/react';

export const components: Record<string, StyleConfig> = {
  Switch: {
    baseStyle: {
      track: {
        _checked: {
          bg: `primary.400`,
        },
      },
    },
  },
  Button: {
    baseStyle: {
      fontWeight: 500,
      borderRadius: '8px',
    },
    sizes: {
      sm: {
        fontSize: 14,
        px: '16px',
      },
      md: {
        fontSize: 14,
        px: '8px',
        minH: '40px',
      },
      lg: {
        fontSize: 16,
        px: '16px',
        minH: '48px',
      },
      xl: {
        height: '56px',
        minHeight: '56px',
        minW: '56px',
        px: '32px',
      },
      full: {
        height: '56px',
        minHeight: '56px',
        width: '100%',
      },
    },
    variants: {
      solid: (props: StyleFunctionProps) => ({
        bg: `${props.colorScheme}.400`,
        color: 'white',
      }),
      ghost: (props: StyleFunctionProps) => ({
        color: `${props.colorScheme}.400`,
        ':hover': {
          bgColor: 'transparent',
        },
      }),
      outline: (props: StyleFunctionProps) => ({
        color: `${props.colorScheme}.400`,
        bgColor: 'white',
        ':hover': {
          bgColor: 'white',
        },
      }),
      unstyled: {
        p: {},
        color: 'gray.900',
        minW: {},
      },
      whiteBg: {
        bg: 'white',
        color: 'gray.900',
        minW: {},
      },
      secondary: {
        bgColor: 'primary.100',
        color: 'primary.400',
        ':disabled': {
          ':hover': {
            bgColor: 'primary.100',
          },
        },
      },
      gray: {
        bgColor: 'gray.200',
        color: 'gray.400',
        ':disabled': {
          ':hover': {
            bgColor: 'gray.200',
          },
        },
      },
      link: {
        ':hover': {
          textDecoration: 'none',
        },
      },
      calculator: (props: StyleFunctionProps) => ({
        bg: `${props.colorScheme}.100`,
        color: `${props.colorScheme}.800`,
      }),
    },
    defaultProps: {
      colorScheme: 'primary',
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: 600,
      color: 'gray.900',
    },
    sizes: {
      sm: {
        fontSize: '20px',
        '@media (max-width: 760px)': {
          fontSize: '18px',
        },
      },
      md: {
        fontSize: '28px',
        '@media (max-width: 760px)': {
          fontSize: '24px',
        },
      },
    },
  },
  Text: {
    baseStyle: {
      color: 'gray.900',
    },
  },
  Textarea: {
    sizes: {
      lg: {
        minHeight: '160px',
        fontSize: '14px',
      },
    },
  },
  Divider: {
    baseStyle: {
      borderColor: 'gray.200',
    },
  },
  Checkbox: {
    baseStyle: {
      container: {
        alignItems: 'flex-start',
      },
      control: {
        p: '10px',
        borderRadius: '4px',
      },
    },
  },
  Input: {
    baseStyle: {
      field: {
        fontSize: '14px',
        ':disabled': {
          opacity: 1,
        },
      },
    },
    sizes: {
      sm: {
        field: {
          height: '30px',
        },
      },
    },
  },
};
