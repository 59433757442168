import { createSlice } from '@reduxjs/toolkit';
import { DepartmentsApi } from 'api/DepartmentsApi';
import { VenuesApi } from 'api/VenuesApi';
import { AppThunk, RootState } from 'store';
import { logout } from 'store/slices/auth';
import { generateOrderedDepartment } from 'utils/product';

type Position = {
  name: string;
  position: number;
};

type DepartmentsState = {
  isLoading: boolean;
  error: Error | null;
  departments: Departments.Department[] | null;
  data: Departments.Department[] | null;
  isFiltered: boolean;
  positions: Position[];
};

const initialState: DepartmentsState = {
  isLoading: true,
  error: null,
  departments: null,
  data: null,
  isFiltered: false,
  positions: [],
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.departments = null;
      state.data = null;
      state.isFiltered = false;
    },
    setRequestSuccess: (
      state,
      action: { payload: Departments.Department[] },
    ) => {
      state.departments = action.payload;
      state.data = action.payload;
      state.isFiltered = false;
    },
    setFilteredData: (
      state,
      action: {
        payload: Departments.Department[];
      },
    ) => {
      state.departments = action.payload;
      state.isFiltered = true;
    },
    setPositions: (
      state,
      action: {
        payload: Position[];
      },
    ) => {
      state.positions = action.payload;
    },
    clear: (state) => {
      state.isLoading = true;
      state.error = null;
      state.departments = null;
      state.data = null;
      state.isFiltered = false;
      state.positions = [];
    },
  },
});

const {
  setLoading,
  setError,
  setRequestStart,
  setRequestSuccess,
  setFilteredData,
  setPositions,
} = departmentsSlice.actions;

export const { clear: clearDepartments } = departmentsSlice.actions;

export const getDepartments =
  ({
    serviceId,
    venueId,
    serviceType,
  }: Departments.DepartmentsProductsParams & {
    serviceType: string | number;
  }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRequestStart());
    try {
      const { user } = getState().auth;
      const res = await DepartmentsApi.getDepartmentsProducts({
        serviceId,
        venueId,
      });
      let departments = [...res.departments];
      if (user) {
        const orderedIds = await VenuesApi.getOrderedProducts(
          venueId,
          serviceType,
        );
        const orderAgainDepartment = generateOrderedDepartment(
          orderedIds.results,
          res.departments,
        );
        if (orderAgainDepartment.products.length > 0) {
          departments = [orderAgainDepartment, ...departments];
        }
      }

      dispatch(setRequestSuccess(departments));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
      if (typeof error === 'object' && error !== null && 'status' in error) {
        dispatch(logout() as AppThunk);
        dispatch(
          setError({
            message: 'Something went wrong, sign in.',
            name: 'error',
          }),
        );
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

export const filterDepartments =
  (text: string): AppThunk =>
  (dispatch, getState) => {
    const { data } = getState().departments;
    if (!data) return;

    if (!text.trim()) {
      dispatch(setFilteredData(data));
    } else {
      const filteredDepartments = data.filter((d) =>
        d.products.some((p) =>
          p.name.toLowerCase().includes(text.toLowerCase()),
        ),
      );
      const filteredProducts = filteredDepartments.map((d) => ({
        ...d,
        products: d.products.filter((p) =>
          p.name.toLowerCase().includes(text.toLowerCase()),
        ),
      }));
      dispatch(setFilteredData(filteredProducts));
    }
  };

export const setElementsPosition =
  (positions: Position[]): AppThunk =>
  (dispatch) => {
    dispatch(setPositions(positions));
  };

export const selectDepartments = (state: RootState) => state.departments;

export default departmentsSlice.reducer;
