import { v4 } from 'uuid';

export const isWithoutModifiers = <P extends Product.RootObject>(
  product: P,
): boolean =>
  product.extra_sections.length < 1 ||
  product.extra_sections.every((section) =>
    [0, 3].includes(product.product_type)
      ? section.is_hidden
      : section.is_hidden ||
        (section.is_ingredient &&
          !(
            (
              section.products.find((p) => p.default_quantity > 0)
                ?.extra_sections ?? []
            ).length > 0
          )),
  );

export const countTotalPrice = <S extends Product.SelectedProducts>(
  selectedProducts: S,
): number =>
  Object.values(selectedProducts)
    .flatMap((product) => Object.values(product))
    .reduce((prev, curr) => {
      const thirdLevelTotalPrice = curr.thirdLevel
        ? countTotalPrice(curr.thirdLevel)
        : 0;
      const amount = curr.amount - curr.defaultQuantity;
      return (
        prev +
        (amount > 0 ? amount : 0) * curr.price +
        curr.amount * thirdLevelTotalPrice
      );
    }, 0);

export const generateProductDataBySelectedIds = <
  P extends Product.RootObject,
  S extends Product.SelectedProducts,
>(
  product: P,
  selected: S,
  amount?: number,
  totalPrice?: number,
  ignoreProductType?: boolean,
  note?: string,
): P => ({
  position_id: v4(),
  ...product,
  amount,
  totalPrice,
  note,
  extra_sections: product.extra_sections
    .filter(
      (es) => Object.values(selected[es.id] ?? {}).length > 0 || es.is_hidden,
    )
    .map((es) => ({
      ...es,
      products: es.products.flatMap((p) => {
        const regularOrDetailed = [0, 3].includes(product.product_type);
        const sProduct = selected[es.id]?.[p.id];
        return (
          regularOrDetailed || ignoreProductType
            ? sProduct
            : (sProduct &&
                (sProduct.amount > 0 ||
                  sProduct.amount !== sProduct.defaultQuantity)) ||
              (es.is_hidden && p.default_quantity > 0)
        )
          ? [
              sProduct?.thirdLevel ||
              (es.is_hidden && p.extra_sections.length > 0)
                ? generateProductDataBySelectedIds(
                    p,
                    sProduct?.thirdLevel ?? {},
                    sProduct?.amount ?? p.default_quantity,
                    undefined,
                    true,
                  )
                : {
                    ...p,
                    amount: sProduct?.amount ?? p.default_quantity,
                    extra_sections: [],
                  },
            ]
          : [];
      }),
    })),
});

export const generateOrderItems = <
  P extends Product.RootObject,
  Return extends Checkout.OrderItem,
>(
  data: P[],
  prepareOrderItems: (products: P[]) => Return[],
): Return[] => {
  /*
  https://linear.app/bossithq/issue/BOS-1204/rw-and-cc-upsells-implementation
  Take the products from the extras with extra_section_type === 1 ('Upsells) out of the order item and send them as order items.
  When send these order items need to pass the “relation_id” as the field “extra_section_relation_id” and amount from the parent product
  */

  let productsTakenFromExtras: P[] = [];
  const productsWithoutExtrasType1: P[] = [];

  data.forEach((product) => {
    // separate extras(extra_section_type === 1) from other in product
    const extrasType1 = product.extra_sections.filter(
      (ex) => ex.extra_section_type === 1,
    );
    const extrasTypeOther = product.extra_sections.filter(
      (ex) => ex.extra_section_type !== 1,
    );

    productsWithoutExtrasType1.push({
      ...product,
      extra_sections: extrasTypeOther,
    });

    if (extrasType1.length) {
      let extrasType1Products: P[] = [];

      extrasType1.forEach((ex) => {
        const formattedProducts: P[] = ex.products.map((exProduct) => {
          const amount = product.amount
            ? product.amount
            : product.default_quantity;

          return {
            ...exProduct,
            amount: amount * Number(exProduct.amount ?? 1), // if order 3 products with extra upsells, amount of upsells also should be 3 * amount of upsells
            extra_section_relation_id: exProduct.relation_id,
          };
        }) as P[];

        extrasType1Products = [...extrasType1Products, ...formattedProducts];
      });

      productsTakenFromExtras = [
        ...productsTakenFromExtras,
        ...extrasType1Products,
      ];
    }
  });

  return [
    ...prepareOrderItems(productsWithoutExtrasType1),
    ...prepareOrderItems(productsTakenFromExtras),
  ];
};
