import { Box } from '@chakra-ui/layout';
import { useDisclosure } from '@chakra-ui/react';
import { ArrowUpButton } from '@shared/components/Buttons/ArrowUpButton';
import { Modal } from '@shared/components/Modal';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import { useIsMobile } from '@shared/utils/screen';
import { MENU_HEIGHT } from 'pages/NavigationMenu';

interface Props {
  summarySection: React.ReactNode;
}

export const ConfirmationWrapper = ({
  children,
  summarySection,
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (isMobile) {
    return (
      <Box p="24px" position="relative">
        <ArrowUpButton onClick={onOpen} position="absolute" right="24px" />
        {summarySection}
        <Modal isOpen={isOpen} onClose={onClose} variant="drawer">
          {children}
        </Modal>
      </Box>
    );
  }
  return (
    <Box
      minW="300px"
      flex={0.3}
      borderRadius="2xl"
      p="24px"
      minH="500px"
      maxH={`calc(100dvh - ${MENU_HEIGHT}px - ${TOOLBAR_HEIGHT}px - 88px - 88px)`}
      overflowY="auto"
    >
      {children}
    </Box>
  );
};
