import { BaseApiService } from './BaseApiService';

export class WebsitesApi {
  public static async getWebsite(id: string) {
    const response = await BaseApiService.get<Website.RootObject>({
      path: `websites/${id}/?expand=config`, // should allways expand config

      authRequired: false,
    });
    return response;
  }

  public static async contact(id: string, data: ContactBody.RootObject) {
    const response = await BaseApiService.post<ContactBody.RootObject>({
      path: `websites/${id}/contact-us/`,

      authRequired: false,
      body: data,
    });
    return response;
  }
}
