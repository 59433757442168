export enum Paths {
  Home = '/',
  About = '#about',
  LandingVouchers = '#vouchers',
  Contact = '#contact',
  TermsConditions = '/terms-conditions',
  CookiePolicy = '/cookie-policy',
  PrivacyPolicy = '/privacy-policy',
  ResetPassword = '/reset-password/confirm/:uid/:token',
  Order = '/order',
  OrderStatus = '/order/details/:orderId',
  OrderTracking = '/track-order/:token',
  Menu = '/order/menu/:venueId/:serviceId/:department?',
  Profile = '/profile',
  ProfileOrderHistory = '/profile/order-history',
  ProfileOrderHistoryItem = '/profile/order-history/:orderId',
  ProfileSettings = '/profile/settings',
  ProfileVouchers = '/profile/vouchers',
  ProfileVoucherDetails = '/profile/vouchers/:voucherId',
  ProfileVoucherRedeem = '/profile/vourchers/redeem',
  ProfileDeleteAccount = '/profile/delete-account',
  Checkout = '/order/checkout',
  ProfileSavedAddresses = '/profile/saved-addresses',
  ProfileSavedAddressesEdit = '/profile/saved-addresses/:addressId',
  Voucher = '/voucher',
  VoucherRedeem = '/redeem/:voucherId',
  Test = '/test',
}

export enum AuthModalParams {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ForgotPassword = 'ForgotPassword',
  PhoneVerification = 'PhoneVerification',
}
