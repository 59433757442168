import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback } from 'react';

import useCheckoutVouchers from './hooks';
import VoucherDetails from './VoucherDetails';
import VoucherModalList from './VoucherModalList';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  totalAmount: string;
}

const VoucherModal = ({ isOpen, onClose, totalAmount }: Props) => {
  const isMobile = useIsMobile();
  const {
    actions: { handleSelectVoucher },
    data: { isLoading, selectedVoucher, vouchers },
  } = useCheckoutVouchers();

  const handleCloseModal = useCallback(() => {
    onClose();
    handleSelectVoucher(null);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      size={{ mobile: 'full', desktop: 'md' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
      title={selectedVoucher ? 'Confirm voucher' : 'Voucher Wallet'}
      onBack={selectedVoucher ? () => handleSelectVoucher(null) : undefined}
    >
      {selectedVoucher ? (
        <VoucherDetails
          voucher={selectedVoucher}
          handleSelectVoucher={handleSelectVoucher}
          totalAmount={totalAmount}
        />
      ) : (
        <VoucherModalList
          vouchers={vouchers}
          isLoading={isLoading}
          handleSelectVoucher={handleSelectVoucher}
        />
      )}
    </Modal>
  );
};

export default VoucherModal;
