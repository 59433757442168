export const findLast = <T>(
  array: T[],
  predicate: (value: T) => boolean,
): T | undefined => {
  if (typeof array.findLast === 'function') {
    return array.findLast(predicate);
  } else {
    for (let i = array.length - 1; i >= 0; i--) {
      if (predicate(array[i])) {
        return array[i];
      }
    }
    return undefined;
  }
};
