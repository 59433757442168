import { Heading } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { VirtualizedList } from '@shared/components/VirtualizedList';
import OrderHistoryItem from 'components/OrderHistoryItem';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';

import useProfileOrdersHistory from './hooks';

export const ProfileOrderHistory = () => {
  const {
    actions: { onScroll },
    data: { ordersHistory, pagination },
  } = useProfileOrdersHistory();
  const navigate = useNavigate();

  return (
    <WidgetWrapper
      withContainer
      containerWidth="xl"
      containerProps={{ flex: 1 }}
    >
      <BackButton onClick={() => navigate(-1)} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" m="16px 0 24px">
        Order history
      </Heading>

      <VirtualizedList
        loadMoreRows={onScroll}
        remoteRowCount={pagination.count}
        flex={1}
        deps={ordersHistory}
      >
        {ordersHistory.map(
          ({
            id,
            venue_name,
            total_amount,
            currency,
            created_at,
            order_number,
            status,
            review,
          }) => (
            <OrderHistoryItem
              key={id}
              name={venue_name}
              id={id}
              amount={total_amount}
              currency={currency}
              date={created_at}
              basePath={Paths.ProfileOrderHistory}
              order_number={order_number}
              status={status}
              review={review}
            />
          ),
        )}
      </VirtualizedList>
    </WidgetWrapper>
  );
};
