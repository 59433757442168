import { Box, Flex, Heading, Image, Link, Stack, Text } from '@chakra-ui/react';
import CallIcon from '@shared/assets/icons/call-2.svg';
import { LabelWithPriceText } from '@shared/components/Texts/LabelWithPriceText';
import { formatCurrency } from '@shared/utils/format';
import { useIsMobile } from '@shared/utils/screen';
import OrderDetailsList from 'components/OrderDetailsList';
import { format, isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import get from 'lodash.get';
import { memo, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

import { OrderStatusConfirmationHeading } from './Heading';
import { getDepositCount } from './helpers';
import { ReviewResponseBlock } from './ReviewResponseBlock';

interface Iprops {
  eta: Date | null;
  serviceType: number | null;
  venuePhone: string | null;
  points: number | null;
  currency: Currency | null;
  subtotalAmount: string;
  tipsAmount?: string;
  driverTipsAmount?: string;
  totalAmount: string;
  orderItems: Orders.OrderItemDetails[];
  deliveryFeeAmount?: string;
  serviceFeeAmount?: string;
  orderDiscounts: Orders.OrderDiscountShort[];
  status?: Orders.Status;
  driverStatus?: Orders.DriverStatus;
  address: string;
  venueName: string;
  orderNumber: number;
  voucherTransactions: Orders.OrderVaucherTransaction[];
  startPreparingAt: string | null;
  completeAt: string | null;
  timeZone?: string;
  review: Orders.Review | null;
  depositReturnSchemeAmount?: string;
}

const Confirmation = ({
  eta,
  serviceType,
  venuePhone,
  points,
  currency,
  subtotalAmount,
  tipsAmount,
  driverTipsAmount,
  totalAmount,
  orderItems,
  deliveryFeeAmount,
  serviceFeeAmount,
  orderDiscounts,
  status,
  driverStatus,
  address,
  venueName,
  orderNumber,
  voucherTransactions,
  completeAt,
  startPreparingAt,
  timeZone,
  review,
  depositReturnSchemeAmount,
}: Iprops) => {
  const { websiteData } = useAppSelector(selectWebsite);
  const isMobile = useIsMobile();

  const discountAmount = orderDiscounts.reduce(
    (acc, item) => acc + Number(item.total_amount),
    0,
  );

  const voucherAmountSum = voucherTransactions.reduce(
    (acc, next) => acc + Number(next.amount),
    0,
  );

  const discountType = get(orderDiscounts, [0, 'discount_type']);

  const scheduledFor = useMemo(() => {
    const date =
      completeAt &&
      timeZone &&
      isValid(new Date(completeAt)) &&
      utcToZonedTime(completeAt, timeZone);

    return date ? format(date, 'MMMM dd, yyyy HH:mm') : null;
  }, [completeAt, timeZone]);

  const depositCount = useMemo(() => getDepositCount(orderItems), [orderItems]);

  const formattedCurrency = formatCurrency(currency);

  return (
    <>
      <OrderStatusConfirmationHeading
        hideReview={!!review}
        startPreparingAt={startPreparingAt}
        status={status}
        driverStatus={driverStatus}
        orderNumber={orderNumber}
        serviceType={serviceType}
        eta={eta}
        completeAt={completeAt}
      />

      {!!venuePhone && (
        <Box padding="24px 0" borderBottom="1px solid" borderColor="gray.200">
          <Flex>
            <Box flexGrow="1" paddingTop="2px">
              <Text
                as="span"
                display="block"
                fontSize="12px"
                lineHeight="16px"
                marginBottom="4px"
                color="gray.400"
              >
                Call restaurant
              </Text>
              <Text
                display="block"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
              >
                {venuePhone}
              </Text>
            </Box>

            <Box width="50px">
              <Link
                href={`tel:${venuePhone}`}
                display="inline-block"
                padding="15px"
                borderRadius="50%"
                backgroundColor="gray.100"
                verticalAlign="top"
                _hover={{ opacity: '0.7' }}
                _focus={{ opacity: '0.7' }}
              >
                <Image src={CallIcon} w="100%" alt="call" />
              </Link>
            </Box>
          </Flex>
        </Box>
      )}

      <Box padding="24px 0" borderBottom="1px solid" borderColor="gray.200">
        <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
          <Box flex={1}>
            <Text fontSize="12px" lineHeight="16px" color="gray.400">
              Venue
            </Text>

            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {venueName}
            </Text>
          </Box>

          <Box flex={1}>
            <Text fontSize="12px" lineHeight="16px" color="gray.400">
              Address
            </Text>

            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {address}
            </Text>
          </Box>
        </Stack>

        {scheduledFor && (
          <Box mt="16px">
            <Text fontSize="12px" lineHeight="16px" color="gray.400">
              Scheduled For
            </Text>

            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {scheduledFor}
            </Text>
          </Box>
        )}
      </Box>

      <Box borderBottom="1px solid" borderColor="gray.200" paddingBottom="24px">
        <Heading as="h3" fontSize="20px" lineHeight="32px" margin="24px 0">
          Order details
        </Heading>

        <OrderDetailsList
          items={orderItems}
          formattedCurrency={formattedCurrency}
        />
      </Box>

      {/* Show earned points only if order is completed (status=7) */}
      {Boolean(points) && status === 7 && (
        <Flex
          borderBottom="1px solid"
          borderColor="gray.200"
          padding="24px 0"
          columnGap="16px"
        >
          <Text fontSize="12px" lineHeight="16px" fontWeight="600" width="60%">
            {websiteData?.venue_group?.points_config?.name || 'Points'} earned
          </Text>
          <Text
            fontSize="12px"
            lineHeight="16px"
            textAlign="right"
            color="gray.400"
            width="40%"
          >
            {points}
          </Text>
        </Flex>
      )}

      <Box paddingTop="24px">
        <Flex rowGap="8px" flexDirection="column" marginBottom="16px">
          <LabelWithPriceText
            label="Subtotal"
            price={subtotalAmount}
            currency={currency}
          />
          {deliveryFeeAmount && serviceType === 0 && (
            <LabelWithPriceText
              label="Delivery"
              price={deliveryFeeAmount}
              currency={currency}
            />
          )}
          {depositReturnSchemeAmount && (
            <LabelWithPriceText
              label="Deposit return scheme"
              price={depositReturnSchemeAmount}
              count={depositCount}
              currency={currency}
            />
          )}
          {serviceFeeAmount && (
            <LabelWithPriceText
              label="Processing Fee"
              price={serviceFeeAmount}
              currency={currency}
            />
          )}
          {tipsAmount && (
            <LabelWithPriceText
              label="Tips"
              price={tipsAmount}
              currency={currency}
            />
          )}
          {driverTipsAmount && (
            <LabelWithPriceText
              label="Driver tips"
              price={driverTipsAmount}
              currency={currency}
            />
          )}
          {Boolean(orderDiscounts.length) && (
            <LabelWithPriceText
              label={
                discountType === 1
                  ? `Discount (${
                      websiteData?.venue_group?.points_config?.name || 'points'
                    })`
                  : 'Discount'
              }
              price={discountAmount.toFixed(2)}
              currency={currency}
              negative
            />
          )}

          {Boolean(voucherTransactions.length) && (
            <LabelWithPriceText
              label="Voucher"
              price={voucherAmountSum.toFixed(2)}
              currency={currency}
              negative
            />
          )}
        </Flex>

        <LabelWithPriceText
          largeSize
          label="Total"
          price={totalAmount}
          currency={currency}
        />

        {review && <ReviewResponseBlock review={review} />}
      </Box>
    </>
  );
};

export default memo(Confirmation);
