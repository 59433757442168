import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { addMinutes, format, isAfter } from 'date-fns';
import { useMemo } from 'react';

import { Reorder } from './Reorder';
import { Review } from './Review';

interface Props {
  status?: Orders.Status;
  orderNumber: number;
  driverStatus?: Orders.DriverStatus;
  serviceType: number | null;
  eta: Date | null;
  hideDivider?: true;
  startPreparingAt: string | null;
  hideReview: boolean;
  completeAt: string | null;
}

export const OrderStatusConfirmationHeading = ({
  status,
  orderNumber,
  driverStatus,
  serviceType,
  eta,
  hideDivider,
  startPreparingAt,
  hideReview,
  completeAt,
}: Props) => {
  const showEta = !!eta && [0, 1, 2, 3, 4, 9, 11].includes(status ?? -1);

  const orderNumberString = orderNumber > 0 ? `#${orderNumber}` : '';

  const orderCompleted = status === 7;

  const driverStatusText = useMemo(() => {
    switch (driverStatus) {
      case 2:
        return '. Delivering your order';
      case 3:
        return '. Delivery completed';
      case 4:
        return '. Driver has arrived';
      default:
        return ' is preparing';
    }
  }, [driverStatus]);

  const title = useMemo(() => {
    const startPrepareDate = startPreparingAt && new Date(startPreparingAt);
    switch (status) {
      case 1:
      case 2:
      case 3:
        return completeAt !== null &&
          startPrepareDate &&
          isAfter(startPrepareDate, new Date())
          ? `Your order ${orderNumberString} is scheduled`
          : `Your order ${orderNumberString} is preparing`;
      case 4:
        return `Order ${orderNumberString}${driverStatusText}`;
      case 5:
      case 6:
      case 7:
        return `Order ${orderNumberString} is completed`;
      case 8:
        return `Order ${orderNumberString} is cancelled`;
      case 0:
      case 10:
        return `${
          orderNumberString ? `Order ${orderNumberString}.` : ''
        } Awaiting confirmation`;
      case 11:
        return completeAt !== null &&
          startPrepareDate &&
          isAfter(startPrepareDate, new Date())
          ? `Your order ${orderNumberString} is scheduled`
          : `${
              orderNumberString ? `Order ${orderNumberString}.` : ''
            } Awaiting preparation`;
    }
  }, [status, driverStatusText, startPreparingAt]);

  return (
    <>
      <HStack justify="space-between">
        <Heading as="h2" fontSize="24px" lineHeight="28px">
          {title}
        </Heading>
        {orderCompleted && <Reorder />}
      </HStack>

      {orderCompleted && !hideReview && <Review />}

      {[0, 10].includes(status ?? -1) && (
        <Box padding="24px 0">
          <Text as="span" fontSize="14px" lineHeight="24px" color="gray.400">
            {`Your order ${orderNumberString} is awaiting confirmation from the venue.`}
          </Text>
        </Box>
      )}

      {showEta && (
        <Box
          pt="24px"
          pb={hideDivider ? 0 : '24px'}
          borderBottom={hideDivider ? undefined : '1px solid'}
          borderColor="gray.200"
        >
          <Box
            fontSize="12px"
            lineHeight="16px"
            display="flex"
            alignItems="center"
          >
            <Text
              as="span"
              fontSize="14px"
              lineHeight="24px"
              color="gray.400"
              width="60%"
            >
              {serviceType === 0
                ? 'Estimated arrival'
                : 'Estimated to be ready'}
            </Text>
            <Text
              as="span"
              fontSize="20px"
              lineHeight="32px"
              fontWeight="600"
              width="40%"
              textAlign="right"
            >{`${format(eta, 'HH:mm')}-${format(
              addMinutes(eta, 10),
              'HH:mm',
            )}`}</Text>
          </Box>
        </Box>
      )}
    </>
  );
};
