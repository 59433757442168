import {
  Box,
  CloseButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import SearchIcon from '../../assets/icons/search.svg';

interface Props {
  onTextChange: (value: string) => void;
  isShowBorder?: true;
  isSmallSize?: true;
}

export const ExpandableSearch = ({
  onTextChange,
  isShowBorder,
  isSmallSize,
}: Props) => {
  const [showInput, setShowInput] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const handleOpen = useCallback(() => {
    setShowInput(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowInput(false);
    setText('');
    onTextChange('');
  }, []);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    onTextChange(e.target.value);
  }, []);

  return (
    <Box
      w={isSmallSize ? '40px' : '48px'}
      h={isSmallSize ? '40px' : '48px'}
      zIndex={1}
    >
      <InputGroup
        position="absolute"
        right={0}
        w={showInput ? '100%' : isSmallSize ? '40px' : '48px'}
        transitionProperty="width"
        transitionDuration="1s"
        size={isSmallSize ? 'md' : 'lg'}
        borderColor={isShowBorder ? 'gray.200' : 'transparent'}
      >
        <InputLeftElement pointerEvents="none">
          <Image src={SearchIcon} />
        </InputLeftElement>
        <Input
          value={showInput ? text : ''}
          onChange={onChange}
          p={showInput ? undefined : 0}
          bgColor="white"
          focusBorderColor="transparent"
          _hover={{}}
          _focusVisible={{}}
          cursor={showInput ? undefined : 'pointer'}
          onClick={showInput ? undefined : handleOpen}
          fontSize="inputBase"
        />
        {showInput && (
          <InputRightElement>
            <CloseButton color="gray.400" onClick={handleClose} />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
};
