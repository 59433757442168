import { AspectRatio, Box, Container, Heading } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';
import ReactPlayer from 'react-player';

interface Props {
  videoUrl: string;
}

export const VideoSection = ({ videoUrl }: Props) => {
  const isMobile = useIsMobile();
  const filteredUrl = videoUrl.replace('youtube.com', 'youtube-nocookie.com');
  return (
    <Box bgColor="gray.900">
      <Container
        maxW="1280px"
        px={isMobile ? '16px' : '72px'}
        py={isMobile ? '80px' : '120px'}
      >
        <Heading size="xl" mb="40px" color="white">
          How we do it
        </Heading>
        <AspectRatio ratio={16 / 9} sx={{ iframe: { borderRadius: '12px' } }}>
          <ReactPlayer url={filteredUrl} controls width="100%" height="100%" />
        </AspectRatio>
      </Container>
    </Box>
  );
};
