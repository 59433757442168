import { Button, HStack } from '@chakra-ui/react';
import { CounterButton } from '@shared/components/Buttons/CounterButton';
import { LabelWithPriceButton } from '@shared/components/Buttons/LabelWithPriceButton';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectProductModal, setTotalAmount } from 'store/slices/productModal';

interface Props {
  price: number;
  onSubmit: (count: number) => void;
  disabled?: boolean;
}

export const AddToCard = ({ price, onSubmit, disabled }: Props) => {
  const { totalAmount } = useAppSelector(selectProductModal);
  const isMobile = useIsMobile();
  const [count, setCount] = useState<number>(totalAmount);
  const [amount, setAmount] = useState<number>(price);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAmount(price * count);
    dispatch(setTotalAmount(count));
  }, [count, price]);

  const handleSubmit = useCallback(() => {
    onSubmit(count);
  }, [count, onSubmit]);

  return (
    <HStack p={isMobile ? '12px 16px' : '24px'} spacing="24px">
      <CounterButton count={count} onChange={setCount} />
      {amount > 0 ? (
        <LabelWithPriceButton
          label="Add to basket"
          price={amount}
          onClick={handleSubmit}
          isDisabled={disabled}
        />
      ) : (
        <Button w="full" size="xl" onClick={handleSubmit} isDisabled={disabled}>
          Add to basket
        </Button>
      )}
    </HStack>
  );
};
