import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import auth from './slices/auth';
import basketUpsells from './slices/basketUpsells';
import checkoutCoupons from './slices/checkoutCoupons';
import checkoutVouchers from './slices/checkoutVouchers';
import departments from './slices/departments';
import orders from './slices/orders';
import ordersHistory from './slices/ordersHistory';
import orderTracking from './slices/orderTracking';
import productModal from './slices/productModal';
import profile from './slices/profile';
import profileAddresses from './slices/profileAddresses';
import profileVouchers from './slices/profileVouchers';
import services from './slices/services';
import tables from './slices/tables';
import uiServices from './slices/uiServices';
import venues from './slices/venues';
import website from './slices/website';

const reducers = {
  auth,
  basketUpsells,
  checkoutCoupons,
  checkoutVouchers,
  website,
  departments,
  orders,
  ordersHistory,
  orderTracking,
  productModal,
  profile,
  profileAddresses,
  profileVouchers,
  venues,
  uiServices,
  tables,
  services,
};

const rootReducer = combineReducers(reducers);

const store = configureStore({
  reducer: rootReducer,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
