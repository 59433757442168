import { Box, Button, Flex, IconButton, Image } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';

import ChevronLeftIcon from '../../assets/icons/chevron-left.svg';
import ChevronRightIcon from '../../assets/icons/chevron-right.svg';
import {
  DepartmentPosition,
  useDepartmentScrollNavigation,
} from '../../hooks/useDepartmentScrollNavigation';
import { useIsMobile } from '../../utils/screen';
import { removeSpaces } from '../../utils/string';

interface Props {
  items: string[];
  positions: DepartmentPosition[];
  menuPath: string;
}

export const MenuTabs = ({ items = [], positions, menuPath }: Props) => {
  const isMobile = useIsMobile();
  const wrapperRef = createRef<HTMLDivElement>();
  const btnRefs = useRef<Record<string, HTMLButtonElement>>({});

  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const [selected, onSelect] = useDepartmentScrollNavigation({
    menuPath,
    selected: items[0],
    positions,
  });

  const onScroll = (el: HTMLDivElement) =>
    debounce(() => {
      setShowPrev(el.scrollLeft > 0);
      setShowNext(el.scrollLeft < el.scrollWidth - el.clientWidth);
    }, 40);

  useEffect(() => {
    wrapperRef.current && onScroll(wrapperRef.current)();
    wrapperRef.current?.addEventListener(
      'scroll',
      onScroll(wrapperRef.current),
    );

    return () => {
      wrapperRef.current?.removeEventListener(
        'scroll',
        onScroll(wrapperRef.current),
      );
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    if (btnRefs.current[selected]) {
      btnRefs.current[selected]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [selected, btnRefs]);

  const handleSelect = useCallback(
    (value: string) => () => {
      onSelect(value);
    },
    [onSelect],
  );

  const handleMouseDown = (direction: 'left' | 'right') => {
    const maxScrollWidth = wrapperRef.current
      ? wrapperRef.current.scrollWidth - wrapperRef.current.clientWidth
      : 0;
    wrapperRef.current?.scrollTo({
      left: direction === 'left' ? 0 : maxScrollWidth,
      behavior: 'smooth',
    });
  };

  const handleMouseUp = () => {
    wrapperRef.current?.scrollTo({
      left: wrapperRef.current.scrollLeft,
    });
  };

  return (
    <>
      {showPrev && !isMobile && (
        <>
          <IconButton
            aria-label="arrow-left"
            variant="whiteBg"
            size="lg"
            minW="24px"
            w="24px"
            borderRadius="md"
            position="absolute"
            zIndex={1}
            icon={<Image src={ChevronLeftIcon} userSelect="none" />}
            onTouchStart={() => handleMouseDown('left')}
            onTouchEnd={handleMouseUp}
            onMouseDown={() => handleMouseDown('left')}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            userSelect="none"
          />
          <Box
            zIndex={1}
            position="absolute"
            w="16px"
            h="48px"
            left="24px"
            background="linear-gradient(90deg, #F4F5F5 0%, rgba(244, 245, 245, 0) 100%)"
          />
        </>
      )}
      <Flex
        ref={wrapperRef}
        flex={1}
        className="hide-scrollbar"
        overflowX="auto"
      >
        {items.map((item, i) => {
          const removedSpaceName = removeSpaces(item);
          return (
            <Button
              key={`${i}-${item}`}
              ref={(ref) => {
                btnRefs.current[removedSpaceName] = ref as HTMLButtonElement;
              }}
              size="lg"
              variant={removedSpaceName === selected ? 'whiteBg' : 'unstyled'}
              onClick={handleSelect(removedSpaceName)}
            >
              {item}
            </Button>
          );
        })}
      </Flex>
      {showNext && !isMobile && (
        <>
          <Box
            position="absolute"
            w="16px"
            h="48px"
            right="76px"
            background="linear-gradient(270deg, #F4F5F5 0%, rgba(244, 245, 245, 0) 100%)"
          />
          <IconButton
            position="absolute"
            zIndex={1}
            right="52px"
            aria-label="arrow-right"
            variant="whiteBg"
            size="lg"
            minW="24px"
            w="24px"
            borderRadius="md"
            icon={<Image src={ChevronRightIcon} userSelect="none" />}
            onTouchStart={() => handleMouseDown('right')}
            onTouchEnd={handleMouseUp}
            onMouseDown={() => handleMouseDown('right')}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            userSelect="none"
          />
        </>
      )}
    </>
  );
};
