import { Card, CardBody, Heading, HStack, Image, Text } from '@chakra-ui/react';
import { useCallback } from 'react';

import ProductIcon from '../../assets/images/product.svg';
import { CaloriesText } from '../Texts/CaloriesText';
import { PriceText } from '../Texts/PriceText';

interface Props {
  product: Product.RootObject;
  isMobile: boolean;
  onClick: (product: Product.RootObject) => void;
  Points?: React.ReactNode;
  showDescription?: boolean;
  showCalories?: boolean;
}

export const ProductCard = ({
  product,
  isMobile,
  onClick,
  Points,
  showDescription = true,
  showCalories = true,
}: Props) => {
  const {
    image,
    image_thumbnails,
    name,
    display_name,
    description,
    price,
    calories,
  } = product;

  const imageUrl = image_thumbnails?.medium?.url ?? image;

  const handleOnClick = useCallback(() => {
    onClick(product);
  }, [product, onClick]);

  return (
    <Card
      direction={isMobile ? 'row' : 'column'}
      variant="outline"
      w={isMobile ? '100%' : '200px'}
      h="100%"
      borderRadius="lg"
      p={isMobile ? '8px' : '16px'}
      cursor="pointer"
      borderColor="transparent"
      _hover={{
        borderColor: 'gray.200',
      }}
      onClick={handleOnClick}
    >
      <Image
        w={isMobile ? '96px' : '168px'}
        h={isMobile ? '96px' : '168px'}
        objectFit={imageUrl ? 'cover' : 'contain'}
        src={imageUrl}
        fallbackSrc={ProductIcon}
        mr={isMobile ? '8px' : 0}
        mb={isMobile ? 0 : '4px'}
        borderRadius="md"
      />
      <CardBody p={isMobile ? '8px' : 0} display="flex" flexDirection="column">
        <Heading size="xs" mb="4px">
          {display_name ?? name}
        </Heading>
        {description && showDescription && (
          <Text
            fontSize="xs"
            color="gray.400"
            mb="4px"
            whiteSpace="break-spaces"
          >
            {description}
          </Text>
        )}
        {showCalories && Number(calories) ? (
          <CaloriesText calories={calories} mb="4px" />
        ) : null}
        <HStack spacing="16px" mt="auto" alignItems="center">
          {+price > 0 && <PriceText price={+price} />}
          {Points}
        </HStack>
      </CardBody>
    </Card>
  );
};
