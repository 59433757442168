import { Divider } from '@chakra-ui/react';
import { generateProductDataBySelectedIds } from '@shared/utils/product';
import TagManager from '@sooro-io/react-gtm-module';
import { WIDGET_UPSELL_ADDED } from 'constants/googleTagManagerEvents';
import get from 'lodash.get';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAddedToCardProductIdsAction } from 'store/slices/basketUpsells';
import {
  addProductToBasket,
  selectProductModal,
} from 'store/slices/productModal';

import { AddToCard } from './AddToCard';
import { ProductDetail } from './ProductDetail';

interface Props {
  product: Product.RootObject;
}

export const ProductWithoutModifiers = ({ product }: Props) => {
  const dispatch = useAppDispatch();
  const { isUpsellsProduct } = useAppSelector(selectProductModal);

  const handleAddToCard = useCallback(
    (amount: number) => {
      const productData = generateProductDataBySelectedIds(
        product,
        {},
        amount,
        Number(product.price),
      );

      dispatch(addProductToBasket(productData));

      if (isUpsellsProduct) {
        dispatch(setAddedToCardProductIdsAction(product.id));

        TagManager.dataLayer({
          dataLayer: {
            event: WIDGET_UPSELL_ADDED,
            product_id: product.id,
            product_price: +get(productData, 'totalPrice', 0).toFixed(2),
            product_count: amount,
          },
        });
      }
    },
    [isUpsellsProduct],
  );

  const { image, image_thumbnails } = product;

  return (
    <>
      <ProductDetail
        name={product.display_name ?? product.name}
        price={product.price}
        points={product.points}
        description={product.description}
        imageUrl={get(image_thumbnails, ['medium', 'url'], image)}
        allergens={product.allergens}
      />
      <Divider />
      <AddToCard price={Number(product.price)} onSubmit={handleAddToCard} />
    </>
  );
};
