import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  clearData,
  getOrdersHistory,
  getOrdersHistoryPaginationItems,
  selectOrdersHistory,
} from 'store/slices/ordersHistory';

const useProfileOrdersHistory = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { user } = useAppSelector(selectAuth);
  const { ordersHistory, error, pagination } =
    useAppSelector(selectOrdersHistory);

  useEffect(() => {
    if (user?.venue_group) {
      dispatch(
        getOrdersHistory({
          params: {
            venue_group: user?.venue_group,
            ordering: '-created_at',
            limit: 30,
            expand: 'review',
          },
        }),
      );
    }
  }, [user?.venue_group]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const onScroll = useCallback(() => {
    if (pagination.next) {
      dispatch(
        getOrdersHistoryPaginationItems({
          params: {
            venue_group: user?.venue_group,
            ordering: '-created_at',
            limit: 10,
            cursor: pagination.next,
          },
        }),
      );
    }
  }, [user?.venue_group, pagination.next]);

  useEffect(() => {
    return () => {
      dispatch(clearData());
    };
  }, []);

  return {
    actions: { onScroll },
    data: {
      ordersHistory,
      pagination,
    },
  };
};

export default useProfileOrdersHistory;
