export enum PaymentProvider {
  None = 'None',
  Stripe = 'stripe',
  Paydoo = 'paydoo',
  Viva = 'viva_wallet',
}

export enum PaymentType {
  'Cash',
  'Card',
  'AnyExcuse',
  'Online',
  'Online cash',
  'Voucher',
  'Deliveroo',
  'Just-Eat',
  'Corporate',
  'Corporate Invoiced',
  'Corporate Prepaid',
  'TLT',
  'Legacy Online',
  'MOTO',
  'Open',
  'Uber',
}
