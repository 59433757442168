import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { UserApi } from 'api/UserApi';
import { useCallback, useRef, useState } from 'react';

import { PhoneInputForm } from './PhoneInputForm';
import { PointsScreen } from './PointsScreen';
import { VerificationForm } from './VerificationForm';

interface Props {
  onClose: VoidFunction;
}

enum Screen {
  registration,
  verification,
  points,
}

export const PhoneVerificationForm = ({ onClose }: Props) => {
  const [phone, setPhone] = useState<string>('');
  const [screen, setScreen] = useState<Screen>(Screen.registration);
  const [points, setPoints] = useState<number>(0);

  const toast = useToast();
  const [timer, setTimer] = useState<number>(0);

  const interval = useRef<NodeJS.Timer>();

  const sendVerificationCode = useCallback(
    (phoneNumber: string, ignoreTimer?: boolean) => {
      if (timer === 0 || ignoreTimer) {
        clearInterval(interval.current);
        setPhone(phoneNumber);

        let startTimer = 60;
        setTimer(startTimer);

        interval.current = setInterval(() => {
          startTimer--;
          setTimer(startTimer);
          if (startTimer === 0) {
            clearInterval(interval.current);
          }
        }, 1000);

        void UserApi.phoneVerifyStart(phoneNumber)
          .then(() => {
            setScreen(Screen.verification);
          })
          .catch((error: ApiTypes.ResponseError) => {
            toast.closeAll();
            toast({
              description: error.message || String(error),
              status: 'error',
              isClosable: true,
            });
          });
      }
    },
    [timer, interval.current],
  );

  const openPointsScreen = useCallback((value: number) => {
    setPoints(value);
    setScreen(Screen.points);
  }, []);

  switch (screen) {
    case Screen.verification:
      return (
        <>
          <VerificationForm
            phone={phone}
            onClose={onClose}
            onEdit={() => setScreen(Screen.registration)}
            setPoints={openPointsScreen}
          />
          <Flex align="center" justify="space-between" my="24px">
            <Text>Resend code{timer > 0 ? ` in ${timer} sec` : ''}</Text>
            <Button
              variant="secondary"
              onClick={() => sendVerificationCode(phone)}
              isDisabled={timer > 0}
            >
              Resend
            </Button>
          </Flex>
        </>
      );
    case Screen.points:
      return <PointsScreen points={points} onClose={onClose} />;
    default:
      return (
        <PhoneInputForm
          phone={phone}
          onClose={onClose}
          onSubmit={sendVerificationCode}
        />
      );
  }
};
