import { Button, Center, Heading, Text } from '@chakra-ui/react';
import { PointIcon } from '@shared/assets/icons';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

interface Props {
  points: number;
  onClose: VoidFunction;
}

export const PointsScreen = ({ points, onClose }: Props) => {
  const { websiteData } = useAppSelector(selectWebsite);
  return (
    <Center flexDir="column">
      <PointIcon
        w="160px"
        h="160px"
        bgColor="secondary"
        borderRadius="full"
        color="primary.400"
      />
      <Heading my="16px" size="md">
        Congratulations!
      </Heading>
      <Text>
        You get {points}{' '}
        {websiteData?.venue_group?.points_config?.name || 'points'}!
      </Text>
      <Button mt="40px" size="full" onClick={onClose}>
        OK
      </Button>
    </Center>
  );
};
