import {
  Box,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  Text,
  Wrap,
} from '@chakra-ui/react';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import { useIsMobile } from '@shared/utils/screen';

interface Props {
  locations?: Website.Location[];
}

export const Locations = ({ locations = [] }: Props) => {
  const isMobile = useIsMobile();
  if (locations.length < 1) return null;
  return (
    <Box bgColor="gray.100">
      <Container
        maxW="1280px"
        px={isMobile ? '16px' : '72px'}
        py={isMobile ? '80px' : '120px'}
      >
        <Heading size="xl" mb="56px">
          Locations
        </Heading>
        <Wrap spacing="20px">
          {locations.map((location) => (
            <Box
              key={location.id}
              width={isMobile ? '100%' : 'calc(50% - 20px)'}
            >
              <HStack spacing="20px">
                <Image src={MarkerIcon} alt="Location" />
                <Text fontWeight={600} fontSize="sm">
                  {location.address}
                </Text>
              </HStack>
              <Divider mt="16px" />
            </Box>
          ))}
        </Wrap>
      </Container>
    </Box>
  );
};
