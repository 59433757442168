export enum ColorPalettes {
  '#AA0DFE',
  '#3283FE',
  '#85660D',
  '#70CC8B',
  '#565656',
  '#1C8356',
  '#16FF32',
  '#F7E1A0',
  '#CBDC38',
  '#1CBE4F',
  '#C4451C',
  '#DEA0FD',
  '#FE00FA',
  '#325A9B',
  '#FEAF16',
  '#F8A19F',
  '#90AD1C',
  '#F6222E',
  '#1CFFCE',
  '#2ED9FF',
  '#B10DA1',
  '#FF72FF',
  '#C075A6',
  '#FC1CBF',
  '#B00068',
  '#FBE426',
  '#FA0087',
  '#0000FF',
  '#AA4466',
  '#00BDD6',
  '#36648B',
  '#FF9F73',
  '#F1903D',
}
