import { Button, Image } from '@chakra-ui/react';
import UserIcon from '@shared/assets/icons/user.svg';
import { memo } from 'react';

import useUserButton from './hooks';

const UserButton = () => {
  const {
    actions: { handleSignIn, handleGoToProfile },
    data: { isLoggedIn, name },
  } = useUserButton();

  return (
    <Button
      onClick={isLoggedIn ? handleGoToProfile : handleSignIn}
      color="gray.900"
      padding="0"
      height="auto"
      bgColor="transparent"
      fontSize="14px"
      lineHeight="20px"
      _hover={{
        bgColor: 'transparent',
        opacity: 0.8,
      }}
      _focus={{
        bgColor: 'transparent',
        opacity: 0.8,
      }}
    >
      <Image src={UserIcon} marginRight="12px" />
      {isLoggedIn ? name : 'Sign in'}
    </Button>
  );
};

export default memo(UserButton);
