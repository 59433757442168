import { useToast } from '@chakra-ui/react';
import { CurrencyEnum } from '@shared/constants/currency';
import { formatOnlyNumbers } from '@shared/utils/format';
import { isEmpty } from '@shared/utils/string';
import { isValidEmail } from '@shared/utils/validation';
import { VouchersApi } from 'api/VouchersApi';
import get from 'lodash.get';
import uniqBy from 'lodash.uniqby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

interface Props {
  onSuccess: (data: Vouchers.BuyResponse & { email: string }) => void;
}

const useVoucherCard = ({ onSuccess }: Props) => {
  const toast = useToast();

  const [value, setValue] = useState<{
    balance: string;
    currency: Currency;
  }>({
    balance: '10',
    currency: CurrencyEnum.EUR,
  });

  const [isPresent, setIsPresent] = useState(false);
  const [voucherData, setVoucherData] = useState<Vouchers.BuyResponse>();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [userName, setUserName] = useState('');
  const [userNameError, setUserNameError] = useState('');

  const [recipientEmail, setRecipientEmail] = useState('');
  const [recipientEmailError, setRecipientEmailError] = useState('');

  const { websiteData } = useAppSelector(selectWebsite);
  const currencies = useMemo(() => {
    return uniqBy(get(websiteData, 'currencies', []), 'currency');
  }, [websiteData]);

  useEffect(() => {
    if (currencies.length) {
      setValue((prevState) => ({
        ...prevState,
        currency: currencies[0].currency,
      }));
    }
  }, [currencies]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.target;
      setValue((prevState) => ({
        ...prevState,
        [name]: name === 'balance' ? formatOnlyNumbers(value) : value,
      }));
    },
    [],
  );

  const onBuy = useCallback(() => {
    if (value.balance && value.currency && email && websiteData) {
      VouchersApi.buyPublic({
        ...value,
        email: isPresent ? recipientEmail : email,
        venue_group: websiteData.venue_group.id,
        gifted_by: userName,
      })
        .then((res) => {
          setVoucherData(res);
        })
        .catch((error: ApiTypes.ResponseError) => {
          toast({
            description: error.message || String(error),
            status: 'error',
            isClosable: true,
          });
        });
    }
  }, [value, email, recipientEmail, userName, websiteData, isPresent]);

  const handleSuccess = useCallback(
    (email: string) => {
      voucherData && onSuccess({ ...voucherData, email });
    },
    [onSuccess, voucherData],
  );

  const handleChangeEmail = useCallback((email: string) => {
    setEmailError('');
    setEmail(email);
  }, []);

  const validateEmail = useCallback(() => {
    const emailError = isEmpty(email)
      ? 'Field is required'
      : !isValidEmail(email)
      ? 'Invalid email'
      : '';

    setEmailError(emailError);

    return !emailError;
  }, [email]);

  const handleChangeRecipientEmail = useCallback((email: string) => {
    setRecipientEmailError('');
    setRecipientEmail(email);
  }, []);

  const validateRecipientEmail = useCallback(() => {
    const emailError = isEmpty(recipientEmail)
      ? 'Field is required'
      : !isValidEmail(recipientEmail)
      ? 'Invalid email'
      : '';

    setRecipientEmailError(emailError);

    return !emailError;
  }, [recipientEmail]);

  const handleChangeName = useCallback((userName: string) => {
    setUserNameError('');
    setUserName(userName);
  }, []);

  const validateName = useCallback(() => {
    const nameError = isEmpty(userName) ? 'Field is required' : '';

    setUserNameError(nameError);

    return !emailError;
  }, [userName]);

  const handleCancelPresent = useCallback(() => {
    setIsPresent(false);
    setUserName('');
    setUserNameError('');
    setRecipientEmail('');
    setRecipientEmailError('');
  }, []);

  let isDisabled =
    Number(value.balance) < 10 ||
    Number(value.balance) > 1000 ||
    !email.length ||
    Boolean(emailError.length);

  if (isPresent) {
    isDisabled =
      Number(value.balance) < 10 ||
      Number(value.balance) > 1000 ||
      !email.length ||
      Boolean(emailError.length) ||
      !recipientEmail.length ||
      Boolean(recipientEmailError.length) ||
      !userName.length ||
      Boolean(userNameError.length);
  }

  return {
    actions: {
      handleInputChange,
      onBuy,
      handleSuccess,
      handleChangeEmail,
      validateEmail,
      handleChangeRecipientEmail,
      validateRecipientEmail,
      handleChangeName,
      validateName,
      handleCancelPresent,
      setIsPresent,
      setVoucherData,
    },
    data: {
      isDisabled,
      value,
      email,
      emailError,
      isPresent,
      userName,
      userNameError,
      recipientEmail,
      recipientEmailError,
      voucherData,
      currencies,
    },
  };
};

export default useVoucherCard;
