import { Box, Divider, Flex, Heading } from '@chakra-ui/react';

import { useIsMobile } from '../../utils/screen';
import { LabelWithPriceButton } from '../Buttons/LabelWithPriceButton';

interface Props {
  totalAmount: number;
  onPlaceOrder: VoidFunction;
}

export const Content = ({
  totalAmount,
  onPlaceOrder,
  children,
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();

  return (
    <Flex direction="column" w="100%" h="100%">
      <Heading m="24px" mb="4px" size={'sm'}>
        Your order
      </Heading>
      <Box flex={1} p="24px" overflowY="auto">
        {children}
      </Box>
      <Divider />
      <Box px="16px" py={isMobile ? '12px' : '16px'}>
        <LabelWithPriceButton
          label="Place order"
          price={totalAmount}
          onClick={onPlaceOrder}
        />
      </Box>
    </Flex>
  );
};
