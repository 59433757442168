export const getAllUrlParams = (url: string): Record<string, string> => {
  const searchParams = new URLSearchParams(url);
  const paramsObj: Record<string, string> = {};

  for (const [param, value] of searchParams) {
    paramsObj[param] = value;
  }

  return paramsObj;
};
