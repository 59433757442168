export const getDepositCount = (items: Orders.OrderItemDetails[]): number => {
  let count = 0;

  const processExtras = (extras: Orders.OrderItemExtraDetails[]): void => {
    extras.forEach(({ quantity, product, extras: innerExtras }) => {
      if (Number(product?.deposit_return_scheme_amount) > 0) {
        count += quantity;
      }

      if (innerExtras.length) {
        processExtras(innerExtras);
      }
    });
  };

  items.forEach(({ quantity, product, extras }) => {
    if (Number(product?.deposit_return_scheme_amount) > 0) {
      count += quantity;
    }

    if (extras.length) {
      processExtras(extras);
    }
  });

  return count;
};
