import { StyleProps, Text } from '@chakra-ui/react';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

interface Props extends StyleProps {
  points: string;
  isDefault?: boolean;
}

export const PointsText = ({ points, isDefault, ...props }: Props) => {
  const { websiteData } = useAppSelector(selectWebsite);
  return (
    <Text
      as="span"
      fontSize={isDefault ? undefined : 'xs'}
      color={isDefault ? undefined : 'gray.400'}
      {...props}
    >
      {!isDefault && 'Earn '}
      {+points} {websiteData?.venue_group?.points_config?.name || 'points'}
    </Text>
  );
};
