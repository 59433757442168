import { createSlice } from '@reduxjs/toolkit';
import { OrdersApi } from 'api/OrdersApi';
import { AppThunk, RootState } from 'store';

type OrderTrackingState = {
  error: Error | null;
  order: Orders.OrderTracking | null;
};

const initialState: OrderTrackingState = {
  error: null,
  order: null,
};

export const orderTrackingSlice = createSlice({
  name: 'orderTracking',
  initialState,
  reducers: {
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.error = null;
    },
    setRequestSuccess: (state, action: { payload: Orders.OrderTracking }) => {
      state.order = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.order = null;
    },
  },
});

export const { setRequestStart, setRequestSuccess, setError, clearState } =
  orderTrackingSlice.actions;

export const getOrderTracking =
  (token: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await OrdersApi.getOrderTracking(token);
      dispatch(setRequestSuccess(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    }
  };

export const clearOrderTrackingData = (): AppThunk => (dispatch) => {
  dispatch(clearState());
};

export const selectOrderTracking = (state: RootState) => state.orderTracking;

export default orderTrackingSlice.reducer;
