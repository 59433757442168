import { Box, Button, Center, Heading, Text } from '@chakra-ui/react';
import dialCodes from '@shared/assets/DialCodes.json';
import { PhoneInput } from '@shared/components/Input/PhoneInput';
import {
  formatOnlyNumbers,
  separateDialCodeAndPhoneNumber,
} from '@shared/utils/format';
import { validatePhoneNumber } from '@shared/utils/validation';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

interface Props {
  phone: string;
  onClose?: VoidFunction;
  onSubmit: (phone: string, ignoreTimer: true) => void;
}

const getDialCodeByCountryCode = (contryCode: string) => {
  const current = dialCodes.find(({ code }) => code === contryCode);

  return current ? current.dial_code : '+353';
};

export const PhoneInputForm = ({ phone = '', onClose, onSubmit }: Props) => {
  const { websiteData } = useAppSelector(selectWebsite);

  const [defaultDialCode, defaultNumber] =
    separateDialCodeAndPhoneNumber(phone);
  const [dialCode, setDialCode] = useState<string>(
    defaultDialCode ||
      getDialCodeByCountryCode(
        websiteData?.venue_group?.config?.country_code || 'IE',
      ),
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(defaultNumber);
  const [errorMsg, setErrorMsg] = useState<string>();

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneNumber(formatOnlyNumbers(value));
  }, []);

  const validate = useCallback(() => {
    const msg = validatePhoneNumber(phoneNumber);
    setErrorMsg(msg);
    return !msg;
  }, [phoneNumber]);

  const resetValidation = useCallback(() => {
    setErrorMsg('');
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        onSubmit(
          `${dialCode}${
            dialCode === '+353' && phoneNumber.startsWith('0')
              ? phoneNumber.slice(1)
              : phoneNumber
          }`,
          true,
        );
      }
    },
    [phoneNumber, dialCode],
  );

  return (
    <>
      <Heading mb="24px" size="sm">
        Claim your {websiteData?.venue_group?.points_config?.name || 'points'}!
      </Heading>
      <Text mb="24px" fontSize="sm">
        Please enter your phone number to get{' '}
        {websiteData?.venue_group?.points_config?.name || 'points'} from your
        previous orders. If you would not like to share your phone, please press
        skip.
      </Text>
      <Box as="form" onSubmit={handleSubmit}>
        <PhoneInput
          label="Phone"
          name="phone"
          value={phoneNumber}
          onChange={onChange}
          dialCode={dialCode}
          setDialCode={setDialCode}
          onFocus={resetValidation}
          error={errorMsg}
        />
        <Button size="full" type="submit" mb="24px">
          Send SMS
        </Button>
      </Box>
      <Center>
        <Button variant="link" onClick={onClose}>
          Skip
        </Button>
      </Center>
    </>
  );
};
