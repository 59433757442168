import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { colors } from '@shared/theme/colors';
import { useIsMobile } from '@shared/utils/screen';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeElementStyle,
} from '@stripe/stripe-js';
import { useRef } from 'react';

const InputWrapper = ({ children }: React.PropsWithChildren) => (
  <Flex
    flex={1}
    direction="column"
    justify="center"
    bgColor="white"
    borderRadius="md"
    border="1px solid"
    borderColor="inherit"
    px="12px"
    height="48px"
    minH="48px"
  >
    {children}
  </Flex>
);

const stripeInputStyle: StripeElementStyle = {
  base: {
    fontSize: '14px',
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    fontWeight: '400',
    color: colors.gray[800],
    '::placeholder': {
      color: colors.gray[400],
    },
  },
  invalid: {
    color: colors.red[500],
  },
};

export const CreditCardForm = () => {
  const isMobile = useIsMobile();
  const expiryRef = useRef<StripeCardExpiryElement>();
  const cvcRef = useRef<StripeCardCvcElement>();

  return (
    <Box>
      <Text fontWeight={600} fontSize="xs">
        Pay by card
      </Text>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        align={isMobile ? 'stretch' : 'flex-end'}
        spacing="16px"
        mb="24px"
        mt="8px"
      >
        <InputWrapper>
          <CardNumberElement
            options={{
              showIcon: true,
              placeholder: 'Card number',
              style: stripeInputStyle,
            }}
            onChange={(e) => {
              if (e.complete) {
                expiryRef.current?.focus();
              }
            }}
          />
        </InputWrapper>
        <Stack direction="row" spacing="16px" flex={1}>
          <InputWrapper>
            <CardExpiryElement
              onReady={(element) => {
                expiryRef.current = element;
              }}
              options={{
                style: stripeInputStyle,
              }}
              onChange={(e) => {
                if (e.complete) {
                  cvcRef.current?.focus();
                }
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <CardCvcElement
              onReady={(element) => {
                cvcRef.current = element;
              }}
              options={{
                style: stripeInputStyle,
              }}
            />
          </InputWrapper>
        </Stack>
      </Stack>
    </Box>
  );
};
