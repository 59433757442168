import { Heading } from '@chakra-ui/react';

import { EmptyBasketIcon } from '../../assets/icons';

export const EmptyBasket = () => (
  <>
    <EmptyBasketIcon
      w="160px"
      h="160px"
      bgColor="secondary.400"
      borderRadius="full"
      color="primary.400"
    />
    <Heading
      as="h4"
      color="gray.300"
      fontSize="md"
      textAlign="center"
      mt="16px"
    >
      Your basket is empty
    </Heading>
  </>
);
