import { Box } from '@chakra-ui/react';
import { SearchAddressInput } from '@shared/components/Input/SearchAddressInput';
import { useIsMobile } from '@shared/utils/screen';
import { validateFlatNo } from '@shared/utils/validation';
import { AddressModal } from 'components/AddressModal';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  getAddresses,
  selectProfileAddresses,
} from 'store/slices/profileAddresses';
import { selectUiServices } from 'store/slices/uiServices';

interface Iprops {
  setNewAddress: (values: Partial<Addresses.Address>) => void;
  setShowServicesSection: (show: boolean) => void;
  setShowDeliveryDetailsSection: (show: boolean) => void;
  setEircodeError: (error: string) => void;
  setEircode: (code: string) => void;
  setFlatNo: (code: string) => void;
  setFlatNoError: (error: string) => void;
  handleSetStreetNumber: (value: string) => void;
}

const DeliverySection = ({
  setNewAddress,
  setShowServicesSection,
  setShowDeliveryDetailsSection,
  setEircode,
  setEircodeError,
  setFlatNo,
  setFlatNoError,
  handleSetStreetNumber,
}: Iprops) => {
  const { user } = useAppSelector(selectAuth);
  const { addresses } = useAppSelector(selectProfileAddresses);
  const { countryCodes } = useAppSelector(selectUiServices);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFlatNoError('');
    setEircodeError('');
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getAddresses({ no_page: true }));
    }
  }, [user]);

  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (values: Partial<Addresses.Address>) => {
    setNewAddress(values);
    setShowServicesSection(false);
    setShowDeliveryDetailsSection(true);
    setEircode(values.eircode || '');
    setFlatNo(values.flat_no || '');
    handleSetStreetNumber(values.street_number || values.premise || '');

    if (!values.eircode) {
      setEircodeError('Field is required');
    } else {
      setEircodeError('');
    }

    if (!values.id) {
      setFlatNoError(
        validateFlatNo(
          values.flat_no,
          !Boolean(values.street_number || values.premise),
        ),
      );
    }
  };

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  // show separete modal window for the small screens, or if there is already some saved addresses
  return (
    <Box>
      <Box position="relative">
        <SearchAddressInput
          label="Delivery address"
          onSelect={handleSelect}
          onClick={
            (user && addresses && addresses.length > 0) || isMobile
              ? handleOpen
              : undefined
          }
          countryCodes={countryCodes}
        />
      </Box>
      {isOpen && (
        <AddressModal
          isOpen={isOpen}
          onClose={handleClose}
          onSelect={handleSelect}
          skipSecondScreen
        />
      )}
    </Box>
  );
};

export default DeliverySection;
