import { Box, Heading, List, ListItem, Text, useToast } from '@chakra-ui/react';
import { SearchInput } from '@shared/components/Input/SearchInput';
import { Modal } from '@shared/components/Modal';
import { Radio } from '@shared/components/Radio';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectTables } from 'store/slices/tables';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onSelect: (address: Venues.Table) => void;
  selected?: string;
}

export const TablesModal = ({ isOpen, onClose, onSelect, selected }: Props) => {
  const { isLoading, tables, error } = useAppSelector(selectTables);

  const isMobile = useIsMobile();
  const [text, setText] = useState<string>('');

  const toast = useToast();
  useEffect(() => {
    if (error) {
      toast.closeAll();
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, []);

  const handleSelect = useCallback(
    (tableId: string) => {
      const selected = tables?.find((t) => t.id === tableId);
      if (selected) {
        onSelect(selected);
        onClose();
      }
    },
    [onClose, onSelect, tables],
  );

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);
    },
    [],
  );

  const onClear = useCallback(() => {
    setText('');
  }, []);

  const filteredTables = useMemo(
    () =>
      tables && tables.length > 0
        ? tables.filter((t) => t.name.includes(text))
        : [],
    [text],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      size={{ mobile: 'full', desktop: 'lg' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
    >
      <Heading size="sm" lineHeight="32px" mb="24px">
        Select table
      </Heading>
      <SearchInput
        value={text}
        onChange={onSearchChange}
        placeholder="Search for your table"
        onClear={onClear}
      />

      <Box flex={1} minH="30dvh" overflow="auto">
        {filteredTables.length > 0 ? (
          <Radio
            WrapperComponent={List}
            ItemWrapperComponent={ListItem}
            wrapperProps={{ px: '16px' }}
            onChange={handleSelect}
            defaultValue={selected}
          >
            {filteredTables.map((t) => ({
              value: t.id,
              component: (
                <Heading
                  flex={1}
                  size="xs"
                  color={selected === t.id ? 'primary.400' : undefined}
                  py="12px"
                >
                  {t.name}
                </Heading>
              ),
            }))}
          </Radio>
        ) : (
          <Text align="center">
            {text ? (
              'Table not found.'
            ) : (
              <>
                Sorry, all tables are currently reserved.
                <br />
                Please come back later.
              </>
            )}
          </Text>
        )}
      </Box>
    </Modal>
  );
};
