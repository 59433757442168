import { Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { AuthApi } from 'api/AuthApi';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { logout } from 'store/slices/auth';

export const ProfileDeleteAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleDeleteAccount = () => {
    setLoading(true);
    AuthApi.deleteAccount()
      .then(() => {
        dispatch(logout());

        toast({
          description: 'Account deleted',
          status: 'success',
          isClosable: true,
        });
      })
      .catch((error: Error) => {
        toast({
          description: error.message || String(error),
          status: 'error',
          isClosable: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      <BackButton onClick={() => navigate(-1)} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" m="16px 0 24px">
        Delete account
      </Heading>

      <Text>
        Deleting your account can not be undone. Press confirm below if you wish
        to continue, if not press cancel.
      </Text>

      <Flex columnGap="16px" paddingTop="24px">
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={handleDeleteAccount}
        >
          Confirm
        </Button>
      </Flex>
    </WidgetWrapper>
  );
};
