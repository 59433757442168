import { Box, Heading, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import { ProductCard } from '@shared/components/ProductCard';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import { useIsMobile } from '@shared/utils/screen';
import { removeSpaces } from '@shared/utils/string';
import { ProductDetailsModal } from 'components/ProductDetailsModal';
import { PointsText } from 'components/Texts/PointsText';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectDepartments,
  setElementsPosition,
} from 'store/slices/departments';
import { openProductModal } from 'store/slices/productModal';
import { selectServices } from 'store/slices/services';
import { selectWebsite } from 'store/slices/website';

export const MenuList = () => {
  const isMobile = useIsMobile();
  const { departments } = useAppSelector(selectDepartments);
  const { isAvailable } = useAppSelector(selectServices);
  const { websiteData } = useAppSelector(selectWebsite);
  const dispatch = useAppDispatch();

  const toast = useToast();

  const refs = useRef<Record<string, HTMLHeadingElement>>({});

  if (!departments) return null;

  const onSelectProduct = useCallback(
    (product: Product.RootObject) => {
      if (isAvailable) {
        dispatch(openProductModal(product));
      } else {
        toast.closeAll();
        toast({
          description: 'Unfortunately this venue is currently closed',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [isAvailable],
  );

  const isActivePoints = websiteData?.venue_group?.points_config?.is_active;

  const renderSections = useMemo(() => {
    return departments.map((d) => (
      <Fragment key={d.id}>
        <Heading
          size="sm"
          my="16px"
          ref={(ref) =>
            (refs.current = {
              ...refs.current,
              [removeSpaces(d.name)]: ref as HTMLHeadingElement,
            })
          }
        >
          {d.name}
        </Heading>
        <Wrap
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? '8px' : '16px'}
          align="stretch"
        >
          {d.products.map((p) => (
            <WrapItem key={p.id}>
              <ProductCard
                product={p}
                isMobile={isMobile}
                onClick={onSelectProduct}
                Points={
                  isActivePoints &&
                  Number(p.points) > 0 && (
                    <PointsText
                      points={p.points}
                      ml={isMobile ? '8px' : 'auto'}
                    />
                  )
                }
              />
            </WrapItem>
          ))}
        </Wrap>
      </Fragment>
    ));
  }, [departments, isMobile, onSelectProduct]);

  useEffect(() => {
    const positions = (departments ?? []).map((d) => {
      const name = removeSpaces(d.name);
      const element = refs.current[name];
      return {
        name,
        position: element
          ? element.getBoundingClientRect().top +
            window.scrollY -
            96 -
            TOOLBAR_HEIGHT -
            MENU_HEIGHT
          : 0,
      };
    });
    refs.current = {};
    dispatch(setElementsPosition(positions));
  }, [isMobile, departments]);

  return (
    <Box>
      {renderSections}
      <ProductDetailsModal />
    </Box>
  );
};
