import { createSlice } from '@reduxjs/toolkit';
import { VenuesApi } from 'api/VenuesApi';
import uniq from 'lodash.uniq';
import { AppThunk, RootState } from 'store';

import { setCountryCodes } from './uiServices';

type Venues = {
  isLoading: boolean;
  error: Error | null;
  venues: Venues.Venue[];
};

const initialState: Venues = {
  isLoading: true,
  error: null,
  venues: [],
};

export const venuesSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setVenues: (state, action: { payload: Venues.Venue[] }) => {
      state.venues = action.payload;
    },
    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.venues = [];
    },
  },
});

const { setRequestStart, setLoading, setError, clearState, setVenues } =
  venuesSlice.actions;

export const getVenues =
  (websiteId: string, params: Venues.VenuesParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VenuesApi.getVenues(websiteId, params);
      const countryCodes = uniq(
        res.results.map((venue) => venue.location.country_code),
      );
      dispatch(setVenues(res.results));
      dispatch(setCountryCodes(countryCodes));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearVenuesData = (): AppThunk => (dispatch) =>
  dispatch(clearState());

export const selectVenues = (state: RootState) => state.venues;

export default venuesSlice.reducer;
