import { createSlice } from '@reduxjs/toolkit';
import { VouchersApi } from 'api/VouchersApi';
import { AppThunk, RootState } from 'store';

type VoucherData = {
  id: string; // voucher code
  value: string;
  available_balance: string;
};

type CheckoutVouchersState = {
  isLoading: boolean;
  error: Error | null;
  vouchers: Vouchers.Voucher[];
  selectedVoucher: Vouchers.Voucher | null;
  isVoucherModalOpen: boolean;
  voucherValue: string;
  usedVoucherData: null | VoucherData;
};

const initialState: CheckoutVouchersState = {
  isLoading: true,
  error: null,
  vouchers: [],
  selectedVoucher: null,
  isVoucherModalOpen: false,
  voucherValue: '',
  usedVoucherData: null,
};

export const checkoutVouchersSlice = createSlice({
  name: 'checkoutVouchers',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },

    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },

    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },

    setVouchers: (state, action: { payload: Vouchers.Voucher[] }) => {
      state.vouchers = action.payload;
    },

    setSelectedVoucher: (
      state,
      action: { payload: Vouchers.Voucher | null },
    ) => {
      state.selectedVoucher = action.payload;
    },

    setIsVoucherModalOpen: (state, action: { payload: boolean }) => {
      state.isVoucherModalOpen = action.payload;
      // clear field values
      state.voucherValue = '';
      state.selectedVoucher = null;
    },

    setVoucherValue: (state, action: { payload: string }) => {
      state.voucherValue = action.payload;
    },

    setUsedVoucherData: (state, action: { payload: VoucherData | null }) => {
      state.usedVoucherData = action.payload;
    },

    clearUsedVoucherData: (state) => {
      state.usedVoucherData = null;
    },

    clearCheckoutVouchersState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.vouchers = [];
      state.selectedVoucher = null;
      state.isVoucherModalOpen = false;
      state.voucherValue = '';
      state.usedVoucherData = null;
    },
  },
});

export const {
  setRequestStart,
  setLoading,
  setError,
  setVouchers,
  setSelectedVoucher,
  setIsVoucherModalOpen,
  clearCheckoutVouchersState,
  setVoucherValue,
  setUsedVoucherData,
  clearUsedVoucherData,
} = checkoutVouchersSlice.actions;

export const getCheckoutVouchers =
  (params?: Vouchers.VoucherParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VouchersApi.getConsumerVouchers(params);

      dispatch(setVouchers(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectCheckoutVouchers = (state: RootState) =>
  state.checkoutVouchers;

export default checkoutVouchersSlice.reducer;
