import TagManager from '@sooro-io/react-gtm-module';
import { WIDGET_SKIP_UPSELL } from 'constants/googleTagManagerEvents';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import { selectBasketUpsells } from 'store/slices/basketUpsells';
import { setUpsellsModalOpen } from 'store/slices/basketUpsells';

const useUpsellsModal = () => {
  const { isLoading, products, addedToCardProductIds } =
    useAppSelector(selectBasketUpsells);

  const { user } = useAppSelector(selectAuth);

  const [, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    if (!addedToCardProductIds.length) {
      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_SKIP_UPSELL,
        },
      });
    }
    dispatch(setUpsellsModalOpen(false));
  };

  const handleSubmit = useCallback(() => {
    handleCloseModal();

    if (user) {
      navigate(Paths.Checkout);
    } else {
      setSearchParams({ modal: AuthModalParams.SignIn, ref: 'Checkout' });
    }
  }, []);

  return {
    actions: {
      handleSubmit,
      handleCloseModal,
    },
    data: {
      products,
      isLoading,
      addedToCardProductIds,
    },
  };
};

export default useUpsellsModal;
