import { useLayoutEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectProductModal,
  setScrollPosition,
} from 'store/slices/productModal';

export const useSaveCurrentScrollPosition = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { scrollPosition, customiseExtraSections } =
    useAppSelector(selectProductModal);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (scrollPosition !== null) {
      setTimeout(() => {
        ref.current?.scroll(0, scrollPosition);
      }, 0);
    }
    return () => {
      const previousScrollPosition = ref.current?.scrollTop || null;
      dispatch(setScrollPosition(previousScrollPosition));
    };
  }, [customiseExtraSections]);

  return ref;
};
