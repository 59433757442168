import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearProfileVouchersState,
  getConsumerVouchers,
  selectProfileVouchers,
} from 'store/slices/profileVouchers';
import { selectWebsite } from 'store/slices/website';

const useProfileVouchers = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { websiteData } = useAppSelector(selectWebsite);
  const { vouchers, error, isLoading } = useAppSelector(selectProfileVouchers);

  useEffect(() => {
    if (websiteData?.venue_group?.id && websiteData?.show_voucher) {
      dispatch(
        getConsumerVouchers({ venue_group: websiteData?.venue_group?.id }),
      );
    }
  }, [websiteData?.venue_group?.id]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(clearProfileVouchersState());
    };
  }, []);

  const activeVouchers = useMemo(() => {
    return vouchers.filter(
      ({ available_balance, is_active }) =>
        Number(available_balance) > 0 && is_active,
    );
  }, [vouchers]);

  const disabledVouchers = useMemo(() => {
    return vouchers.filter(
      ({ available_balance, is_active }) =>
        Number(available_balance) <= 0 || !is_active,
    );
  }, [vouchers]);

  return {
    data: {
      isLoading,
      activeVouchers,
      disabledVouchers,
      isShowVoucher: websiteData?.show_voucher || false,
    },
  };
};

export default useProfileVouchers;
