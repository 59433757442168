import { List } from '@chakra-ui/react';
import { memo } from 'react';
import { Paths } from 'routes/paths';

import VoucherItem from './VoucherItem';

interface Props {
  vouchers: Vouchers.Voucher[];
  disabled?: boolean;
}

const VoucherList = ({ vouchers, disabled }: Props) => (
  <List>
    {vouchers
      .sort(
        (a, b) =>
          +b[disabled ? 'balance' : 'available_balance'] -
          +a[disabled ? 'balance' : 'available_balance'],
      )
      .map(({ id, code, available_balance, balance, currency }) => (
        <VoucherItem
          key={id}
          name={code}
          id={id}
          availableBalance={disabled ? balance : available_balance}
          basePath={Paths.ProfileVouchers}
          currency={currency}
          disabled={disabled}
        />
      ))}
  </List>
);

export default memo(VoucherList);
