import { Flex, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import { RadioIcon } from './RadioIcon';

interface Props<WrapperProps, ItemWrapperProps, V> {
  WrapperComponent: FC<WrapperProps>;
  wrapperProps?: Partial<WrapperProps>;
  ItemWrapperComponent?: FC<ItemWrapperProps> | typeof Fragment;
  itemWrapperProps?: Partial<ItemWrapperProps>;
  children: Array<{
    value: V;
    component: ((isChecked?: boolean) => ReactNode) | ReactNode;
    isDisabled?: boolean;
  }>;
  onChange: (value: V) => void;
  defaultValue?: V;
  value?: V;
  isHideIcon?: boolean;
}

const CustomRadio = <WrapperProps, ItemWrapperProps, V>({
  isHideIcon,
  ...props
}: PropsWithChildren<
  UseRadioProps & Pick<Props<WrapperProps, ItemWrapperProps, V>, 'isHideIcon'>
>) => {
  const { getInputProps, getRadioProps } = useRadio(props);
  return (
    <Flex
      as="label"
      cursor={props.isDisabled ? 'not-allowed' : 'pointer'}
      align="center"
      opacity={props.isDisabled ? 0.4 : 1}
    >
      <input {...getInputProps()} type="checkbox" />
      {props.children}
      {!isHideIcon && <RadioIcon {...getRadioProps()} />}
    </Flex>
  );
};

export const Radio = <WrapperProps, ItemWrapperProps, V extends string>({
  WrapperComponent,
  wrapperProps,
  ItemWrapperComponent = Fragment,
  itemWrapperProps,
  children,
  value,
  onChange,
  defaultValue,
  isHideIcon,
}: Props<WrapperProps, ItemWrapperProps, V>) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    onChange,
    defaultValue,
  });

  return (
    <WrapperComponent {...getRootProps()} {...(wrapperProps as WrapperProps)}>
      {children.map((item) => {
        const props: UseRadioProps = getRadioProps({ value: item.value });
        return (
          <ItemWrapperComponent
            key={item.value}
            {...(itemWrapperProps as ItemWrapperProps)}
          >
            <CustomRadio
              {...props}
              isHideIcon={isHideIcon}
              isDisabled={item.isDisabled}
            >
              {/* eslint-disable react/prop-types */}
              {typeof item.component === 'function'
                ? item.component(props.isChecked)
                : item.component}
            </CustomRadio>
          </ItemWrapperComponent>
        );
      })}
    </WrapperComponent>
  );
};
