export const colors = {
  primary: {
    50: '#FFF8F4',
    100: '#FFEDE3',
    200: '#FFD4BD',
    300: '#FF9F73',
    400: '#D64320',
    500: '#B5361D',
    600: '#8C2716',
    700: '#6B1E13',
    800: '#4B1510',
    900: '#34100D',
  },
  secondary: {
    50: '#F6FAFE',
    100: '#E9F2FD',
    200: '#C9DFFB',
    300: '#8FBBF7',
    400: '#1672EF',
    500: '#005CD7',
    600: '#0046A4',
    700: '#00367E',
    800: '#002659',
    900: '#001B3F',
  },
  gray: {
    50: '#F9F9FA',
    100: '#F4F5F6',
    200: '#E4E5E7',
    300: '#B4B9BF',
    400: '#6E7883',
    500: '#596471',
    600: '#414D59',
    700: '#313A44',
    800: '#22282F',
    900: '#181C21',
    950: '#E0E2E4',
  },
  red: {
    50: '#FEF8F8',
    100: '#FCEDEE',
    200: '#F7D4D7',
    300: '#EFA5AB',
    400: '#DF3245',
    500: '#C41430',
    600: '#971026',
    700: '#740C1F',
    800: '#530A17',
  },
  yellow: {
    50: '#FFF9E6',
    100: '#FFF0BE',
    200: '#FFD85A',
    300: '#EDAE09',
    400: '#9A7004',
    500: '#825D04',
    600: '#644602',
    700: '#4D3502',
    800: '#362501',
    900: '#251A01',
  },
  green: {
    50: '#F5FBF5',
    100: '#E5F5E7',
    200: '#BEE7C7',
    300: '#70CC8B',
    400: '#008D26',
    500: '#007723',
    600: '#005B1C',
    700: '#004617',
    800: '#003114',
    900: '#002311',
  },
};
