import { Box, Flex, IconButton, Image, Link, Text } from '@chakra-ui/react';
import { DeleteIconAlt } from '@shared/assets/icons';
import EditIcon from '@shared/assets/icons/edit.svg';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes/paths';

interface Iprops {
  id: string;
  address: string;
  onDelete: (id: string) => void;
  isLast: boolean;
}

const AddressItem = ({ id, address, onDelete, isLast }: Iprops) => {
  const handleDelete = () => onDelete(id);

  return (
    <Box
      py="18px"
      borderBottom="1px solid"
      borderColor="gray.200"
      border={isLast ? 'none' : undefined}
    >
      <Flex alignItems="center" columnGap="8px">
        <Text fontSize="14px" lineHeight="20px" fontWeight="600" flexGrow="1">
          {address}
        </Text>
        <Flex width="76px" columnGap="8px">
          <Link
            as={RouterLink}
            to={`${Paths.ProfileSavedAddresses}/${id}`}
            borderRadius="50%"
            padding="10px"
            width="34px"
            _hover={{
              opacity: 0.8,
            }}
            _focus={{
              opacity: 0.8,
            }}
          >
            <Image src={EditIcon} />
          </Link>

          <IconButton
            aria-label="delete"
            isRound
            onClick={handleDelete}
            icon={<DeleteIconAlt />}
            size="sm"
            borderRadius="50%"
            padding="10px"
            width="34px"
            height="34px"
            color="gray.400"
            backgroundColor="transparent"
            _hover={{
              opacity: 0.8,
              backgroundColor: 'transparent',
            }}
            _focus={{
              opacity: 0.8,
              backgroundColor: 'transparent',
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default memo(AddressItem);
