import { useMediaQuery } from '@chakra-ui/react';

export const useIsMobile = () => {
  const [value] = useMediaQuery('(max-width: 767px)');
  return value;
};

export const useIsTablet = () => {
  const [value] = useMediaQuery('(max-width: 1024px)');
  return value;
};

export const useIsDesktop = () => {
  const [value] = useMediaQuery('(min-width: 1025px)');
  return value;
};

export const useIsMobileHeight = () => {
  const [value] = useMediaQuery('(max-height: 767px)');
  return value;
};
