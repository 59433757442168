import { Heading, Text } from '@chakra-ui/react';
import { Footer } from 'components/LandingSections/Footer';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

export const PrivacyPolicy = () => {
  const { websiteData } = useAppSelector(selectWebsite);

  const name = websiteData?.name;
  const email = websiteData?.email;

  return (
    <>
      <WidgetWrapper withContainer containerWidth="xl">
        <Heading
          as="h1"
          fontSize="28px"
          lineHeight="36px"
          textAlign="center"
          mt="16px"
          mb="36px"
        >
          {name} Privacy Policy
        </Heading>

        <Text mb="16px">
          This is the Privacy Policy for {name}. {name} respects the privacy of
          all users of our website (i.e. our desktop and mobile sites), social
          media pages and apps. This Privacy Statement outlines our policy
          concerning the collection and use of personal information which is
          gathered by {name} from users of this website, subdomains and our
          social media pages and our apps.
        </Text>

        <Text mb="16px">
          The aim of this privacy policy is to explain how {name} collects and
          uses the personal information it gathers in this way. {name} will
          ensure that it keeps all personal information you provide to us in
          accordance with our obligations under current Data Protection
          legislation.
        </Text>

        <Text mb="16px">
          {name} is not responsible for the privacy practices of any external
          websites and platforms which may be accessed or used in connection
          with your use of our website, social media pages or our apps and so we
          would advise that you review the privacy statements of those websites
          and platforms as they may be subject to different privacy
          requirements.
        </Text>

        <Text mb="16px">
          Any processing of personal information gathered by {name} as a result
          of your use of this website, social media pages or our apps is
          governed by the provisions of the Irish Data Protection Acts 1988,
          2003 and 2018.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          What personal information do {name} collect?
        </Heading>
        <Text mb="16px">
          {name} Sign In: If you create an {name} account, we may collect and
          use your name, contact details, gender, IP address, browser, operating
          system and device (including its location) you used and details
          regarding your website, social media pages and app usage (e.g. pages
          time / date of pages visited / actions taken) to provide you with
          extra benefits like saving teams/venues, redeeming/sampling free
          pints, receiving notifications about live scores, promotions or venues
          showing live entertainment
        </Text>

        <Text mb="16px">
          We may also sometimes track who opened and clicked the links in the
          communications that we send so that we can measure a campaign&#8217;s
          success.
        </Text>

        <Text mb="16px">
          If you&#8217;ve signed up to {name}, we may combine your account
          information with information we collect about you using cookies, web
          beacons, device identifiers etc. If you want to control the use of
          cookies etc, see the {name} Cookie Policy.
        </Text>

        <Text mb="16px">
          If you sign up for marketing communications or in-app push
          notifications in our apps, we may also collect, use and combine
          similar information for the purposes of marketing, including through
          the use of cookies,etc. For example, we may combine your account
          information with information we collect about you using cookies, web
          beacons, device identifiers etc (including your device&#8217;s
          location). We may also sometimes track who opened and clicked the
          links in the communications that we send using technologies so that we
          can measure a campaign.
        </Text>

        <Text mb="16px">
          When you opt-in to in-app push notifications, we may also use this
          information to send you customised in-app push notifications regarding
          our service, including newsletters, messages about live scores,
          information on venues showing specific live sports.
        </Text>

        <Text mb="16px">
          We will ensure that all personal information supplied is held
          securely, in accordance with the Data Protection Acts 1988, 2003 and
          2018.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          How do you use my information?
        </Heading>
        <Text mb="16px">
          We use your personal information for the following purposes below:
        </Text>
        <Text mb="16px">Authenticate Your Account</Text>
        <Text mb="16px">
          As necessary to log you into the Platform and ensure the security of
          your account
        </Text>
        <Text mb="16px">Provide the Services</Text>
        <Text mb="16px">
          This includes providing you with the services, products, and
          functionality offered through our Platform and fulfilling your
          requests, including but not limited to: online ordering.
        </Text>
        <Text mb="16px">Communication</Text>
        <Text mb="16px">
          About your account or use of our Platform, products, and/or
          functionality; respond to, or follow up on, your comments and
          questions; and otherwise provide customer service
        </Text>
        <Text mb="16px">Send you marketing communications</Text>
        <Text mb="16px">
          Including communicating with you about Platform or products offered by
          {name}, our group companies, or our business partners and other
          marketing communications that we believe you would be interested in,
          as permitted by law.
        </Text>
        <Text mb="16px">Operate and Improve our Platform</Text>
        <Text mb="16px">
          including using analytics to better understand how you use our
          Platform, for purposes of product, website, application and service
          development and to enhance the user experience.
        </Text>
        <Text mb="16px">Provide information to Outlets</Text>
        <Text mb="16px">
          For the purpose of service functionality. For example the name on your
          booking and contact information.
        </Text>
        <Text mb="16px">Provide you with personalised advertising</Text>
        <Text mb="16px">
          We use the information to show you advertising on and off our
          Platform, which we think you will find relevant. We also provide this
          information to our business partners to display advertisements that
          are relevant and less intrusive. If you sign up to electronic
          marketing communications from {name} or to in-app push notifications
          on our apps, {name} may send you information about our products and
          services which we think may be of interest to you including our weekly
          newsletter. In this case, we may build up a profile of you based upon
          what we infer from your usage of the different pages of our website
          and apps.
        </Text>
        <Text mb="16px">
          When you sign up to electronic communications, we&#8217;ll normally
          contact you by email, but we may send you marketing texts also if
          you&#8217;ve given us your mobile number for this purpose. If you have
          just signed up to in-app push notifications, we&#8217;ll only send you
          in-app push notifications.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          How can I request a copy of my personal information held by {name}?
        </Heading>
        <Text>
          If members of the public wish to see what personal information {name}
          may hold on them, please contact us at {email}
        </Text>
      </WidgetWrapper>
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
      />
    </>
  );
};
