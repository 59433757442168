import { Box, Button } from '@chakra-ui/react';
import { memo } from 'react';

import { useIsMobile } from '../../utils/screen';
import { Input, InputProps } from '../Input';
import {
  SearchAddressInput,
  SearchAddressInputProps,
} from '../Input/SearchAddressInput';

interface Iprops {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onSubmit?: React.FormEventHandler;
  address?: Partial<Addresses.Address>;
  setAddress?: SearchAddressInputProps['onSelect'];
  onClear?: VoidFunction;
  formErrors?: {
    address?: string | boolean;
    flatNo?: string | boolean;
    code?: string | boolean;
  };
  isLoading?: boolean;
  countryCodes?: string[];
  inputProps?: InputProps;
}

const AddressForm = ({
  onChange,
  onFocus,
  onSubmit,
  address,
  setAddress,
  onClear,
  formErrors,
  isLoading,
  countryCodes = [],
  inputProps,
}: Iprops) => {
  const isMobile = useIsMobile();

  return (
    <Box as={onSubmit ? 'form' : undefined} onSubmit={onSubmit}>
      {setAddress ? (
        <SearchAddressInput
          {...inputProps}
          key={address?.id}
          label="Address"
          value={address?.full_address}
          onSelect={setAddress}
          countryCodes={countryCodes}
          onFocus={onFocus}
          onClear={onClear}
          error={formErrors?.address}
        />
      ) : (
        <Input
          {...inputProps}
          label="Address"
          name="address"
          value={address?.full_address ?? ''}
          error={formErrors?.address}
          isDisabled={isLoading}
          isReadOnly
        />
      )}

      <Box display={isMobile ? 'block' : 'flex'} columnGap="16px">
        <Input
          {...inputProps}
          label="Flat number"
          name="flatNo"
          value={address?.flat_no ?? ''}
          onChange={onChange}
          error={formErrors?.flatNo}
          onFocus={onFocus}
          isDisabled={isLoading}
          maxLength={10}
        />

        <Input
          {...inputProps}
          label="Post code"
          name="code"
          value={address?.eircode ?? ''}
          onChange={onChange}
          error={formErrors?.code}
          onFocus={onFocus}
          isDisabled={isLoading}
          maxLength={10}
        />
      </Box>
      {onSubmit && (
        <Button
          w="full"
          size="xl"
          type="submit"
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Save
        </Button>
      )}
    </Box>
  );
};

export default memo(AddressForm);
