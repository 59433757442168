import { Center, CircularProgress, Link, Text } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { useIsMobile } from '@shared/utils/screen';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { isValid } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import get from 'lodash.get';
import { AuthModal } from 'pages/AuthModal';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { Paths } from 'routes/paths';
import { isLandingMode } from 'utils/document';

import Confirmation from './Confirmation';
import { OrderStatusConfirmationHeading } from './Confirmation/Heading';
import { useOrderStatus } from './hooks';
import { InfoWithTrackingMap } from './InfoWithTrackingMap';
import Reject from './Reject';

export const OrderStatus = () => {
  const isMobile = useIsMobile();

  const {
    data: { order, trackingData, error, websiteEmail },
  } = useOrderStatus();

  const navigate = useNavigate();

  const isFromOrderHistory = Boolean(useMatch(Paths.ProfileOrderHistoryItem));

  const status = get(order, 'status');
  const driverStatus = get(order, 'driver_status');
  const hasDeliveryTracking = get(
    order,
    ['venue', 'has_delivery_tracking'],
    false,
  );
  const eta = get(order, 'eta', null);
  const serviceType = get(order, ['service', 'service_type'], null);
  const venuePhone = get(order, ['venue', 'phone'], '');
  const email = get(order, ['venue', 'email'], '');
  const points = get(order, 'points', null);
  const currency = get(order, 'currency', null);

  const completeAt = get(order, 'complete_at', null);
  const startPreparingAt = get(order, 'start_preparing_at', null);
  const timeZone = get(order, ['venue', 'location', 'timezone'], undefined);

  const orderItems = get(order, 'order_items', []);

  const subtotalAmount = get(order, 'subtotal_amount', '0.00');

  const orderAdjustments: Orders.OrderAdjustment[] = get(
    order,
    'order_adjustments',
    [],
  );

  const deliveryAdjustmentData = orderAdjustments.find(
    (adj) => adj.adjustment_type === 0,
  );

  const serviceAdjustmentData = orderAdjustments.find(
    (adj) => adj.adjustment_type === 1,
  );

  const tipAdjustmentData = orderAdjustments.find(
    (adj) => adj.adjustment_type === 6,
  );

  const driverTipAdjustmentData = orderAdjustments.find(
    (adj) => adj.adjustment_type === 3,
  );

  const depositReturnSchemeData = orderAdjustments.find(
    (adj) => adj.adjustment_type === 9,
  );

  const deliveryFeeAmount = get(deliveryAdjustmentData, 'total_amount', '');
  const serviceFeeAmount = get(serviceAdjustmentData, 'total_amount', '');
  const tipsAmount = get(tipAdjustmentData, 'total_amount', '');
  const driverTipsAmount = get(driverTipAdjustmentData, 'total_amount', '');
  const depositReturnSchemeAmount = get(
    depositReturnSchemeData,
    'total_amount',
    '',
  );

  const totalAmount = get(order, 'total_amount', '0.00');
  const orderDiscounts = get(order, 'order_discounts', []);

  const venueName = get(order, ['venue', 'name'], '');

  const venueAddress = get(order, ['venue', 'address'], '');
  const clientAddress = get(
    order,
    ['delivery_address', 'formatted_address'],
    '',
  );

  const orderNumber = get(order, 'order_number', 0);
  const voucherTransactions = get(order, 'voucher_transactions', []);

  const estimateArrival = useMemo(() => {
    const date =
      eta &&
      timeZone &&
      isValid(new Date(eta)) &&
      utcToZonedTime(eta, timeZone);

    return date || null;
  }, [eta, timeZone]);

  const venueLocation: google.maps.LatLngLiteral = {
    lat: get(order, ['venue', 'lat'], 0),
    lng: get(order, ['venue', 'lng'], 0),
  };

  const deliveryLocation: google.maps.LatLngLiteral = {
    lat: get(order, ['delivery_address', 'lat'], 0),
    lng: get(order, ['delivery_address', 'lng'], 0),
  };

  const showConfirmUi = useMemo(() => {
    return Boolean((order && order.status !== 8) || isFromOrderHistory);
  }, [status, isFromOrderHistory]);

  const showTrackingMap = useMemo(() => {
    return Boolean(
      status === 4 &&
        driverStatus &&
        [2, 4].includes(driverStatus ?? null) &&
        serviceType === 0 &&
        hasDeliveryTracking === true,
    );
  }, [status, driverStatus, serviceType, hasDeliveryTracking]);

  return (
    <WidgetWrapper
      withContainer
      toolbar={
        isFromOrderHistory
          ? undefined
          : {
              title: 'Order',
              withBack: true,
              path: isLandingMode ? Paths.Order : Paths.Home,
            }
      }
      containerWidth={showTrackingMap ? '1200px' : '560px'}
      containerProps={showTrackingMap ? { p: 0 } : undefined}
    >
      {!order && !error && (
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      )}

      {!order && error && (
        <Center>
          <Text fontSize="lg">
            Something went wrong with your order, please contact support by{' '}
            <Link color="primary.400" href={`mailto:${websiteEmail}`}>
              {websiteEmail}
            </Link>
          </Text>
        </Center>
      )}

      {order && (
        <>
          {showConfirmUi && (
            <InfoWithTrackingMap
              show={showTrackingMap}
              currentLocation={trackingData?.location ?? null}
              origin={venueLocation}
              destination={deliveryLocation}
              summarySection={
                <>
                  {isFromOrderHistory && (
                    <BackButton onClick={() => navigate(-1)} />
                  )}
                  <OrderStatusConfirmationHeading
                    hideReview={!!order.review}
                    startPreparingAt={startPreparingAt}
                    status={status}
                    driverStatus={driverStatus}
                    orderNumber={orderNumber}
                    serviceType={serviceType}
                    eta={estimateArrival}
                    completeAt={completeAt}
                    hideDivider
                  />
                </>
              }
            >
              {isFromOrderHistory && (!isMobile || !showTrackingMap) && (
                <BackButton onClick={() => navigate(-1)} />
              )}
              <Confirmation
                eta={estimateArrival}
                serviceType={serviceType}
                venuePhone={venuePhone}
                points={points}
                currency={currency}
                orderItems={orderItems}
                subtotalAmount={subtotalAmount}
                tipsAmount={tipsAmount}
                driverTipsAmount={driverTipsAmount}
                totalAmount={totalAmount}
                deliveryFeeAmount={deliveryFeeAmount}
                serviceFeeAmount={serviceFeeAmount}
                orderDiscounts={orderDiscounts}
                status={status}
                driverStatus={driverStatus}
                venueName={venueName}
                address={serviceType === 0 ? clientAddress : venueAddress}
                orderNumber={orderNumber}
                voucherTransactions={voucherTransactions}
                completeAt={completeAt}
                startPreparingAt={startPreparingAt}
                timeZone={timeZone}
                review={order.review}
                depositReturnSchemeAmount={depositReturnSchemeAmount}
              />
            </InfoWithTrackingMap>
          )}
          {status === 8 && !isFromOrderHistory && (
            <Reject
              phone={venuePhone}
              email={email}
              orderNumber={orderNumber}
            />
          )}
        </>
      )}

      <AuthModal />
    </WidgetWrapper>
  );
};
