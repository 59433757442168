import { HStack } from '@chakra-ui/react';
import { StarEmpty, StarFilled } from '@shared/assets/icons';
import { useMemo } from 'react';

interface Props {
  stars: Orders.RatingStars;
  setStars?: (value: Orders.RatingStars) => void;
  isLarge?: boolean;
  spacing?: string;
}

export const Rating = ({
  stars,
  setStars,
  isLarge,
  spacing = '4px',
}: Props) => {
  const renderStars = useMemo(() => {
    const elements = [];
    for (let index = 1; index <= 5; index++) {
      elements.push(
        index > stars ? (
          <StarEmpty
            key={`star-${index}`}
            color="primary.400"
            height={isLarge ? '24px' : undefined}
            width={isLarge ? '24px' : undefined}
            cursor={setStars && 'pointer'}
            onClick={
              setStars &&
              (() =>
                setStars((stars === index ? 0 : index) as Orders.RatingStars))
            }
          />
        ) : (
          <StarFilled
            key={`star-${index}`}
            color="primary.400"
            height={isLarge ? '24px' : undefined}
            width={isLarge ? '24px' : undefined}
            cursor={setStars && 'pointer'}
            onClick={
              setStars &&
              (() =>
                setStars((stars === index ? 0 : index) as Orders.RatingStars))
            }
          />
        ),
      );
    }
    return elements;
  }, [stars]);

  return <HStack spacing={spacing}>{renderStars}</HStack>;
};
