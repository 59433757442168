import { Flex } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';
import { Footer } from 'components/LandingSections/Footer';
import { Vourcher } from 'components/Voucher';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

const Voucher = () => {
  const isMobile = useIsMobile();

  const { websiteData } = useAppSelector(selectWebsite);

  return (
    <Flex
      bg={isMobile ? '#fff' : 'gray.950'}
      minH={`calc(100dvh - ${MENU_HEIGHT}px)`}
      padding={isMobile ? '0' : '88px 0 0'}
      direction="column"
      justifyContent="space-between"
    >
      <Vourcher />
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
      />
    </Flex>
  );
};

export default Voucher;
