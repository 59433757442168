import { HStack } from '@chakra-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { formatOnlyNumbers } from '../../utils/format';
import { Input } from '.';

interface Props {
  value: string;
  onChange: (value: string) => void;
  fields?: number;
  error?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

const BACKSPACE_KEY = 'Backspace';
const LEFT_ARROW_KEY = 'ArrowLeft';
const RIGHT_ARROW_KEY = 'ArrowRight';

export const CodeInput = ({
  value,
  onChange,
  fields = 6,
  error,
  onFocus,
}: Props) => {
  const inputsRefs = useRef<HTMLInputElement[]>([]);
  const [values, setValues] = useState<string[]>(value.split(''));

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = formatOnlyNumbers(e.target.value);

      let fullValue = value;

      if (value !== '') {
        const input = values.slice();

        if (value.length > 1) {
          value.split('').forEach((chart, i) => {
            if (Number(e.target.dataset.id) + i < fields) {
              input[Number(e.target.dataset.id) + i] = chart;
            }
          });
        } else {
          input[Number(e.target.dataset.id)] = value;
        }

        const newTarget =
          inputsRefs.current[
            Number(e.target.dataset.id) < input.length
              ? Number(e.target.dataset.id) + 1
              : Number(e.target.dataset.id)
          ];

        if (newTarget) {
          newTarget.focus();
          newTarget.select();
        }

        fullValue = input.join('');

        setValues(input);
      }

      onChange(fullValue);
    },
    [value, onChange, inputsRefs],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const target = Number(e.currentTarget.dataset.id),
        nextTarget = inputsRefs.current[target + 1],
        prevTarget = inputsRefs.current[target - 1];

      let input: string[];

      switch (e.key) {
        case BACKSPACE_KEY:
          e.preventDefault();
          // inputsRefs.current[target].value = '';
          input = value.split('');
          input[target] = '';

          if (inputsRefs.current[target].value === '') {
            if (prevTarget) {
              prevTarget.focus();
              prevTarget.select();
            }
          }
          setValues(input);
          onChange(input.join(''));
          break;

        case LEFT_ARROW_KEY:
          e.preventDefault();
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
          break;

        case RIGHT_ARROW_KEY:
          e.preventDefault();
          if (nextTarget) {
            nextTarget.focus();
            nextTarget.select();
          }
          break;

        default:
          break;
      }
    },
    [value, onChange, inputsRefs],
  );

  const Fields = useMemo(() => {
    const elements = [];
    for (let index = 0; index < fields; index++) {
      elements.push(
        <Input
          key={`code-input-${index}`}
          ref={(ref) => ref && (inputsRefs.current[index] = ref)}
          data-id={index}
          autoFocus={index === 0}
          value={values[index] ?? ''}
          htmlSize={1}
          width="auto"
          textAlign="center"
          onFocus={(e) => {
            e.target.select();
            onFocus && onFocus(e);
          }}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          disableMoveCaret
          error={error}
        />,
      );
    }
    return elements;
  }, [handleKeyDown, handleChange, values]);
  return (
    <HStack spacing="8px" align="flex-start">
      {Fields}
    </HStack>
  );
};
