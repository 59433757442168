import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { AuthModalParams } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import {
  clearProfileVouchersState,
  getSelectedPublicVoucher,
  selectProfileVouchers,
} from 'store/slices/profileVouchers';
import { selectWebsite } from 'store/slices/website';

const useVoucherRedeem = () => {
  const { websiteData } = useAppSelector(selectWebsite);
  const { user } = useAppSelector(selectAuth);

  const dispatch = useAppDispatch();
  const toast = useToast();
  const { voucherId } = useParams<{ voucherId: string }>();
  const [, setSearchParams] = useSearchParams();

  const { selectedPublicVoucher, error, isLoading } = useAppSelector(
    selectProfileVouchers,
  );

  useEffect(() => {
    if (voucherId) {
      dispatch(getSelectedPublicVoucher(voucherId));
    }
  }, [voucherId]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(clearProfileVouchersState());
    };
  }, []);

  const handleSignIn = useCallback(() => {
    setSearchParams({
      modal: AuthModalParams.SignIn,
    });
  }, []);

  return {
    actions: {
      handleSignIn,
    },
    data: {
      websiteData,
      selectedPublicVoucher,
      isLoading,
      user,
    },
  };
};

export default useVoucherRedeem;
