import { Box, Flex, ListItem, Text } from '@chakra-ui/react';
import { formatCurrency } from '@shared/utils/format';
import { format } from 'date-fns';
import { memo, useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setIsCouponModalOpen } from 'store/slices/checkoutCoupons';

interface Iprops {
  coupon: Coupons.Coupon;
  selectedCode: string;
  handleSetCouponCode: (code: string) => void;
  currency: Currency;
  onCouponApply: (code: string) => void;
}

const CouponListItem = ({
  coupon,
  selectedCode,
  handleSetCouponCode,
  currency,
  onCouponApply,
}: Iprops) => {
  const dispatch = useAppDispatch();
  const { code, discount_value, discount_type, end_at } = coupon;

  const handleClick = useCallback(() => {
    if (code !== selectedCode) {
      onCouponApply(code);
      handleSetCouponCode(code);
      dispatch(setIsCouponModalOpen(false));
    }
  }, [code, selectedCode]);

  return (
    <ListItem
      padding="10px"
      onClick={handleClick}
      cursor="pointer"
      _hover={{ bgColor: 'gray.100' }}
      border="1px solid"
      borderColor={selectedCode === code ? 'primary.400' : 'gray.200'}
      borderRadius="8px"
      mb="16px"
    >
      <Flex columnGap={4}>
        <Text width="50%" fontWeight={600} fontSize="sm">
          {code}
        </Text>
        <Text width="50%" fontWeight={600} fontSize="sm" textAlign="right">
          {`${
            discount_type === 0 ? formatCurrency(currency) : ''
          } ${discount_value} ${discount_type === 1 ? '%' : ''}`}
        </Text>
      </Flex>
      {end_at && (
        <Box paddingTop="4px">
          <Text fontSize="12px" color="gray.400">
            Valid until:
          </Text>
          <Text fontSize="12px" color="gray.400">
            {format(new Date(end_at), 'dd MMM, yyyy HH:mm a')}
          </Text>
        </Box>
      )}
    </ListItem>
  );
};

export default memo(CouponListItem);
