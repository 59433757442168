import { CloseButton, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';
import { VoucherWithBalance } from 'components/VoucherWithBalance';
import { format } from 'date-fns';

interface Props {
  onClose: VoidFunction;
  code: string;
  currency: Currency;
  balance: string;
  createdAt: string;
}

export const ProfileVoucherRedeemConfirmation = ({
  onClose,
  code,
  currency,
  balance,
  createdAt,
}: Props) => {
  const isMobile = useIsMobile();
  return (
    <>
      <CloseButton
        bgColor="gray.100"
        rounded="full"
        color="gray.400"
        onClick={onClose}
        ml="auto"
      />
      <Heading
        flex={1}
        fontSize={isMobile ? '16px' : '24px'}
        mt={isMobile ? '16px' : '20px'}
        textAlign="center"
      >
        Gift Voucher added to wallet
      </Heading>

      <Divider my="20px" />
      <VoucherWithBalance code={code} currency={currency} balance={balance} />
      <Divider my="20px" />
      <Flex padding="8px 0">
        <Text width="50%" fontSize="14px" lineHeight="24px" color="gray.400">
          Purchase Date
        </Text>

        {createdAt && (
          <Text
            width="50%"
            textAlign="right"
            fontSize="14px"
            lineHeight="24px"
            color="gray.400"
          >
            {format(new Date(createdAt), 'MMMM dd, yyyy HH:mm')}
          </Text>
        )}
      </Flex>
    </>
  );
};
