import { createSlice } from '@reduxjs/toolkit';
import { ServicesApi } from 'api/ServicesApi';
import { AppThunk, RootState } from 'store';

type ServicesState = {
  isLoading: boolean;
  error: Error | null;
  isAvailable: boolean;
  currentService: Services.OnlineService | null;
};

const initialState: ServicesState = {
  isLoading: true,
  error: null,
  isAvailable: false,
  currentService: null,
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setAvailable: (state, action: { payload: boolean }) => {
      state.isAvailable = action.payload;
    },
    setCurrentService: (
      state,
      action: { payload: Services.OnlineService | null },
    ) => {
      state.currentService = action.payload;
    },
  },
});

export const { setLoading, setError, setAvailable, setCurrentService } =
  servicesSlice.actions;

export const getAvailability =
  (serviceId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await ServicesApi.getAvailability(serviceId);
      dispatch(setAvailable(res.is_available));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getCurrentService =
  (serviceId: string): AppThunk =>
  async (dispatch) => {
    try {
      const res = await ServicesApi.getCurrentService(serviceId, 'config');
      dispatch(setCurrentService(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    }
  };

export const selectServices = (state: RootState) => state.services;

export default servicesSlice.reducer;
