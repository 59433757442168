import { Box, Button, Center, Text } from '@chakra-ui/react';
import { Input } from '@shared/components/Input';
import { useIsMobile } from '@shared/utils/screen';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { fontsForStripe } from 'assets/fonts';
import { getStripeApiKey } from 'utils/getEnvData';

import { PayForm } from '../PayForm';
import useVoucherCard from './hooks';
import { VoucherCardForm } from './VoucherCardForm';

interface Props {
  onSuccess: (data: Vouchers.BuyResponse & { email: string }) => void;
}

const stripePromise = loadStripe(getStripeApiKey());

const VoucherCard = ({ onSuccess }: Props) => {
  const {
    actions: {
      handleInputChange,
      onBuy,
      handleSuccess,
      handleChangeEmail,
      validateEmail,
      handleChangeRecipientEmail,
      validateRecipientEmail,
      handleChangeName,
      validateName,
      handleCancelPresent,
      setIsPresent,
      setVoucherData,
    },
    data: {
      isDisabled,
      value,
      email,
      emailError,
      isPresent,
      userName,
      userNameError,
      recipientEmail,
      recipientEmailError,
      voucherData,
      currencies,
    },
  } = useVoucherCard({ onSuccess });

  const isMobile = useIsMobile();

  return (
    <>
      <Box
        p={isMobile ? '12px' : '24px'}
        borderRadius="2xl"
        bgColor="white"
        w={isMobile ? 'full' : '570px'}
      >
        <Center>
          <VoucherCardForm
            value={value}
            currencies={currencies}
            handleInputChange={handleInputChange}
            isDisabled={Boolean(voucherData)}
          />
        </Center>

        {Number(value.balance) < 10 && (
          <Text textAlign="center">Available minimum value is 10</Text>
        )}
        {Number(value.balance) > 1000 && (
          <Text textAlign="center">Available maximum value is 1000</Text>
        )}

        <Box paddingTop="48px">
          <Input
            label="Your email address"
            value={email}
            error={emailError}
            onChange={(e) => handleChangeEmail(e.target.value)}
            isDisabled={false}
            onBlur={validateEmail}
            isReadOnly={Boolean(voucherData)}
          />
        </Box>

        {isPresent && (
          <Box>
            <Input
              label="Recipient email"
              value={recipientEmail}
              error={recipientEmailError}
              onChange={(e) => handleChangeRecipientEmail(e.target.value)}
              isDisabled={false}
              onBlur={validateRecipientEmail}
              isReadOnly={Boolean(voucherData)}
              formControlProps={{ mb: '4px' }}
            />
            <Text mb="24px" fontSize="12px">
              Enter the email address of the person you would like to send this
              to
            </Text>

            <Input
              label="Gifted by"
              value={userName}
              error={userNameError}
              onChange={(e) => handleChangeName(e.target.value)}
              isDisabled={false}
              onBlur={validateName}
              isReadOnly={Boolean(voucherData)}
              formControlProps={{ mb: '4px' }}
            />
            <Text mb="24px" fontSize="12px">
              Enter your name
            </Text>

            {!Boolean(voucherData) && (
              <Center>
                <Button size="md" mt="24px" onClick={handleCancelPresent}>
                  Buy this for yourself
                </Button>
              </Center>
            )}
          </Box>
        )}
        {!isPresent && !Boolean(voucherData) && (
          <Center>
            <Button size="md" mt="24px" onClick={() => setIsPresent(true)}>
              Give this to someone else?
            </Button>
          </Center>
        )}

        {voucherData && (
          <Elements stripe={stripePromise} options={{ fonts: fontsForStripe }}>
            <PayForm
              totalPrice={Number(value.balance)}
              onCancel={() => setVoucherData(undefined)}
              voucherId={voucherData.id}
              currency={value.currency}
              onSuccess={handleSuccess}
              email={email}
              recipientEmail={recipientEmail}
            />
          </Elements>
        )}
        {!voucherData && (
          <Button
            size="xl"
            w="full"
            mt="24px"
            onClick={onBuy}
            isDisabled={isDisabled}
          >
            Buy a voucher
          </Button>
        )}
      </Box>
    </>
  );
};

export default VoucherCard;
