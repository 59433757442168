import { IconButton, Image, StyleProps } from '@chakra-ui/react';

import BackArrowIcon from '../../assets/icons/back-arrow.svg';

interface Props extends StyleProps {
  onClick: VoidFunction;
  isWhiteBg?: boolean;
}

export const BackButton = ({ onClick, isWhiteBg, ...props }: Props) => (
  <IconButton
    {...props}
    onClick={onClick}
    aria-label="back"
    size="sm"
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgColor={isWhiteBg ? 'white' : '#f4f5f6'}
    h="32px"
    w="32px"
    borderRadius="50%"
    padding="0"
    outline="none"
    _hover={{
      textDecoration: 'none',
      opacity: 0.8,
      bgColor: isWhiteBg ? 'white' : '#f4f5f6',
    }}
    _focus={{
      textDecoration: 'none',
      opacity: 0.8,
      bgColor: isWhiteBg ? 'white' : '#f4f5f6',
    }}
  >
    <Image src={BackArrowIcon} />
  </IconButton>
);
