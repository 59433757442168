import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import { ErrorText } from '@shared/components/Texts/ErrorText';
import { AddressModal } from 'components/AddressModal';
import { useCallback, useState } from 'react';

interface Props {
  address?: Addresses.Address;
  setAddress: (address: Addresses.Address) => void;
  error: string;
}

export const DeliveryAddress = ({ address, setAddress, error }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <Stack direction="row" align="center" spacing="16px" py="16px">
        <Box w="24px">
          <Image src={MarkerIcon} w="16px" />
        </Box>
        <Box flex={1}>
          <Text fontWeight={600} fontSize="sm">
            {address?.formatted_address ?? ''}
          </Text>
          {error && <ErrorText error={error} />}
        </Box>
        <Button variant="link" onClick={openModal}>
          Change
        </Button>
      </Stack>
      {isOpen && (
        <AddressModal
          isOpen={isOpen}
          onClose={closeModal}
          onSelect={setAddress}
        />
      )}
    </>
  );
};
