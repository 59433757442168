import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import FacebookIcon from '@shared/assets/icons/facebook.svg';
import InstagramIcon from '@shared/assets/icons/instagram.svg';
import TwitterIcon from '@shared/assets/icons/twitter.svg';
import { useIsMobile } from '@shared/utils/screen';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
interface Props {
  facebook?: string;
  instagram?: string;
  twitter?: string;
  name?: string;
  allergensPdfUrl?: string;
}

const currentYear = new Date().getFullYear();

export const Footer = ({
  facebook = '',
  instagram = '',
  twitter = '',
  name = '',
  allergensPdfUrl,
}: Props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box
      display={isMobile ? 'block' : 'flex'}
      minHeight={isMobile ? '80px' : '112px'}
      bgColor="gray.800"
      px={isMobile ? '32px' : '72px'}
      justifyContent="space-between"
      alignItems="center"
      paddingY={isMobile ? '16px' : '0'}
    >
      <Flex
        justifyContent={isMobile ? 'center' : 'start'}
        mb={isMobile ? '16px' : '0'}
      >
        {facebook && (
          <Link href={facebook} isExternal mr="28px">
            <Image src={FacebookIcon} w="28px" />
          </Link>
        )}
        {instagram && (
          <Link href={instagram} isExternal mr="28px">
            <Image src={InstagramIcon} w="28px" />
          </Link>
        )}
        {twitter && (
          <Link href={twitter} isExternal>
            <Image src={TwitterIcon} w="28px" />
          </Link>
        )}
      </Flex>

      <Flex
        justifyContent={isMobile ? 'center' : 'start'}
        mb={isMobile ? '16px' : '0'}
      >
        <Text
          onClick={() => navigate(Paths.TermsConditions)}
          padding="8px"
          color="#fff"
          cursor="pointer"
        >
          Terms & Conditions
        </Text>

        <Text
          onClick={() => navigate(Paths.CookiePolicy)}
          padding="8px"
          color="#fff"
          cursor="pointer"
        >
          Cookie Policy
        </Text>

        <Text
          onClick={() => navigate(Paths.PrivacyPolicy)}
          padding="8px"
          color="#fff"
          cursor="pointer"
        >
          Privacy Policy
        </Text>
        {allergensPdfUrl && (
          <Link
            isExternal
            padding="8px"
            color="#fff"
            href={allergensPdfUrl}
            _hover={{}}
          >
            Allergens
          </Link>
        )}
      </Flex>

      <Text fontSize="xs" color="gray.400" textAlign="center" minWidth="140px">
        © {name}, {currentYear}
      </Text>
    </Box>
  );
};
