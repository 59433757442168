import { Box } from '@chakra-ui/react';

import { DaySelect } from './DaySelect';
import { DayTabs } from './DayTabs';

interface Props {
  items: string[];
  selected: string;
  onSelect: (value: string) => void;
  showSelect?: boolean;
}

export const DayPicker = ({
  items = [],
  selected,
  onSelect,
  showSelect,
}: Props) => (
  <Box
    flex={1}
    sx={{
      '.hide-scrollbar::-webkit-scrollbar': {
        display: 'none',
      },
      '.hide-scrollbar': {
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      },
    }}
  >
    {showSelect ? (
      <DaySelect items={items} selected={selected} onSelect={onSelect} />
    ) : (
      <DayTabs items={items} selected={selected} onSelect={onSelect} />
    )}
  </Box>
);
