import { Box, Button } from '@chakra-ui/react';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectVenues } from 'store/slices/venues';

import { IselectVenue } from '../hooks';
import { VenuesModal } from '../VenuesModal';

interface Iprops {
  handleSelectCollectionVenue: (args: IselectVenue) => void;
}

const CollectionSection = ({ handleSelectCollectionVenue }: Iprops) => {
  const [isVenuesOpen, setVenuesOpen] = useState(false);
  const { isLoading, venues } = useAppSelector(selectVenues);

  const handleStartOrder = useCallback(() => {
    if (venues.length === 1) {
      const {
        id,
        name,
        services,
        location: { currency },
      } = get(venues, '0');
      const serviceId: string = get(services, [0, 'id'], '');

      handleSelectCollectionVenue({
        venueId: id,
        venueName: name,
        serviceId,
        currency,
      });
    } else {
      setVenuesOpen(true);
    }
  }, [venues]);

  return (
    <Box>
      <Button
        size="full"
        isDisabled={isLoading}
        isLoading={isLoading}
        onClick={handleStartOrder}
      >
        Start order
      </Button>

      {isVenuesOpen && (
        <VenuesModal
          isOpen={isVenuesOpen}
          onClose={() => setVenuesOpen(false)}
          handleClick={handleSelectCollectionVenue}
        />
      )}
    </Box>
  );
};

export default CollectionSection;
