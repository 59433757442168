import { Flex } from '@chakra-ui/layout';
import { useIsMobile } from '@shared/utils/screen';
import {
  DeliveryTrackingMap,
  DeliveryTrackingMapProps,
} from 'components/DeliveryTrackingMap';

import { ConfirmationWrapper } from './ConfirmationWrapper';

interface Props extends DeliveryTrackingMapProps {
  show: boolean;
  summarySection: React.ReactNode;
}

export const InfoWithTrackingMap = ({
  children,
  show,
  summarySection,
  ...mapProps
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();
  if (!show) {
    return <>{children}</>;
  }
  return (
    <Flex flex={1} direction={isMobile ? 'column-reverse' : 'row'}>
      <ConfirmationWrapper summarySection={summarySection}>
        {children}
      </ConfirmationWrapper>
      <DeliveryTrackingMap
        flex={isMobile ? 1 : 0.7}
        borderRightRadius={isMobile ? 'none' : '2xl'}
        {...mapProps}
      />
    </Flex>
  );
};
