import { Button } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';

import { useReorder } from '../hooks';

export const Reorder = () => {
  const isMobile = useIsMobile();

  const onReorder = useReorder();

  return (
    <Button
      variant="secondary"
      size={isMobile ? 'xs' : 'md'}
      onClick={onReorder}
    >
      Repeat order
    </Button>
  );
};
