import { ListItem, Text } from '@chakra-ui/react';

interface Props {
  address: Addresses.Address;
  onClick?: (address: Addresses.Address) => void;
}

export const AddressItem = ({ address, onClick }: Props) => {
  return (
    <ListItem
      py="16px"
      borderBottom="1px solid"
      borderColor="gray.200"
      _last={{ borderBottom: 'none' }}
      onClick={() => onClick?.(address)}
      cursor={onClick ? 'pointer' : 'default'}
    >
      <Text fontSize="sm" fontWeight="600">
        {address.formatted_address}
      </Text>
    </ListItem>
  );
};
