import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

import { areObjectsEquals } from '../utils/object';

export const useDeepEffect = (
  callback: EffectCallback,
  dependencies: DependencyList,
): void => {
  const depRef = useRef<DependencyList>([]);

  useEffect(() => {
    if (!areObjectsEquals(depRef.current, dependencies)) {
      depRef.current = dependencies;
      return callback();
    }
  }, dependencies);
};
