import { Flex, Heading, IconButton } from '@chakra-ui/react';
import { EditIcon } from '@shared/assets/icons';
import { CounterButton } from '@shared/components/Buttons/CounterButton';
import { ExtraListItem } from '@shared/components/OrderDetailsList/ExtraListItem';
import { PriceText } from '@shared/components/Texts/PriceText';
import { formatCurrency } from '@shared/utils/format';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback, useMemo, useState } from 'react';

import { SelectedProduct } from './ExtraProductList';

interface Props {
  id: string;
  name: string;
  limit: number;
  price: number;
  isWithCounter?: boolean;
  selected?: SelectedProduct[];
  onChange?: (value?: SelectedProduct) => void;
  isEditable: boolean;
  disableUnselected?: boolean;
  onEditClick?: () => void;
  defaultQuantity: number;
  thirdLevelProducts: Product.RootObject;
  isEditRequired: boolean;
}

export const ExtraProductItem = ({
  id,
  name,
  limit,
  price,
  isWithCounter,
  selected,
  onChange,
  isEditable,
  onEditClick,
  disableUnselected,
  defaultQuantity = 0,
  thirdLevelProducts,
  isEditRequired,
}: Props) => {
  const isMobile = useIsMobile();
  const [count, setCount] = useState<number>(
    () => selected?.find((s) => s.id === id)?.amount ?? 0,
  );
  const isSelected = useMemo(
    () =>
      selected && selected.findIndex((s) => s.id === id && s.amount > 0) > -1,
    [selected, id],
  );

  const thirdProductList = useMemo(
    () =>
      thirdLevelProducts.extra_sections.flatMap((es) =>
        es.products.flatMap((p) =>
          p.amount === p.default_quantity
            ? []
            : [
                <ExtraListItem
                  key={p.id}
                  name={p.display_name ?? p.name}
                  quantity={p.amount as number}
                  defaultQuantity={p.default_quantity}
                  currency={formatCurrency()}
                  price={
                    p.amount && p.amount - p.default_quantity > 0
                      ? p.price
                      : undefined
                  }
                />,
              ],
        ),
      ),
    [thirdLevelProducts],
  );

  const handleChange = useCallback(
    (value: number) => {
      onChange &&
        onChange(
          value > 0 || defaultQuantity > 0
            ? { id, amount: value, defaultQuantity, price }
            : undefined,
        );

      setCount(value);
    },
    [onChange, defaultQuantity],
  );

  const onItemClick = useCallback(() => {
    handleChange(count + 1);
  }, [handleChange, count]);

  return (
    <Flex
      justify="space-between"
      align={
        thirdProductList.length > 0 && isWithCounter ? 'flex-start' : 'center'
      }
      flex={1}
      py="8px"
      minH="48px"
    >
      <Flex
        direction="column"
        justify="space-between"
        flex={1}
        cursor={isWithCounter && isMobile ? 'pointer' : undefined}
        onClick={isWithCounter && isMobile ? onItemClick : undefined}
      >
        <Heading
          size="xs"
          mb="4px"
          color={!isSelected ? undefined : 'primary.400'}
          maxW="230px"
        >
          {name}
        </Heading>
        {thirdProductList}
        {isWithCounter && Boolean(price) && (
          <PriceText
            price={price}
            color={!isSelected ? 'gray.400' : 'primary.400'}
            fontSize="xs"
          />
        )}
      </Flex>
      {isEditable && (
        <IconButton
          aria-label="edit"
          isRound
          size="sm"
          icon={<EditIcon width="24px" height="24px" />}
          onClick={onEditClick}
          variant="gray"
          mx="16px"
          color={isEditRequired ? 'red.500' : undefined}
        />
      )}
      {!isWithCounter && Boolean(price) && (
        <PriceText
          price={price}
          color={!isSelected ? 'gray.400' : 'primary.400'}
          fontSize="xs"
        />
      )}
      {isWithCounter && (
        <CounterButton
          minimum={0}
          maximum={disableUnselected ? count : limit}
          grayVariant
          count={count}
          onChange={handleChange}
          disabled={disableUnselected && !isSelected}
        />
      )}
    </Flex>
  );
};
