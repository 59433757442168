import { BaseApiService } from './BaseApiService';

export class ServicesApi {
  public static async getAvailability(serviceId: string) {
    return await BaseApiService.get<Services.AvailabilityResponse>({
      path: `services/${serviceId}/is-available/`,
      authRequired: false,
      version: 'v2',
    });
  }

  public static async getCurrentService(serviceId: string, expand?: string) {
    return await BaseApiService.get<Services.OnlineService>({
      path: `services/${serviceId}/?expand=${expand}`,
      authRequired: false,
    });
  }
}
