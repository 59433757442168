import { Button, Center, Heading } from '@chakra-ui/react';
import {
  captureException,
  ErrorBoundary as SentryErrorBoundary,
  withScope,
} from '@sentry/react';

export const ErrorBoundary = ({ children }: React.PropsWithChildren) => {
  return (
    <SentryErrorBoundary
      fallback={({ error, resetError }) => {
        withScope((scope) => {
          scope.setLevel('fatal');
          captureException(error);
        });
        return (
          <Center flexDirection="column">
            <Heading size="3xl">Oops!</Heading>
            <Heading my="40px">Something went wrong.</Heading>
            <Button size="xl" onClick={resetError}>
              Reload
            </Button>
          </Center>
        );
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};
