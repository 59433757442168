import { percentageOfNumber } from '@shared/utils/number';
import roundHalfEven from 'round-half-even';
// roundHalfEvendoes not work correctly with numbers that have more than 3 digits after the decimal point.
export const countCouponDiscount = (
  discountType: Discounts.DiscountType,
  totalAmount: number,
  discountValue: number,
) =>
  discountType === 1
    ? roundHalfEven(
        Number(
          percentageOfNumber(
            totalAmount,
            discountValue > 100 ? 100 : discountValue,
          ).toFixed(3),
        ),
      )
    : discountValue > totalAmount
    ? totalAmount
    : discountValue;
