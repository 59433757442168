import { StyleProps, Text } from '@chakra-ui/react';

interface Props extends StyleProps {
  items: Allergen[];
}

export const AllergensText = ({ items, ...props }: Props) => (
  <Text as="span" fontSize="xs" color="gray.400" {...props}>
    Allergens: {items.map((item) => item.title).join(', ')}
  </Text>
);
