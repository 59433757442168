import { captureException } from '@sentry/react';
import { getAuthTokens } from '@shared/utils/auth';
import { ErrorWithStackTrace } from '@shared/utils/errors';
import { getApiHost, getWebSocketHost } from '@shared/utils/getEnvData';
import { parseStringToObject } from '@shared/utils/object';
import debounce from 'lodash.debounce';
import { getWebsiteId } from 'utils/getEnvData';

interface RequestParams<Body = Record<string, unknown>> {
  path: string;
  customHeaders?: HeadersInit;
  authRequired?: boolean;
  body?: Body;
  version?: 'v1' | 'v2';
  customUrl?: string;
}

interface ErrorResponse {
  message: string;
}

export type Handler<Data> = (
  data: Data | null,
  close: VoidFunction,
) => Promise<void> | void;

const ErrorMessage = (
  status: string | number,
  message: string,
  data?: unknown,
) => ({
  status,
  message,
  data,
});

export class BaseApiService {
  private static apiHost = () => {
    const [protocol, url] = getApiHost().split('://');
    const websiteId = getWebsiteId();
    return `${protocol}://${websiteId}.${url}`;
  };
  private static apiPath = 'api';
  private static wsHost = getWebSocketHost();

  public static async get<
    Return = { message: string } & Record<string, unknown>,
  >({
    customUrl,
    path,
    customHeaders,
    authRequired = true,
    version = 'v1',
  }: RequestParams): Promise<Return> {
    const url =
      customUrl ?? `${this.apiHost()}/${this.apiPath}/${version}/${path}`;

    try {
      let headers = {
        ...customHeaders,
      };
      if (authRequired) {
        const { access } = getAuthTokens();
        headers = {
          Authorization: `Bearer ${access}`,
          ...headers,
        };
      }
      const response: Response = await fetch(url, {
        headers,
        method: 'GET',
      });
      const result = (await response.json()) as Promise<Return>;
      if (!response || response.status !== 200) {
        throw ErrorMessage(
          response.status,
          (result as unknown as ErrorResponse)?.message ?? '',
          result,
        );
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  public static async post<
    RequestBody = Record<string, unknown>,
    Return = string,
  >({
    customUrl,
    path,
    body,
    authRequired = true,
    version = 'v1',
  }: RequestParams<RequestBody>): Promise<Return> {
    const url =
      customUrl ?? `${this.apiHost()}/${this.apiPath}/${version}/${path}`;
    try {
      let headers: HeadersInit = {
        'content-type': 'application/json',
      };
      if (authRequired) {
        const { access } = getAuthTokens();
        headers = {
          Authorization: `Bearer ${access}`,
          ...headers,
        };
      }
      const response: Response = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(body),
      });
      const result = (await response.json()) as Promise<Return>;
      if (!response || ![200, 201].includes(response.status)) {
        throw ErrorMessage(
          response.status,
          (result as unknown as ErrorResponse)?.message ?? '',
          result,
        );
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  public static async patch<
    RequestBody = Record<string, unknown>,
    Return = string,
  >({
    path,
    body,
    version = 'v1',
  }: RequestParams<RequestBody>): Promise<Return> {
    const url = `${this.apiHost()}/${this.apiPath}/${version}/${path}`;
    try {
      const { access } = getAuthTokens();
      const response: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${access}`,
          'content-type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      const result = (await response.json()) as Promise<Return>;
      if (!response || response.status !== 200) {
        throw ErrorMessage(
          response.status,
          (result as unknown as ErrorResponse)?.message ?? '',
          result,
        );
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  public static async put<
    RequestBody = Record<string, unknown>,
    Return = string,
  >({
    path,
    body,
    version = 'v1',
  }: RequestParams<RequestBody>): Promise<Return> {
    const url = `${this.apiHost()}/${this.apiPath}/${version}/${path}`;
    try {
      const { access } = getAuthTokens();
      const response: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${access}`,
          'content-type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(body),
      });
      const result = (await response.json()) as Promise<Return>;
      if (!response || response.status !== 200) {
        throw ErrorMessage(
          response.status,
          (result as unknown as ErrorResponse)?.message ?? '',
          result,
        );
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  public static async delete<Return = string>({
    path,
    version = 'v1',
  }: RequestParams): Promise<Return> {
    const url = `${this.apiHost()}/${this.apiPath}/${version}/${path}`;
    try {
      const { access } = getAuthTokens();
      const response: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
        method: 'DELETE',
      });
      // response.json() for DELETE method causing error
      if (!response || response.status !== 204) {
        throw ErrorMessage(response.status, '', response);
      }
      return {} as Promise<Return>;
    } catch (error) {
      throw error;
    }
  }

  public static connectWebSocket<Return = unknown>({
    path,
    version = 'v1',
    authRequired = true,
    handler,
  }: Pick<RequestParams, 'path' | 'version' | 'authRequired'> & {
    handler: Handler<Return>;
  }): void {
    let socketReconnectionDelay = 1000;

    const connect = () => {
      const ws = new WebSocket(
        `${this.wsHost}/${this.apiPath}/${version}/${path}`,
      );

      ws.onopen = () => {
        if (authRequired) {
          const { access } = getAuthTokens();
          ws.send(
            JSON.stringify({
              message_type: 'authorization',
              payload: {
                access_token: access,
              },
            }),
          );
        }
        debounce(() => {
          socketReconnectionDelay = 1000;
        }, socketReconnectionDelay);
      };

      ws.onerror = () => {
        ws.close(3000);
      };

      ws.onclose = (event) => {
        if (event.code !== 1000) {
          // send error message once on the first try
          if (socketReconnectionDelay === 1000) {
            const error = new ErrorWithStackTrace('Websocket is reconnecting', {
              url: ws.url,
            });
            captureException(error);
          }
          /**
           * exponential recoconnect delay - x2 everytime
           * max - 100s
           * min - 1s
           *  */
          setTimeout(
            () => connect(),
            Math.min(socketReconnectionDelay, 100 * 1000),
          );
          socketReconnectionDelay = Math.min(
            socketReconnectionDelay * 2,
            100 * 1000,
          );
        }
      };

      ws.onmessage = async (e: MessageEvent<string>) => {
        await handler(parseStringToObject<Return>(e.data), () =>
          ws.close(1000),
        );
      };
    };
    connect();
  }
}
