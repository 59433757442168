import { Divider, Heading, useToast } from '@chakra-ui/react';
import AddressForm from '@shared/components/AddressForm';
import { AddressList } from '@shared/components/AddressList';
import { SearchAddressInput } from '@shared/components/Input/SearchAddressInput';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';
import { validateEircode } from '@shared/utils/validation';
import { AddressApi } from 'api/AdressesApi';
import useProfileAddresses from 'pages/ProfileSavedAddresses/hooks';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUiServices } from 'store/slices/uiServices';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onSelect: (address: Addresses.Address) => void;
  skipSecondScreen?: true;
}

export const AddressModal = ({
  isOpen,
  onClose,
  onSelect,
  skipSecondScreen,
}: Props) => {
  const {
    data: { addresses, isLoading },
  } = useProfileAddresses(skipSecondScreen);
  const { countryCodes } = useAppSelector(selectUiServices);

  const isMobile = useIsMobile();
  const [newAddress, setNewAddress] = useState<Partial<Addresses.Address>>();
  const [formErrors, setFormErrors] =
    useState<Record<'address' | 'code', string>>();
  const toast = useToast();

  const handleSelect = useCallback(
    (address: Partial<Addresses.Address>) => {
      onSelect(address as Addresses.Address);
      onClose();
    },
    [onClose, onSelect],
  );

  const handleAddressFormChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      switch (name) {
        case 'flatNo':
          setNewAddress((prevState) => ({ ...prevState, flat_no: value }));
          break;
        case 'code':
          setNewAddress((prevState) => ({ ...prevState, eircode: value }));
          break;
      }
    },
    [],
  );

  const handleSaveAddress = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (newAddress && validateForm()) {
        AddressApi.saveNewAddress({
          ...newAddress,
          formatted_address:
            (newAddress.flat_no ? `${newAddress.flat_no}, ` : '') +
            (newAddress.full_address ?? '') +
            (newAddress.eircode ? `, ${newAddress.eircode}` : ''),
        })
          .then((res) => {
            handleSelect(res);
            setNewAddress(undefined);
          })
          .catch((error: Error) => {
            toast({
              description: error.message || String(error),
              status: 'error',
              isClosable: true,
            });
          });
      }
    },
    [handleSelect, newAddress],
  );

  /**
   * return {true} - pass
   */
  const validateForm = useCallback(() => {
    const e = {
      address: newAddress?.full_address ? '' : 'Field is required',
      code: validateEircode(newAddress?.eircode || ''),
    };
    setFormErrors(e);
    return !e.address && !e.code;
  }, [newAddress]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      size={{ mobile: 'full', desktop: 'lg' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
    >
      <Heading size="sm" lineHeight="32px" mb="24px">
        Set delivery address
      </Heading>
      {newAddress && !skipSecondScreen ? (
        <AddressForm
          onChange={handleAddressFormChange}
          onSubmit={handleSaveAddress}
          address={newAddress}
          formErrors={formErrors}
          onFocus={() => setFormErrors(undefined)}
        />
      ) : (
        <>
          <SearchAddressInput
            onSelect={skipSecondScreen ? handleSelect : setNewAddress}
            countryCodes={countryCodes}
          />
          {addresses && addresses.length > 0 && (
            <>
              <Divider />
              <AddressList addresses={addresses} onSelect={handleSelect} />
            </>
          )}
        </>
      )}
    </Modal>
  );
};
