import { AspectRatio, Center, Image, SimpleGrid, Text } from '@chakra-ui/react';
import PhotoIcon from '@shared/assets/images/photo.svg';
import { useIsMobile } from '@shared/utils/screen';
import { Carousel } from 'components/Carousel';
import { useCallback, useMemo, useState } from 'react';

interface Props {
  images: Website.Gallery[];
}

export const Gallery = ({ images = [] }: Props) => {
  if (images.length < 3) return null;
  const isMobile = useIsMobile();
  const [imgId, setImgId] = useState<string | null>(null);

  const handleOpenCarousel = useCallback(
    (id: string | null) => () => {
      setImgId(id);
    },
    [],
  );

  const slicedImages = useMemo(() => {
    const totalImgs = images.length;
    if (totalImgs < 10) {
      if (totalImgs % 3 === 0) {
        return images;
      } else {
        return images.slice(0, totalImgs - (totalImgs % 3));
      }
    }
    return images.slice(0, 9);
  }, [images]);

  return (
    <SimpleGrid columns={isMobile ? 1 : 3}>
      {slicedImages.map((img, index) => (
        <AspectRatio
          key={img.id}
          ratio={16 / 9}
          overflow="hidden"
          onClick={handleOpenCarousel(img.id)}
          cursor="pointer"
        >
          <>
            <Image
              src={img.image}
              objectFit="cover"
              transform="scale3d(1, 1, 1)"
              transition="all 0.5s linear"
              _hover={{ transform: 'scale3d(1.2, 1.2, 1)' }}
            />
            {images.length % 3 !== 0 && index === slicedImages.length - 1 && (
              <Center flexDirection="column" bgColor="gray.900" opacity={0.8}>
                <Image src={PhotoIcon} alt="photo" />
                <Text color="white" fontSize="sm" mt="16px">
                  See more
                </Text>
              </Center>
            )}
          </>
        </AspectRatio>
      ))}
      <Carousel
        isOpen={imgId !== null}
        onClose={handleOpenCarousel(null)}
        images={images}
        defaultImageId={imgId ?? ''}
      />
    </SimpleGrid>
  );
};
