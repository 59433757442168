import {
  Box,
  Center,
  CircularProgress,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';
import { useAppSelector } from 'store/hooks';
import { selectVenues } from 'store/slices/venues';

import { IselectVenue } from '../hooks';
import VenueItem from './VenueItem';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  address?: string;
  handleClick: (args: IselectVenue) => void;
}

export const VenuesModal = ({
  isOpen,
  onClose,
  address,
  handleClick,
}: Props) => {
  const isMobile = useIsMobile();

  const { isLoading, venues } = useAppSelector(selectVenues);

  // null === default true
  const filteredVenues = venues.filter(
    ({ is_delivery_possible }) =>
      is_delivery_possible === true || is_delivery_possible === null,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      size={{ mobile: 'full', desktop: '3xl' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
    >
      <Heading size="sm" lineHeight="32px" mb="24px">
        Choose venue
      </Heading>

      {address && (
        <Flex columnGap="20px" alignItems="flex-start" marginBottom="24px">
          <Image src={MarkerIcon} alt="Location" />
          <Box>
            <Text
              fontSize="12px"
              lineHeight="16px"
              marginBottom="4px"
              color="gray.400"
            >
              Delivery address
            </Text>
            <Text fontSize="14px" lineHeight="24px" fontWeight="600">
              {address}
            </Text>
          </Box>
        </Flex>
      )}

      {isLoading && (
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      )}
      {!isLoading && (
        <Flex rowGap={'8px'} flexWrap="wrap">
          {Boolean(filteredVenues.length) ? (
            <>
              {filteredVenues.map((venue) => (
                <VenueItem
                  key={venue.id}
                  venue={venue}
                  handleClick={handleClick}
                />
              ))}
            </>
          ) : (
            <Text
              size="sm"
              lineHeight="32px"
              textAlign="center"
              color="gray.400"
              width="100%"
            >
              Unfortunately no venues service your area
            </Text>
          )}
        </Flex>
      )}
    </Modal>
  );
};
