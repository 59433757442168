import { Box, Checkbox, Heading, Text } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { Input } from '@shared/components/Input';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useNavigate } from 'react-router-dom';

import useProfileSettings from './hooks';

export const ProfileSettings = () => {
  const {
    actions: { handleSubscribe },
    data: { user, isAllowed, isLoading },
  } = useProfileSettings();

  const navigate = useNavigate();

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      <BackButton onClick={() => navigate(-1)} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" mt={4} mb={10}>
        Profile settings
      </Heading>

      <Box paddingBottom="16px" borderBottom="1px solid" borderColor="gray.200">
        <Input label="Name" name="name" value={user?.name || ''} isReadOnly />

        <Input
          label="Email"
          name="email"
          value={user?.email || ''}
          isReadOnly
        />
      </Box>

      <Box py={8}>
        <Heading as="h2" fontSize="xl" lineHeight="32px" m="0 0 24px">
          Email subscription settings
        </Heading>

        <Checkbox
          colorScheme="primary"
          name="marketing_allowed"
          isChecked={isAllowed}
          onChange={handleSubscribe}
          isDisabled={isLoading}
        >
          <Text fontSize="xs" lineHeight="24px" color="gray.800">
            Receive vouchers, discount codes and promotions
          </Text>
        </Checkbox>
      </Box>
    </WidgetWrapper>
  );
};
