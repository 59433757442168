import { Select } from '@chakra-ui/react';
import { format, getDay, isToday, isTomorrow, isValid } from 'date-fns';
import { useMemo } from 'react';

import { getDayName } from '../../utils/date';

interface Props {
  items: string[];
  selected: string;
  onSelect: (value: string) => void;
}

export const DaySelect = ({ items = [], selected, onSelect }: Props) => {
  const options = useMemo(() => {
    return items.map((item, i) => {
      const date = new Date(item);
      if (!isValid(date)) return null;
      const title = isToday(date)
        ? 'Today'
        : isTomorrow(date)
        ? 'Tomorrow'
        : getDayName(getDay(date));
      const subtitle = format(date, 'PP');

      return (
        <option
          key={`${i}-${item}`}
          value={item}
        >{`${title} - ${subtitle}`}</option>
      );
    });
  }, [items]);

  return (
    <Select
      name="day"
      value={selected}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onSelect(e.target.value)
      }
    >
      {options}
    </Select>
  );
};
