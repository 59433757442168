import {
  Button,
  Center,
  Divider,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react';
import { TextareaInput } from '@shared/components/Input/TextareaInput';
import { Modal } from '@shared/components/Modal';
import { OrdersApi } from 'api/OrdersApi';
import { Rating } from 'components/Rating';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { getOrder } from 'store/slices/orders';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  orderId: string;
}

export const ReviewModal = ({ isOpen, onClose, orderId }: Props) => {
  const dispatch = useAppDispatch();

  const [stars, setStars] = useState<Orders.RatingStars>(0);
  const [feedback, setFeedback] = useState<string>('');

  const toast = useToast();

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFeedback(e.target.value);
    },
    [],
  );

  const onSubmit = useCallback(() => {
    OrdersApi.review(orderId, { stars, feedback })
      .then(() => {
        dispatch(
          getOrder({
            orderId,
          }),
        );
        onClose();
      })
      .catch((error: ApiTypes.ResponseError) => {
        toast({
          description: error.message || String(error),
          status: 'error',
          isClosable: true,
        });
      });
  }, [stars, feedback, orderId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ mobile: 'full', desktop: 'lg' }}
    >
      <Heading fontSize="2xl">Review your order</Heading>
      <Divider mt="8px" mb="24px" />
      <Text fontSize="xs" mb="8px" fontWeight={600}>
        Stars
      </Text>
      <Center bgColor="gray.100" p="24px" borderRadius="md" mb="24px">
        <Rating stars={stars} setStars={setStars} isLarge spacing="20px" />
      </Center>
      <TextareaInput
        label="Feedback"
        value={feedback}
        onChange={onInputChange}
      />
      <Button size="xl" onClick={onSubmit}>
        Submit review
      </Button>
    </Modal>
  );
};
