import { ColorHues, ToastProviderProps } from '@chakra-ui/react';

import { CustomToastAlert } from '../components/CustomToastAlert';
import { colors } from './colors';
import { components } from './components';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';

export type UpdatedColors = {
  primary?: ColorHues;
  secondary?: ColorHues;
};

export const theme = (updatedColors?: UpdatedColors) => ({
  fonts,
  components,
  colors: {
    ...colors,
    ...updatedColors,
  },
  fontSizes,
  radii: {
    sm: '4px',
    md: '8px',
    lg: '12px',
    '2xl': '16px',
  },
  sizes: {
    container: {
      xl: '1712px',
    },
  },
});

export type Theme = ReturnType<typeof theme>;

export const toastOptions: ToastProviderProps = {
  defaultOptions: {
    render: CustomToastAlert,
  },
};
