import {
  Box,
  Button,
  Center,
  CircularProgress,
  Container,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';
import CoinImage from '@shared/assets/images/coin.svg';
import { LabelWithPriceText } from '@shared/components/Texts/LabelWithPriceText';
import { useIsMobile } from '@shared/utils/screen';
import { Footer } from 'components/LandingSections/Footer';
import { AuthModal } from 'pages/AuthModal';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes/paths';

import useVoucherRedeem from './hooks';

export const VoucherRedeem = () => {
  const isMobile = useIsMobile();

  const {
    actions: { handleSignIn },
    data: { websiteData, selectedPublicVoucher, isLoading, user },
  } = useVoucherRedeem();

  return (
    <Flex
      bg={isMobile ? '#fff' : 'gray.950'}
      minH={`calc(100dvh - ${MENU_HEIGHT}px)`}
      padding={isMobile ? '0' : '88px 0 0'}
      direction="column"
      justifyContent="space-between"
    >
      <Container maxW="600px" px={'16px'} py={isMobile ? '80px' : '120px'}>
        {isLoading ||
          (!selectedPublicVoucher && (
            <Center>
              <CircularProgress isIndeterminate color="primary.400" />
            </Center>
          ))}

        {!isLoading && selectedPublicVoucher && (
          <Box bgColor="#fff" p="24px" borderRadius="2xl">
            <Center marginBottom="20px">
              <Image src={CoinImage} alt="coin-icon" />
            </Center>
            <Heading as="h1" size="lg" mb="32px" textAlign="center">
              You have received a voucher!
            </Heading>

            {selectedPublicVoucher.gifted_by && (
              <>
                <Text textAlign="center">
                  Congratulation, you have received a voucher from:
                </Text>
                <Text
                  fontWeight="600"
                  fontSize="20px"
                  marginBottom="40px"
                  textAlign="center"
                >
                  {selectedPublicVoucher.gifted_by}
                </Text>
              </>
            )}

            <Divider my="24px" />
            <LabelWithPriceText
              largeSize
              currency={selectedPublicVoucher.currency}
              label="Voucher total:"
              price={selectedPublicVoucher.balance}
            />
            <Divider my="24px" />

            {user ? (
              <Text fontWeight="600" textAlign="center">
                <Link as={RouterLink} to={Paths.ProfileVouchers}>
                  Go to your vouchers
                </Link>
              </Text>
            ) : (
              <>
                <Text marginBottom="20px" textAlign="center">
                  If you are not registered yet click register below
                </Text>

                <Center>
                  <Button onClick={handleSignIn} size="lg">
                    Sign In
                  </Button>
                </Center>
              </>
            )}
          </Box>
        )}
      </Container>
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
      />

      <AuthModal />
    </Flex>
  );
};
