export const percentageOfNumber = (
  number: number,
  percentage: number,
): number => number * (percentage / 100);

export const bankersRound = (num: number, decimalPlaces = 2): number => {
  const multiplier = 10 ** decimalPlaces;
  const roundedNum = +(decimalPlaces ? num * multiplier : num).toFixed(8);
  const integerPart = Math.floor(roundedNum);
  const fractionalPart = roundedNum - integerPart;
  const epsilon = 1e-8; // Allow for rounding errors in fractionalPart
  const roundedInteger =
    fractionalPart > 0.5 - epsilon && fractionalPart < 0.5 + epsilon
      ? integerPart % 2 === 0
        ? integerPart
        : integerPart + 1
      : Math.round(roundedNum);

  return decimalPlaces ? roundedInteger / multiplier : roundedInteger;
};
