import {
  Button,
  Center,
  CircularProgress,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';

import useUpsellsModal from './hooks';
import UpsellItem from './UpsellItem';

interface Iprops {
  isOpen: boolean;
}

export const UpsellsModal = ({ isOpen }: Iprops) => {
  const isMobile = useIsMobile();

  const {
    actions: { handleSubmit, handleCloseModal },
    data: { products, isLoading, addedToCardProductIds },
  } = useUpsellsModal();

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      isLoading={false}
      size={{ mobile: 'full', desktop: '3xl' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
      contentProps={{
        maxWidth: '920px',
        position: 'relative',
      }}
    >
      <Heading as="h2" mb="24px" fontSize="20px" lineHeight="32px">
        People also added
      </Heading>

      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      ) : (
        <Flex
          columnGap="16px"
          rowGap="16px"
          flexWrap="wrap"
          paddingBottom="96px"
        >
          {products.map((product) => (
            <UpsellItem
              key={product.id}
              product={product}
              addedToCardProductIds={addedToCardProductIds}
            />
          ))}
        </Flex>
      )}

      <Flex
        justifyContent="center"
        position="absolute"
        left="0"
        right="0"
        bottom={isMobile ? '0' : '40px'}
        borderTop={isMobile ? '1px solid' : '0'}
        padding={isMobile ? '12px' : '0'}
        borderColor="gray.200"
        bgColor={isMobile ? '#fff' : 'transparent'}
      >
        <Button
          width="100%"
          maxWidth="320px"
          size="xl"
          onClick={handleSubmit}
          boxShadow={isMobile ? '0' : '0px 15px 15px rgba(0, 0, 0, 0.2)'}
        >
          {Boolean(addedToCardProductIds.length) ? 'Done' : 'Skip'}
        </Button>
      </Flex>
    </Modal>
  );
};
