import { Flex, FlexProps } from '@chakra-ui/react';

export const RadioIcon = (props: FlexProps) => (
  <Flex
    {...props}
    align="center"
    justify="center"
    minW="18px"
    minH="18px"
    w="18px"
    h="18px"
    borderWidth="2px"
    borderColor="gray.200"
    borderRadius="full"
    _checked={{
      borderColor: 'primary.400',
      _before: {
        content: '""',
        display: 'block',
        position: 'relative',
        width: '8px',
        height: '8px',
        borderRadius: '100%',
        background: 'primary.400',
      },
    }}
    ml="12px"
  />
);
