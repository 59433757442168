import { Box, Button, Flex } from '@chakra-ui/react';

import { IAvailableService } from '../hooks';

interface Iprops {
  availableServices: IAvailableService[];
  selectedServiceType: number | null;
  setSelectedServiceType: (service_type: number) => void;
}

const ServiceSection = ({
  availableServices,
  selectedServiceType,
  setSelectedServiceType,
}: Iprops) => {
  // show buttons if there is 2 or more options
  return (
    <Box marginBottom="40px">
      {availableServices.length > 1 && (
        <Flex bgColor="#F4F5F6" borderRadius="12px">
          {availableServices.map(({ service_type, name }) => {
            if (selectedServiceType === service_type) {
              return (
                <Button size="full" height="48px" key={service_type}>
                  {name}
                </Button>
              );
            }

            return (
              <Button
                size="full"
                height="48px"
                bgColor="transparent"
                color="gray.900"
                _hover={{
                  color: '#fff',
                  bgColor: 'primary.600',
                }}
                _focus={{
                  color: '#fff',
                  bgColor: 'primary.400',
                }}
                key={service_type}
                onClick={() => {
                  setSelectedServiceType(service_type);
                }}
              >
                {name}
              </Button>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};

export default ServiceSection;
