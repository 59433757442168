import { Heading, HeadingProps } from '@chakra-ui/react';

import { formatCurrency } from '../../utils/format';

interface Props extends HeadingProps {
  price: number;
  currency?: Currency | null;
  negative?: boolean;
  count?: number;
}

export const PriceText = ({
  price,
  currency,
  negative,
  count,
  ...props
}: Props) => (
  <Heading
    as="span"
    size="xs"
    color="primary.400"
    whiteSpace="nowrap"
    {...props}
  >
    {`${negative ? '-' : ''}${formatCurrency(currency)} ${price.toFixed(2)} ${
      count ? `(${count})` : ''
    }`}
  </Heading>
);
