import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { findLast } from '../utils/array';
import { removeSpaces } from '../utils/string';

export type DepartmentPosition = {
  name: string;
  position: number;
};
interface Props {
  menuPath: string;
  selected: string;
  positions: DepartmentPosition[];
}

export const useDepartmentScrollNavigation = ({
  menuPath,
  selected,
  positions,
}: Props): [string, (value: string) => void] => {
  const [activeSection, setActiveSection] = useState<string>('');
  const isUserScrolled = useRef<boolean>(false);
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const { venueId, serviceId, department } = useParams<{
    venueId: string;
    serviceId: string;
    department: string;
  }>();

  const handleScroll = debounce(() => {
    if (!isUserScrolled.current) return;
    const currentPosition = window.scrollY;
    const dep = findLast(positions, (item) => item.position < currentPosition);
    if (dep && venueId && serviceId) {
      scrollToDepartment(dep.name, true);
    }
  }, 10);

  const setUserScrolled = useCallback(() => {
    isUserScrolled.current = true;
  }, []);

  useEffect(() => {
    const handleKeyboardAppear = () => {
      if (
        window.visualViewport?.height &&
        window.innerHeight - 100 > window.visualViewport?.height
      ) {
        isUserScrolled.current = false;
      }
    };

    window.visualViewport?.addEventListener('resize', handleKeyboardAppear);
    window.addEventListener('wheel', setUserScrolled);
    window.addEventListener('touchmove', setUserScrolled);
    return () => {
      window.removeEventListener('wheel', setUserScrolled);
      window.removeEventListener('touchmove', setUserScrolled);
      window.visualViewport?.removeEventListener(
        'resize',
        handleKeyboardAppear,
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [positions]);

  const scrollToDepartment = (value: string, disableScroll?: true) => {
    const elPosition = positions.find((item) => item.name === value);

    isUserScrolled.current = !!disableScroll;

    if (elPosition && venueId && serviceId) {
      setActiveSection(value);
      navigate(
        generatePath(menuPath, {
          venueId,
          serviceId,
          department: encodeURIComponent(value),
        }),
        {
          replace: true,
        },
      );

      setSearchParams((prev) => prev, {
        replace: true,
      });

      setTimeout(() => {
        !disableScroll &&
          window.scrollTo({
            top: elPosition.position,
            behavior: 'smooth',
          });
      }, 30);
    }
  };

  useEffect(() => {
    if (selected && venueId && serviceId) {
      if (department) {
        scrollToDepartment(decodeURIComponent(department));
      } else {
        scrollToDepartment(removeSpaces(selected));
      }
    }
  }, [selected, positions]);

  return [activeSection, scrollToDepartment];
};
