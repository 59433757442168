import {
  Box,
  Center,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  IconButton,
  Modal as ChakraModal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalHeader,
} from '@chakra-ui/react';

import { BackIcon } from '../../assets/icons';
import { useIsMobile, useIsMobileHeight } from '../../utils/screen';

type Sizes = 'full' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

interface Props {
  isOpen: boolean;
  onClose?: VoidFunction;
  bodyProps?: ModalBodyProps;
  size?: {
    mobile: Sizes;
    desktop: Sizes;
  };
  variant?: 'modal' | 'drawer';
  contentProps?: ModalContentProps;
  isLoading?: boolean;
  onBack?: VoidFunction;
  hideCloseButton?: true;
  title?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  bodyProps,
  size = {
    mobile: 'full',
    desktop: 'sm',
  },
  variant = 'modal',
  contentProps,
  isLoading,
  onBack,
  hideCloseButton,
  title,
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();
  const isMobileHeight = useIsMobileHeight();

  const Wrapper = variant === 'modal' ? ChakraModal : Drawer;
  const Content = variant === 'modal' ? ModalContent : DrawerContent;
  const CloseButton =
    variant === 'modal' ? ModalCloseButton : DrawerCloseButton;
  const Body = variant === 'modal' ? ModalBody : DrawerBody;

  return (
    <Wrapper
      isOpen={isOpen}
      onClose={onClose ? onClose : () => void 0}
      isCentered
      size={isMobile ? size.mobile : size.desktop}
      placement="bottom"
      scrollBehavior="inside"
    >
      <Box
        position="fixed"
        left={0}
        top={0}
        width="100vw"
        height="100dvh"
        background="gray.800"
        opacity={0.2}
        zIndex="modal"
      />
      <Content
        containerProps={{
          overflow: 'unset',
        }}
        maxH={isMobileHeight || isMobile ? '100%' : ' 90dvh'}
        {...contentProps}
        borderRadius={
          isMobile && size.mobile === 'full'
            ? 0
            : contentProps?.borderRadius ?? '2xl'
        }
      >
        <Flex columnGap="12px">
          {onBack && (
            <IconButton
              aria-label="back"
              isRound
              icon={<BackIcon width={6} height={6} />}
              onClick={onBack}
              variant="gray"
              position="absolute"
              size="sm"
              top={isMobile ? 4 : 6}
              left={isMobile ? 4 : 6}
              zIndex={1}
            />
          )}

          {title && (
            <Box flex="1 1 auto">
              <ModalHeader
                pt="26px"
                pb={0}
                pr="64px"
                pl={onBack ? '68px' : '26px'}
              >
                {title}
              </ModalHeader>
            </Box>
          )}
          {!!onClose && !hideCloseButton && (
            <CloseButton
              bgColor="gray.100"
              rounded="full"
              color="gray.400"
              top={isMobile ? 4 : 6}
              right={isMobile ? 4 : 6}
              zIndex={1}
            />
          )}
        </Flex>
        <Body
          px={isMobile ? 4 : 6}
          pt={hideCloseButton && !onBack ? 6 : 10}
          pb={6}
          display="flex"
          flexDirection="column"
          {...bodyProps}
        >
          {isLoading ? (
            <Center>
              <CircularProgress isIndeterminate color="primary.400" />
            </Center>
          ) : (
            children
          )}
        </Body>
      </Content>
    </Wrapper>
  );
};
