import { useBoolean } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

const useVoucher = () => {
  const { websiteData } = useAppSelector(selectWebsite);

  const [open, setOpen] = useBoolean();

  const [voucherData, setVoucherData] = useState<
    (Vouchers.BuyResponse & { email: string }) | null
  >(null);

  const reset = useCallback(() => {
    setVoucherData(null);
    setOpen.off();
  }, []);

  return {
    actions: {
      reset,
      setVoucherData,
      setOpen,
    },
    data: {
      websiteData,
      voucherData,
      open,
    },
  };
};

export default useVoucher;
