import { useToast } from '@chakra-ui/react';

import { ErrorWithStackTrace } from '../utils/errors';

export const useCatchError = () => {
  const toast = useToast();
  return (error: ApiTypes.ResponseError | ErrorWithStackTrace | Error) => {
    toast({
      description: error.message || String(error),
      status: 'error',
      isClosable: true,
    });
  };
};

export const useSuccessToast = () => {
  const toast = useToast();
  return (text: string) => {
    toast({
      description: text,
      status: 'success',
      isClosable: true,
    });
  };
};
