import {
  Box,
  Center,
  CircularProgress,
  Divider,
  Flex,
  Text,
} from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { useIsMobile } from '@shared/utils/screen';
import { VoucherWithBalance } from 'components/VoucherWithBalance';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import useProfileVoucherDetails from './hooks';

export const ProfileVoucherDetails = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    data: { isLoading, selectedVoucher, isShowVoucher },
  } = useProfileVoucherDetails();

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      <Flex>
        <BackButton onClick={() => navigate(-1)} />
        <Text
          as="span"
          padding="0 16px"
          fontSize="14px"
          lineHeight="24px"
          flexGrow="1"
          fontWeight="600"
          textAlign="center"
        >
          Voucher details
        </Text>
        <Box width="32px" />
      </Flex>
      {isShowVoucher && selectedVoucher ? (
        <>
          {isLoading ? (
            <Center>
              <CircularProgress isIndeterminate color="primary.400" />
            </Center>
          ) : (
            <>
              {isMobile && <Divider mt="20px" />}
              <Box py="24px">
                <VoucherWithBalance
                  code={selectedVoucher.code}
                  currency={selectedVoucher.currency}
                  balance={selectedVoucher.available_balance}
                />
              </Box>
              <Divider />
              <Flex padding="8px 0" mt="40px">
                <Text
                  width="50%"
                  fontSize="14px"
                  lineHeight="24px"
                  color="gray.400"
                >
                  Purchase Date
                </Text>

                {selectedVoucher.created_at && (
                  <Text
                    width="50%"
                    textAlign="right"
                    fontSize="14px"
                    lineHeight="24px"
                    color="gray.400"
                  >
                    {format(
                      new Date(selectedVoucher.created_at),
                      'MMMM dd, yyyy HH:mm',
                    )}
                  </Text>
                )}
              </Flex>
            </>
          )}
        </>
      ) : (
        <Text as="h2" textAlign="center" fontWeight="bold">
          404 forbidden
        </Text>
      )}
    </WidgetWrapper>
  );
};
