import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth, updateAuthUser } from 'store/slices/auth';

const useProfileSettings = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [isAllowed, setIsAllowed] = useState(false);

  const { user, error, isLoading } = useAppSelector(selectAuth);

  useEffect(() => {
    setIsAllowed(user?.marketing_allowed || false);
  }, [user]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const handleSubscribe = () => {
    dispatch(updateAuthUser({ marketing_allowed: !isAllowed }));
    setIsAllowed(!isAllowed);
  };

  return {
    actions: {
      handleSubscribe,
    },
    data: {
      user,
      isAllowed,
      isLoading,
    },
  };
};

export default useProfileSettings;
