import { Flex, IconButton, StyleProps, Text } from '@chakra-ui/react';

import { DeleteIconSmall } from '../../assets/icons';
import { PriceText } from './PriceText';

interface Props extends StyleProps {
  label: string;
  price: string | number;
  originalPrice?: number;
  currency?: Currency | null;
  largeSize?: boolean;
  negative?: boolean;
  handleDelete?: () => void;
  count?: number;
}

export const LabelWithPriceText = ({
  label,
  price,
  originalPrice = 0,
  currency,
  largeSize,
  negative,
  handleDelete,
  count,
  ...styleProps
}: Props) => {
  return (
    <Flex w="100%" justify="space-between" {...styleProps}>
      <Text fontWeight={600} fontSize={largeSize ? 'xl' : 'xs'}>
        {label}
      </Text>
      <Flex columnGap="8px" alignItems="center">
        {Boolean(originalPrice) && (
          <PriceText
            price={originalPrice}
            currency={currency}
            color="gray.400"
            fontSize={largeSize ? 'xl' : 'xs'}
            fontWeight={400}
            textDecorationLine="line-through"
            mr="8px"
            negative={negative}
          />
        )}
        {handleDelete && (
          <IconButton
            aria-label="delete"
            isRound
            onClick={handleDelete}
            icon={<DeleteIconSmall color="gray.400" />}
            size="sm"
            borderRadius="50%"
            padding="6px"
            minWidth="20px"
            width="20px"
            height="20px"
            color="gray.400"
            bgColor="gray.100"
            _hover={{
              opacity: 0.8,
              bgColor: 'gray.100',
            }}
            _focus={{
              opacity: 0.8,
              bgColor: 'gray.100',
            }}
          />
        )}
        <PriceText
          price={+price}
          currency={currency}
          color={largeSize ? 'gray.900' : 'gray.400'}
          fontSize={largeSize ? 'xl' : 'xs'}
          fontWeight={largeSize ? 600 : 400}
          negative={negative}
          count={count}
        />
      </Flex>
    </Flex>
  );
};
