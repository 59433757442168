import { useEffect } from 'react';
import { useBeforeUnload } from 'react-router-dom';

export const useBeforeClose = (cb: VoidFunction) => {
  useBeforeUnload(cb);
  useEffect(() => {
    window.addEventListener('pagehide', cb);
    return () => {
      window.removeEventListener('pagehide', cb);
    };
  }, [cb]);
};
