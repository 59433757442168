import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import VouchersIcon from '@shared/assets/images/vouchers.svg';
import { useIsMobile, useIsTablet } from '@shared/utils/screen';

import { Confirmation } from './Confirmation';
import useVoucher from './hooks';
import VoucherCard from './VoucherCard';

export const Vourcher = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const {
    actions: { reset, setVoucherData, setOpen },
    data: { websiteData, voucherData, open },
  } = useVoucher();

  return (
    <Container
      maxW="1280px"
      px={isMobile ? 0 : '72px'}
      py={isMobile ? 0 : '120px'}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 0 : 100}
        align="center"
        justify="center"
      >
        {voucherData ? (
          <Confirmation
            code={voucherData.code}
            email={voucherData.email}
            balance={voucherData.balance}
            currency={voucherData.currency}
            onClose={reset}
          />
        ) : open ? (
          <VoucherCard onSuccess={setVoucherData} />
        ) : (
          <>
            <Image
              src={VouchersIcon}
              w={isMobile ? 300 : isTablet ? 250 : 380}
            />
            <Box
              p="24px"
              borderRadius="2xl"
              borderBottomRadius={isMobile ? 0 : undefined}
              bgColor="white"
            >
              <Heading size="md" mb="16px">
                {websiteData?.voucher_title}
              </Heading>
              <Text fontSize="sm" mb="24px">
                {websiteData?.voucher_subtitle}
              </Text>
              <Button size="xl" w="full" onClick={setOpen.on}>
                Buy Now
              </Button>
            </Box>
          </>
        )}
      </Stack>
    </Container>
  );
};
