import {
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  IconButton,
  Image,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftAddonProps,
  InputLeftElement,
  InputProps as ChakraInputProps,
  InputRightElement,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { createRef, forwardRef, useCallback, useState } from 'react';

import EyeIcon from '../../assets/icons/eye.svg';
import { ErrorText } from '../../components/Texts/ErrorText';

export interface InputProps extends ChakraInputProps {
  label?: string;
  error?: string | boolean;
  additionalElement?: React.ReactNode;
  formControlProps?: FormControlProps;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  disableMoveCaret?: boolean;
  leftAddon?: React.ReactNode;
  leftAddonProps?: InputLeftAddonProps;
  isTextarea?: boolean;
  labelProps?: FormLabelProps;
  showErrorMessage?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    label,
    error,
    type = 'text',
    additionalElement,
    isDisabled,
    formControlProps,
    leftElement,
    rightElement,
    size = 'lg',
    disableMoveCaret,
    leftAddon,
    leftAddonProps,
    isTextarea,
    labelProps,
    showErrorMessage = true,
    ...inputProps
  }: InputProps,
  ref,
) {
  const inputRef = createRef<HTMLInputElement>();
  const [inputType, setInputType] =
    useState<React.HTMLInputTypeAttribute>(type);

  const toggleShowPassword = useCallback(() => {
    inputRef.current?.focus();

    setInputType((prevState) =>
      prevState === 'password' ? 'text' : 'password',
    );
  }, [inputRef]);

  const moveCaretAtEnd = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!disableMoveCaret) {
        const length: number = e.target.value.length;
        setTimeout(() => {
          e.target.setSelectionRange(length, length);
        }, 0);
      }
      inputProps.onFocus && inputProps.onFocus(e);
    },
    [inputProps.onFocus, disableMoveCaret],
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      inputProps.onBlur && inputProps.onBlur(e);
    },
    [inputProps.onBlur],
  );

  return (
    <FormControl
      isInvalid={Boolean(error)}
      mb={6}
      isDisabled={isDisabled}
      {...formControlProps}
    >
      <Flex justifyContent="space-between">
        {label && (
          <FormLabel fontSize="12px" fontWeight={600} {...labelProps}>
            {label}
          </FormLabel>
        )}
        {additionalElement}
      </Flex>
      <InputGroup size={size}>
        {leftAddon && (
          <InputLeftAddon {...leftAddonProps}>{leftAddon}</InputLeftAddon>
        )}
        {leftElement && (
          <InputLeftElement pointerEvents="none">
            {leftElement}
          </InputLeftElement>
        )}
        {isTextarea ? (
          <Textarea
            ref={ref ?? inputRef}
            type={inputType}
            {...(inputProps as TextareaProps)}
            bgColor="white"
            fontSize="inputBase"
          />
        ) : (
          <ChakraInput
            ref={ref ?? inputRef}
            pr={type === 'password' ? '24px' : undefined}
            type={inputType}
            {...inputProps}
            onFocus={moveCaretAtEnd}
            bgColor="white"
            onBlur={handleBlur}
            fontSize="inputBase"
          />
        )}
        {(type === 'password' || rightElement) && (
          <InputRightElement>
            {type === 'password' ? (
              <IconButton
                aria-label="Show password"
                icon={<Image src={EyeIcon} />}
                onClick={toggleShowPassword}
                variant="unstyled"
              />
            ) : (
              rightElement
            )}
          </InputRightElement>
        )}
      </InputGroup>

      {error && showErrorMessage && typeof error === 'string' && (
        <ErrorText error={error} />
      )}
    </FormControl>
  );
});
