import { Box, Heading, Text } from '@chakra-ui/react';
import VenuePhotoPlaceholder from '@shared/assets/images/venue-photo-placeholder.svg';
import { useIsMobile } from '@shared/utils/screen';
import get from 'lodash.get';
import { useMemo } from 'react';

import { IselectVenue } from '../hooks';

interface Iprops {
  venue: Venues.Venue;
  handleClick: (args: IselectVenue) => void;
}

const VenueItem = ({ venue, handleClick }: Iprops) => {
  const isMobile = useIsMobile();

  const {
    name,
    address,
    venue_image,
    id,
    services,
    location: { currency },
    is_online,
  } = venue;

  const serviceId: string = get(services, [0, 'id'], '');
  const isAllowPreorder = useMemo(() => {
    return services.some((s) => s.config.allow_preorder);
  }, [services]);

  const dayTimeIntervals: Services.TimeInterval[] | [] =
    venue?.services[0]?.available_days[0]?.time_intervals || [];

  const timeIntervals: string[] = [];

  dayTimeIntervals.forEach(({ time_from, time_to }) => {
    timeIntervals.push(`${time_from.slice(0, -3)} - ${time_to.slice(0, -3)}`);
  });

  const onClick = () =>
    handleClick({ venueId: id, venueName: name, serviceId, currency });

  return (
    <Box
      display={'flex'}
      width={'100%'}
      padding={'8px'}
      borderRadius="12px"
      border="1px solid"
      borderColor="gray.200"
      onClick={onClick}
      cursor="pointer"
      _hover={{
        boxShadow: '0 1px 3px 1px #E4E5E7',
      }}
      position="relative"
    >
      <Box
        height="16px"
        width="16px"
        borderRadius="50%"
        position="absolute"
        top="-8px"
        right="-8px"
        bgColor={is_online ? '#35b308' : isAllowPreorder ? 'yellow' : 'red'}
      />
      <Box
        height={isMobile ? '96px' : '120px'}
        width={isMobile ? '96px' : '160px'}
        backgroundSize={venue_image ? 'cover' : '58px'}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        bgImage={venue_image || VenuePhotoPlaceholder}
        borderBottom="1px solid"
        borderColor="gray.100"
        bgColor="gray.200"
        order={2}
        borderRadius={'10px'}
        borderTopLeftRadius={'10px'}
        borderTopRightRadius={'10px'}
        flexShrink="0"
      />
      <Box padding={'8px 8px 0'} order={1} flexGrow="1">
        <Heading as="h2" fontSize="14px" lineHeight="20px" marginBottom="4px">
          {name}
        </Heading>
        <Text fontSize="14px" lineHeight="20px" color="gray.400">
          {address}
        </Text>

        {Boolean(timeIntervals.length) && (
          <Text
            fontSize="14px"
            lineHeight="20px"
            color="gray.400"
            marginTop="8px"
          >
            {`${
              !is_online && isAllowPreorder ? 'Pre order for' : 'Open from'
            }: ${timeIntervals.join(', ')}`}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default VenueItem;
