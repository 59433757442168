import { Box, Button, Image, useToast } from '@chakra-ui/react';
import VoucherIcon from '@shared/assets/icons/voucher.svg';
import { memo, useEffect } from 'react';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectCheckoutVouchers,
  setIsVoucherModalOpen,
  setUsedVoucherData,
} from 'store/slices/checkoutVouchers';
import { selectWebsite } from 'store/slices/website';

import VoucherModal from './VoucherModal';

interface Iprops {
  isPointsUsed: boolean;
  hasDiscount: boolean;
  totalAmount: string;
}

const Voucher = ({ isPointsUsed, hasDiscount, totalAmount }: Iprops) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { isVoucherModalOpen, usedVoucherData } = useAppSelector(
    selectCheckoutVouchers,
  );
  const { websiteData } = useAppSelector(selectWebsite);

  useEffect(() => {
    if (usedVoucherData) {
      dispatch(
        setUsedVoucherData({
          ...usedVoucherData,
          value:
            usedVoucherData.value ||
            Math.min(
              Number(usedVoucherData.available_balance),
              Number(totalAmount),
            ).toFixed(2),
        }),
      );
    }
  }, [totalAmount]);

  const handleClose = useCallback(
    () => dispatch(setIsVoucherModalOpen(false)),
    [],
  );

  const handleUseVoucher = () => {
    if (isPointsUsed) {
      toast.closeAll();
      toast({
        description: `You can not use voucher while using ${
          websiteData?.venue_group?.points_config?.name || 'points'
        }`,
        position: 'bottom-left',
        isClosable: true,
        status: 'info',
      });
    } else if (hasDiscount) {
      toast.closeAll();
      toast({
        description: 'You can not use voucher with coupon code applied',
        position: 'bottom-left',
        isClosable: true,
        status: 'info',
      });
    } else {
      dispatch(setIsVoucherModalOpen(true));
    }
  };

  return (
    <Box>
      <Button
        w="full"
        variant="outline"
        leftIcon={<Image src={VoucherIcon} />}
        borderRadius="8px"
        borderColor="gray.300"
        color="gray.400"
        onClick={handleUseVoucher}
      >
        Use Voucher
      </Button>
      <VoucherModal
        isOpen={isVoucherModalOpen}
        onClose={handleClose}
        totalAmount={totalAmount}
      />
    </Box>
  );
};

export default memo(Voucher);
