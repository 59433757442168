import { Box, Heading, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectProductModal } from 'store/slices/productModal';

import { ExtraProductList } from './ExtraProductList';

interface Props {
  extraSection: Product.ExtraSection;
  parentId?: string;
  productId?: string;
  validate: (id: string, isValid: boolean) => void;
}

export const ExtraSection = ({
  extraSection,
  parentId,
  productId,
  validate,
}: Props) => {
  const { id, name, minimum, limit, products, parentProductQuantity } =
    extraSection;

  const { selected } = useAppSelector(selectProductModal);

  const isMinimumPass = useMemo(() => {
    let totalAmount = 0;
    let thirdLevelValid = true;

    if (parentId && productId) {
      const thirdLevel = selected[parentId]?.[productId].thirdLevel;
      totalAmount = products.reduce((prev, curr) => {
        return prev + (thirdLevel?.[id]?.[curr.id]?.amount ?? 0);
      }, 0);
    } else {
      const productWithThirdLevel = products.filter(
        (p) =>
          selected[id]?.[p.id]?.amount > 0 &&
          p.extra_sections.some((es) => es.minimum > 0),
      );

      if (productWithThirdLevel && productWithThirdLevel.length > 0) {
        productWithThirdLevel.forEach((tp) => {
          const thirdLevel = selected[id]?.[tp.id].thirdLevel;
          tp.extra_sections
            .filter((es) => es.minimum > 0)
            .forEach((es) => {
              const thirdLevelTotalAmount = es.products.reduce((prev, curr) => {
                return prev + (thirdLevel?.[es.id]?.[curr.id]?.amount ?? 0);
              }, 0);
              thirdLevelValid =
                thirdLevelValid && thirdLevelTotalAmount >= es.minimum;
            });
        });
      }

      totalAmount = products.reduce((prev, curr) => {
        return prev + (selected[id]?.[curr.id]?.amount ?? 0);
      }, 0);
    }
    validate(id, totalAmount >= minimum && thirdLevelValid);
    return totalAmount >= minimum;
  }, [selected]);

  const chooseText = useMemo(() => {
    if (minimum > 0) {
      if (minimum === limit) {
        return limit;
      } else {
        return limit > minimum
          ? `from ${minimum} up to ${limit}`
          : `from ${minimum}`;
      }
    } else {
      if (limit > 0) {
        return `up to ${limit}`;
      } else {
        return null;
      }
    }
  }, []);
  return (
    <Box mb="40px">
      <Heading size="sm">{name}</Heading>
      {chooseText && (
        <Text fontSize="xs" color={isMinimumPass ? 'gray.400' : 'red.500'}>
          Choose {chooseText}
        </Text>
      )}
      <Box mt="8px">
        <ExtraProductList
          id={id}
          parentId={parentId}
          productId={productId}
          products={products}
          limit={limit}
          isWithRadioBtns={limit === 1}
          parentProductQuantity={parentProductQuantity}
        />
      </Box>
    </Box>
  );
};
