import { useToast } from '@chakra-ui/react';
import { storage, StorageKeys } from '@shared/utils/storage';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearCheckoutVouchersState,
  getCheckoutVouchers,
  selectCheckoutVouchers,
  setSelectedVoucher,
} from 'store/slices/checkoutVouchers';
import { selectWebsite } from 'store/slices/website';

const useCheckoutVouchers = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const orderCurrency = storage.get(StorageKeys.CURRENCY) as Currency;
  const { websiteData } = useAppSelector(selectWebsite);
  const { vouchers, error, isLoading, selectedVoucher } = useAppSelector(
    selectCheckoutVouchers,
  );

  useEffect(() => {
    if (websiteData?.venue_group?.id) {
      dispatch(
        getCheckoutVouchers({
          venue_group: websiteData?.venue_group?.id,
          currency: orderCurrency,
        }),
      );
    }
  }, [websiteData?.venue_group?.id, orderCurrency]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(clearCheckoutVouchersState());
    };
  }, []);

  const activeVouchers = useMemo(() => {
    return vouchers.filter(
      ({ available_balance, is_active }) =>
        Number(available_balance) > 0 && is_active,
    );
  }, [vouchers]);

  const handleSelectVoucher = useCallback(
    (voucher: Vouchers.Voucher | null) => {
      dispatch(setSelectedVoucher(voucher));
    },
    [],
  );

  return {
    actions: {
      handleSelectVoucher,
    },
    data: {
      isLoading,
      vouchers: activeVouchers,
      selectedVoucher,
    },
  };
};

export default useCheckoutVouchers;
