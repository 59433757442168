import { Box, BoxProps } from '@chakra-ui/react';
import { useDeepEffect } from '@shared/hooks/useDeepEffect';
import { forwardRef, useId, useImperativeHandle, useState } from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized/dist/es/CellMeasurer';
import {
  InfiniteLoader,
  InfiniteLoaderChildProps,
  InfiniteLoaderProps,
} from 'react-virtualized/dist/es/InfiniteLoader';
import { List, ListRowProps } from 'react-virtualized/dist/es/List';

import { useElementResize } from '../../hooks/useElementResize';

type CommonProps = {
  deps: React.DependencyList;
  children: React.ReactNode[];
  scrollToIndex?: number;
};

type InfiniteScrollProps = {
  loadMoreRows: InfiniteLoaderProps['loadMoreRows'] | VoidFunction;
  remoteRowCount: number;
} & CommonProps;

type Props = (CommonProps | InfiniteScrollProps) & BoxProps;

export const VirtualizedList = forwardRef<HTMLDivElement, Props>(
  function VirtualizedList(
    { scrollToIndex, children, deps = [], ...props },
    forwardedRef,
  ) {
    const id = useId();
    const isInfiniteScrollList =
      'remoteRowCount' in props && 'loadMoreRows' in props;

    const wrapperProps = isInfiniteScrollList
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (({ loadMoreRows, remoteRowCount, ...rest }) => rest)(props)
      : props;

    const [parentRef, { fullHeight, fullWidth }] =
      useElementResize<HTMLDivElement>();

    useImperativeHandle(
      forwardedRef,
      () =>
        document.querySelector(
          `[data-id="list-container-${id}"]`,
        ) as HTMLDivElement,
      [],
    );

    const [cache, setCache] = useState<CellMeasurerCache>(
      new CellMeasurerCache({
        fixedWidth: true,
      }),
    );
    useDeepEffect(() => {
      setCache(
        new CellMeasurerCache({
          fixedWidth: true,
        }),
      );
    }, [deps, fullWidth, fullHeight]);

    const RowRender = ({ index, key, parent, style }: ListRowProps) => {
      const child = children[index];

      if (!child) return null;

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          rowIndex={index}
          parent={parent}
        >
          <Box style={style}>{child}</Box>
        </CellMeasurer>
      );
    };

    const ListComponent = (listProps?: InfiniteLoaderChildProps) => (
      <List
        {...listProps}
        containerProps={{ 'data-id': `list-container-${id}` }}
        deferredMeasurementCache={cache}
        height={fullHeight}
        rowCount={children.length ?? 0}
        rowHeight={cache.rowHeight}
        rowRenderer={RowRender}
        width={fullWidth}
        overscanRowCount={5}
        scrollToIndex={scrollToIndex}
      />
    );

    const isRowLoaded: InfiniteLoaderProps['isRowLoaded'] = ({ index }) =>
      !!children[index];

    return (
      <Box h="full" {...wrapperProps} ref={parentRef}>
        {isInfiniteScrollList ? (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={
              props.loadMoreRows as InfiniteLoaderProps['loadMoreRows']
            }
            rowCount={props.remoteRowCount as InfiniteLoaderProps['rowCount']}
          >
            {ListComponent}
          </InfiniteLoader>
        ) : (
          ListComponent()
        )}
      </Box>
    );
  },
);
