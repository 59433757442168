import { abbreviateText } from '../../utils/string';

export const CarrierIcon = (color: string, name = '') =>
  window.btoa(
    name
      ? `
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="39" height="39" rx="19.5" fill="${color}"/>
    <rect x="1.5" y="1.5" width="39" height="39" rx="19.5" stroke="white" stroke-width="3"/>
    <text x="50%" y="55%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="18">
    ${abbreviateText(name)}
    </text>
    </svg>
    `
      : `
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24" y="8" width="48" height="48" rx="24" fill="${color}" shape-rendering="crispEdges"/>
    <path d="M46 36C46 37.6569 44.6569 39 43 39C41.3431 39 40 37.6569 40 36M47 35L48 29H42C39.2386 29 37 31.2386 37 34V35H47ZM47 35H53M49 23H52C55.0256 26.2868 55.8663 28.3766 56 32.5M59 36C59 37.6569 57.6569 39 56 39C54.3431 39 53 37.6569 53 36C53 34.3431 54.3431 33 56 33C57.6569 33 59 34.3431 59 36Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
  );

export const DestinationIcon = (color: string) =>
  window.btoa(`
<svg width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6667 0C9.25278 0 0 9.25278 0 20.6667C0 36.1667 14.7512 44.858 20.6667 49.0833C26.537 44.8656 41.3333 36.1667 41.3333 20.6667C41.3333 9.25278 32.0806 0 20.6667 0Z" fill="${color}"/>
<rect x="3.5" y="3" width="34" height="34" rx="17" fill="white"/>
<rect x="14.5" y="14" width="12" height="12" rx="6" fill="${color}"/>
</svg>
`);

export const OriginIcon = (color: string) =>
  window.btoa(`
<svg width="42" height="50" viewBox="0 0 42 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6667 0C9.25278 0 0 9.25278 0 20.6667C0 36.1667 14.7512 44.858 20.6667 49.0833C26.537 44.8656 41.3333 36.1667 41.3333 20.6667C41.3333 9.25278 32.0806 0 20.6667 0Z" fill="${color}"/>
<rect x="4" y="3" width="34" height="34" rx="17" fill="white"/>
<rect x="4" y="3" width="34" height="34" rx="17" stroke="${color}" stroke-width="2"/>
<path d="M28 18V27C28 27.5523 27.5523 28 27 28H15C14.4477 28 14 27.5523 14 27V18M17 12H13V16C13 17.1046 13.8954 18 15 18C16.1046 18 17 17.1046 17 16M17 12V16M17 12H21M17 16C17 17.1046 17.8954 18 19 18C20.1046 18 21 17.1046 21 16M21 12V16M21 12H25M21 16C21 17.1046 21.8954 18 23 18C24.1046 18 25 17.1046 25 16M25 12V16M25 12H29V16C29 17.1046 28.1046 18 27 18C25.8954 18 25 17.1046 25 16M18 28H24V24C24 22.3431 22.6569 21 21 21C19.3431 21 18 22.3431 18 24V28Z" stroke="${color}" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
</svg>
`);

export const OrderIcon = (color: string, text = '', textColor = 'black') =>
  window.btoa(`
<svg width="64" height="37" viewBox="0 0 64 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9581_33990)">
<path d="M32.003 37C34.5919 35.1329 39.7916 31.9502 43 27H21C24.2018 31.9484 29.3945 35.1297 32.003 37Z" fill="${color}"/>
<rect x="1.5" y="1.5" width="61" height="24" rx="9.5" fill="white"/>
<rect x="1.5" y="1.5" width="61" height="24" rx="9.5" stroke="${color}" stroke-width="3"/>
<text x="50%" y="38%" dominant-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="12">
${text.length > 9 ? text.slice(0, 6) + '...' : text}
</text>
</g>
<defs>
<clipPath id="clip0_9581_33990">
<rect width="64" height="37" fill="white"/>
</clipPath>
</defs>
</svg>
`);

export const ClusterIcon = (color: string) =>
  window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".6" r="70" />
        <circle cx="120" cy="120" opacity=".3" r="90" />
        <circle cx="120" cy="120" opacity=".2" r="110" />
        <circle cx="120" cy="120" opacity=".1" r="130" />
        </svg>`);

export const EventIcon = (color: string) =>
  window.btoa(`
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="${color}"/>
<rect x="1.5" y="1.5" width="17" height="17" rx="8.5" stroke="white" stroke-width="3"/>
</svg>
`);
