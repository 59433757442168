import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@shared/assets/icons';
import { Input } from '@shared/components/Input';
import { formatNoSpaces } from '@shared/utils/format';
import { isEmpty } from '@shared/utils/string';
import { isValidEmail, isValidPassword } from '@shared/utils/validation';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearAuthData, registration, selectAuth } from 'store/slices/auth';
import { selectWebsite } from 'store/slices/website';
import { isLandingMode } from 'utils/document';

type FormValues = {
  name: string;
  email: string;
  password: string;
  confirmPass: string;
  isAccepted: boolean;
};

export const SignUpForm = () => {
  const [, setSearchParams] = useSearchParams();
  const { isLoading, error } = useAppSelector(selectAuth);
  const { websiteData } = useAppSelector(selectWebsite);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    password: '',
    confirmPass: '',
    isAccepted: false,
  });
  const [formError, setFormError] = useState<Omit<FormValues, 'isAccepted'>>({
    name: '',
    email: '',
    password: '',
    confirmPass: '',
  });
  const toast = useToast();

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]:
        name === 'isAccepted'
          ? checked
          : name === 'name'
          ? value
          : formatNoSpaces(value),
    }));
  }, []);

  const validate = useCallback(() => {
    const { name, email, password, confirmPass } = formValues;

    const validation = {
      name: isEmpty(name) ? 'Field is required' : '',
      email: isEmpty(email)
        ? 'Field is required'
        : !isValidEmail(email)
        ? 'Invalid email'
        : '',
      password: isEmpty(password)
        ? 'Field is required'
        : !isValidPassword(password)
        ? 'Password must contain at least 8 characters and at least 1 number'
        : '',
      confirmPass: password === confirmPass ? '' : 'Passwords need to match',
    };

    setFormError(validation);

    return Object.values(validation).every((v) => !v);
  }, [formValues]);

  const resetFieldValidation = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFormError((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    },
    [],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        const { name, email, password, confirmPass } = formValues;
        dispatch(
          registration({
            name,
            email,
            password1: password,
            password2: confirmPass,
          }),
        );
      }
    },
    [formValues],
  );

  const redirectToSignIn = useCallback(() => {
    dispatch(clearAuthData());
    setSearchParams(
      (prev) => ({
        modal: AuthModalParams.SignIn,
        ref: prev.get('ref') ?? '',
        refParams: prev.get('refParams') ?? '',
      }),
      { replace: true },
    );
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  return (
    <>
      <Heading mb="40px" size="md">
        Sign up
      </Heading>
      <Box as="form" onSubmit={onSubmit} mb="24px" flex={1}>
        <Input
          label="Name"
          name="name"
          value={formValues.name}
          onChange={onChange}
          error={formError.name}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Input
          label="Email address"
          name="email"
          value={formValues.email}
          onChange={onChange}
          error={formError.email}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Input
          label="Password"
          type="password"
          name="password"
          value={formValues.password}
          onChange={onChange}
          error={formError.password}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Input
          label="Repeat password"
          type="password"
          name="confirmPass"
          value={formValues.confirmPass}
          onChange={onChange}
          error={formError.confirmPass}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Checkbox
          colorScheme="primary"
          name="isAccepted"
          checked={formValues.isAccepted}
          onChange={onChange}
          isDisabled={isLoading}
          mb="24px"
        >
          <Text fontSize="xs" color="gray.400">
            By signing up you agree to the{' '}
            <Link
              as={RouterLink}
              to={
                (!isLandingMode &&
                  websiteData?.legal_links.find((item) =>
                    item.name.toLowerCase().includes('terms'),
                  )?.link) ||
                Paths.TermsConditions
              }
              isExternal
              textDecoration="underline"
            >
              Terms of Service
            </Link>{' '}
            and acknowledge our{' '}
            <Link
              as={RouterLink}
              to={
                (!isLandingMode &&
                  websiteData?.legal_links.find((item) =>
                    item.name.toLowerCase().includes('privacy'),
                  )?.link) ||
                Paths.PrivacyPolicy
              }
              isExternal
              textDecoration="underline"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              as={RouterLink}
              to={
                (!isLandingMode &&
                  websiteData?.legal_links.find((item) =>
                    item.name.toLowerCase().includes('cookie'),
                  )?.link) ||
                Paths.CookiePolicy
              }
              isExternal
              textDecoration="underline"
            >
              Cookie Policy
            </Link>
          </Text>
        </Checkbox>
        <Button
          type="submit"
          size="full"
          isDisabled={!formValues.isAccepted}
          isLoading={isLoading}
        >
          Sign up
        </Button>
      </Box>
      <Divider />
      <Flex align="center" justify="space-between" my="24px">
        <Text>Already have an account?</Text>
        <Button
          variant="secondary"
          rightIcon={<ArrowRightIcon boxSize="24px" />}
          onClick={redirectToSignIn}
        >
          Sign in
        </Button>
      </Flex>
    </>
  );
};
