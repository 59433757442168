import { Image, Text } from '@chakra-ui/react';

import ErrorIcon from '../../assets/icons/error.svg';

interface Props {
  error: string;
}

export const ErrorText = ({ error }: Props) => (
  <Text
    color="red.500"
    display="flex"
    mt="8px"
    lineHeight="normal"
    fontSize="xs"
  >
    <Image src={ErrorIcon} mr="8px" /> {error}
  </Text>
);
