import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import { updateAuthUser } from 'store/slices/auth';

export const MarketingOpt = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = useCallback(
    (marketing_allowed: boolean | null) => () => {
      dispatch(updateAuthUser({ marketing_allowed }));
    },
    [],
  );

  return (
    <>
      <Heading
        as="h2"
        fontSize="24px"
        lineHeight="28px"
        m="0 0 16px"
        textAlign="center"
      >
        Would you like to join our community?
      </Heading>

      <Text
        fontSize="16px"
        lineHeight="24px"
        marginBottom="40px"
        textAlign="center"
      >
        We can chat to you via email, sms or push notifications, you can choose!
        We like to tell you about super special offers, exciting deals & other
        fun stuff. You can opt out at any stage in your profile.
      </Text>

      <Box>
        <Button
          onClick={handleSubmit(true)}
          w="full"
          size="xl"
          marginBottom="16px"
        >
          Yes, Opt me in
        </Button>
        <Button onClick={handleSubmit(null)} variant="ghost" w="full" size="xl">
          Skip for now
        </Button>
      </Box>
    </>
  );
};
