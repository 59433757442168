import { createSlice } from '@reduxjs/toolkit';
import { VouchersApi } from 'api/VouchersApi';
import { AppThunk, RootState } from 'store';

type ProfileVouchersState = {
  isLoading: boolean;
  error: Error | null;
  vouchers: Vouchers.Voucher[];
  selectedVoucher: Vouchers.Voucher | null;
  selectedPublicVoucher: Vouchers.PublicVoucher | null;
};

const initialState: ProfileVouchersState = {
  isLoading: true,
  error: null,
  vouchers: [],
  selectedVoucher: null,
  selectedPublicVoucher: null,
};

export const profileVouchersSlice = createSlice({
  name: 'profileVouchers',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },

    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },

    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },

    setVouchers: (state, action: { payload: Vouchers.Voucher[] }) => {
      state.vouchers = action.payload;
    },

    setSelectedVoucher: (state, action: { payload: Vouchers.Voucher }) => {
      state.selectedVoucher = action.payload;
    },

    setSelectedPublicVoucher: (
      state,
      action: { payload: Vouchers.PublicVoucher },
    ) => {
      state.selectedPublicVoucher = action.payload;
    },

    clearProfileVouchersState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.vouchers = [];
      state.selectedVoucher = null;
      state.selectedPublicVoucher = null;
    },
  },
});

export const {
  setRequestStart,
  setLoading,
  setError,
  setVouchers,
  setSelectedVoucher,
  clearProfileVouchersState,
  setSelectedPublicVoucher,
} = profileVouchersSlice.actions;

export const getConsumerVouchers =
  (params?: Vouchers.VoucherParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VouchersApi.getConsumerVouchers(params);

      dispatch(setVouchers(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getSelectedVoucher =
  (voucherId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VouchersApi.getConsumerVoucher(voucherId);
      dispatch(setSelectedVoucher(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getSelectedPublicVoucher =
  (voucherId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VouchersApi.getConsumerPublicVoucher(voucherId);
      dispatch(setSelectedPublicVoucher(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectProfileVouchers = (state: RootState) =>
  state.profileVouchers;

export default profileVouchersSlice.reducer;
