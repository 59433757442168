import { Box, Button, Center, Divider, Heading, Text } from '@chakra-ui/react';
import { Input } from '@shared/components/Input';
import { isEmpty } from '@shared/utils/string';
import { isValidPassword } from '@shared/utils/validation';
import { AuthApi } from 'api/AuthApi';
import { WidgetWrapper } from 'components/WidgetWrapper';
import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { isLandingMode } from 'utils/document';

type FormValues = {
  password: string;
  confirmPass: string;
};

export const ResetPassword = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    password: '',
    confirmPass: '',
  });
  const [formError, setFormError] = useState<FormValues>({
    password: '',
    confirmPass: '',
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);

  const { uid, token } = useParams<{ uid: string; token: string }>();
  if (!uid || !token) return null;

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]:
        e.target.name === 'isAccepted' ? e.target.checked : e.target.value,
    }));
  }, []);

  const resetFieldValidation = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFormError((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    },
    [],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        setLoading(true);
        AuthApi.setNewPassword({
          uid: uid,
          token: token,
          new_password: formValues.password,
        })
          .then(() => {
            setIsSent(true);
          })
          .catch((e: Error) => {
            setFormError({
              password: e.message || String(e),
              confirmPass: e.message || String(e),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [formValues],
  );

  const validate = useCallback(() => {
    const { password, confirmPass } = formValues;

    const validation = {
      password: isEmpty(password)
        ? 'Field is required'
        : !isValidPassword(password)
        ? 'Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter'
        : '',
      confirmPass: password === confirmPass ? '' : 'Passwords need to match',
    };

    setFormError(validation);

    return Object.values(validation).every((v) => !v);
  }, [formValues]);

  return (
    <WidgetWrapper withContainer>
      <Heading size="md" mb={isSent ? '32px' : '16px'} flex={isSent ? 1 : 0}>
        {isSent
          ? 'Your password has been changed successfully'
          : 'New password'}
      </Heading>

      {!isSent ? (
        <Box as="form" onSubmit={onSubmit}>
          <Text mb="40px">
            Password must contain minimum 8 characters, at least 1 uppercase
            letter, 1 lowercase letter.
          </Text>
          <Input
            label="New password"
            type="password"
            name="password"
            value={formValues.password}
            onChange={onChange}
            error={formError.password}
            onFocus={resetFieldValidation}
            isDisabled={isLoading}
          />
          <Input
            label="Confirm new password"
            type="password"
            name="confirmPass"
            value={formValues.confirmPass}
            onChange={onChange}
            error={formError.confirmPass}
            onFocus={resetFieldValidation}
            isDisabled={isLoading}
          />
          <Button type="submit" size="full" isLoading={isLoading} mt="16px">
            Set new password
          </Button>
        </Box>
      ) : (
        <>
          <Divider />
          <Center>
            <Button
              as={Link}
              variant="ghost"
              my="24px"
              to={{
                pathname: isLandingMode ? Paths.Order : Paths.Home,
                search: `?modal=${AuthModalParams.SignIn}`,
              }}
            >
              Back to Log in
            </Button>
          </Center>
        </>
      )}
    </WidgetWrapper>
  );
};
