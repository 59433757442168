import { Box, Button, Image, Stack, Text, useBoolean } from '@chakra-ui/react';
import { format, isValid } from 'date-fns';
import { memo, useCallback, useMemo } from 'react';

import ClockIcon from '../../assets/icons/clock.svg';
import { ASAP_TIMESLOT } from '../../constants/common';
import ScheduleModal from '../ScheduleModal';
import { ErrorText } from '../Texts/ErrorText';

export interface ScheduleProps {
  selectedTimeSlot: string;
  handleSelectTimeSlot: (timeslot: string) => void;
  error: string;
  isAsapAvailable: boolean;
  timeSlots: string[];
  dayTimeSlots: Venues.VenueDayTimeSlots[];
  timeZone: string;
  showSelect?: boolean;
}

const Schedule = ({
  selectedTimeSlot,
  handleSelectTimeSlot,
  error,
  isAsapAvailable,
  timeSlots,
  dayTimeSlots,
  timeZone,
  showSelect,
}: ScheduleProps) => {
  const [isOpen, { on, off }] = useBoolean(false);

  const selectTimeSlot = useCallback(
    (timeslot: string) => {
      handleSelectTimeSlot(timeslot);
      off();
    },
    [handleSelectTimeSlot],
  );

  const label = useMemo(() => {
    if (!isAsapAvailable && !selectedTimeSlot) {
      return 'Pick a preorder time*';
    } else if (selectedTimeSlot === ASAP_TIMESLOT) {
      return ASAP_TIMESLOT;
    } else {
      const date = new Date(selectedTimeSlot.substring(0, 19));

      return isValid(date) && format(date, 'MMM dd, yyyy HH:mm');
    }
  }, [isAsapAvailable, selectedTimeSlot]);

  return (
    <Box>
      <Stack direction="row" align="center" spacing="16px" py="16px">
        <Box w="24px">
          <Image src={ClockIcon} w="16px" />
        </Box>
        <Box flex={1}>
          <Text fontWeight={600} fontSize="sm">
            {label}
          </Text>
          {error && <ErrorText error={error} />}
        </Box>
        <Button variant="link" onClick={on} size="sm">
          Change
        </Button>
      </Stack>

      <ScheduleModal
        isOpen={isOpen}
        onClose={off}
        selectedTimeSlot={selectedTimeSlot}
        handleSelectTimeSlot={selectTimeSlot}
        isAsapAvailable={isAsapAvailable}
        timeSlots={timeSlots}
        dayTimeSlots={dayTimeSlots}
        timeZone={timeZone}
        showSelect={showSelect}
      />
    </Box>
  );
};

export default memo(Schedule);
