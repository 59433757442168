import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import BinIcon from '@shared/assets/icons/bin.svg';
import ChevronRightIcon from '@shared/assets/icons/chevron-right.svg';
import GearIcon from '@shared/assets/icons/gear.svg';
import HistoryIcon from '@shared/assets/icons/history.svg';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import VoucherIcon from '@shared/assets/icons/voucher.svg';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { Link as RouterLink } from 'react-router-dom';
import { Paths } from 'routes/paths';

import useProfile from './hooks';

const listItemStyles = {
  display: 'block',
  fontSize: '14px',
  lineHeight: '24px',
  color: 'gray.900',
  fontWeight: '600',
  py: '24px',
};

export const Profile = () => {
  const {
    actions: { handleLogout, handleBack },
    data: { userName, totalPoints, isShowVoucher, pointsName, isShowPoints },
  } = useProfile();

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      <BackButton onClick={handleBack} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" mb="8px">
        {userName}
      </Heading>
      {isShowPoints && (
        <Text
          color="gray.400"
          fontWeight="600"
          fontSize="12px"
          lineHeight="16px"
        >
          {`You have ${totalPoints || '0'} ${pointsName || 'points'}`}
        </Text>
      )}

      <Box py="24px">
        <List>
          <ListItem>
            <Link
              as={RouterLink}
              to={Paths.ProfileOrderHistory}
              {...listItemStyles}
              borderBottom="1px solid #E4E5E7"
              _hover={{
                textDecoration: 'none',
                opacity: 0.8,
              }}
            >
              <Flex>
                <Image src={HistoryIcon} mr="20px" alt="history icon" />
                <Text flexGrow="1">Order history</Text>
                <Image
                  src={ChevronRightIcon}
                  pr="4px"
                  alt="go to order history"
                />
              </Flex>
            </Link>
          </ListItem>
          {isShowVoucher && (
            <ListItem>
              <Link
                as={RouterLink}
                to={Paths.ProfileVouchers}
                borderBottom="1px solid #E4E5E7"
                {...listItemStyles}
                _hover={{
                  textDecoration: 'none',
                  opacity: 0.8,
                }}
              >
                <Flex>
                  <Image
                    src={VoucherIcon}
                    mr="20px"
                    alt="voucher wallet icon"
                  />
                  <Text flexGrow="1">Voucher Wallet</Text>
                  <Image
                    src={ChevronRightIcon}
                    pr="4px"
                    alt="go to voucher wallet"
                  />
                </Flex>
              </Link>
            </ListItem>
          )}

          <ListItem>
            <Link
              as={RouterLink}
              to={Paths.ProfileSavedAddresses}
              {...listItemStyles}
              borderBottom="1px solid #E4E5E7"
              _hover={{
                textDecoration: 'none',
                opacity: 0.8,
              }}
            >
              <Flex>
                <Image src={MarkerIcon} mr="20px" alt="saved addresses icon" />
                <Text flexGrow="1">Saved addresses</Text>
                <Image
                  src={ChevronRightIcon}
                  pr="4px"
                  alt="go to saved addresses"
                />
              </Flex>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              as={RouterLink}
              to={Paths.ProfileSettings}
              {...listItemStyles}
              _hover={{
                textDecoration: 'none',
                opacity: 0.8,
              }}
            >
              <Flex>
                <Image src={GearIcon} mr="20px" alt="profile settings icon" />
                <Text flexGrow="1">Profile settings</Text>
                <Image
                  src={ChevronRightIcon}
                  pr="4px"
                  alt="go to profile settings"
                />
              </Flex>
            </Link>
          </ListItem>

          <ListItem>
            <Link
              as={RouterLink}
              to={Paths.ProfileDeleteAccount}
              {...listItemStyles}
              _hover={{
                textDecoration: 'none',
                opacity: 0.8,
              }}
            >
              <Flex>
                <Image
                  src={BinIcon}
                  mr="20px"
                  alt="delete account icon"
                  height="24px"
                />
                <Text flexGrow="1">Delete account</Text>
                <Image
                  src={ChevronRightIcon}
                  pr="4px"
                  alt="go to profile settings"
                />
              </Flex>
            </Link>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Button variant="secondary" px="24px" size="md" onClick={handleLogout}>
          Sign out
        </Button>
      </Box>
    </WidgetWrapper>
  );
};
