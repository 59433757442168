import { Button, ButtonProps, Text } from '@chakra-ui/react';

import { formatCurrency } from '../../utils/format';

interface Props extends ButtonProps {
  label: string;
  price: number;
  currency?: Currency;
}

export const LabelWithPriceButton = ({
  label,
  price,
  onClick,
  currency,
  ...props
}: Props) => {
  return (
    <Button w="full" size="xl" onClick={onClick} {...props}>
      <Text color="inherit" mr="8px">
        {label}
      </Text>
      <Text ml="auto" color="inherit" minW="80px" textAlign="end">
        {formatCurrency(currency)} {price.toFixed(2)}
      </Text>
    </Button>
  );
};
