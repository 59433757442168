export enum ServiceType {
  Delivery,
  Table,
  Collection,
  'Delivery room',
  Takeaway,
}

export enum ServiceTypeCellColor {
  'secondary.400',
  'yellow.400',
  'green.400',
  'red.400',
  '#FF6F00',
}
