import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

type UiServicesState = {
  newAddress: Partial<Addresses.Address> | null;
  flatNo: string;
  flatNoError: string;
  eircode: string;
  eircodeError: string;
  selectedServiceType: number | null;
  showServicesSection: boolean;
  showDeliveryDetailsSection: boolean;
  countryCodes: string[];
  streetNumber: string;
};

const initialState: UiServicesState = {
  newAddress: null,
  flatNo: '',
  flatNoError: '',
  eircode: '',
  eircodeError: '',
  streetNumber: '',
  selectedServiceType: null,
  showServicesSection: true,
  showDeliveryDetailsSection: false,
  countryCodes: [],
};

export const uiServicesSlice = createSlice({
  name: 'uiServices',
  initialState,
  reducers: {
    setNewAddress: (
      state,
      action: { payload: Partial<Addresses.Address> | null },
    ) => {
      state.newAddress = action.payload;
    },
    setFlatNo: (state, action: { payload: string }) => {
      state.flatNo = action.payload;
    },
    setFlatNoError: (state, action: { payload: string }) => {
      state.flatNoError = action.payload;
    },
    setEircode: (state, action: { payload: string }) => {
      state.eircode = action.payload;
    },
    setEircodeError: (state, action: { payload: string }) => {
      state.eircodeError = action.payload;
    },
    setStreetNumber: (state, action: { payload: string }) => {
      state.streetNumber = action.payload;
    },
    setSelectedServiceType: (state, action: { payload: number | null }) => {
      state.selectedServiceType = action.payload;
    },
    setShowServicesSection: (state, action: { payload: boolean }) => {
      state.showServicesSection = action.payload;
    },
    setShowDeliveryDetailsSection: (state, action: { payload: boolean }) => {
      state.showDeliveryDetailsSection = action.payload;
    },
    setCountryCodes: (state, action: { payload: string[] }) => {
      state.countryCodes = action.payload;
    },
    clearUiServicesData: (state) => {
      state.newAddress = null;
      state.flatNo = '';
      state.flatNoError = '';
      state.eircode = '';
      state.eircodeError = '';
      state.streetNumber = '';
      state.selectedServiceType = null;
      state.showServicesSection = true;
      state.showDeliveryDetailsSection = false;
      state.countryCodes = [];
    },
  },
});

export const {
  setNewAddress,
  setFlatNo,
  setFlatNoError,
  setEircode,
  setEircodeError,
  setStreetNumber,
  setSelectedServiceType,
  setShowServicesSection,
  setShowDeliveryDetailsSection,
  setCountryCodes,
  clearUiServicesData,
} = uiServicesSlice.actions;

export const selectUiServices = (state: RootState) => state.uiServices;

export default uiServicesSlice.reducer;
