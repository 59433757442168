import { Heading } from '@chakra-ui/react';
import AddressForm from '@shared/components/AddressForm';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useNavigate } from 'react-router-dom';

import useAddressEdit from './hooks';

export const ProfileAddressEdit = () => {
  const {
    actions: { onChange, resetFieldValidation, onSubmit },
    data: { isLoading, address, formErrors },
  } = useAddressEdit();

  const navigate = useNavigate();

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      <BackButton onClick={() => navigate(-1)} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" m="16px 0 24px">
        Edit address
      </Heading>

      <AddressForm
        onChange={onChange}
        onFocus={resetFieldValidation}
        onSubmit={onSubmit}
        address={address ?? undefined}
        formErrors={formErrors}
        isLoading={isLoading}
      />
    </WidgetWrapper>
  );
};
