import { BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import { useIsMobile, useIsMobileHeight } from '@shared/utils/screen';
import { MENU_HEIGHT } from 'pages/NavigationMenu';

import { HeadToolbar, HeadToolbarProps } from './HeadToolbar';

interface Props extends BoxProps {
  withContainer?: boolean;
  toolbar?: HeadToolbarProps;
  containerWidth?: string;
  containerProps?: FlexProps;
}

export const WidgetWrapper = ({
  children,
  withContainer = false,
  toolbar,
  containerWidth = 'sm',
  containerProps,
  ...props
}: React.PropsWithChildren<Props>) => {
  const isMobile = useIsMobile();
  const isMobileHeight = useIsMobileHeight();
  return (
    <Flex
      direction="column"
      bgColor="gray.50"
      minH={`calc(100dvh - ${MENU_HEIGHT}px)`}
      {...props}
    >
      {toolbar && <HeadToolbar {...toolbar} />}
      {withContainer ? (
        <Flex
          mx="auto"
          direction="column"
          bg="white"
          maxW={isMobile ? 'full' : containerWidth}
          minH={
            isMobile
              ? `calc(100dvh - ${MENU_HEIGHT}px - ${
                  toolbar ? TOOLBAR_HEIGHT : 0
                }px)`
              : undefined
          }
          borderRadius={isMobile ? 0 : '2xl'}
          p="24px"
          my={isMobile ? 0 : isMobileHeight ? 10 : 20}
          w="full"
          {...containerProps}
        >
          <Flex direction="column" h="full" flex={1}>
            {children}
          </Flex>
        </Flex>
      ) : (
        children
      )}
    </Flex>
  );
};
