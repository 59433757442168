import { getQueryString } from '@shared/utils/requests';

import { BaseApiService } from './BaseApiService';

export class AddressApi {
  public static async getAddresses(params: Addresses.AddressParams) {
    let path = `addresses/`;
    if (params) {
      path = path + '?' + getQueryString(params);
    }

    return await BaseApiService.get<Addresses.AddressResponse>({
      path,
    });
  }

  public static async getAddress(addressId: string) {
    return await BaseApiService.get<Addresses.Address>({
      path: `addresses/${addressId}/`,
    });
  }

  public static async saveNewAddress(body: Partial<Addresses.Address>) {
    return await BaseApiService.post<
      Partial<Addresses.Address>,
      Addresses.Address
    >({
      path: `addresses/`,
      body,
    });
  }

  public static async updateAddress(
    addressId: string,
    body: Addresses.AddressPayload,
  ) {
    return await BaseApiService.patch({
      path: `addresses/${addressId}/`,

      body,
    });
  }

  public static async deleteAddress(addressId: string) {
    return await BaseApiService.delete({
      path: `addresses/${addressId}/`,
    });
  }
}
