const calculateProductDeposit = <P extends Product.RootObject>(
  product: P,
  parentAmount = 1,
) => {
  const productAmount = (product.amount ?? 0) * parentAmount;
  const productDeposit = product.deposit_return_scheme_amount;
  const productExtras = product.extra_sections;

  let totalDeposit = 0;
  let totalCount = 0;

  if (Number(productDeposit) > 0) {
    totalDeposit += productAmount * Number(productDeposit);
    totalCount += productAmount;
  }

  if (productExtras.length) {
    productExtras.forEach((productExtra) => {
      productExtra.products.forEach((product) => {
        const [deposit, count] = calculateProductDeposit(
          product,
          productAmount,
        );
        totalDeposit += deposit;
        totalCount += count;
      });
    });
  }

  return [totalDeposit, totalCount];
};

export const depositReturn = <Product extends Product.RootObject>(
  products: Product[],
) => {
  let productWithDepositCount = 0;
  let totalDeposit = 0;

  products.forEach((product) => {
    const [deposit, count] = calculateProductDeposit(product);
    productWithDepositCount += count;
    totalDeposit += deposit;
  });

  return [productWithDepositCount, Number(totalDeposit.toFixed(2))];
};

// same thing but for reorder type
const calculateReorderProductDeposit = <
  P extends Product.ReorderProduct | Product.ReorderExtra,
>(
  product: P,
  parentAmount = 1,
) => {
  const productAmount = product.quantity * parentAmount;
  const productDeposit = product.deposit_return_scheme_amount;
  const productExtras = product.extras;

  let totalDeposit = 0;
  let totalCount = 0;

  if (Number(productDeposit) > 0) {
    totalDeposit += productAmount * Number(productDeposit);
    totalCount += productAmount;
  }

  if (productExtras.length) {
    productExtras.forEach((productExtra) => {
      const [deposit, count] = calculateReorderProductDeposit(
        productExtra,
        productAmount,
      );
      totalDeposit += deposit;
      totalCount += count;
    });
  }

  return [totalDeposit, totalCount];
};

export const reorderDepositReturn = <Product extends Product.ReorderProduct>(
  products: Product[],
) => {
  let productWithDepositCount = 0;
  let totalDeposit = 0;

  products.forEach((product) => {
    const [deposit, count] = calculateReorderProductDeposit(product);
    productWithDepositCount += count;
    totalDeposit += deposit;
  });

  return [productWithDepositCount, Number(totalDeposit.toFixed(2))];
};
