import { ColorPalettes } from '../constants/color';

const colorPaletteLength = Object.values(ColorPalettes).length;

export const getColorByIndex = (index: number) => {
  // Calculate the index within the color palette array using modulo operator
  const colorIndex = index % colorPaletteLength;

  return ColorPalettes[colorIndex];
};
