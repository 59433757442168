import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  IconButton,
  Image,
} from '@chakra-ui/react';
import BurgerIcon from '@shared/assets/icons/burger.svg';
import { useNavigateOnLanding } from 'hooks/useNavigateOnLanding';
import { useCallback, useState } from 'react';
import { Paths } from 'routes/paths';

import { NavButton } from './NavButton';

interface Props {
  isShowAbout: boolean;
  isShowVouchers: boolean;
  handleNavigation: (path: Paths) => () => void;
}

export const MobileMenu = ({
  isShowAbout,
  isShowVouchers,
  handleNavigation,
}: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [activeSection] = useNavigateOnLanding();

  const onMenuClick = useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onNavigate = useCallback(
    (path: Paths) => () => {
      handleNavigation(path)();
      onClose();
    },
    [handleNavigation],
  );

  return (
    <>
      <IconButton
        variant="unstyled"
        aria-label="menu"
        size="sm"
        icon={<Image src={BurgerIcon} w="100%" />}
        onClick={onMenuClick}
      />
      <Drawer placement="start" onClose={onClose} isOpen={isOpen} size="full">
        <DrawerContent>
          <DrawerCloseButton
            size="lg"
            color="gray.400"
            top="24px"
            left="24px"
          />
          <DrawerBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <NavButton
              isMobile
              onClick={onNavigate(Paths.Home)}
              selected={activeSection === Paths.Home}
            >
              Home
            </NavButton>
            {isShowAbout && (
              <NavButton
                isMobile
                onClick={onNavigate(Paths.About)}
                selected={activeSection === Paths.About}
              >
                About
              </NavButton>
            )}
            {isShowVouchers && (
              <NavButton
                isMobile
                onClick={onNavigate(Paths.LandingVouchers)}
                selected={activeSection === Paths.LandingVouchers}
              >
                Vouchers
              </NavButton>
            )}
            <NavButton
              isMobile
              onClick={onNavigate(Paths.Contact)}
              selected={activeSection === Paths.Contact}
            >
              Contact
            </NavButton>
            <Button size="xl" onClick={onNavigate(Paths.Order)}>
              Order online
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
