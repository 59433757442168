import { createRef, useCallback, useEffect, useState } from 'react';

import { useGoogleApi } from '../../hooks/useGoogleApi';
import { InputProps } from '.';
import { SearchInput } from './SearchInput';

export interface SearchAddressInputProps extends Omit<InputProps, 'onSelect'> {
  label?: string;
  value?: string;
  onSelect: (address: Partial<Addresses.Address>) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onClear?: VoidFunction;
  isHideClearButton?: boolean;
  icon?: string;
  countryCodes: string[];
}

export const SearchAddressInput = ({
  label,
  onSelect,
  onFocus,
  onBlur,
  onClick,
  isHideClearButton,
  icon,
  countryCodes = [],
  value = '',
  onClear,
  ...restInputProps
}: SearchAddressInputProps) => {
  const [text, setText] = useState<string>(value);

  const inputRef = createRef<HTMLInputElement>();
  const { isScriptLoaded, loadScript, address, setupAutoComplete } =
    useGoogleApi();

  useEffect(() => {
    if (isScriptLoaded) {
      if (inputRef.current) {
        setupAutoComplete(inputRef.current, countryCodes);
      }
    } else {
      loadScript();
    }
  }, [inputRef.current, isScriptLoaded, JSON.stringify(countryCodes)]);

  useEffect(() => {
    if (address) {
      setText(address.formatted_address ?? text);
      onSelect(address);
    }
  }, [address]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setText('');
    inputRef.current?.focus();
    onClear?.();
  }, [inputRef, onClear]);

  return (
    <SearchInput
      {...restInputProps}
      ref={inputRef}
      label={label}
      value={text}
      onChange={onChange}
      placeholder="Search address"
      onClear={isHideClearButton ? undefined : handleClear}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      isReadOnly={!!onClick}
      icon={icon}
    />
  );
};
