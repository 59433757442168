import { StyleProps, Text } from '@chakra-ui/react';

interface Props extends StyleProps {
  calories: string;
}

export const CaloriesText = ({ calories, ...props }: Props) => (
  <Text as="span" fontSize="xs" color="gray.400" {...props}>
    {+calories} cal
  </Text>
);
