import { Heading, List, ListProps } from '@chakra-ui/react';

import { AddressItem } from './AddressItem';

interface Props {
  addresses: Addresses.Address[];
  onSelect: (address: Addresses.Address) => void;
  listProps?: ListProps;
}

export const AddressList = ({ addresses, onSelect, listProps }: Props) => {
  return (
    <>
      <Heading size="xs" my="16px" color="gray.400">
        Saved addresses
      </Heading>
      <List {...listProps}>
        {addresses.map((address) => (
          <AddressItem key={address.id} address={address} onClick={onSelect} />
        ))}
      </List>
    </>
  );
};
