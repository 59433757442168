export const isEmpty = (input: string | null | undefined): boolean => {
  return !input || input.trim().length === 0;
};

export const removeSpaces = (text: string): string => {
  return String(text ?? '').replaceAll(' ', '');
};

export const abbreviateText = (text: string): string => {
  // Split the name into words
  const words = text.split(' ');

  // Extract the first letter of each word
  const initials = words.map((word) => word.charAt(0).toUpperCase());

  // Join the initials together
  return initials.join('');
};

export const createRegexFromStrings = (patternString: string): RegExp[] => {
  // Split the pattern string by comma and trim whitespace from each pattern
  const patterns = patternString.split(',').map((pattern) => pattern.trim());

  // Create an array to store regular expressions
  const regexArray: RegExp[] = [];

  // Convert each pattern string to a regular expression
  patterns.forEach((pattern) => {
    // Escape special characters in the pattern and create a RegExp object
    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // Create RegExp only if escapedPattern not empty
    if (escapedPattern) {
      const regex = new RegExp(escapedPattern, 'i'); // Case insensitive

      // Push the regular expression to the array
      regexArray.push(regex);
    }
  });

  return regexArray;
};
