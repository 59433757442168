import debounce from 'lodash.debounce';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';

const ScrollablePaths = [Paths.About, Paths.LandingVouchers, Paths.Contact];

export const useNavigateOnLanding = (): [
  string | null,
  (path: Paths) => void,
] => {
  const [activeSection, setActiveSection] = useState<Paths | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY + MENU_HEIGHT;
    let currActiveSection = Paths.Home;
    ScrollablePaths.forEach((path) => {
      const section = document.getElementById(path);
      if (section) {
        const offsetTop = section.offsetTop;

        if (scrollPosition >= offsetTop) {
          currActiveSection = path;
        }
      }
    });

    setActiveSection(currActiveSection);
    navigate(currActiveSection);
  };

  useEffect(() => {
    const debouncedCb = debounce(handleScroll, 30);
    if (location.pathname !== Paths.Home) {
      setActiveSection(null);
      window.removeEventListener('scroll', debouncedCb);
    } else {
      setActiveSection(Paths.Home);
      window.addEventListener('scroll', debouncedCb);
    }

    return () => {
      window.removeEventListener('scroll', debouncedCb);
    };
  }, [location.pathname]);

  const scrollByPath = useCallback((path: Paths) => {
    const section = document.getElementById(path);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - MENU_HEIGHT + 1,
        behavior: 'smooth',
      });
      setActiveSection(path);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setActiveSection(Paths.Home);
    }
  }, []);

  useEffect(() => {
    switch (location.hash) {
      case Paths.About:
        scrollByPath(Paths.About);
        break;
      case Paths.LandingVouchers:
        scrollByPath(Paths.LandingVouchers);
        break;
      case Paths.Contact:
        scrollByPath(Paths.Contact);
        break;
      default:
        scrollByPath(Paths.Home);
        break;
    }
  }, []);

  return [activeSection, scrollByPath];
};
