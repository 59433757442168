import {
  Box,
  Center,
  CircularProgress,
  Modal,
  ModalContent,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
}

export const LoadingBlock = ({ isOpen }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={() => void 0} isCentered>
      <Box
        position="fixed"
        left={0}
        top={0}
        width="100vw"
        height="100dvh"
        zIndex="modal"
        backdropFilter="blur(2px)"
      />
      <ModalContent bg="transparent" boxShadow="none">
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      </ModalContent>
    </Modal>
  );
};
