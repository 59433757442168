import { createSlice } from '@reduxjs/toolkit';
import { VenueGroupsApi } from 'api/VenueGroupsApi';
import { AppThunk, RootState } from 'store';

type ProfileState = {
  isLoading: boolean;
  error: Error | null;
  points: Points.TotalPoints | null;
};

const initialState: ProfileState = {
  isLoading: false,
  error: null,
  points: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setRequestSuccess: (state, action: { payload: Points.TotalPoints }) => {
      state.points = action.payload;
    },
    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.points = null;
    },
  },
});

const { setRequestStart, setRequestSuccess, setLoading, setError, clearState } =
  profileSlice.actions;

export const getTotalPoints =
  (venueGroupId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await VenueGroupsApi.getTotalPoints(venueGroupId);
      dispatch(setRequestSuccess(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearData = (): AppThunk => (dispatch) => {
  dispatch(clearState());
};

export const selectProfile = (state: RootState) => state.profile;

export default profileSlice.reducer;
