import { IconButton, IconButtonProps } from '@chakra-ui/react';

import { ArrowUp } from '../../assets/icons';

export const ArrowUpButton = (
  props: Omit<IconButtonProps, 'aria-label' | 'icon'>,
) => (
  <IconButton
    isRound
    size="sm"
    variant="gray"
    {...props}
    icon={<ArrowUp width="24px" height="24px" />}
    aria-label="expand"
  />
);
