import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { logout, selectAuth } from 'store/slices/auth';
import { getTotalPoints, selectProfile } from 'store/slices/profile';
import { selectWebsite } from 'store/slices/website';
import { isLandingMode } from 'utils/document';

const useProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { user } = useAppSelector(selectAuth);
  const { points, error } = useAppSelector(selectProfile);
  const { websiteData } = useAppSelector(selectWebsite);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    navigate(isLandingMode ? Paths.Order : Paths.Home);
  }, []);

  useEffect(() => {
    if (user?.venue_group) {
      dispatch(getTotalPoints(user.venue_group));
    }
  }, [user?.venue_group]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  return {
    actions: {
      handleLogout,
      handleBack,
    },
    data: {
      userName: user?.name,
      totalPoints: points?.total_points,
      isShowVoucher: websiteData?.show_voucher || false,
      pointsName: websiteData?.venue_group?.points_config?.name,
      isShowPoints: websiteData?.venue_group?.is_points_available,
    },
  };
};

export default useProfile;
