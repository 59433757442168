import { useToast } from '@chakra-ui/react';
import { OrdersApi } from 'api/OrdersApi';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearOrderTrackingData,
  getOrderTracking,
  selectOrderTracking,
} from 'store/slices/orderTracking';

let wsClose: VoidFunction;

export const useOrderTracking = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { order, error } = useAppSelector(selectOrderTracking);

  const { token } = useParams<{ token: string }>();

  const [driverData, setDriverData] = useState<
    Orders.TrackingData['payload'] | null
  >(null);

  const isOrderFinished =
    order?.status === 5 || order?.status === 7 || order?.status === 8;

  useEffect(() => {
    if (isOrderFinished) {
      setDriverData(null);
    }
  }, [isOrderFinished]);

  useEffect(() => {
    if (token) {
      dispatch(getOrderTracking(token));
    }

    const interval = setInterval(() => {
      // if not completed or cancelled, update every 10 sec
      if (token && !isOrderFinished) {
        dispatch(getOrderTracking(token));
      }
    }, 10000);

    if (isOrderFinished) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [token, order?.status, isOrderFinished]);

  useEffect(() => {
    if (order?.id) {
      OrdersApi.trackShipment(
        (data, close) => {
          wsClose = close;
          if (
            data &&
            order &&
            order?.status !== 5 &&
            order?.status !== 7 &&
            order?.status !== 8
          ) {
            setDriverData(data.payload);
          }
        },
        order.id,
        false,
      );

      return () => {
        wsClose?.();
      };
    }
  }, [order?.id]);

  useEffect(() => {
    return () => {
      // clear basket upsells data
      dispatch(clearOrderTrackingData());
    };
  }, []);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  return {
    data: {
      order,
      driverData,
      isOrderFinished,
    },
  };
};
