import { storage, StorageKeys } from '@shared/utils/storage';

export const clearStorageDiscountData = () => {
  storage.remove(StorageKeys.COUPON);
  storage.remove(StorageKeys.DISCOUNT_AMOUNT);
  storage.remove(StorageKeys.DISCOUNT_DATA);
  storage.remove(StorageKeys.VOUCHER_ID);
  storage.remove(StorageKeys.VOUCHER_VALUE);
  storage.remove(StorageKeys.VOUCHER_AVAILABLE_BALANCE);
};
