import { Box, Center, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface Props {
  backgroundImages?: string[];
  title?: string;
  subtitle?: string;
}

export const TitleSection = ({
  backgroundImages = [],
  title = '',
  subtitle = '',
}: Props) => {
  const [imgIndex, setImgIndex] = useState<number>(0);
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (backgroundImages.length > 1) {
      interval = setInterval(() => {
        setImgIndex((prevState) =>
          prevState < backgroundImages.length - 1 ? ++prevState : 0,
        );
      }, 3000);
    }
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      bgImage={`url(${backgroundImages[imgIndex]})`}
      bgRepeat="no-repeat"
      bgSize="cover"
      bgPosition="center"
      bgColor="primary.100"
      h="720px"
      transition="0.5s"
    >
      {Boolean(title || subtitle) && (
        <Center
          w="100%"
          h="100%"
          bgColor={'rgba(0,0,0,0.3)'}
          flexDirection="column"
          px="16px"
        >
          <Heading
            as="h1"
            color="white"
            size="3xl"
            mb="16px"
            textAlign="center"
          >
            {title}
          </Heading>
          <Text color="white" fontSize="sm" align="center">
            {subtitle}
          </Text>
        </Center>
      )}
    </Box>
  );
};
