import { useCallback, useEffect, useState } from 'react';

type Sizes = {
  fullHeight: number;
  fullWidth: number;
  height: number;
  width: number;
};

export const useElementResize = <Element extends HTMLElement>(): [
  (el: Element | null) => void,
  Sizes,
] => {
  const [element, setElement] = useState<Element | null>(null);
  const elementRef = useCallback((el: Element | null) => {
    if (el !== null) {
      setElement(el);
    }
  }, []);
  const [sizes, setSizes] = useState<Sizes>({
    fullHeight: 0,
    fullWidth: 0,
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (element) {
      const resizeObserver = new ResizeObserver((entries) => {
        const [entry] = entries;
        setSizes({
          fullHeight: entry.borderBoxSize[0].blockSize,
          fullWidth: entry.borderBoxSize[0].inlineSize,
          height: entry.contentRect.height,
          width: entry.contentRect.width,
        });
      });

      resizeObserver.observe(element);

      return () => resizeObserver.disconnect();
    } else {
      setSizes({
        fullHeight: 0,
        fullWidth: 0,
        height: 0,
        width: 0,
      });
    }
  }, [element]);

  return [elementRef, sizes];
};
