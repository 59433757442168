import { Flex, IconButton, Text } from '@chakra-ui/react';
import { useCallback } from 'react';

import { DeleteIcon, MinusIcon, PlusIcon } from '../../assets/icons';
import { useIsMobile } from '../../utils/screen';

interface Props {
  count: number;
  onChange: (value: number) => void;
  grayVariant?: true;
  minimum?: number;
  maximum?: number;
  disabled?: boolean;
}

export const CounterButton = ({
  count,
  onChange,
  grayVariant,
  minimum = 1,
  maximum,
  disabled,
}: Props) => {
  const isMobile = useIsMobile();
  const onMinus = useCallback(() => {
    onChange(count > minimum ? --count : minimum);
  }, [minimum, count]);
  const onPlus = useCallback(() => {
    onChange(maximum ? (count === maximum ? count : ++count) : ++count);
  }, [maximum, count]);

  const Icon = grayVariant && count === minimum + 1 ? DeleteIcon : MinusIcon;

  return (
    <Flex align="center">
      <IconButton
        aria-label="minus"
        isRound
        size={grayVariant || isMobile ? 'sm' : 'xl'}
        icon={<Icon width="24px" height="24px" />}
        onClick={onMinus}
        variant={grayVariant ? 'gray' : 'secondary'}
        isDisabled={count < minimum + 1 || disabled}
      />
      <Text
        fontWeight={600}
        color={grayVariant ? 'gray.400' : 'primary.400'}
        minW="32px"
        textAlign="center"
        fontSize={grayVariant || isMobile ? 'sm' : 'md'}
      >
        {count}
      </Text>
      <IconButton
        aria-label="plus"
        isRound
        size={grayVariant || isMobile ? 'sm' : 'xl'}
        icon={<PlusIcon width="24px" height="24px" />}
        onClick={onPlus}
        variant={grayVariant ? 'gray' : 'secondary'}
        isDisabled={maximum ? count === maximum : false || disabled}
      />
    </Flex>
  );
};
