import { Box, Button, Stack, useToast } from '@chakra-ui/react';
import { LabelWithPriceButton } from '@shared/components/Buttons/LabelWithPriceButton';
import { PaymentProvider } from '@shared/constants/payment';
import { useIsMobile } from '@shared/utils/screen';
import TagManager from '@sooro-io/react-gtm-module';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { OrdersApi } from 'api/OrdersApi';
import { CreditCardForm } from 'components/CreditCardForm';
import {
  WIDGET_PLACE_ORDER,
  WIDGET_PROMOTION_REDEEMED,
} from 'constants/googleTagManagerEvents';
import get from 'lodash/get';
import { useCallback, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearUsedVoucherData,
  selectCheckoutVouchers,
} from 'store/slices/checkoutVouchers';
import { clearBasket } from 'store/slices/productModal';

interface Props {
  priceWithoutDiscount: number;
  priceWithDiscountGA: number;
  totalPrice: number;
  onCancel: VoidFunction;
  name: string;
  email: string;
  phone: string;
  orderId: string;
  discount: {
    amount: number;
    data?: Points.AvailablePoints | Discounts.Discount;
  };
  deliverySurcharge: number;
  payment: Payments.PaymentMethod;
  validate: () => boolean;
  currency: Currency;
  checkAvailability: (cb: VoidFunction) => VoidFunction;
  showPayByCardFormDirectly: boolean;
  tipAmount: number;
  driverTipAmount: number;
  syncCart: (orderId: string) => void;
  totalDeposit: number;
}

export const PayByCardForm = ({
  priceWithoutDiscount,
  priceWithDiscountGA,
  totalPrice,
  onCancel,
  name,
  email,
  phone,
  orderId,
  discount,
  deliverySurcharge,
  payment,
  validate,
  currency,
  checkAvailability,
  showPayByCardFormDirectly,
  tipAmount,
  driverTipAmount,
  syncCart,
  totalDeposit,
}: Props) => {
  const { usedVoucherData } = useAppSelector(selectCheckoutVouchers);

  const isMobile = useIsMobile();
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleError = useCallback((errorMsg?: string) => {
    if (!errorMsg) return;
    setLoading(false);
    toast({
      description: errorMsg,
      status: 'error',
      isClosable: true,
    });
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!stripe || !elements || !validate()) {
        return;
      }
      const wrappedFunction = checkAvailability(() => {
        const createPaymentMethod = async () => {
          setLoading(true);
          const { error: submitError } = await elements.submit();
          if (submitError) {
            handleError(submitError.message);
            return;
          }
          try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
              type: 'card',
              card: elements.getElement(
                'cardNumber',
              ) as StripeCardNumberElement,
              billing_details: {
                name,
                email,
                phone,
              },
            });
            if (error) throw error;

            const res: Payments.PayRequestResponse = await OrdersApi.pay(
              orderId,
              {
                payment: payment.id,
                paid_amount: totalPrice.toFixed(2),
                currency,
                payment_method: paymentMethod.id,
                used_points: (discount.data as Points.AvailablePoints)
                  ?.available_points,
                payment_provider: PaymentProvider.Stripe,
                discount: (discount.data as Discounts.Discount)?.id,
                delivery_surcharge: deliverySurcharge.toFixed(2),
                voucher: usedVoucherData?.id,
                voucher_amount: usedVoucherData?.value,
                tips_amount: tipAmount.toString(),
                driver_tips_amount: driverTipAmount.toString(),
                deposit_return_scheme_amount: totalDeposit.toString(),
              },
            );

            if (res.status === 'requires_action') {
              const { error: confirmError } = await stripe.confirmCardPayment(
                res.client_secret,
                {
                  payment_method: paymentMethod.id,
                },
              );
              if (confirmError) throw confirmError;
            }

            if (
              discount.data &&
              get(discount, ['data', 'discount_type']) === 0
            ) {
              TagManager.dataLayer({
                dataLayer: {
                  event: WIDGET_PROMOTION_REDEEMED,
                },
              });
            }

            TagManager.dataLayer({
              dataLayer: {
                event: WIDGET_PLACE_ORDER,
                order_id: orderId,
                order_value_before_discount: priceWithoutDiscount.toFixed(2),
                order_value_after_discount: priceWithDiscountGA.toFixed(2),
                order_currency: currency,
              },
            });

            setLoading(false);

            syncCart(orderId);
            dispatch(clearUsedVoucherData());
            dispatch(clearBasket());
            navigate(
              generatePath(Paths.OrderStatus, {
                orderId,
              }),
            );
          } catch (error) {
            handleError((error as StripeError).message);
          }
        };

        void createPaymentMethod();
      });
      wrappedFunction();
    },
    [
      stripe,
      elements,
      name,
      email,
      phone,
      orderId,
      discount,
      deliverySurcharge,
      payment,
      validate,
      checkAvailability,
      usedVoucherData,
      tipAmount,
      driverTipAmount,
    ],
  );

  const handleScrollToView = useCallback((ref: HTMLDivElement) => {
    ref?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box
      ref={handleScrollToView}
      as="form"
      bgColor="gray.100"
      borderRadius="xl"
      p="24px"
      onSubmit={handleSubmit}
    >
      <CreditCardForm />
      <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
        <LabelWithPriceButton
          type="submit"
          label="Pay now"
          price={totalPrice}
          flex={1}
          isLoading={loading}
        />
        {!showPayByCardFormDirectly && (
          <Button
            variant="outline"
            size="xl"
            onClick={onCancel}
            flex={1}
            isLoading={loading}
          >
            Cancel
          </Button>
        )}
      </Stack>
    </Box>
  );
};
