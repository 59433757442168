import { CloseButton, Image } from '@chakra-ui/react';
import { forwardRef } from 'react';

import SearchIcon from '../../assets/icons/search.svg';
import { Input, InputProps } from '.';

interface Props extends InputProps {
  onClear?: VoidFunction;
  icon?: string;
}

export const SearchInput = forwardRef<HTMLInputElement, Props>(
  function SearchInput({ onClear, icon, ...props }: Props, ref) {
    return (
      <Input
        leftElement={<Image src={icon ? icon : SearchIcon} />}
        disableMoveCaret
        rightElement={
          props.value &&
          onClear && (
            <CloseButton _hover={{}} color="gray.400" onClick={onClear} />
          )
        }
        {...props}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        ref={ref}
      />
    );
  },
);
