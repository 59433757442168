import { List, ListItem } from '@chakra-ui/react';
import { Checkbox } from '@shared/components/Checkbox';
import { Radio } from '@shared/components/Radio';
import { generateProductDataBySelectedIds } from '@shared/utils/product';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  handleSelect,
  openCustomise,
  selectProductModal,
} from 'store/slices/productModal';

import { ExtraProductItem } from './ExtraProductItem';

export type SelectedProduct = {
  id: string;
  amount: number;
  price: number;
  defaultQuantity: number;
};

interface Props {
  id: string;
  parentId?: string;
  productId?: string;
  parentProductQuantity?: number;
  products: Product.RootObject[];
  isWithRadioBtns: boolean;
  limit: number;
}

const filterThirdLevelExtras = (extras: Product.ExtraSection[]) => {
  return extras.filter((ex) => !ex.is_hidden);
};

const listItemProps = { borderBottomWidth: 1, borderBottomColor: 'gray.200' };

export const ExtraProductList = ({
  id,
  parentId,
  productId,
  parentProductQuantity,
  products,
  isWithRadioBtns,
  limit,
}: Props) => {
  const { selected } = useAppSelector(selectProductModal);
  const dispatch = useAppDispatch();

  const [selectedProducts, setSelected] = useState<SelectedProduct[]>(() => {
    const defaultProduct = products.find((p) => p.default_quantity > 0);
    const selectedIds = Object.keys(selected[id] ?? {});
    const thirdLevel =
      parentId && productId
        ? selected[parentId]?.[productId]?.thirdLevel
        : null;

    return thirdLevel
      ? Object.keys(thirdLevel[id] ?? {}).flatMap((tId) =>
          thirdLevel[id][tId] ? [{ id: tId, ...thirdLevel[id][tId] }] : [],
        )
      : (selectedIds.length > 0 &&
          selectedIds.flatMap((sId) => [{ id: sId, ...selected[id][sId] }])) ||
          (limit === 1
            ? defaultProduct
              ? [
                  {
                    id: defaultProduct.id,
                    amount: defaultProduct.default_quantity,
                    price: +defaultProduct.price,
                    defaultQuantity: defaultProduct.default_quantity,
                  },
                ]
              : []
            : products.flatMap((p) =>
                p.default_quantity > 0
                  ? [
                      {
                        id: p.id,
                        amount: p.default_quantity,
                        price: +p.price,
                        defaultQuantity: p.default_quantity,
                      },
                    ]
                  : [],
              ));
  });

  const [selectedProduct, setSelectedProduct] = useState<Product.RootObject>();

  useEffect(() => {
    if (parentId && productId) {
      const data = selectedProducts.reduce(
        (prev, { id, ...rest }) => ({ ...prev, [id]: { ...rest } }),
        {},
      );
      dispatch(
        handleSelect(
          {
            [id]: data,
          },
          parentId,
          productId,
          parentProductQuantity,
        ),
      );
    } else {
      const data = selectedProducts.reduce((prev, { id: currId, ...rest }) => {
        const thirdLevel = products
          .find((p) => p.id === currId)
          ?.extra_sections.reduce(
            (prev, es) => ({
              ...prev,
              [es.id]: es.products.reduce(
                (p, cP) =>
                  cP.default_quantity > 0
                    ? {
                        ...p,
                        [cP.id]: {
                          id: cP.id,
                          amount: cP.default_quantity,
                          price: +cP.price,
                          defaultQuantity: cP.default_quantity,
                        },
                      }
                    : p,
                {},
              ),
            }),
            {},
          );
        const prevData = selected[id] ? selected[id][currId] : undefined;

        return {
          ...prev,
          [currId]: {
            ...prevData,
            thirdLevel:
              (prevData && rest.amount > 0 && prevData.thirdLevel) ??
              thirdLevel,
            ...rest,
          },
        };
      }, {});
      dispatch(handleSelect({ [id]: data }));
      selectedProduct && handleAutoOpen3rdLevel(selectedProduct);
    }
  }, [selectedProducts]);

  const sortedProducts = useMemo(
    () => [...products].sort((a, b) => a.list_order - b.list_order),
    [products],
  );

  const disableSelect = useMemo(() => {
    const amount = selectedProducts.reduce(
      (prev, curr) => prev + curr.amount,
      0,
    );
    return !!limit && amount >= limit;
  }, [selectedProducts, limit]);

  const isEditRequired = useCallback(
    (product: Product.RootObject) => {
      let thirdLevelValid = true;
      product.extra_sections
        .filter((es) => es.minimum > 0)
        .forEach((es) => {
          const thirdLevel = selected[id]?.[product.id]?.thirdLevel;

          const thirdLevelTotalAmount = es.products.reduce((prev, curr) => {
            return (
              prev +
              (thirdLevel?.[es.id]?.[curr.id]?.amount ??
                curr.default_quantity ??
                0)
            );
          }, 0);
          thirdLevelValid =
            thirdLevelValid && thirdLevelTotalAmount >= es.minimum;
        });

      return !thirdLevelValid;
    },
    [id, selected],
  );

  const isEditable = useCallback(
    (product: Product.RootObject) =>
      Boolean(
        filterThirdLevelExtras(product.extra_sections).length > 0 &&
          selectedProducts.findIndex(
            (sp) => sp.id === product.id && sp.amount > 0,
          ) > -1,
      ),
    [selectedProducts],
  );

  const onEditBtnClick = useCallback(
    (productId: string, extraSections: Product.ExtraSection[]) => () => {
      dispatch(
        openCustomise(id, productId, filterThirdLevelExtras(extraSections)),
      );
    },
    [],
  );

  const handleAutoOpen3rdLevel = useCallback(
    (product: Product.RootObject) => {
      if (
        isEditRequired(product) &&
        product.extra_sections.length > 0 &&
        selectedProducts.findIndex(
          (sp) =>
            sp.id === product.id &&
            sp.amount > 0 &&
            sp.amount >= sp.defaultQuantity,
        ) > -1
      ) {
        onEditBtnClick(product.id, product.extra_sections)();
      }
    },
    [selectedProducts, isEditRequired],
  );

  const handleOnChange = useCallback((value: string | string[]) => {
    if (Array.isArray(value)) {
      const sp = value.map((id) => {
        const product = sortedProducts.find((p) => p.id === id);
        return {
          id,
          amount: 1,
          price: product ? +product.price : 0,
          defaultQuantity: product?.default_quantity ?? 0,
        };
      });
      setSelected((prevState) => {
        const defaultProducts = prevState
          .filter((s) => s.defaultQuantity > 0 && !value.includes(s.id))
          .map((p) => ({ ...p, amount: 0 }));
        return [...defaultProducts, ...sp];
      });

      const lastItem = sp.slice(-1);
      const lastProduct = sortedProducts.find((p) => p.id === lastItem[0]?.id);
      setSelectedProduct(lastProduct);
    } else {
      const product = sortedProducts.find((p) => p.id === value);
      product &&
        setSelected((prevState) => {
          // find if this product already selected
          const foundIndex = prevState.findIndex(
            (s) => s.id === value && s.amount > 0,
          );
          if (foundIndex > -1) {
            const newSelectedProduct = [...prevState];
            newSelectedProduct.splice(foundIndex, 1);
            setSelectedProduct(undefined);
            return newSelectedProduct;
          }

          setSelectedProduct(product);

          const newSelectedProduct = {
            id: value,
            amount: 1,
            price: +product.price,
            defaultQuantity: product.default_quantity,
          };
          const defaultProduct = prevState.find(
            (s) => s.defaultQuantity > 0 && s.id !== value,
          );
          if (defaultProduct) {
            return [{ ...defaultProduct, amount: 0 }, newSelectedProduct];
          }
          return [newSelectedProduct];
        });
    }
  }, []);

  const handleSelectedChange = useCallback(
    (product: Product.RootObject) => (value?: SelectedProduct) => {
      setSelected((prevState) => {
        const filteredValues = prevState.filter((s) => s.id !== product.id);
        return value && (value.amount > 0 || product.default_quantity > 0)
          ? [...filteredValues, value]
          : filteredValues;
      });
      setSelectedProduct(product);
    },
    [],
  );

  if (isWithRadioBtns) {
    const selectedValue = selectedProducts.find((sp) => sp.amount > 0)?.id;
    return (
      <Radio
        WrapperComponent={List}
        ItemWrapperComponent={ListItem}
        itemWrapperProps={listItemProps}
        onChange={handleOnChange}
        value={selectedValue ?? ''}
      >
        {sortedProducts.map((p) => ({
          value: p.id,
          component: (
            <ExtraProductItem
              id={p.id}
              name={p.display_name ?? p.name}
              limit={p.limit}
              price={+p.price}
              isEditable={isEditable(p)}
              isEditRequired={isEditRequired(p)}
              onEditClick={onEditBtnClick(p.id, p.extra_sections)}
              selected={selectedProducts}
              defaultQuantity={p.default_quantity}
              thirdLevelProducts={generateProductDataBySelectedIds(
                p,
                selected[id]?.[p.id]?.thirdLevel ?? {},
              )}
            />
          ),
        }))}
      </Radio>
    );
  }

  const isWithCheckboxes = sortedProducts.every((p) => p.limit === 1);
  if (isWithCheckboxes) {
    const defaultValues = selectedProducts.flatMap((sp) =>
      sp.amount > 0 ? [sp.id] : [],
    );
    return (
      <Checkbox
        WrapperComponent={List}
        ItemWrapperComponent={ListItem}
        itemWrapperProps={listItemProps}
        onChange={handleOnChange}
        defaultValue={defaultValues}
        disableUnchecked={disableSelect}
      >
        {sortedProducts.map((p) => ({
          value: p.id,
          element: (
            <ExtraProductItem
              id={p.id}
              name={p.display_name ?? p.name}
              limit={p.limit}
              price={+p.price}
              isEditable={isEditable(p)}
              isEditRequired={isEditRequired(p)}
              onEditClick={onEditBtnClick(p.id, p.extra_sections)}
              selected={selectedProducts}
              defaultQuantity={p.default_quantity}
              thirdLevelProducts={generateProductDataBySelectedIds(
                p,
                selected[id]?.[p.id]?.thirdLevel ?? {},
              )}
            />
          ),
        }))}
      </Checkbox>
    );
  }

  return (
    <List>
      {sortedProducts.map((p) => (
        <ListItem key={p.id} {...listItemProps}>
          <ExtraProductItem
            id={p.id}
            isWithCounter
            name={p.display_name ?? p.name}
            limit={p.limit}
            price={+p.price}
            selected={selectedProducts}
            onChange={handleSelectedChange(p)}
            isEditable={isEditable(p)}
            isEditRequired={isEditRequired(p)}
            onEditClick={onEditBtnClick(p.id, p.extra_sections)}
            disableUnselected={disableSelect}
            defaultQuantity={p.default_quantity}
            thirdLevelProducts={generateProductDataBySelectedIds(
              p,
              selected[id]?.[p.id]?.thirdLevel ?? {},
            )}
          />
        </ListItem>
      ))}
    </List>
  );
};
