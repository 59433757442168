import { Link, ListItem } from '@chakra-ui/react';
import { VoucherWithBalance } from 'components/VoucherWithBalance';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Iprops {
  name: string;
  id: string;
  availableBalance: string;
  currency: Currency;
  basePath: string;
  disabled?: boolean;
}

const VoucherItem = ({
  name,
  id,
  availableBalance,
  currency,
  basePath,
  disabled,
}: Iprops) => {
  return (
    <ListItem padding="16px 0" borderTop="1px solid" borderColor="gray.200">
      <Link
        as={RouterLink}
        to={`${basePath}/${id}`}
        display="block"
        _hover={{
          textDecoration: 'none',
          opacity: 0.8,
        }}
      >
        <VoucherWithBalance
          isUsed={disabled}
          code={name}
          balance={availableBalance}
          currency={currency}
        />
      </Link>
    </ListItem>
  );
};

export default memo(VoucherItem);
