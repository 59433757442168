import { Button, ButtonProps } from '@chakra-ui/react';

type NavButtonProps = ButtonProps & { selected: boolean; isMobile?: true };

export const NavButton = ({ selected, isMobile, ...props }: NavButtonProps) =>
  isMobile ? (
    <Button
      variant="unstyled"
      size="xl"
      mb="24px"
      bgColor={selected ? 'secondary' : 'transparent'}
      color={selected ? 'primary.400' : 'gray.800'}
      {...props}
    />
  ) : (
    <Button
      variant="unstyled"
      padding="24px"
      h="100%"
      borderBottomWidth="4px"
      borderColor={selected ? 'primary.400' : 'transparent'}
      borderRadius={0}
      color={selected ? 'primary.400' : 'gray.800'}
      {...props}
    />
  );
