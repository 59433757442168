import { ListItem } from '@chakra-ui/react';
import { VoucherWithBalance } from 'components/VoucherWithBalance';
import { memo } from 'react';

interface Iprops {
  voucher: Vouchers.Voucher;
  handleSelectVoucher: (voucher: Vouchers.Voucher) => void;
}

const VoucherListItem = ({ voucher, handleSelectVoucher }: Iprops) => {
  const { code, available_balance, currency } = voucher;

  const handleClick = () => handleSelectVoucher(voucher);

  return (
    <ListItem
      padding="10px 0"
      onClick={handleClick}
      cursor="pointer"
      _hover={{ bgColor: 'gray.100' }}
    >
      <VoucherWithBalance
        code={code}
        balance={available_balance}
        currency={currency}
      />
    </ListItem>
  );
};

export default memo(VoucherListItem);
