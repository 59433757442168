import { ErrorBoundary } from '@shared/routes/ErrorBoundary';
import { RedirectToHome } from '@shared/routes/RedirectToHome';
import { ScrollToTop } from '@shared/routes/ScrollToTop';
import { CookiePolicy } from 'pages/CookiePolicy';
import { Landing } from 'pages/Landing';
import { LandingContent } from 'pages/Landing/Content';
import { OrderStatus } from 'pages/OrderStatus';
import { OrderTracking } from 'pages/OrderTracking';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { ProductMenu } from 'pages/ProductMenu';
import { Profile } from 'pages/Profile';
import { ProfileAddressEdit } from 'pages/ProfileAddressEdit';
import { ProfileDeleteAccount } from 'pages/ProfileDeleteAccount';
import { ProfileOrderHistory } from 'pages/ProfileOrderHistory';
import { ProfileSavedAddresses } from 'pages/ProfileSavedAddresses';
import { ProfileSettings } from 'pages/ProfileSettings';
import { ProfileVoucherDetails } from 'pages/ProfileVoucherDetails';
import { ProfileVoucherRedeem } from 'pages/ProfileVoucherRedeem';
import { ProfileVouchers } from 'pages/ProfileVouchers';
import { ResetPassword } from 'pages/ResetPassword';
import { TermsConditions } from 'pages/TermsConditions';
import { TestPage } from 'pages/Test';
import { VoucherRedeem } from 'pages/VoucherRedeem';
import { Widget } from 'pages/Widget';
import { lazy } from 'react';
import { createHashRouter, RouteObject } from 'react-router-dom';

import { Paths } from './paths';
import { PrivateRoute } from './PrivateRoute';

const Checkout = lazy(() => import('pages/Checkout'));
const Voucher = lazy(() => import('pages/Voucher'));

const widgetRoutes: RouteObject[] = [
  {
    path: Paths.TermsConditions,
    element: (
      <ScrollToTop>
        <TermsConditions />
      </ScrollToTop>
    ),
  },
  {
    path: Paths.CookiePolicy,
    element: (
      <ScrollToTop>
        <CookiePolicy />
      </ScrollToTop>
    ),
  },
  {
    path: Paths.PrivacyPolicy,
    element: (
      <ScrollToTop>
        <PrivacyPolicy />
      </ScrollToTop>
    ),
  },
  {
    path: Paths.ResetPassword,
    element: <ResetPassword />,
  },
  {
    path: Paths.OrderStatus,
    element: <OrderStatus />,
  },
  {
    path: Paths.Profile,
    element: (
      <PrivateRoute routeName="Profile">
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileOrderHistory,
    element: (
      <PrivateRoute routeName="ProfileOrderHistory">
        <ProfileOrderHistory />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileOrderHistoryItem,
    element: (
      <PrivateRoute routeName="ProfileOrderHistoryItem">
        <OrderStatus />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileVouchers,
    element: (
      <PrivateRoute routeName="ProfileVouchers">
        <ProfileVouchers />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileVoucherDetails,
    element: (
      <PrivateRoute routeName="ProfileVoucherDetails">
        <ProfileVoucherDetails />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileVoucherRedeem,
    element: (
      <PrivateRoute routeName="ProfileVoucherRedeem">
        <ProfileVoucherRedeem />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileSettings,
    element: (
      <PrivateRoute routeName="ProfileSettings">
        <ProfileSettings />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileSavedAddresses,
    element: (
      <PrivateRoute routeName="ProfileSavedAddresses">
        <ProfileSavedAddresses />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileSavedAddressesEdit,
    element: (
      <PrivateRoute routeName="ProfileSavedAddressesEdit">
        <ProfileAddressEdit />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.ProfileDeleteAccount,
    element: (
      <PrivateRoute routeName="ProfileDeleteAccount">
        <ProfileDeleteAccount />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.Menu,
    element: <ProductMenu />,
  },
  {
    path: Paths.Checkout,
    element: (
      <PrivateRoute routeName="Checkout">
        <Checkout />
      </PrivateRoute>
    ),
  },
  {
    path: Paths.Voucher,
    element: <Voucher />,
  },
  {
    path: Paths.VoucherRedeem,
    element: <VoucherRedeem />,
  },
  {
    path: Paths.OrderTracking,
    element: <OrderTracking />,
  },
  {
    path: '*',
    element: <RedirectToHome path="/" />,
  },
];

const testRoute = {
  path: Paths.Test,
  element: <TestPage />,
};

export const landingRouter = createHashRouter([
  {
    element: (
      <ErrorBoundary>
        <Landing />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <LandingContent />,
      },
      {
        path: Paths.Order,
        element: <Widget />,
      },
      ...widgetRoutes,
      ...(import.meta.env.MODE === 'development' ? [testRoute] : []),
    ],
  },
]);

export const widgetRouter = createHashRouter([
  {
    element: (
      <ErrorBoundary>
        <Landing />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <Widget />,
      },
      ...widgetRoutes,
      ...(import.meta.env.MODE === 'development' ? [testRoute] : []),
    ],
  },
]);
