import { Center, CircularProgress } from '@chakra-ui/react';
import { isObjectEmpty } from '@shared/utils/object';
import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import { isLandingMode } from 'utils/document';

import { AuthModalParams, Paths } from './paths';

interface Props {
  routeName: keyof typeof Paths;
}

export const PrivateRoute = ({
  routeName,
  children,
}: React.PropsWithChildren<Props>) => {
  const { user, isChecking } = useAppSelector(selectAuth);
  const params = useParams();

  if (isChecking) {
    return (
      <Center>
        <CircularProgress isIndeterminate color="primary.400" />
      </Center>
    );
  }

  if (!user) {
    return (
      <Navigate
        to={{
          pathname: isLandingMode ? Paths.Order : Paths.Home,
          search: `?modal=${AuthModalParams.SignIn}&ref=${routeName}${
            isObjectEmpty(params) ? '' : `&refParams=${JSON.stringify(params)}`
          }`,
        }}
        replace
      />
    );
  }

  return <>{children}</>;
};
