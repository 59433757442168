import { createSlice } from '@reduxjs/toolkit';
import { OrdersApi } from 'api/OrdersApi';
import get from 'lodash.get';
import { AppThunk, RootState } from 'store';

type Ipagination = {
  next: string | null;
  previous: string | null;
  count: number;
};

type OrdersHistory = {
  isLoading: boolean;
  error: Error | null;
  ordersHistory: Orders.OrderItemHistory[];
  pagination: Ipagination;
};

const initialState: OrdersHistory = {
  isLoading: false,
  error: null,
  ordersHistory: [],
  pagination: {
    next: null,
    previous: null,
    count: 0,
  },
};

export const ordersHistorySlice = createSlice({
  name: 'ordersHistory',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setRequestSuccess: (
      state,
      action: { payload: Orders.OrderItemHistory[] },
    ) => {
      state.ordersHistory = action.payload;
    },
    setOrdersHistory: (
      state,
      action: { payload: Orders.OrderItemHistory[] },
    ) => {
      state.ordersHistory = [...state.ordersHistory, ...action.payload];
    },
    setPagination: (state, action: { payload: Ipagination }) => {
      state.pagination = action.payload;
    },
    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.ordersHistory = [];
      state.pagination = {
        next: null,
        previous: null,
        count: 0,
      };
    },
  },
});

const {
  setRequestStart,
  setRequestSuccess,
  setLoading,
  setError,
  clearState,
  setPagination,
  setOrdersHistory,
} = ordersHistorySlice.actions;

export const getOrdersHistory =
  (params: Orders.OrderHistoryParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await OrdersApi.getOrdersHistory(params);
      const orders: Orders.OrderItemHistory[] = get(res, 'results', []);
      dispatch(setPagination(res.pagination));
      dispatch(setRequestSuccess(orders)); // from the latest to the oldest
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getOrdersHistoryPaginationItems =
  (params: Orders.OrderHistoryParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await OrdersApi.getOrdersHistory(params);

      const orders: Orders.OrderItemHistory[] = get(res, 'results', []);
      dispatch(setPagination(res.pagination));
      dispatch(setOrdersHistory(orders)); // from the latest to the oldest
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearData = (): AppThunk => (dispatch) => {
  dispatch(clearState());
};

export const selectOrdersHistory = (state: RootState) => state.ordersHistory;

export default ordersHistorySlice.reducer;
