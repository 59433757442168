import { Global } from '@emotion/react';
import { colors } from '@shared/theme/colors';

export const GlobalStyles: React.FC = (): JSX.Element => (
  <Global
    styles={`
        button, div{
        -webkit-tap-highlight-color: transparent;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        }
        .pac-container{
          z-index: 1500;
          border: 1px solid ${colors.gray[200]};
          border-radius: 8px;
          box-shadow: none;
        }
        .pac-item{
          color: ${colors.gray[900]};
        }
        #CookiebotWidget:not(.CookiebotWidget-open){
          z-index: 0;
        }
      `}
  />
);
