import { Heading, Text } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { VirtualizedList } from '@shared/components/VirtualizedList';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useNavigate } from 'react-router-dom';

import AddressItem from './AddressItem';
import ConfirmDeleteAddressModal from './ConfirmDeleteModal';
import useProfileAddresses from './hooks';

export const ProfileSavedAddresses = () => {
  const {
    actions: { handleOpenModal, handleDelete, handleClose },
    data: { addresses, isOpen, isLoading },
  } = useProfileAddresses();

  const navigate = useNavigate();

  return (
    <WidgetWrapper
      withContainer
      containerWidth="xl"
      containerProps={{ flex: 1 }}
    >
      <BackButton onClick={() => navigate(-1)} />
      <Heading as="h1" fontSize="28px" lineHeight="32px" m="16px 0 24px">
        Saved addresses
      </Heading>

      {Boolean(addresses.length) ? (
        <VirtualizedList deps={addresses} flex={1}>
          {addresses.map(({ id, formatted_address }, index) => (
            <AddressItem
              key={`${id}-${index}`}
              id={id ?? ''}
              address={formatted_address}
              onDelete={handleOpenModal}
              isLast={index === addresses.length - 1}
            />
          ))}
        </VirtualizedList>
      ) : (
        <Text fontSize="16px" lineHeight="24px" color="gray.300">
          You haven&apos;t saved any addresses yet
        </Text>
      )}

      <ConfirmDeleteAddressModal
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleDelete}
        isLoading={isLoading}
      />
    </WidgetWrapper>
  );
};
