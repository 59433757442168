import { Box, Button, Stack, useToast } from '@chakra-ui/react';
import { LabelWithPriceButton } from '@shared/components/Buttons/LabelWithPriceButton';
import { useIsMobile } from '@shared/utils/screen';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { VouchersApi } from 'api/VouchersApi';
import { CreditCardForm } from 'components/CreditCardForm';
import { useCallback, useState } from 'react';

interface Props {
  totalPrice: number;
  onCancel: VoidFunction;
  voucherId: string;
  currency: Currency;
  onSuccess: (email: string) => void;
  email: string;
  recipientEmail: string;
}

export const PayForm = ({
  totalPrice,
  onCancel,
  voucherId,
  currency,
  onSuccess,
  email,
  recipientEmail,
}: Props) => {
  const isMobile = useIsMobile();
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleError = useCallback((errorMsg?: string) => {
    if (!errorMsg) return;
    setLoading(false);
    toast({
      description: errorMsg,
      status: 'error',
      isClosable: true,
    });
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const createPaymentMethod = async () => {
        setLoading(true);
        const { error: submitError } = await elements.submit();
        if (submitError) {
          handleError(submitError.message);
          return;
        }
        try {
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement('cardNumber') as StripeCardNumberElement,
            billing_details: {
              email,
            },
          });
          if (error) throw error;

          // voucher.email and buyer email can be different, send buyer email
          const res = await VouchersApi.payPublic(voucherId, {
            payment_method: paymentMethod.id,
            receipt_email: email,
          });

          if (res.status === 'requires_action') {
            const { error: confirmError } = await stripe.confirmCardPayment(
              res.client_secret,
              {
                payment_method: paymentMethod.id,
              },
            );
            if (confirmError) throw confirmError;
          }

          setLoading(false);

          onSuccess(recipientEmail.length ? recipientEmail : email);
        } catch (error) {
          handleError((error as StripeError).message);
        }
      };

      void createPaymentMethod();
    },
    [stripe, elements, email, voucherId, email],
  );

  return (
    <Box>
      <Box
        as="form"
        bgColor="gray.100"
        borderRadius="xl"
        p="24px"
        onSubmit={handleSubmit}
      >
        <CreditCardForm />
        <Stack direction={isMobile ? 'column' : 'row'} spacing="16px">
          <LabelWithPriceButton
            type="submit"
            label="Pay now"
            price={totalPrice}
            currency={currency}
            flex={1}
            isLoading={loading}
          />
          <Button
            variant="outline"
            size="xl"
            onClick={onCancel}
            flex={1}
            isLoading={loading}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
