import {
  Center,
  ChakraProvider,
  CircularProgress,
  extendTheme,
  useToast,
} from '@chakra-ui/react';
import { Fonts } from '@shared/assets/fonts';
import { useBeforeClose } from '@shared/hooks/useBeforeClose';
import { theme, toastOptions, UpdatedColors } from '@shared/theme/index';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import TagManager from '@sooro-io/react-gtm-module';
import chroma from 'chroma-js';
import { WIDGET_OPEN } from 'constants/googleTagManagerEvents';
import { GlobalStyles } from 'GlobalStyles';
import {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { RouterProvider } from 'react-router';
import { landingRouter, widgetRouter } from 'routes/router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { checkAuth } from 'store/slices/auth';
import {
  restoreBasketDataFromStorage,
  selectProductModal,
} from 'store/slices/productModal';
import { getWebsiteData, selectWebsite } from 'store/slices/website';
import { generateColorRange } from 'utils/colors';
import { isLandingMode, setTitleAndFavicon } from 'utils/document';
import { initFacebookPixel } from 'utils/metapixel';
import { getAllUrlParams } from 'utils/url';

const App = () => {
  const { websiteData, isLoading, error } = useAppSelector(selectWebsite);
  const { basketData, reorderData, serviceId, venueId } =
    useAppSelector(selectProductModal);
  const dispatch = useAppDispatch();
  const [colors, setColors] = useState<UpdatedColors>({});
  const toast = useToast();

  const isInitialOpen = storageSession.get(StorageKeys.IS_INITIAL_OPEN);

  useBeforeClose(
    useCallback(() => {
      storage.set(StorageKeys.BASKET_DATA, JSON.stringify(basketData));
      storage.set(StorageKeys.REORDER_DATA, JSON.stringify(reorderData));
    }, [basketData, venueId, serviceId, reorderData]),
  );

  useEffect(() => {
    if (websiteData?.gtm) {
      TagManager.initialize({ gtmId: websiteData.gtm });
    }
  }, [websiteData?.gtm]);

  useEffect(() => {
    if (websiteData?.meta_pixel_id) {
      initFacebookPixel(websiteData.meta_pixel_id);
    }
  }, [websiteData?.meta_pixel_id]);

  useEffect(() => {
    if (!isInitialOpen) {
      storageSession.set(StorageKeys.IS_INITIAL_OPEN, 'true');

      TagManager.dataLayer({
        dataLayer: {
          event: WIDGET_OPEN,
        },
      });
    }
  }, [isInitialOpen]);

  useEffect(() => {
    dispatch(getWebsiteData());
    dispatch(restoreBasketDataFromStorage());
    dispatch(checkAuth());

    const params = getAllUrlParams(window.location.href);

    if (
      Object.values(params).includes('refferal') &&
      params['venue_group_consumer']
    ) {
      storage.set(StorageKeys.REFERRER_ID, params['venue_group_consumer']);
    }
  }, []);

  useEffect(() => {
    if (error) {
      toast.closeAll();
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  useLayoutEffect(() => {
    if (websiteData) {
      const primary = chroma.valid(websiteData.primary_color)
        ? { primary: generateColorRange(websiteData.primary_color) }
        : {};
      const secondary = chroma.valid(websiteData.secondary_color)
        ? { secondary: generateColorRange(websiteData.secondary_color) }
        : {};
      storage.set(
        StorageKeys.PRIMARY_COLOR,
        String(primary.primary?.[400] ?? ''),
      );
      setColors({ ...primary, ...secondary });

      setTitleAndFavicon(websiteData.name, websiteData.logo);
    }
  }, [websiteData]);

  if (isLoading || !websiteData)
    return (
      <Center>
        <CircularProgress
          isIndeterminate
          color={storage.get(StorageKeys.PRIMARY_COLOR) || undefined}
        />
      </Center>
    );

  return (
    <ChakraProvider
      theme={extendTheme(theme(colors))}
      toastOptions={toastOptions}
    >
      <Fonts />
      <GlobalStyles />
      <Suspense>
        <RouterProvider router={isLandingMode ? landingRouter : widgetRouter} />
      </Suspense>
    </ChakraProvider>
  );
};

export default App;
