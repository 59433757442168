import * as Sentry from '@sentry/react';
import {
  getApiHost,
  getSentryIgnoreErrorListString,
} from '@shared/utils/getEnvData';
import { createRegexFromStrings } from '@shared/utils/string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import store from 'store';

import App from './App';

const ignoreErrors = createRegexFromStrings(getSentryIgnoreErrorListString());

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://51571a800b6143cbba820b40ccaa7864@sentry.bossit.cloud/6',
  integrations: [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Sentry.replayIntegration(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    Sentry.extraErrorDataIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: getApiHost().includes('backend.gobossit.com')
    ? 'production'
    : 'stage',
  ignoreErrors,
});

ReactDOM.createRoot(
  document.getElementById('bossit-app') as HTMLElement,
).render(
  <Provider store={store}>
    <App />
  </Provider>,
);
