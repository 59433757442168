import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { CodeInput } from '@shared/components/Input/CodeInput';
import { isEmpty } from '@shared/utils/string';
import { UserApi } from 'api/UserApi';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { checkAuth } from 'store/slices/auth';

interface Props {
  phone: string;
  onClose: VoidFunction;
  onEdit: VoidFunction;
  setPoints: (points: number) => void;
}

export const VerificationForm = ({
  phone,
  onClose,
  onEdit,
  setPoints,
}: Props) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const validate = useCallback(() => {
    const msg =
      isEmpty(value) || value.length < 6 ? 'Verification code is required' : '';
    setErrorMsg(msg);
    return !msg;
  }, [value]);

  const clearError = useCallback(() => {
    setErrorMsg('');
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        UserApi.phoneVerifyCheck(value, phone)
          .then((res) => {
            if (res.phone_verification_status === 1) {
              dispatch(checkAuth());
              if (Number(res.points) > 0) {
                setPoints(Number(res.points));
              } else {
                onClose();
              }
            } else {
              throw res;
            }
          })
          .catch(() => {
            setErrorMsg(
              'Oops! The verification code you entered is incorrect. Please try again.',
            );
          });
      }
    },
    [value, setPoints],
  );

  return (
    <>
      <Heading mb="40px" size="md">
        Enter verification code
      </Heading>
      <Flex align="start" justify="space-between">
        <Box>
          <Text>Enter the code we sent to</Text>
          <Text mb="24px">{phone}</Text>
        </Box>
        <Button variant="link" h="24px" onClick={onEdit}>
          Edit
        </Button>
      </Flex>
      <Box as="form" onSubmit={onSubmit}>
        <CodeInput value={value} onChange={setValue} onFocus={clearError} />
        <Button type="submit" size="full" mb="24px">
          Verify
        </Button>
        {errorMsg && <Text color="red.500">{errorMsg}</Text>}
      </Box>
    </>
  );
};
