import { createSlice } from '@reduxjs/toolkit';
import { OrdersApi } from 'api/OrdersApi';
import { AppThunk, RootState } from 'store';

type OrderState = {
  isLoading: boolean;
  error: Error | null;
  order: Orders.Order | null;
};

const initialState: OrderState = {
  isLoading: true,
  error: null,
  order: null,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setRequestSuccess: (state, action: { payload: Orders.Order }) => {
      state.order = action.payload;
    },
    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.order = null;
    },
  },
});

export const {
  setRequestStart,
  setRequestSuccess,
  setLoading,
  setError,
  clearState,
} = ordersSlice.actions;

export const getOrder =
  (
    {
      orderId,
      params = {
        expand: [
          'order_discounts.discount',
          'venue',
          'order_adjustments',
          'voucher_transactions',
          'venue.location',
          'review',
        ],
      },
    }: Orders.OrderParams,
    authRequired?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await OrdersApi.getOrder({ orderId, params }, authRequired);
      dispatch(setRequestSuccess(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearData = (): AppThunk => (dispatch) => {
  dispatch(clearState());
};

export const selectOrders = (state: RootState) => state.orders;

export default ordersSlice.reducer;
