import { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

interface Props {
  path: string;
}

export const RedirectToHome = ({ path }: Props) => {
  const [search, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams(search);
  }, []);
  return <Navigate to={{ pathname: path }} replace />;
};
