import {
  Day,
  differenceInHours,
  differenceInMinutes,
  format,
  isValid,
} from 'date-fns';

export const getDayName = (day: Day) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return daysOfWeek[day];
};

export const formatOnlyDate = (date?: Date | null) =>
  date ? format(date, 'yyyy-MM-dd') : null;

export const formatDateTime = (date?: Date | string | null) =>
  date && isValid(new Date(date))
    ? format(new Date(date), 'HH:mm dd-MM')
    : null;

export const differenceInHoursMinutes = (date: Date, compareTo: Date) => {
  const hours = differenceInHours(date, compareTo);
  const minutes = differenceInMinutes(date, compareTo);

  return {
    text: `${String(Math.abs(hours)).padStart(2, '0')}:${String(
      Math.abs(minutes) % 60,
    ).padStart(2, '0')}`,
    hours,
    minutes,
  };
};

export const getEarliestDate = (dates: Date[]) =>
  new Date(Math.min(...dates.map((date) => date.getTime())));
