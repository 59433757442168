import { Global } from '@emotion/react';

import PlusJakartaSans from './PlusJakartaSans.ttf';
import PlusJakartaSansItalic from './PlusJakartaSans-Italic.ttf';

export const Fonts: React.FC = (): JSX.Element => (
  <Global
    styles={`
      @font-face {
        font-family: 'PlusJakartaSans';
        font-style: normal;
        font-weight: 300 700;
        font-display: swap;
        src: url(${PlusJakartaSans as string}) format('truetype');
      }
      @font-face {
        font-family: 'PlusJakartaSans';
        font-style: italic;
        font-weight: 300 700;
        font-display: swap;
        src: url(${PlusJakartaSansItalic as string}) format('truetype');
      }
      `}
  />
);
