import { BaseApiService } from './BaseApiService';

export class UserApi {
  public static async getUser() {
    return await BaseApiService.get<Authentication.User>({
      path: 'rest-auth/user/',
    });
  }

  public static async updateUser(data: Authentication.UserPayload) {
    return await BaseApiService.patch<
      Authentication.UserPayload,
      Authentication.User
    >({
      path: 'rest-auth/user/',
      body: data,
    });
  }

  public static async phoneVerifyStart(phone: string) {
    return await BaseApiService.post<Authentication.PhoneVerificationStart>({
      path: 'user/phone/verification/start/',
      body: { phone },
    });
  }

  public static async phoneVerifyCheck(
    verification_code: string,
    phone: string,
  ) {
    return await BaseApiService.post<
      Authentication.PhoneVerificationCheck,
      Authentication.PhoneVerificationResponse
    >({
      path: 'user/phone/verification/check/',
      body: { verification_code, phone },
    });
  }
}
