import { ColorHues } from '@chakra-ui/react';
import chroma from 'chroma-js';

export const generateColorRange = (color: string): ColorHues => {
  const lab = chroma(color).lab();
  const range = [-65, -50, -30, -15, 0, 10, 20, 35, 50, 65];
  const genColors = range.map((l) => chroma.lab(lab[0] - l, lab[1], lab[2]));
  const keys = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
  const values = chroma.scale(genColors).colors(10);
  return keys.reduce(
    (prev, key, index) => ({ ...prev, [key]: values[index] }),
    {},
  ) as ColorHues;
};
