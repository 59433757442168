import { Center, Heading, Image, Link, Text } from '@chakra-ui/react';
import AppStoreIcon from '@shared/assets/images/app-store.svg';
import PlayStoreIcon from '@shared/assets/images/google-play.svg';

interface Props {
  iosLink?: string;
  androidLink?: string;
}

export const AppStoreSection = ({ iosLink, androidLink }: Props) => {
  return (
    <Center h="400px" bgColor="gray.100" px="16px" flexDirection="column">
      <Heading size="xl" mb="8px" textAlign="center">
        Download the free app
      </Heading>
      <Text fontSize="sm" mb="40px" align="center">
        Get our new app on your device and order your favourites faster than
        ever.
      </Text>
      <Center>
        {iosLink && (
          <Link href={iosLink} isExternal mx="8px">
            <Image src={AppStoreIcon} w="120px" h="40px" />
          </Link>
        )}
        {androidLink && (
          <Link href={androidLink} isExternal mx="8px">
            <Image src={PlayStoreIcon} w="135px" h="40px" />
          </Link>
        )}
      </Center>
    </Center>
  );
};
