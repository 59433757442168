import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import CallIcon from '@shared/assets/icons/call-2.svg';
import EmailIcon from '@shared/assets/icons/email.svg';
import { useIsMobile } from '@shared/utils/screen';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { isLandingMode } from 'utils/document';

interface Iprops {
  phone?: string;
  email?: string;
  orderNumber: string | number;
}

const Reject = ({ phone, email, orderNumber }: Iprops) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const showContacts = Boolean(phone) || Boolean(email);

  const redirectToHome = useCallback(
    () => navigate(isLandingMode ? Paths.Order : Paths.Home),
    [],
  );

  return (
    <>
      <Box paddingBottom="40px">
        <Heading as="h2" fontSize="24px" lineHeight="28px" marginBottom="16px">
          {`Your order #${orderNumber} has been rejected`}
        </Heading>

        <Text fontSize="16px" lineHeight="24px" marginBottom="40px">
          For some reason something went wrong. Try to start over and choose a
          different venue. Sorry for the inconvenience.
        </Text>

        <Button size="lg" onClick={redirectToHome}>
          Start over
        </Button>
      </Box>

      {showContacts && (
        <Box paddingTop="40px" borderTop="1px solid" borderColor="gray.200">
          <Heading
            as="h3"
            fontSize="20px"
            lineHeight="32px"
            marginBottom="16px"
          >
            Contact us
          </Heading>

          <Box display={isMobile ? 'block' : 'flex'}>
            {Boolean(phone) && (
              <Box
                width={isMobile ? '100%' : '50%'}
                display="flex"
                alignItems="center"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
                marginBottom={isMobile ? '16px' : '0'}
                borderRight={isMobile ? 'none' : '1px solid'}
                borderColor="gray.200"
              >
                <Image src={CallIcon} alt="phone" marginRight="20px" />
                <Text as="span">{phone}</Text>
              </Box>
            )}

            {Boolean(email) && (
              <Box
                width={isMobile ? '100%' : '50%'}
                display="flex"
                alignItems="center"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
                paddingLeft={isMobile ? '0' : '24px'}
              >
                <Image src={EmailIcon} alt="email" marginRight="20px" />
                <Text as="span">{email}</Text>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Reject;
