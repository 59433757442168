import { Flex, FlexProps } from '@chakra-ui/react';

import { CheckIcon } from '../../assets/icons';

type Props = {
  isChecked: boolean;
  iconPosition?: 'start' | 'end';
} & FlexProps;

export const CheckboxIcon = ({
  isChecked,
  iconPosition = 'start',
  ...props
}: Props) => {
  return (
    <Flex
      cursor="pointer"
      {...props}
      align="center"
      justify="center"
      w="20px"
      h="20px"
      minW="20px"
      minH="20px"
      borderWidth="2px"
      borderColor={isChecked ? 'primary.400' : 'gray.200'}
      borderRadius="4px"
      bgColor={isChecked ? 'primary.400' : undefined}
      ml={iconPosition === 'end' ? '12px' : 0}
      mr={iconPosition === 'start' ? '12px' : 0}
    >
      {isChecked && <CheckIcon color="white" w="11px" h="8px" />}
    </Flex>
  );
};
