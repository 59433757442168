import { Box, Divider, Flex } from '@chakra-ui/react';
import {
  countTotalPrice,
  generateProductDataBySelectedIds,
} from '@shared/utils/product';
import { useIsMobile, useIsMobileHeight } from '@shared/utils/screen';
import TagManager from '@sooro-io/react-gtm-module';
import { WIDGET_UPSELL_ADDED } from 'constants/googleTagManagerEvents';
import get from 'lodash.get';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAddedToCardProductIdsAction } from 'store/slices/basketUpsells';
import {
  addProductToBasket,
  selectProductModal,
} from 'store/slices/productModal';

import { AddToCard } from './AddToCard';
import { ExtraSection } from './ExtraSection';
import { useSaveCurrentScrollPosition } from './hooks';
import { ProductDetail } from './ProductDetail';

interface Props {
  product: Product.RootObject;
}

export const ProductWithModifiers = ({ product }: Props) => {
  const isMobile = useIsMobile();
  const isMobileHeight = useIsMobileHeight();

  const wrapperRef = useSaveCurrentScrollPosition();

  const { selected, isUpsellsProduct } = useAppSelector(selectProductModal);
  const dispatch = useAppDispatch();
  const [validation, setValidation] = useState<Record<string, boolean>>({});

  const handleValidation = useCallback((id: string, isValid: boolean) => {
    setValidation((prevState) => ({ ...prevState, [id]: isValid }));
  }, []);

  const isValid = useMemo(() => {
    return Object.values(validation).every((v) => v);
  }, [validation]);

  const filteredExtraSections = useMemo(() => {
    const regularOrDetailed = [0, 3].includes(product.product_type);

    const secondLevel = product.extra_sections.filter(
      (es) => !es.is_hidden && (regularOrDetailed || !es.is_ingredient),
    );

    const thirdLevel = regularOrDetailed
      ? []
      : product.extra_sections
          .filter((es) => !es.is_hidden && es.is_ingredient)
          .flatMap((thirdLv) =>
            thirdLv.products
              .filter((p) => p.default_quantity > 0)
              .map((p) => ({
                ...p,
                parentExtraSectionId: thirdLv.id,
                list_order: thirdLv.list_order,
              })),
          )
          .flatMap((product) =>
            product.extra_sections
              .filter((es) => !es.is_hidden && es.is_ingredient)
              .map((es) => ({
                ...es,
                parentProductId: product.id,
                parentExtraSectionId: product.parentExtraSectionId,
                parentProductQuantity: product.default_quantity,
                list_order: product.list_order,
              })),
          );

    return [...secondLevel, ...thirdLevel].sort(
      (a, b) => a.list_order - b.list_order,
    );
  }, [product]);

  const totalPrice = useMemo(
    () => +product.price + countTotalPrice(selected),
    [selected],
  );

  const handleAddToCard = useCallback(
    (amount: number) => {
      const productData = generateProductDataBySelectedIds(
        product,
        selected,
        amount,
        totalPrice,
      );

      dispatch(addProductToBasket(productData));

      if (isUpsellsProduct) {
        dispatch(setAddedToCardProductIdsAction(product.id));

        TagManager.dataLayer({
          dataLayer: {
            event: WIDGET_UPSELL_ADDED,
            product_id: product.id,
            product_price: +get(productData, 'totalPrice', 0).toFixed(2),
            product_count: amount,
          },
        });
      }
    },
    [totalPrice, selected, isUpsellsProduct],
  );

  const { image, image_thumbnails } = product;

  return (
    <Flex
      ref={isMobile ? wrapperRef : undefined}
      maxH={isMobile || isMobileHeight ? '100dvh' : '80dvh'}
      h="100%"
      overflow={isMobile ? 'auto' : undefined}
      direction={isMobile ? 'column' : 'row'}
    >
      <ProductDetail
        isWithModifiers={!isMobile}
        name={product.display_name ?? product.name}
        price={product.price}
        points={product.points}
        description={product.description}
        imageUrl={get(image_thumbnails, ['medium', 'url'], image)}
        allergens={product.allergens}
      />
      <Flex
        flex={1}
        direction="column"
        borderLeftWidth="1px"
        borderLeftColor="gray.200"
      >
        <Box
          ref={!isMobile ? wrapperRef : undefined}
          flex={1}
          padding={isMobile ? '24px 16px' : '32px 24px'}
          overflow={isMobile ? undefined : 'auto'}
          mb={isMobile ? '80px' : undefined}
        >
          {filteredExtraSections.map((section, i) => (
            <ExtraSection
              key={`${section.id}-${i}`}
              extraSection={section}
              parentId={section.parentExtraSectionId}
              productId={section.parentProductId}
              validate={handleValidation}
            />
          ))}
        </Box>
        <Box
          position={isMobile ? 'fixed' : undefined}
          bottom={0}
          bgColor={isMobile ? 'white' : undefined}
          w={isMobile ? 'full' : undefined}
        >
          <Divider />
          <AddToCard
            price={totalPrice}
            disabled={!isValid}
            onSubmit={handleAddToCard}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
