import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';

const useUserButton = () => {
  const { user } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const handleSignIn = useCallback(() => {
    setSearchParams({ modal: AuthModalParams.SignIn });
  }, []);

  const handleGoToProfile = useCallback(() => {
    navigate(Paths.Profile);
  }, []);

  return {
    actions: {
      handleSignIn,
      handleGoToProfile,
    },
    data: {
      isLoggedIn: Boolean(user),
      name: user?.name || 'Profile',
    },
  };
};

export default useUserButton;
