import { ASAP_TIMESLOT } from '@shared/constants/common';
import { PaymentProvider } from '@shared/constants/payment';
import { useCatchError } from '@shared/hooks/customToast';
import { depositReturn, reorderDepositReturn } from '@shared/utils/deposit';
import { separateDialCodeAndPhoneNumber } from '@shared/utils/format';
import { storage, StorageKeys, storageSession } from '@shared/utils/storage';
import { VenuesApi } from 'api/VenuesApi';
import get from 'lodash.get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import { selectCheckoutVouchers } from 'store/slices/checkoutVouchers';
import { selectProductModal } from 'store/slices/productModal';
import {
  editBasketProductError,
  editReorderProductError,
  removeBasketProductError,
  removeReorderProductError,
} from 'store/slices/productModal';
import {
  getCurrentService,
  selectServices,
  setCurrentService,
} from 'store/slices/services';
import { selectWebsite } from 'store/slices/website';
import { isLandingMode } from 'utils/document';

type InputValues = {
  phone: string;
  note: string;
};

type Errors = {
  phone: string;
  address: string;
  table: string;
  timeSlot: string;
};

const PRODUCT_ERROR_MESSAGE_1 = 'Product price was changed';
const PRODUCT_ERROR_MESSAGE_2 = 'Product is not available';
const productErrors = [PRODUCT_ERROR_MESSAGE_1, PRODUCT_ERROR_MESSAGE_2];

export const useCheckout = () => {
  const catcher = useCatchError();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  /* base data */
  const { basketData, serviceId, venueId, reorderData } =
    useAppSelector(selectProductModal);
  const { websiteData } = useAppSelector(selectWebsite);
  const { usedVoucherData } = useAppSelector(selectCheckoutVouchers);
  const { user } = useAppSelector(selectAuth);
  const { currentService } = useAppSelector(selectServices);

  /* user and order specific data */
  const phone = storage.get(StorageKeys.PHONE);
  const serviceName = storageSession.get(StorageKeys.SERVICE_NAME);
  const serviceType = storageSession.get(StorageKeys.SERVICE_TYPE);
  const currency = storage.get(StorageKeys.CURRENCY) as Currency;
  const isDelivery = serviceName === 'Delivery' || serviceType === '0';
  const isTable = serviceName === 'Table' || serviceType === '1';
  const [checkoutData, setCheckoutData] = useState<Checkout.RequestResponse>();

  /* ui state and inputs */
  const [isLoading, setLoading] = useState<boolean>(true);

  const [defaultDialCode, defaultNumber] = separateDialCodeAndPhoneNumber(
    phone || user?.phone || '',
  );
  const [dialCode, setDialCode] = useState<string>(defaultDialCode || '');
  const [deliverySurcharge, setDeliverySurcharge] = useState<number>(0);
  const [discount, setDiscount] = useState<{
    amount: number;
    data?: Points.AvailablePoints | Discounts.Discount;
  }>({ amount: 0 });

  const [address, setAddress] = useState<Addresses.Address>();
  const [table, setTable] = useState<Venues.Table>();
  const [inputValues, setInputValues] = useState<InputValues>({
    phone: defaultNumber ?? '',
    note: '',
  });
  const [errors, setErrors] = useState<Errors>({
    phone: '',
    address: '',
    table: '',
    timeSlot: '',
  });

  const [tip, setTip] = useState(0);
  const [customTip, setCustomTip] = useState('');
  const [driverTip, setDriverTip] = useState(0);
  const [customDriverTip, setCustomDriverTip] = useState('');
  const [timeZone, setTimeZone] = useState('');

  /* related to timeslots ui start */
  const [
    {
      asap_available: isAsapAvailable,
      time_slots: timeSlots,
      days: dayTimeSlots,
    },
    setScheduleValues,
  ] = useState<Venues.VenueTimeSlots>({
    time_slots: [],
    days: [],
    asap_available: false,
  });

  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

  const isAllowPreorder = checkoutData?.service.config.allow_preorder ?? false;

  useEffect(() => {
    if (isAsapAvailable) {
      setSelectedTimeSlot(ASAP_TIMESLOT);
      setErrors((prevState) => ({ ...prevState, timeSlot: '' }));
    } else {
      setSelectedTimeSlot('');
    }
  }, [isAsapAvailable]);

  const getVenueTimeSlots = useCallback(() => {
    setLoading(true);
    VenuesApi.getVenueTimeSlots(venueId, serviceId)
      .then(setScheduleValues)
      .catch(catcher)
      .finally(() => {
        setLoading(false);
      });
  }, [venueId, serviceId]);
  // get and update available time slots every minute
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (venueId && serviceId && isAllowPreorder) {
      getVenueTimeSlots();
      interval = setInterval(() => {
        getVenueTimeSlots();
      }, 60000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [venueId, serviceId, isAllowPreorder]);

  const handleSelectTimeSlot = useCallback((timeslot: string) => {
    setErrors((prevState) => ({ ...prevState, timeSlot: '' }));
    setSelectedTimeSlot(timeslot);
  }, []);

  useEffect(() => {
    // The page has been cached - Reload
    window.onpageshow = (event) => {
      if (event.persisted && isLoading) {
        setLoading(false);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getCurrentService(serviceId));

    return () => {
      dispatch(setCurrentService(null));
    };
  }, [serviceId]);

  const handleSetTip = useCallback(
    (value: number) => {
      if (tip === value) {
        setTip(0);
      } else {
        setTip(value);
      }
    },
    [tip],
  );

  const handleSetDriverTip = useCallback(
    (value: number) => {
      if (driverTip === value) {
        setDriverTip(0);
      } else {
        setDriverTip(value);
      }
    },
    [driverTip],
  );

  const [productWithDepositCount, totalDeposit] = useMemo(() => {
    const [basketCount, basketTotalDeposit] = depositReturn(basketData);
    const [reorderCount, reorderTotalDeposit] =
      reorderDepositReturn(reorderData);

    return [
      basketCount + reorderCount,
      Number((basketTotalDeposit + reorderTotalDeposit).toFixed(2)),
    ];
  }, [basketData, reorderData]);

  const handlePriceError = useCallback(
    (error: ApiTypes.ResponseError) => {
      if (
        error.data?.errors &&
        Object.keys(error.data.errors).length > 0 &&
        productErrors.includes(error.data.message)
      ) {
        // if some products have errors -> add error message and redirect to menu, so user can update the basket
        const formattedErrors = Object.entries(error.data.errors).map(
          ([positionId, errors]) => ({
            positionId,
            errorMessage: errors.join(', '),
          }),
        );

        // update basket and reorder products with errors
        dispatch(editBasketProductError(formattedErrors));
        dispatch(editReorderProductError(formattedErrors));

        navigate(
          !venueId || !serviceId
            ? isLandingMode
              ? Paths.Order
              : Paths.Home
            : generatePath(Paths.Menu, {
                venueId,
                serviceId,
                department: null,
              }),
          { replace: true },
        );
      }
    },
    [venueId, serviceId, dispatch, isLandingMode, navigate],
  );

  const handleClearPriceErrors = useCallback(() => {
    dispatch(removeBasketProductError());
    dispatch(removeReorderProductError());
  }, [dispatch]);

  return {
    actions: {
      setDialCode,
      setLoading,
      setCheckoutData,
      setDeliverySurcharge,
      setDiscount,
      setAddress,
      setTable,
      setInputValues,
      setErrors,
      handleSelectTimeSlot,
      setTip: handleSetTip,
      setCustomTip,
      setDriverTip: handleSetDriverTip,
      setCustomDriverTip,
      setTimeZone,
      handlePriceError,
      handleClearPriceErrors,
    },
    data: {
      blockedLoading:
        (isAllowPreorder && timeSlots.length < 1 && dayTimeSlots.length < 1) ||
        !checkoutData,
      basketData,
      reorderData,
      serviceId,
      venueId,
      websiteData,
      usedVoucherData,
      user,
      serviceType,
      currency,
      isDelivery,
      isTable,
      dialCode,
      isLoading,
      checkoutData: checkoutData as Checkout.RequestResponse,
      deliverySurcharge,
      discount,
      address,
      table,
      inputValues,
      errors,
      isAsapAvailable,
      selectedTimeSlot,
      isAllowPreorder,
      hasTips: get(currentService, ['config', 'has_tips'], false),
      hasDriverTips:
        get(currentService, ['config', 'has_driver_tips'], false) && isDelivery,
      tip,
      customTip,
      driverTip,
      customDriverTip,
      productWithDepositCount,
      totalDeposit,
      timeZone,
      timeSlots,
      dayTimeSlots,
    },
  };
};

export const usePayments = () => {
  const [paymentMethods, setPaymentMethods] = useState<{
    cash?: Payments.PaymentMethod;
    card?: Payments.PaymentMethod;
  }>({ cash: undefined, card: undefined });
  const [showStripeCardForm, setShowStripeCardForm] = useState<boolean>(false);
  const [venue, setVenue] = useState<Venues.Venue>();

  const paymentProvider =
    venue?.config.new_payment_provider ?? venue?.config.payment_provider;

  const isStripe = paymentProvider === PaymentProvider.Stripe;
  const isViva = paymentProvider === PaymentProvider.Viva;

  const showPayByCard = paymentMethods.card && (isStripe || isViva);
  const showPayByCardFormDirectly =
    showPayByCard && isStripe && !paymentMethods.cash;
  const showCheckoutText = showPayByCard && isViva && !paymentMethods.cash;

  return {
    paymentMethods,
    setPaymentMethods,
    showStripeCardForm,
    setShowStripeCardForm,
    setVenue,
    showPayByCard,
    showPayByCardFormDirectly,
    showCheckoutText,
    isStripe,
    isViva,
  };
};
