import { Flex, Heading, HStack, IconButton } from '@chakra-ui/react';
import { BackIcon } from '@shared/assets/icons';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import UserButton from 'components/UserButton';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from 'routes/paths';

import { BackToOrderOnline } from './BackToOrderOnline';

export interface HeadToolbarProps {
  title: string;
  withBack?: boolean;
  path?: Paths;
  withBackToOrderOnline?: boolean;
}

export const HeadToolbar = ({
  title,
  withBack,
  path,
  withBackToOrderOnline,
}: HeadToolbarProps) => {
  const navigate = useNavigate();
  const onBack = useCallback(() => {
    path ? navigate(path) : navigate(-1);
  }, []);

  return (
    <Flex
      position="sticky"
      top={`${MENU_HEIGHT}px`}
      justify="space-between"
      px="24px"
      h={`${TOOLBAR_HEIGHT}px`}
      bgColor="white"
      zIndex={100}
    >
      <HStack spacing="16px">
        {withBack && (
          <IconButton
            aria-label="back"
            icon={<BackIcon w="24px" h="24px" />}
            isRound
            variant="gray"
            size="sm"
            onClick={onBack}
          />
        )}

        {withBackToOrderOnline && <BackToOrderOnline />}
        <Heading size="md">{title}</Heading>
      </HStack>

      <HStack spacing="16px">
        <UserButton />
      </HStack>
    </Flex>
  );
};
