import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import CoinImage from '@shared/assets/images/coin.svg';
import { Modal } from '@shared/components/Modal';
import { LabelWithPriceText } from '@shared/components/Texts/LabelWithPriceText';
import { useIsMobile } from '@shared/utils/screen';

interface Props {
  code: string;
  email: string;
  balance: string;
  currency: Currency;
  onClose: VoidFunction;
}

export const Confirmation = ({
  code,
  email,
  balance,
  currency,
  onClose,
}: Props) => {
  const isMobile = useIsMobile();
  const content = (
    <Box w={isMobile ? 'full' : '560px'}>
      <Heading size="lg" mb="32px" textAlign="center">
        Voucher confirmation
      </Heading>
      <Center flexDirection="column" borderRadius="xl" bgColor="white" p="24px">
        <Image src={CoinImage} alt="coin-icon" />
        <Heading size="sm" my="16px">
          Your new voucher code:
        </Heading>
        <Heading userSelect="text">{code}</Heading>
        <Box w="100%" mt="24px">
          <Text fontSize="12px" color="gray.400">
            Email address
          </Text>
          <Text>{email}</Text>

          <Divider my="24px" />
          <Heading size="sm" mb="16px">
            Order details
          </Heading>
          <LabelWithPriceText
            label="Gift Voucher"
            currency={currency}
            price={balance}
          />
          <Divider my="24px" />
          <LabelWithPriceText
            largeSize
            currency={currency}
            label="Total"
            price={balance}
          />
        </Box>
      </Center>
      {!isMobile && (
        <Center mt="24px">
          <Button size="xl" onClick={onClose}>
            OK
          </Button>
        </Center>
      )}
    </Box>
  );
  if (isMobile) {
    return (
      <Modal isOpen onClose={onClose} bodyProps={{ pt: '32px' }}>
        {content}
      </Modal>
    );
  }
  return content;
};
