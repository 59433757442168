import { Box, Button, useBoolean } from '@chakra-ui/react';
import { StarFilled } from '@shared/assets/icons';
import { ReviewModal } from 'components/ReviewModal';
import { useParams } from 'react-router-dom';

export const Review = () => {
  const [isOpen, toggle] = useBoolean();
  const { orderId } = useParams<{ orderId: string }>();

  if (!orderId) return <></>;

  return (
    <Box mt="16px">
      <Button leftIcon={<StarFilled />} variant="link" onClick={toggle.on}>
        Review order
      </Button>

      <ReviewModal isOpen={isOpen} onClose={toggle.off} orderId={orderId} />
    </Box>
  );
};
