import { Button, ListItem } from '@chakra-ui/react';

import { RadioIcon } from '../Radio/RadioIcon';

interface Props {
  selected: boolean;

  onClick: VoidFunction;
}

export const SlotItem = ({
  selected,
  onClick,
  children,
}: React.PropsWithChildren<Props>) => (
  <ListItem>
    <Button
      w="full"
      size="md"
      variant="whiteBg"
      bgColor={selected ? 'gray.100' : undefined}
      fontWeight="600"
      justifyContent="space-between"
      paddingX="16px"
      onClick={onClick}
      _hover={{
        bgColor: 'gray.100',
      }}
      _focus={{
        bgColor: 'gray.100',
      }}
    >
      {children}
      <RadioIcon data-checked={selected ? true : null} />
    </Button>
  </ListItem>
);
