import { getAuthTokens, setAuthTokens } from '@shared/utils/auth';

import { BaseApiService } from './BaseApiService';

export class AuthApi {
  public static async registration(body: Authentication.RegistrationBody) {
    const response = await BaseApiService.post<
      Authentication.RegistrationBody,
      Authentication.Response
    >({
      path: 'rest-auth/registration/',
      authRequired: false,
      body,
    });
    return response;
  }

  public static async login(body: Authentication.LoginBody) {
    const response = await BaseApiService.post<
      Authentication.LoginBody,
      Authentication.Response
    >({
      path: 'rest-auth/login/',
      authRequired: false,
      body,
    });
    return response;
  }

  public static async refresh() {
    const { refresh } = getAuthTokens();
    const response = await BaseApiService.post<
      { refresh: string },
      { refresh?: string; access: string }
    >({
      path: 'rest-auth/token/refresh/',
      authRequired: false,
      body: {
        refresh: refresh ?? '',
      },
    });
    setAuthTokens(response.access, response.refresh || refresh || '');
  }

  public static async resetPassword(body: Authentication.ResetPasswordBody) {
    const response =
      await BaseApiService.post<Authentication.ResetPasswordBody>({
        path: 'rest-auth/password/reset/',
        authRequired: false,
        body,
      });

    return response;
  }

  public static async setNewPassword(body: Authentication.SetNewPasswordBody) {
    const response =
      await BaseApiService.post<Authentication.SetNewPasswordBody>({
        path: 'rest-auth/password/reset/confirm/',
        authRequired: false,
        body,
      });

    return response;
  }

  public static async tokenVerify(token: string) {
    const response = await BaseApiService.post<{ token: string }>({
      path: 'rest-auth/token/verify/',
      authRequired: false,
      body: { token },
    });

    return response;
  }

  public static async deleteAccount() {
    const response = await BaseApiService.delete({
      path: 'rest-auth/user/',
    });

    return response;
  }
}
