import { Button, Heading } from '@chakra-ui/react';
import { Input } from '@shared/components/Input';
import { Modal } from '@shared/components/Modal';
import { formatCurrency } from '@shared/utils/format';
import { useState } from 'react';

interface Iprops {
  isOpen: boolean;
  onClose: () => void;
  currency: string;
  handleSelectTip: (percent: number) => void;
  handleSelectCustomTip: (amount: string) => void;
  tipAmount: string;
}

const numberRegex = /^\d+(\.\d+)?$/;

const TipModal = ({
  isOpen,
  onClose,
  currency,
  handleSelectTip,
  handleSelectCustomTip,
  tipAmount,
}: Iprops) => {
  const [error, setError] = useState('');
  const [amount, setAmount] = useState(tipAmount);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!numberRegex.test(e.target.value)) {
      setError('Please enter valid amount');
    } else if (+e.target.value === 0) {
      setError('The tip should be greater than 0');
    } else {
      setError('');
    }
    setAmount(e.target.value);
  };

  const handleSubmit = () => {
    if (!error) {
      handleSelectTip(0);
      handleSelectCustomTip(amount);
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ mobile: 'sm', desktop: 'sm' }}
    >
      <Heading fontSize="16px" lineHeight="18px" mb="20px">
        Enter custom amount
      </Heading>

      <Input
        leftAddon={formatCurrency(currency)}
        value={amount}
        onChange={handleChange}
        error={error}
      />

      <Button size="lg" onClick={handleSubmit}>
        Add a tip
      </Button>
    </Modal>
  );
};

export default TipModal;
