import { Box, Button, Divider, Flex, Heading } from '@chakra-ui/react';
import { BackButton } from '@shared/components/Buttons/BackButton';
import { Input } from '@shared/components/Input';
import { VouchersApi } from 'api/VouchersApi';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { FormEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProfileVoucherRedeemConfirmation } from './Confirmation';

export const ProfileVoucherRedeem = () => {
  const navigate = useNavigate();
  const [confirmationData, setConfirmationData] =
    useState<Vouchers.ClaimResponse | null>(null);
  const [voucherCode, setVoucherCode] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(e.target.value);
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!voucherCode) {
      return setErrorMsg('Field is required');
    }
    if (!errorMsg) {
      VouchersApi.claim(voucherCode)
        .then((res) => {
          setVoucherCode('');
          setConfirmationData(res);
        })
        .catch((err: ApiTypes.ResponseError) => {
          setErrorMsg(err.message ?? String(err));
        });
    }
  };

  const clearError = useCallback(() => {
    setErrorMsg('');
  }, []);

  const onBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <WidgetWrapper withContainer containerWidth="xl">
      {confirmationData ? (
        <ProfileVoucherRedeemConfirmation
          onClose={onBack}
          code={confirmationData.code}
          balance={confirmationData.balance}
          currency={confirmationData.currency}
          createdAt={confirmationData.created_at}
        />
      ) : (
        <>
          <Flex mb={4}>
            <BackButton onClick={onBack} />
            <Heading flex={1} fontSize="24px" textAlign="center">
              Redeem a Gift Voucher
            </Heading>
            <Box width="32px" />
          </Flex>
          <Divider />
          <Box as="form" mt="12px" onSubmit={onSubmit}>
            <Input
              label="Voucher code"
              value={voucherCode}
              onChange={onChange}
              error={errorMsg}
              onFocus={clearError}
            />
            <Button type="submit" size="full">
              Redeem
            </Button>
          </Box>
        </>
      )}
    </WidgetWrapper>
  );
};
