import { createSlice } from '@reduxjs/toolkit';
import { VenuesApi } from 'api/VenuesApi';
import { AppThunk, RootState } from 'store';

type TablesState = {
  isLoading: boolean;
  tables: Venues.Table[] | null;
  error: ApiTypes.ResponseError | null;
};

const initialState: TablesState = {
  isLoading: true,
  tables: null,
  error: null,
};

export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    requestStart: (state) => {
      state.isLoading = true;
      state.tables = null;
      state.error = null;
    },

    getTablesSuccess: (state, action: { payload: Venues.Table[] }) => {
      state.isLoading = false;
      state.tables = action.payload;
    },
    getTablesFailure: (state, action: { payload: ApiTypes.ResponseError }) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

const { requestStart, getTablesSuccess, getTablesFailure } =
  tablesSlice.actions;

export const getTables =
  (venueId: string): AppThunk =>
  async (dispatch) => {
    dispatch(requestStart());
    try {
      const res = await VenuesApi.getTables(venueId);

      dispatch(getTablesSuccess(res));
    } catch (error: unknown) {
      dispatch(getTablesFailure(error as ApiTypes.ResponseError));
    }
  };

export const selectTables = (state: RootState) => state.tables;
export default tablesSlice.reducer;
