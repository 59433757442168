import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@shared/assets/icons';
import { Input } from '@shared/components/Input';
import { isEmpty } from '@shared/utils/string';
import { isValidEmail } from '@shared/utils/validation';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthModalParams } from 'routes/paths';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearAuthData, login, selectAuth } from 'store/slices/auth';

type FormValues = {
  email: string;
  password: string;
};

export const SignInForm = () => {
  const [, setSearchParams] = useSearchParams();
  const { isLoading, error } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState<FormValues>({
    email: '',
    password: '',
  });
  const [formError, setFormError] = useState<FormValues>({
    email: '',
    password: '',
  });

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const resetFieldValidation = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setFormError((prevState) => ({
        ...prevState,
        [e.target.name]: '',
      }));
    },
    [],
  );

  const validate = useCallback(() => {
    const { email, password } = formValues;

    const validation = {
      email: isEmpty(email)
        ? 'Field is required'
        : !isValidEmail(email)
        ? 'Invalid email'
        : '',
      password: isEmpty(password) ? 'Field is required' : '',
    };

    setFormError(validation);

    return Object.values(validation).every((v) => !v);
  }, [formValues]);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate()) {
        const { email, password } = formValues;
        dispatch(login(email, password));
      }
    },
    [formValues],
  );

  const redirectToSignUp = useCallback(() => {
    dispatch(clearAuthData());
    setSearchParams(
      (prev) => ({
        modal: AuthModalParams.SignUp,
        ref: prev.get('ref') ?? '',
        refParams: prev.get('refParams') ?? '',
      }),
      { replace: true },
    );
  }, []);

  const redirectToForgotPassword = useCallback(() => {
    dispatch(clearAuthData());
    setSearchParams(
      (prev) => ({
        modal: AuthModalParams.ForgotPassword,
        ref: prev.get('ref') ?? '',
        refParams: prev.get('refParams') ?? '',
      }),
      { replace: true },
    );
  }, []);

  return (
    <>
      <Heading mb="40px" size="md">
        Sign in
      </Heading>
      <Box as="form" onSubmit={onSubmit} mb="32px" flex={1}>
        <Input
          label="Email address"
          name="email"
          value={formValues.email}
          onChange={onChange}
          error={!!error || formError.email}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Input
          label="Password"
          type="password"
          name="password"
          value={formValues.password}
          onChange={onChange}
          additionalElement={
            <Link
              fontSize="xs"
              color="primary.400"
              onClick={redirectToForgotPassword}
            >
              Forgot password?
            </Link>
          }
          error={!!error || formError.password}
          onFocus={resetFieldValidation}
          isDisabled={isLoading}
        />
        <Button type="submit" size="full" isLoading={isLoading}>
          Sign in
        </Button>
        {error && (
          <Text color="red.500" mt="24px">
            {error.message || String(error)}
          </Text>
        )}
      </Box>
      <Divider />
      <Flex align="center" justify="space-between" my="24px">
        <Text>No account yet?</Text>
        <Button
          variant="secondary"
          rightIcon={<ArrowRightIcon boxSize="24px" />}
          onClick={redirectToSignUp}
        >
          Sign up
        </Button>
      </Flex>
    </>
  );
};
