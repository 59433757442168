import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useIsMobile } from '@shared/utils/screen';

interface Props {
  reverse: boolean;
  title: string;
  content: string;
  imgUrl: string;
}

export const AboutCard = ({ reverse, title, content, imgUrl }: Props) => {
  const isMobile = useIsMobile();
  return (
    <Flex
      direction={isMobile ? 'column-reverse' : reverse ? 'row-reverse' : 'row'}
    >
      <Box
        w={isMobile ? '100%' : '50%'}
        p={isMobile ? 0 : reverse ? '0 0 0 112px' : '0 112px 0 0'}
      >
        <Heading size="md" mb="16px">
          {title}
        </Heading>
        <Text fontSize="sm" whiteSpace="pre-line">
          {content}
        </Text>
      </Box>
      <Box w={isMobile ? '100%' : '50%'} mb={isMobile ? '24px' : 0}>
        <Image
          src={imgUrl}
          alt={title}
          objectFit="contain"
          borderRadius="12px"
          w="100%"
        />
      </Box>
    </Flex>
  );
};
