import { getQueryString } from '@shared/utils/requests';

import { BaseApiService } from './BaseApiService';

export class VouchersApi {
  public static async getConsumerVouchers(params?: Vouchers.VoucherParams) {
    let path = 'vouchers/consumer-vouchers/';
    if (params) {
      path = path + '?' + getQueryString(params);
    }

    return await BaseApiService.get<Vouchers.Voucher[]>({
      path,
    });
  }

  public static async getConsumerVoucher(voucherId: string) {
    return await BaseApiService.get<Vouchers.Voucher>({
      path: `vouchers/${voucherId}/`,
    });
  }

  public static async getConsumerPublicVoucher(voucherId: string) {
    return await BaseApiService.get<Vouchers.PublicVoucher>({
      path: `vouchers/${voucherId}/public/`,
      authRequired: false,
    });
  }

  public static async buy(body: Vouchers.BuyParams) {
    return await BaseApiService.post<Vouchers.BuyParams, Vouchers.BuyResponse>({
      path: `vouchers/buy/`,
      body,
    });
  }

  public static async buyPublic(body: Vouchers.BuyParams) {
    return await BaseApiService.post<Vouchers.BuyParams, Vouchers.BuyResponse>({
      path: `vouchers/buy/`,
      body,
      authRequired: false,
    });
  }

  public static async pay(voucherId: string, body: Vouchers.PayParams) {
    return await BaseApiService.post<
      Vouchers.PayParams,
      Payments.PayRequestResponse
    >({
      path: `vouchers/${voucherId}/pay/`,
      body,
    });
  }

  public static async payPublic(voucherId: string, body: Vouchers.PayParams) {
    return await BaseApiService.post<
      Vouchers.PayParams,
      Payments.PayRequestResponse
    >({
      path: `vouchers/${voucherId}/pay/`,
      body,
      authRequired: false,
    });
  }

  public static async claim(code: string) {
    return await BaseApiService.post<{ code: string }, Vouchers.ClaimResponse>({
      path: `vouchers/code/claim/`,
      body: { code },
    });
  }
}
