import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { DeleteIconAlt } from '@shared/assets/icons';
import { formatCurrency } from '@shared/utils/format';
import { useState } from 'react';

import TipModal from '../TipModal';

interface Iprops {
  title: string;
  tip: number;
  customTip: string;
  handleSelectTip: (percent: number) => void;
  handleSelectCustomTip: (amount: string) => void;
  currency: string;
}

const Tip = ({
  title,
  tip,
  customTip,
  handleSelectTip,
  handleSelectCustomTip,
  currency,
}: Iprops) => {
  const [showModal, setShowModal] = useState(false);

  const setTip = (percent: number) => {
    handleSelectTip(percent);
    handleSelectCustomTip('');
  };

  const handleClear = () => {
    handleSelectTip(0);
    handleSelectCustomTip('');
  };

  return (
    <Box
      padding="20px 12px"
      borderRadius="12px"
      border="1px solid"
      borderColor="gray.200"
    >
      <Flex alignItems="center" columnGap="16px" marginBottom="12px">
        <Text fontSize="14px" lineHeight="18px" fontWeight="600" flexGrow="1">
          {title}
        </Text>
        <IconButton
          aria-label="delete"
          isRound
          onClick={handleClear}
          icon={<DeleteIconAlt />}
          size="sm"
          borderRadius="50%"
          padding="10px"
          width="34px"
          height="34px"
          color="gray.400"
          bgColor="gray.100"
          _hover={{
            opacity: 0.8,
            bgColor: 'gray.100',
          }}
          _focus={{
            opacity: 0.8,
            bgColor: 'gray.100',
          }}
        />
      </Flex>
      <Flex columnGap="8px">
        <Button
          variant={tip === 5 ? 'solid' : 'outline'}
          flex="1"
          minWidth="0"
          onClick={() => setTip(5)}
        >
          5%
        </Button>
        <Button
          variant={tip === 10 ? 'solid' : 'outline'}
          flex="1"
          minWidth="0"
          onClick={() => setTip(10)}
        >
          10%
        </Button>
        <Button
          variant={tip === 15 ? 'solid' : 'outline'}
          flex="1"
          minWidth="0"
          onClick={() => setTip(15)}
        >
          15%
        </Button>
        <Button
          variant={customTip ? 'solid' : 'outline'}
          flex="1"
          minWidth="0"
          onClick={() => setShowModal(true)}
        >
          {customTip ? `${formatCurrency(currency)} ${customTip}` : 'Custom'}
        </Button>
      </Flex>

      {showModal && (
        <TipModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          currency={currency}
          handleSelectTip={handleSelectTip}
          handleSelectCustomTip={handleSelectCustomTip}
          tipAmount={customTip}
        />
      )}
    </Box>
  );
};

export default Tip;
