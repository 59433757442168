import { Modal } from '@shared/components/Modal';
import { parseStringToObject } from '@shared/utils/object';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthModalParams, Paths } from 'routes/paths';
import { useAppSelector } from 'store/hooks';
import { selectAuth } from 'store/slices/auth';
import { selectWebsite } from 'store/slices/website';

import { ForgotPassword } from './ForgotPassword';
import { MarketingOpt } from './MarketingOpt';
import { PhoneVerificationForm } from './PhoneVerificationForm';
import { SignInForm } from './SignInForm';
import { SignUpForm } from './SignUpForm';

export const AuthModal = () => {
  const { user } = useAppSelector(selectAuth);
  const { websiteData } = useAppSelector(selectWebsite);

  const navigate = useNavigate();
  const [search, setSearchParams] = useSearchParams();
  const currentModal = search.get('modal') as AuthModalParams;

  const [showMarketingOpt, setShowMarketingOpt] = useState<boolean>(false);
  const [isMarketingShown, setIsMarketingShown] = useState(false);

  const isOpen = useMemo(() => {
    return Boolean(
      currentModal && Object.values(AuthModalParams).includes(currentModal),
    );
  }, [currentModal]);

  useLayoutEffect(() => {
    if (isOpen && currentModal) {
      if (user) {
        if (
          user.marketing_allowed === null &&
          !isMarketingShown &&
          [(AuthModalParams.SignIn, AuthModalParams.SignUp)].includes(
            currentModal,
          )
        ) {
          setShowMarketingOpt(true);
          setIsMarketingShown(true);
        } else if (
          websiteData?.venue_group.is_consumer_phone_required &&
          ([AuthModalParams.PhoneVerification].includes(currentModal) ||
            [AuthModalParams.SignUp].includes(currentModal) ||
            ([AuthModalParams.SignIn].includes(currentModal) &&
              search.get('backTo') === AuthModalParams.PhoneVerification))
        ) {
          setSearchParams(
            (prev) => ({
              modal: AuthModalParams.PhoneVerification,
              ref: prev.get('ref') ?? '',
              refParams: prev.get('refParams') ?? '',
            }),
            { replace: true },
          );
          setShowMarketingOpt(false);
        } else {
          /**
           * #redirectToPrevRoute
           * @ref - routeName
           * @refParams - params of the route path
           * redirect to previous route path after login
           */
          const refPath = search.get('ref') as keyof typeof Paths;
          const refParams = search.get('refParams');
          const params = refParams
            ? parseStringToObject<PathParams>(refParams) ?? undefined
            : undefined;

          if (refPath) {
            try {
              /**
               * Need to wrap it in a try-catch block
               * @generatePath can throw an error if there are no parameters for the specific route that requires them
               */
              const path = generatePath(Paths[refPath], params);
              navigate(path, {
                replace: true,
              });
            } catch (_e) {
              setSearchParams();
            }
          } else {
            setSearchParams();
          }
          setShowMarketingOpt(false);
        }
      } else {
        if (currentModal === AuthModalParams.PhoneVerification) {
          setSearchParams(
            (prev) => ({
              modal: AuthModalParams.SignIn,
              backTo: AuthModalParams.PhoneVerification,
              ref: prev.get('ref') ?? '',
            }),
            { replace: true },
          );
        }
      }
    }
  }, [user, currentModal]);

  const onClose = useCallback(() => {
    /**
     * Description on #redirectToPrevRoute
     */
    const refPath = search.get('ref') as keyof typeof Paths;
    const refParams = search.get('refParams');
    const params = refParams
      ? parseStringToObject<PathParams>(refParams) ?? undefined
      : undefined;

    if (user && refPath) {
      try {
        const path = generatePath(Paths[refPath], params);
        navigate(path, {
          replace: true,
        });
      } catch (_e) {
        setSearchParams();
      }
    } else {
      setSearchParams();
    }
  }, [user, search, showMarketingOpt]);

  const renderModalContent = useMemo(() => {
    switch (currentModal) {
      case AuthModalParams.SignIn:
        return showMarketingOpt ? <MarketingOpt /> : <SignInForm />;
      case AuthModalParams.SignUp:
        return showMarketingOpt ? <MarketingOpt /> : <SignUpForm />;
      case AuthModalParams.PhoneVerification:
        return <PhoneVerificationForm onClose={onClose} />;
      case AuthModalParams.ForgotPassword:
        return <ForgotPassword />;

      default:
        return null;
    }
  }, [currentModal, showMarketingOpt]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        mobile: 'full',
        desktop: 'md',
      }}
      bodyProps={{
        py: 6,
      }}
    >
      {renderModalContent}
    </Modal>
  );
};
