import { Box, Button, Divider, Flex, Heading } from '@chakra-ui/react';
import { useIsMobile, useIsMobileHeight } from '@shared/utils/screen';
import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { closeCustomise } from 'store/slices/productModal';

import { ExtraSection } from './ExtraSection';

interface Props {
  title: string;
  parentId: string;
  productId: string;
  extraSections: Product.ExtraSection[];
}

export const Customise = ({
  title,
  parentId,
  productId,
  extraSections,
}: Props) => {
  const isMobile = useIsMobile();
  const isMobileHeight = useIsMobileHeight();
  const dispatch = useAppDispatch();

  const [validation, setValidation] = useState<Record<string, boolean>>({});

  const handleValidation = useCallback((id: string, isValid: boolean) => {
    setValidation((prevState) => ({ ...prevState, [id]: isValid }));
  }, []);

  const isValid = useMemo(() => {
    return Object.values(validation).every((v) => v);
  }, [validation]);

  const handleSubmit = useCallback(() => {
    dispatch(closeCustomise());
  }, []);

  const renderExtraSections = useMemo(
    () =>
      [...extraSections]
        .sort((a, b) => a.list_order - b.list_order)
        .map((section, i) => (
          <ExtraSection
            key={`${section.id}-${i}`}
            parentId={parentId}
            productId={productId}
            extraSection={section}
            validate={handleValidation}
          />
        )),
    [extraSections, parentId],
  );

  return (
    <Flex
      direction="column"
      maxH={isMobile || isMobileHeight ? '100dvh' : '80dvh'}
      h="100%"
    >
      <Box
        paddingTop="60px"
        paddingBottom="40px"
        px={isMobile ? '16px' : '24px'}
      >
        <Heading size="md">Customise {title}</Heading>
      </Box>
      <Box flex={1} px={isMobile ? '16px' : '24px'} overflowY="auto">
        {renderExtraSections}
      </Box>
      <Divider />
      <Box p={isMobile ? '12px 16px' : '24px'} onClick={handleSubmit}>
        <Button w="full" size="xl" isDisabled={!isValid}>
          Done
        </Button>
      </Box>
    </Flex>
  );
};
