import { Box, Button, Center, Divider, Heading, Text } from '@chakra-ui/react';
import { Input } from '@shared/components/Input';
import { isEmpty } from '@shared/utils/string';
import { isValidEmail } from '@shared/utils/validation';
import { AuthApi } from 'api/AuthApi';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthModalParams } from 'routes/paths';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

export const ForgotPassword = () => {
  const { websiteData } = useAppSelector(selectWebsite);
  const [email, setEmail] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [, setSearchParams] = useSearchParams();

  const onEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    [],
  );

  const resetFieldValidation = useCallback(() => {
    setErrorMsg('');
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (validate() && websiteData) {
        setLoading(true);
        AuthApi.resetPassword({
          venue_group: websiteData.venue_group.id,
          email: email,
          website: websiteData.id,
        })
          .then(() => {
            setIsSent(true);
          })
          .catch((e: Error) => {
            setErrorMsg(e.message || String(e));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [email, websiteData],
  );

  const validate = useCallback(() => {
    const errorMsg = isEmpty(email)
      ? 'Field is required'
      : !isValidEmail(email)
      ? 'Invalid email'
      : '';

    setErrorMsg(errorMsg);

    return !errorMsg;
  }, [email]);

  const redirectToSignIn = useCallback(() => {
    setSearchParams(
      (prev) => ({
        modal: AuthModalParams.SignIn,
        ref: prev.get('ref') ?? '',
        refParams: prev.get('refParams') ?? '',
      }),
      { replace: true },
    );
  }, []);

  return (
    <>
      <Heading size="md" mb="16px">
        {isSent ? 'Email sent' : 'Forgot password'}
      </Heading>
      <Box mb="40px" flex={isSent ? 1 : 0}>
        <Text textAlign="justify">
          {isSent
            ? 'Check your email and open the link we sent to continue.'
            : `Enter the email associated with your account and we’ll send an email
        with instructions to reset your password.`}
        </Text>
        {websiteData?.show_new_app_screen && (
          <Text mt="16px" textAlign="justify">
            If you have recently downloaded our new app, please note that your
            old app credentials are not applicable. We kindly ask you to re-sign
            up using your email address to open a new account.
          </Text>
        )}
      </Box>

      {!isSent && (
        <Box as="form" mb="32px" flex={1} onSubmit={onSubmit}>
          <Input
            label="Email address"
            name="email"
            value={email}
            onChange={onEmailChange}
            error={errorMsg}
            onFocus={resetFieldValidation}
            isDisabled={isLoading}
          />
          <Button type="submit" size="full" isLoading={isLoading} mt="16px">
            Reset password
          </Button>
        </Box>
      )}
      <Divider />
      <Center>
        <Button
          variant="link"
          my="24px"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={redirectToSignIn}
        >
          Back to Log in
        </Button>
      </Center>
    </>
  );
};
