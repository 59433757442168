import { Box, Center, CircularProgress, Image, List } from '@chakra-ui/react';
import VoucherPlaceholder from '@shared/assets/images/voucher-placeholder.svg';

import VoucherListItem from './VoucherListItem';

interface Props {
  vouchers: Vouchers.Voucher[];
  isLoading: boolean;
  handleSelectVoucher: (voucher: Vouchers.Voucher) => void;
}

const VoucherModalList = ({
  vouchers,
  isLoading,
  handleSelectVoucher,
}: Props) => {
  return (
    <Box paddingBottom="52px">
      {isLoading ? (
        <Center>
          <CircularProgress isIndeterminate color="primary.400" />
        </Center>
      ) : (
        <Box
          padding="10px 0"
          borderTop="1px solid"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          {vouchers.length ? (
            <List>
              {vouchers
                .sort((a, b) => +b.available_balance - +a.available_balance)
                .map((voucher) => (
                  <VoucherListItem
                    key={voucher.id}
                    voucher={voucher}
                    handleSelectVoucher={handleSelectVoucher}
                  />
                ))}
            </List>
          ) : (
            <Box padding="30px 0">
              <Box
                height="150px"
                width="150px"
                borderRadius="50%"
                bgColor="gray.100"
                display="flex"
                alignItems="center"
                margin="0 auto"
              >
                <Image
                  src={VoucherPlaceholder}
                  alt="no vouchers"
                  margin="0 auto"
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default VoucherModalList;
