import { Box, Flex } from '@chakra-ui/react';

export const TestPage = () => {
  return (
    <Box h="100dvh">
      <Flex>
        <Box flex={1} bgColor="red">
          <Box h={200}>1</Box>
        </Box>
        <Box
          flex={1}
          maxH="100%"
          overflow="hidden"
          bgColor="green"
          css={{
            maxHeight: '100%',
          }}
        >
          <Box h={300}>2</Box>
        </Box>
      </Flex>
    </Box>
  );
};
