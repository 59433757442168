import { Button, Flex, Heading } from '@chakra-ui/react';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';

interface Iprops {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

export const ConfirmDeleteAddressModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
}: Iprops) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        mobile: 'sm',
        desktop: 'sm',
      }}
      variant={isMobile ? 'drawer' : 'modal'}
    >
      <Heading as="h2" fontSize="28px" lineHeight="32px" m="0 0 64px">
        Delete address?
      </Heading>

      <Flex columnGap="16px">
        <Button onClick={onClose} variant="secondary" w="full" size="xl">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          w="full"
          size="xl"
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Delete
        </Button>
      </Flex>
    </Modal>
  );
};

export default ConfirmDeleteAddressModal;
