import { Box, Flex, Text } from '@chakra-ui/react';

interface Iprops {
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

export const OrderTitle = ({ title, subtitle, children }: Iprops) => (
  <Box>
    <Flex alignItems="center" marginBottom="16px">
      {children}
      <Box paddingLeft="14px">
        <Text
          as="h1"
          display="block"
          fontSize="24px"
          lineHeight="28px"
          fontWeight="600"
          color="gray.900"
        >
          {title}
        </Text>
      </Box>
    </Flex>
    <Text as="p" fontSize="12px" lineHeight="16px" color="gray.400">
      {subtitle}
    </Text>
  </Box>
);
