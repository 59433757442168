import { Flex, Image, Link, Text } from '@chakra-ui/react';
import ChevronRightIcon from '@shared/assets/icons/chevron-right.svg';
import { formatCurrency } from '@shared/utils/format';
import { Rating } from 'components/Rating';
import { format } from 'date-fns';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Iprops {
  name: string;
  id: string;
  amount: string;
  currency: Currency;
  date: string;
  basePath: string;
  order_number: number;
  status: number;
  review: Orders.Review | null;
}

const OrderHistoryItem = ({
  name,
  id,
  amount,
  currency,
  date,
  basePath,
  order_number,
  status,
  review,
}: Iprops) => {
  return (
    <Link
      as={RouterLink}
      to={`${basePath}/${id}`}
      display="block"
      padding="16px 0 14px"
      borderBottom="1px solid"
      borderColor="gray.200"
      _hover={{
        textDecoration: 'none',
        opacity: 0.8,
      }}
    >
      <Flex>
        <Text
          as="h2"
          flexGrow="1"
          fontSize="14px"
          lineHeight="20px"
          fontWeight="600"
        >{`#${order_number} ${name}`}</Text>
        {status !== 8 && (
          <Text
            as="span"
            display="block"
            fontSize="14px"
            lineHeight="20px"
            paddingRight="18px"
            color="gray.400"
            fontWeight="600"
          >{`${formatCurrency(currency)} ${amount}`}</Text>
        )}
        <Image src={ChevronRightIcon} pr="5px" />
      </Flex>
      <Flex mt="4px" justify="space-between" align="center">
        <Text
          as="span"
          fontSize="12px"
          lineHeight="16px"
          color="gray.400"
          fontWeight="600"
        >
          {format(new Date(date), 'MMMM dd, yyyy HH:mm')}
        </Text>
        {review && <Rating stars={review.stars} />}
      </Flex>
    </Link>
  );
};

export default memo(OrderHistoryItem);
