import { v4 } from 'uuid';

// position_id is used to identify the product in the basket, must be unique, dont use id
export const prepareOrderItems = (
  data: Product.RootObject[],
): Checkout.OrderItem[] =>
  data.map((p) => ({
    position_id: p.position_id ?? v4(),
    product: p.id,
    price: p.price,
    quantity: p.amount ?? p.default_quantity,
    vat_rate: p.vat_rate,
    extra_section_relation_id: p.extra_section_relation_id ?? undefined,
    deposit_return_scheme_amount: p.deposit_return_scheme_amount,
    extras: p.extra_sections.flatMap((es) =>
      es.products.map((sP) => ({
        position_id: sP.position_id ?? v4(),
        product: sP.id,
        extra_section: es.id,
        price: sP.price,
        vat_rate: sP.vat_rate,
        quantity: sP.amount ?? sP.default_quantity,
        deposit_return_scheme_amount: sP.deposit_return_scheme_amount,
        extras: sP.extra_sections.flatMap((tes) =>
          tes.products.map((tp) => ({
            position_id: tp.position_id ?? v4(),
            product: tp.id,
            extra_section: tes.id,
            price: tp.price,
            vat_rate: tp.vat_rate,
            quantity: tp.amount ?? tp.default_quantity,
            deposit_return_scheme_amount: tp.deposit_return_scheme_amount,
            extras: [],
          })),
        ),
      })),
    ),
  }));

export const prepareReorderProductsForCheckout = (
  products: Product.ReorderProduct[],
): Product.ReorderProduct[] =>
  products.map((p) => ({
    ...p,
    position_id: p.position_id ?? v4(),
    id: p.product,
    extras: p.extras.map((ex) => ({
      ...ex,
      position_id: ex.position_id ?? v4(),
      extras: ex.extras.map((eEx) => ({
        ...eEx,
        position_id: eEx.position_id ?? v4(),
      })),
    })),
  }));

export const generateOrderedDepartment = (
  ids: string[],
  departments: Departments.Department[],
): Departments.Department => ({
  id: 'order-again',
  name: 'Order Again',
  products: ids.flatMap((id) =>
    departments.flatMap((d) => d.products.filter((p) => p.plu === id)),
  ),
});

export const hasProductExtraSectionError = (
  product: Product.RootObject,
): boolean => {
  const checkExtraSections = (sections: Product.ExtraSection[]): boolean => {
    return sections.some((section) =>
      section.products.some((subProduct) => {
        // Check for errorMessage in sub-products
        if (subProduct.errorMessage) {
          return true;
        }
        // Recursively check extra sections of sub-products
        return checkExtraSections(subProduct.extra_sections);
      }),
    );
  };

  // Only check extra sections, ignoring the root product's errorMessage
  return checkExtraSections(product.extra_sections);
};

export const hasReorderProductExtraSectionError = (
  product: Product.ReorderProduct | Product.ReorderExtra,
  isRoot = true,
): boolean => {
  // Check extras for ReorderProduct
  if ('extras' in product && Array.isArray(product.extras)) {
    return product.extras.some((extra) =>
      hasReorderProductExtraSectionError(extra, false),
    );
  }

  // For non-root products, check for errorMessage
  if (!isRoot && product.errorMessage) {
    return true;
  }

  return false;
};
