import { createSlice } from '@reduxjs/toolkit';
import { BasketApi } from 'api/BasketApi';
import uniq from 'lodash.uniq';
import { AppThunk, RootState } from 'store';

type Upsells = {
  isLoading: boolean;
  error: Error | null;
  products: Product.RootObject[];
  addedToCardProductIds: string[];
  isUpsellsModalOpen: boolean;
  isUpsellsModalWasOpen: boolean;
};

const initialState: Upsells = {
  isLoading: true,
  error: null,
  products: [],
  addedToCardProductIds: [],
  isUpsellsModalOpen: false,
  isUpsellsModalWasOpen: false,
};

export const basketUpsellsSlice = createSlice({
  name: 'basketUpsells',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },
    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setProducts: (state, action: { payload: Product.RootObject[] }) => {
      state.products = action.payload;
    },
    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.products = [];
      state.addedToCardProductIds = [];
      state.isUpsellsModalOpen = false;
      state.isUpsellsModalWasOpen = false;
    },
    toggleUpsellsModal: (state, action: { payload: boolean }) => {
      state.isUpsellsModalOpen = action.payload;
      // show modal only once
      state.isUpsellsModalWasOpen = true;
    },
    setAddedToCardProductIds: (state, action: { payload: string }) => {
      const newIds = uniq([...state.addedToCardProductIds, action.payload]);
      state.addedToCardProductIds = newIds;
    },
  },
});

const {
  setRequestStart,
  setLoading,
  setError,
  clearState,
  setProducts,
  toggleUpsellsModal,
  setAddedToCardProductIds,
} = basketUpsellsSlice.actions;

export const setUpsellsModalOpen = (show: boolean) => toggleUpsellsModal(show);

export const setAddedToCardProductIdsAction = (productId: string) =>
  setAddedToCardProductIds(productId);

export const getBasketUpsells =
  (serviceId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await BasketApi.getBasketUpsells(serviceId);

      dispatch(setProducts(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearBasketUpsellsData = (): AppThunk => (dispatch) =>
  dispatch(clearState());

export const selectBasketUpsells = (state: RootState) => state.basketUpsells;

export default basketUpsellsSlice.reducer;
