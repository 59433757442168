export const setTitleAndFavicon = (title: string, url: string) => {
  document.title = title || 'BossIT';

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.setAttribute('rel', 'icon');
    link.setAttribute('type', 'image/png');
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.setAttribute('href', url);
};

export const isLandingMode =
  document.getElementById('bossit-app')?.getAttribute('data-mode') ===
  'landing';
