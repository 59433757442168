import { Button, Flex, Text } from '@chakra-ui/react';
import { Modal } from '@shared/components/Modal';
import { useIsMobile } from '@shared/utils/screen';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: () => void;
}

export const ConfirmFlatModal = ({ isOpen, onClose, onConfirm }: Props) => {
  const isMobile = useIsMobile();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      size={{ mobile: 'full', desktop: 'sm' }}
      bodyProps={{ pt: isMobile ? '16px' : '24px' }}
      title="&nbsp;"
    >
      <Text size="sm" mb="24px">
        Are you sure there is no house / flat number for delivery?
      </Text>

      <Flex columnGap="16px">
        <Button onClick={onClose} variant="secondary" w="full" size="md">
          Cancel
        </Button>
        <Button onClick={onConfirm} w="full" size="md">
          Yes
        </Button>
      </Flex>
    </Modal>
  );
};
