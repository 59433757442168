import {
  Button,
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';

import dialCodes from '../../assets/DialCodes.json';
import { VirtualizedList } from '../VirtualizedList';
import { Input, InputProps } from '.';
import { SearchInput } from './SearchInput';

interface Props extends InputProps {
  dialCode: string;
  setDialCode: (dialCode: string) => void;
}

export const PhoneInput = ({
  setDialCode,
  dialCode = '+353',
  formControlProps,
  ...props
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [searchText, setSearchText] = useState<string>('');

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    },
    [],
  );

  const onSearchClear = useCallback(() => {
    setSearchText('');
  }, []);

  const filteredDialCodes = useMemo(() => {
    const text = searchText.toLowerCase();
    if (!text) return dialCodes;
    return dialCodes.filter(
      (item) =>
        item.name.toLowerCase().includes(text) ||
        item.dial_code.includes(text) ||
        item.code.toLowerCase().includes(text),
    );
  }, [searchText]);

  const handleItemClick = useCallback(
    (dialCode: string) => () => {
      setDialCode(dialCode);
      onClose();
    },
    [],
  );

  return (
    <Input
      placeholder="xxx xxx xxx"
      {...props}
      formControlProps={{ zIndex: 1, ...formControlProps }}
      leftAddonProps={{ p: 0 }}
      leftAddon={
        <Popover
          strategy="fixed"
          isLazy
          isOpen={isOpen}
          onClose={onClose}
          placement="bottom-start"
          autoFocus={false}
        >
          <PopoverTrigger>
            <Center h="full" onClick={onOpen} px="8px">
              <Text fontSize="md">{dialCode || '+xxx'}</Text>
            </Center>
          </PopoverTrigger>

          <PopoverContent w={350} h={350}>
            <PopoverHeader p="16px">
              <SearchInput
                value={searchText}
                onChange={onSearchChange}
                onClear={onSearchClear}
                size="md"
                formControlProps={{ mb: '0' }}
              />
            </PopoverHeader>
            <PopoverBody overflow="hidden" flex={1}>
              <VirtualizedList deps={filteredDialCodes}>
                {filteredDialCodes.map((item) => (
                  <Button
                    key={item.code}
                    variant="unstyled"
                    display="flex"
                    justifyContent="space-between"
                    w="full"
                    onClick={handleItemClick(item.dial_code)}
                    whiteSpace="normal"
                  >
                    <Text textAlign="start">
                      {item.flag} {item.name}
                    </Text>
                    <Text>{item.dial_code}</Text>
                  </Button>
                ))}
              </VirtualizedList>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
    />
  );
};
