import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import EmailIcon from '@shared/assets/icons/email.svg';
import MarkerIcon from '@shared/assets/icons/marker.svg';
import PhoneIcon from '@shared/assets/icons/phone.svg';
import { useIsMobile } from '@shared/utils/screen';
import { isEmpty } from '@shared/utils/string';
import { isValidEmail } from '@shared/utils/validation';
import { WebsitesApi } from 'api/WebsitesApi';
import React, { useCallback, useState } from 'react';
import { Paths } from 'routes/paths';

interface Props {
  websiteId?: string;
  location?: string;
  email?: string;
  phone?: string;
}

export const Contact = ({
  websiteId = '',
  location = '',
  email = '',
  phone = '',
}: Props) => {
  const isMobile = useIsMobile();
  const toast = useToast();
  const [contactData, setContactData] = useState<ContactBody.RootObject>({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [formError, setFormError] = useState<
    Record<keyof ContactBody.RootObject, boolean>
  >({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (validate()) {
        WebsitesApi.contact(websiteId, contactData)
          .then(() => {
            setContactData({
              name: '',
              email: '',
              subject: '',
              message: '',
            });
            toast({
              description: 'Your message has been sent.',
              status: 'success',
              isClosable: true,
            });
          })
          .catch((e) => {
            toast({
              description: String(e),
              status: 'error',
              isClosable: true,
            });
          });
      }
    },
    [contactData],
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setContactData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    },
    [],
  );

  const resetFieldValidation = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormError((prevState) => ({
        ...prevState,
        [e.target.name]: false,
      }));
    },
    [],
  );

  const validate = useCallback(() => {
    const { name, email, subject, message } = contactData;
    const validation = {
      name: isEmpty(name),
      email: isEmpty(email) || !isValidEmail(email),
      subject: isEmpty(subject),
      message: isEmpty(message),
    };
    setFormError(validation);
    return Object.values(validation).every((v) => !v);
  }, [contactData]);

  return (
    <Box id={Paths.Contact}>
      <Container
        maxW="1280px"
        px={isMobile ? '16px' : '72px'}
        py={isMobile ? '80px' : '120px'}
      >
        <Heading mb="40px" size="xl">
          Contact
        </Heading>
        <Flex direction={isMobile ? 'column' : 'row'}>
          <VStack
            spacing="24px"
            width={isMobile ? '100%' : '50%'}
            align="flex-start"
            mb={isMobile ? '40px' : 0}
            pr={isMobile ? 0 : '24px'}
          >
            {location && (
              <HStack spacing="20px">
                <Image src={MarkerIcon} alt="Location" />
                <Text fontWeight={600} fontSize="lg">
                  {location}
                </Text>
              </HStack>
            )}
            {email && (
              <HStack spacing="20px">
                <Image src={EmailIcon} alt="Email" />
                <Text fontWeight={600} fontSize="lg">
                  {email}
                </Text>
              </HStack>
            )}
            {phone && (
              <HStack spacing="20px">
                <Image src={PhoneIcon} alt="Phone" />
                <Text fontWeight={600} fontSize="lg">
                  {phone}
                </Text>
              </HStack>
            )}
          </VStack>
          <Box width={isMobile ? '100%' : '50%'}>
            <Text mb="40px" fontSize="sm">
              If you have any inquiries please get in touch via the form below.
            </Text>
            <form onSubmit={onSubmit}>
              <Input
                name="name"
                placeholder="Name"
                size="lg"
                mb="24px"
                isInvalid={formError.name}
                onBlur={resetFieldValidation}
                value={contactData.name}
                onChange={onInputChange}
                fontSize="inputBase"
              />
              <Input
                name="email"
                placeholder="Email"
                size="lg"
                mb="24px"
                isInvalid={formError.email}
                onBlur={resetFieldValidation}
                value={contactData.email}
                onChange={onInputChange}
                fontSize="inputBase"
              />
              <Input
                name="subject"
                placeholder="Subject"
                size="lg"
                mb="24px"
                isInvalid={formError.subject}
                onBlur={resetFieldValidation}
                value={contactData.subject}
                onChange={onInputChange}
                fontSize="inputBase"
              />
              <Textarea
                name="message"
                placeholder="Message"
                resize="none"
                size="lg"
                mb="24px"
                isInvalid={formError.message}
                onBlur={resetFieldValidation}
                value={contactData.message}
                onChange={onInputChange}
                fontSize="inputBase"
              />
              <Button type="submit" size="xl">
                Send
              </Button>
            </form>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
