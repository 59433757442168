import { Box, Input, Select, Stack } from '@chakra-ui/react';
import VoucherCardImage from '@shared/assets/images/voucher-card.svg';
import { formatCurrency } from '@shared/utils/format';

interface Props {
  value: {
    balance: string;
    currency: Currency;
  };
  currencies: { currency: string }[];
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  isDisabled: boolean;
}

export const VoucherCardForm = ({
  value,
  currencies,
  handleInputChange,
  isDisabled,
}: Props) => {
  return (
    <Box
      bgImage={`url(${VoucherCardImage})`}
      bgRepeat="no-repeat"
      bgPosition="center"
      bgSize="contain"
      w="366px"
      minW="300"
      h="189px"
      position="relative"
    >
      <Stack
        direction="row"
        position="absolute"
        bottom="15%"
        right="10%"
        align="end"
      >
        <Input
          name="balance"
          value={value.balance}
          placeholder="10"
          variant="flushed"
          fontSize="40px"
          w="120px"
          color="gray.200"
          focusBorderColor="white"
          onChange={handleInputChange}
          isDisabled={isDisabled}
          height="auto"
          lineHeight="1"
        />
        <Select
          name="currency"
          fontSize="20px"
          color="white"
          w="90px"
          focusBorderColor="white"
          value={value.currency}
          onChange={handleInputChange}
          isDisabled={isDisabled}
          _disabled={{ opacity: 1, cursor: 'not-allowed' }}
        >
          {currencies.map(({ currency }) => (
            <option
              key={currency}
              value={currency}
              style={{ color: '#181C21' }}
            >
              {formatCurrency(currency)}
            </option>
          ))}
        </Select>
      </Stack>
    </Box>
  );
};
