import {
  Box,
  Center,
  CircularProgress,
  Container,
  Flex,
} from '@chakra-ui/react';
import { ExpandableSearch } from '@shared/components/ExpandableSearch';
import { MenuTabs } from '@shared/components/MenuTabs';
import { TOOLBAR_HEIGHT } from '@shared/constants/common';
import { useIsMobile, useIsTablet } from '@shared/utils/screen';
import { Basket, basketWidth } from 'components/Basket';
import { MenuList } from 'components/MenuList';
import { UpsellsModal } from 'components/UpsellsModal';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { AuthModal } from 'pages/AuthModal';
import { MENU_HEIGHT } from 'pages/NavigationMenu';
import { Paths } from 'routes/paths';

import useProductMenu from './hooks';

export const ProductMenu = () => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const {
    actions: { handleSearch },
    data: {
      isUpsellsModalOpen,
      isLoading,
      serviceName,
      menuTabsItems,
      currency,
      positions,
    },
  } = useProductMenu();

  // wait until get currency from backend\localStorage
  if (isLoading || !currency)
    return (
      <Center>
        <CircularProgress isIndeterminate color="primary.400" />
      </Center>
    );

  return (
    <WidgetWrapper
      toolbar={{ title: serviceName || '', withBackToOrderOnline: true }}
    >
      <Container maxW="container.xl" px={isMobile ? 0 : '44px'}>
        <Flex direction={isMobile ? 'column' : 'row'}>
          <Box
            w={
              isMobile
                ? '100%'
                : `calc(100% - ${
                    isTablet ? basketWidth.tablet : basketWidth.desktop
                  } - 16px)`
            }
            mr={isMobile ? 0 : '16px'}
            mb="16px"
            px={isMobile ? '16px' : 0}
          >
            <Flex
              position="sticky"
              top={`calc(${MENU_HEIGHT}px + ${TOOLBAR_HEIGHT}px)`}
              sx={{
                '.hide-scrollbar::-webkit-scrollbar': {
                  display: 'none',
                },
                '.hide-scrollbar': {
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none',
                },
              }}
              zIndex={100}
              py="16px"
              bgColor="gray.50"
            >
              <MenuTabs
                items={menuTabsItems}
                positions={positions}
                menuPath={Paths.Menu}
              />
              <ExpandableSearch onTextChange={handleSearch} />
            </Flex>

            <MenuList />
          </Box>
          <Basket />
        </Flex>
      </Container>

      {isUpsellsModalOpen && <UpsellsModal isOpen={isUpsellsModalOpen} />}

      <AuthModal />
    </WidgetWrapper>
  );
};
