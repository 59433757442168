import { Box } from '@chakra-ui/react';
import { About } from 'components/LandingSections/About';
import { AppStoreSection } from 'components/LandingSections/AppStore';
import { Contact } from 'components/LandingSections/Contact';
import { Footer } from 'components/LandingSections/Footer';
import { Gallery } from 'components/LandingSections/Gallery';
import { Locations } from 'components/LandingSections/Locations';
import { TitleSection } from 'components/LandingSections/TitleSection';
import { VideoSection } from 'components/LandingSections/Video';
import { VourchersSection } from 'components/LandingSections/Vouchers';
import { Paths } from 'routes/paths';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

export const LandingContent = () => {
  const { websiteData } = useAppSelector(selectWebsite);
  return (
    <>
      <TitleSection
        backgroundImages={
          websiteData?.background_image
            ? [websiteData.background_image]
            : websiteData?.background_images.map((img) => img.image)
        }
        title={websiteData?.title}
        subtitle={websiteData?.subtitle}
      />
      <Box id={Paths.About}>
        <About aboutSections={websiteData?.about_sections} />
        {websiteData?.video_url && (
          <VideoSection videoUrl={websiteData.video_url} />
        )}
      </Box>
      <Locations locations={websiteData?.locations} />
      {websiteData?.gallery && <Gallery images={websiteData.gallery} />}
      {websiteData?.show_voucher && <VourchersSection />}
      <Contact
        websiteId={websiteData?.id}
        location={websiteData?.locations[0]?.address}
        email={websiteData?.email}
        phone={websiteData?.phone}
      />
      {(websiteData?.app_store_link || websiteData?.play_store_link) && (
        <AppStoreSection
          iosLink={websiteData?.app_store_link}
          androidLink={websiteData?.play_store_link}
        />
      )}
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
        allergensPdfUrl={websiteData?.allergens_pdf ?? undefined}
      />
    </>
  );
};
