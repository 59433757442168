import { Box, Button, Image, Text, useToast } from '@chakra-ui/react';
import WalletIcon from '@shared/assets/icons/wallet.svg';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearCheckoutCouponsState,
  getCheckoutCoupons,
  selectCheckoutCoupons,
  setIsCouponModalOpen,
} from 'store/slices/checkoutCoupons';
import { selectWebsite } from 'store/slices/website';

import CouponModal from './CouponModal';

interface Iprops {
  isApplied: boolean;
  onCouponApply: (coupon: string) => void;
  onCouponClear: () => void;
  isVoucherUsed: boolean;
  isPointsUsed: boolean;
  appliedCode?: string;
}

export const Coupon = ({
  isApplied,
  onCouponApply,
  onCouponClear,
  isVoucherUsed,
  isPointsUsed,
  appliedCode,
}: Iprops) => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { selectedCouponCode, coupons } = useAppSelector(selectCheckoutCoupons);
  const { websiteData } = useAppSelector(selectWebsite);

  useEffect(() => {
    dispatch(getCheckoutCoupons());
    return () => {
      dispatch(clearCheckoutCouponsState());
    };
  }, []);

  const handleUseCoupon = () => {
    if (isPointsUsed) {
      toast.closeAll();
      toast({
        description: `You can not use coupon while using ${
          websiteData?.venue_group?.points_config?.name || 'points'
        }`,
        position: 'bottom-left',
        isClosable: true,
        status: 'info',
      });
    } else if (isVoucherUsed) {
      toast.closeAll();
      toast({
        description: 'You can not use coupon with voucher applied',
        position: 'bottom-left',
        isClosable: true,
        status: 'info',
      });
    } else {
      dispatch(setIsCouponModalOpen(true));
    }
  };

  return (
    <Box w="100%">
      <Button
        w="full"
        variant="outline"
        leftIcon={<Image src={WalletIcon} />}
        borderRadius="8px"
        borderColor="gray.300"
        color="gray.400"
        onClick={handleUseCoupon}
      >
        <Text noOfLines={1} color="inherit">
          {appliedCode || 'Use Coupon'}
        </Text>
        {Boolean(coupons.length) && !selectedCouponCode && (
          <Box
            marginLeft="8px"
            height="32px"
            width="32px"
            lineHeight="32px"
            backgroundColor="gray.500"
            color="#fff"
            borderRadius="50%"
            as="span"
            textAlign="center"
          >
            {coupons.length}
          </Box>
        )}
      </Button>

      <CouponModal
        isApplied={isApplied}
        onCouponApply={onCouponApply}
        onCouponClear={onCouponClear}
      />
    </Box>
  );
};
