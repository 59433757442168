import { List } from '@chakra-ui/react';
import { memo } from 'react';

import { ListItem } from './ListItem';

interface Iprops {
  items: Array<
    Orders.OrderItemDetails | Product.RootObject | Product.ReorderProduct
  >;
  formattedCurrency: string;
}

const OrderDetailsList = ({ items, formattedCurrency }: Iprops) => (
  <List fontSize="14px" lineHeight="20px">
    {items.map((item, index, arr) => (
      <ListItem
        key={`${item.id}-${index}-${arr.length}`}
        item={item}
        currency={formattedCurrency}
      />
    ))}
  </List>
);

export default memo(OrderDetailsList);
