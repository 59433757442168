import {
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Footer } from 'components/LandingSections/Footer';
import { WidgetWrapper } from 'components/WidgetWrapper';
import { useAppSelector } from 'store/hooks';
import { selectWebsite } from 'store/slices/website';

export const CookiePolicy = () => {
  const { websiteData } = useAppSelector(selectWebsite);

  const name = websiteData?.name;
  const domain = websiteData?.domain;
  const email = websiteData?.email;

  return (
    <>
      <WidgetWrapper withContainer containerWidth="xl">
        <Text mb="16px">
          This Cookie Policy explains how {name} and its affiliates, including
          without limitation {name} Ltd Company (collectively «{name}», «we»,
          «us», and «ours»), use cookies and similar technologies to recognise
          you when you visit our websites, including without limitation
          {domain} and any successor URLs, mobile or localised versions and
          related domains / sub-domains («Websites») and/or our mobile
          application («App») and / or our communication platform («Platform»).
          It explains what these technologies are and why we use them, as well
          as your rights to control our use of them.
        </Text>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          What are cookies?
        </Heading>
        <Text mb="16px">
          Cookies are small data files that are placed on your computer or
          mobile device when you visit a website, or mobile app or use an online
          platform. Cookies are widely used by online service providers to
          facilitate and help to make the interaction between users and
          websites, mobile apps and online platforms faster and easier, as well
          as to provide reporting information.
        </Text>
        <Text mb="16px">
          Cookies set by the website and/or mobile app and/or platform owner (in
          this case, {name} ) are called «first-party cookies». Cookies set by
          parties, other than the website and/or mobile app and/or platform
          owner, are called «third-party cookies». Third-party cookies enable
          third-party features or functionality to be provided on or through the
          website and/or mobile app and/or platform (e. g. like advertising,
          interactive content and analytics).
        </Text>
        <Text mb="16px">
          The parties that set these third party cookies can recognise your
          computer or device both when it visits the website and/or mobile app
          and/or platform in question and also when it visits certain other
          websites and/or mobile apps and/or platforms.
        </Text>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Why do we use cookies?
        </Heading>
        <Text mb="16px">
          We use first-party and third-party cookies for several reasons. Some
          cookies are required for technical reasons in order for the {name}
          Website and/or App and/or platform to operate, and we refer to these
          as «essential» or «strictly necessary» cookies. Other cookies also
          enable us to track and target the interests of our users to enhance
          the experience on our Websites and/or App and/or platform.
        </Text>
        <Text mb="16px">
          Third parties serve cookies through our Websites and/or App and/or
          platform for analytics and other purposes. This is described in more
          detail below.
        </Text>
        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Our Websites
        </Heading>

        <Table mb="24px">
          <Thead>
            <Tr>
              <Th>Types of cookies</Th>
              <Th>Who serves this cookies</Th>
              <Th>How to refuse</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Essential website cookies: These cookies are strictly necessary
                to provide you with services available through our Websites and
                to use some of its features, such as access to secure areas.
              </Td>
              <Td>{name}</Td>
              <Td>
                <Text mb="16px">
                  Because these cookies are strictly necessary to deliver the
                  Websites to you, you cannot refuse them.
                </Text>
                <Text>
                  You can block or delete them by changing your browser settings
                  however, as described below under the heading «How can I
                  control cookies?».
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Performance and functionality cookies: These cookies are used to
                enhance the performance and functionality of our Websites but
                are non-essential to their use. However, without these cookies,
                certain functionality like videos may become unavailable.
              </Td>
              <Td>{name}</Td>
              <Td>
                To refuse these cookies, please follow the instructions below
                under the heading «How can I control cookies?»
              </Td>
            </Tr>
            <Tr>
              <Td>
                Analytics and customisation cookies: These cookies collect
                information that is used either in aggregate form to help us
                understand how our Websites are being used or how effective are
                marketing campaigns, or to help us customise our Websites for
                you.
              </Td>
              <Td>Google Analytics</Td>
              <Td>
                <Text mb="16px">
                  To refuse these cookies, please follow the instructions below
                  under the heading «How can I control cookies?»
                </Text>
                <Text mb="16px">
                  Alternatively, please click on the relevant opt-out link
                  below:
                </Text>
                <Text>Google Analytics</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Advertising cookies: These cookies are used to make advertising
                messages more relevant to you. They perform functions like
                preventing the same ad from continuously reappearing, ensuring
                that ads are properly displayed for advertisers, and in some
                cases selecting advertisements that are based on your interests.
              </Td>
              <Td>
                <Text mb="16px">Facebook Custom Audience</Text>
                <Text mb="16px">Google AdWords Conversion</Text>
                <Text mb="16px">Google Dynamic</Text>
              </Td>
              <Td>
                <Text mb="16px">
                  To refuse these cookies, please follow the instructions below
                  under the heading «How can I control cookies?»
                </Text>
                <Text mb="16px">
                  Alternatively, please click on the relevant opt-out link
                  below:
                </Text>
                <Text mb="16px"> - Facebook Custom</Text>
                <Text mb="16px">
                  Audience: Instructions on how to opt out are provided here
                </Text>
                <Text mb="16px">- Google AdWords Conversion </Text>
                <Text>- Google Dynamic Remarketing: same as above.</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Social networking cookies: These cookies are used to enable you
                to share pages and content that you find interesting on our
                Websites through third party social networking and other
                websites. These cookies may also be used for advertising
                purposes too.
              </Td>
              <Td>Facebook Connect</Td>
              <Td>
                <Text mb="16px">
                  To refuse these cookies, please follow the instructions below
                  under the heading «How can I control cookies?»
                </Text>
                <Text>
                  Alternatively, please click on the relevant opt-out link
                  below: - Facebook Connect
                </Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Advertising cookies: These cookies are used to make advertising
                messages more relevant to you. They perform functions like
                preventing the same ad from continuously re-appearing, ensuring
                that ads are properly displayed for advertisers, and in some
                cases selecting advertisements that are based on your interests.
              </Td>
              <Td>
                <Text mb="16px">Facebook Custom Audience</Text>
                <Text mb="16px">Google Dynamic Remarketing</Text>
              </Td>
              <Td>
                <Text mb="16px">
                  To refuse these cookies, please follow the instructions below
                  under the heading «How can I control cookies?»
                </Text>
                <Text mb="16px">
                  Alternatively, please click on the relevant opt-out link
                  below:
                </Text>
                <Text mb="16px">- Facebook Custom Audience: </Text>
                <Text mb="16px">
                  Instructions on how to opt out are provided here
                </Text>
                <Text>- Google Dynamic Remarketing</Text>
              </Td>
            </Tr>
            <Tr>
              <Td>
                Social networking cookies: These cookies are used to enable you
                to share content that you find interesting on our App through
                third party social networking. These cookies may also be used
                for advertising purposes too.
              </Td>
              <Td>
                <Text mb="16px">Facebook Connect</Text>
                <Text mb="16px">Facebook Social Graph</Text>
              </Td>
              <Td>
                <Text mb="16px">
                  To refuse these cookies, please follow the instructions below
                  under the heading «How can I control cookies?»
                </Text>
                <Text mb="16px">
                  Alternatively, please click on the relevant opt-out link
                  below:
                </Text>
                <Text mb="16px">- Facebook Connect</Text>
                <Text mb="16px">- Facebook Social </Text>
                <Text mb="16px">
                  Graph: You can disable and manage through use of personal
                  privacy settings when logged into a Facebook account
                </Text>
              </Td>
            </Tr>
          </Tbody>
        </Table>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          What about other tracking technologies, like web beacons?
        </Heading>
        <Text mb="16px">
          Cookies are not the only way to recognise or track visitors to a
          website and/or mobile app and/or platform. {name} may use other,
          similar technologies from time to time, like web beacons (sometimes
          called «tracking pixels» or «clear gifs»). These are tiny graphics
          files that contain a unique identifier which enable us to recognise
          when someone has visited the {name} Website and/or App and/or
          platform.
        </Text>

        <Text mb="16px">
          In many instances, these technologies are reliant on cookies to
          function properly, and so declining cookies will impair their
          functioning.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Do you serve targeted advertising?
        </Heading>
        <Text mb="16px">
          Third parties may serve cookies on your computer or mobile device to
          advertise through our Websites and/or App and/or platform. These
          companies may use information about your visits to this and other
          websites in order to provide relevant advertisements about goods and
          services that you may be interested in, including promotions that are
          running and events that are on.
        </Text>

        <Text mb="16px">
          They may also employ technology that is used to measure the
          effectiveness of advertisements. This can be accomplished by third
          parties using cookies or web beacons to collect information about your
          visits to this and other websites and/or mobile apps and/or platforms
          in order to provide relevant advertisements about goods and services
          of potential interest to you. The information collected through this
          process does not enable us or them to identify your name, contact
          details or other personally identifying details unless you choose to
          provide these.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          How do I control cookies?
        </Heading>
        <Text mb="16px">
          You have the right to decide whether to accept or reject cookies. You
          can exercise your cookie preferences by clicking on the appropriate
          opt-out links provided in the cookie table as outlined previously.
        </Text>
        <Text mb="16px">
          You can set or amend your web browser controls to accept or refuse
          cookies. If you choose to reject cookies, you may still use the
          {name} Website and/or App and/or platform though your access to some
          functionality and areas may be restricted. As the means by which you
          can refuse cookies through your web browser controls vary from
          browser-to-browser, you should visit your browser&#8217;s help menu
          for more information.
        </Text>
        <Text mb="16px">
          In addition, most advertising networks offer you a way to opt out of
          targeted advertising.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px"></Heading>
        <Text mb="16px">
          {name} may update this Cookie Policy from time to time in order to
          reflect, for example, changes to the cookies we use or for other
          operational, legal or regulatory reasons.
        </Text>
        <Text mb="16px">
          Therefore please re-visit this Cookie Policy regularly to stay
          informed about our use of cookies and related technologies.
        </Text>
        <Text mb="16px">
          The date at the top of this Cookie Policy indicates when it was last
          updated.
        </Text>

        <Heading as="h2" fontSize="20px" lineHeight="32px" mb="16px">
          Where can I get further information?
        </Heading>
        <Text>
          If you have any questions about our use of cookies or other
          technologies, please email us at {email}
        </Text>
      </WidgetWrapper>
      <Footer
        facebook={websiteData?.facebook}
        instagram={websiteData?.instagram}
        twitter={websiteData?.twitter}
        name={websiteData?.name}
      />
    </>
  );
};
