import { createSlice } from '@reduxjs/toolkit';
import { AddressApi } from 'api/AdressesApi';
import { AppThunk, RootState } from 'store';

type ProfileAddressesState = {
  isLoading: boolean;
  error: Error | null;
  addresses: Addresses.Address[];
  selectedAddress: Addresses.Address | null;
  message: string | null;
  isConfirmModalOpen: boolean;
};

const initialState: ProfileAddressesState = {
  isLoading: false,
  error: null,
  addresses: [],
  selectedAddress: null,
  message: null,
  isConfirmModalOpen: false,
};

export const profileAddressesSlice = createSlice({
  name: 'profileAddresses',
  initialState,
  reducers: {
    setLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },

    setError: (state, action: { payload: Error | null }) => {
      state.error = action.payload;
    },

    setRequestStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.message = null;
    },

    setAddresses: (state, action: { payload: Addresses.Address[] }) => {
      state.addresses = action.payload;
    },

    setSelectedAddress: (state, action: { payload: Addresses.Address }) => {
      state.selectedAddress = action.payload;
    },

    setMessage: (state, action: { payload: string }) => {
      state.message = action.payload;
    },

    setConfirmModalOpen: (state, action: { payload: boolean }) => {
      state.isConfirmModalOpen = action.payload;
    },

    clearState: (state) => {
      state.isLoading = true;
      state.error = null;
      state.addresses = [];
      state.selectedAddress = null;
      state.message = null;
    },
  },
});

const {
  setRequestStart,
  setAddresses,
  setSelectedAddress,
  setLoading,
  setError,
  clearState,
  setMessage,
  setConfirmModalOpen,
} = profileAddressesSlice.actions;

export const getAddresses =
  (params: Addresses.AddressParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      const res = await AddressApi.getAddresses(params);
      dispatch(setAddresses(res.results));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAddress =
  (addressId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await AddressApi.getAddress(addressId);
      dispatch(setSelectedAddress(res));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateAddress =
  (addressId: string, body: Addresses.AddressPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setRequestStart());

      await AddressApi.updateAddress(addressId, body);

      const res = await AddressApi.getAddress(addressId);
      dispatch(setSelectedAddress(res));
      dispatch(setMessage('Address updated'));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteAddress =
  (addressId: string, params: Addresses.AddressParams): AppThunk =>
  async (dispatch) => {
    dispatch(setRequestStart());
    try {
      await AddressApi.deleteAddress(addressId);

      const res = await AddressApi.getAddresses(params);
      dispatch(setAddresses(res.results));
      dispatch(setConfirmModalOpen(false));
    } catch (error: unknown) {
      dispatch(setError(error as Error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clearData = (): AppThunk => (dispatch) => {
  dispatch(clearState());
};

export const stopAddressLoading = (): AppThunk => (dispatch) => {
  dispatch(setLoading(false));
};

export const toggleConfirmModal =
  (status: boolean): AppThunk =>
  (dispatch) =>
    dispatch(setConfirmModalOpen(status));

export const selectProfileAddresses = (state: RootState) =>
  state.profileAddresses;

export default profileAddressesSlice.reducer;
