import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearProfileVouchersState,
  getSelectedVoucher,
  selectProfileVouchers,
} from 'store/slices/profileVouchers';
import { selectWebsite } from 'store/slices/website';

const useProfileVoucherDetails = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { voucherId } = useParams<{ voucherId: string }>();

  const { selectedVoucher, error, isLoading } = useAppSelector(
    selectProfileVouchers,
  );
  const { websiteData } = useAppSelector(selectWebsite);

  useEffect(() => {
    if (voucherId) {
      dispatch(getSelectedVoucher(voucherId));
    }
  }, [voucherId]);

  useEffect(() => {
    if (error) {
      toast({
        description: error.message || String(error),
        status: 'error',
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(clearProfileVouchersState());
    };
  }, []);

  return {
    data: {
      isLoading,
      selectedVoucher,
      isShowVoucher: websiteData?.show_voucher || false,
    },
  };
};

export default useProfileVoucherDetails;
